/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import MapContainer from '../../../components/map/direction.js';
import About from './about.js';
import Review from './review.js';



/*Assets*/
import Img from '../../../assets/images/online/login-img.svg';

/*Services*/



class Collections extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }




  render() {


    return (
        <div className="container-fluid p-0">
          <section className="e-section-about pt-4 mt-2 mb-5 pt-5">
            <About/>
          </section>
          <section className="e-section-review pt-4 mt-2 mb-5 pt-5">
            <Review/>
          </section>
        </div>


    );
  }
}

export default Collections;

