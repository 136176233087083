
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
/*Components*/



/*Assets*/

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/apiCall/post';

class ProductAccordion extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      productSelected: null,
      id: 1,
      categorySelected: null,
      subcategorySelected: null,
      mobDDownSwap: false
    }
  }

  async componentWillMount() {
    await this.setState({productSelected: this.props.id, subcategorySelected: this.props.subcategory, categorySelected: this.props.category, name: this.props.name});
    this.getCategory();
  }

  findCat(id, data){
    let cat;
    data.forEach(item=>{
      if(item.product_data && item.product_data.length){
        item.product_data.forEach(product=>{
          if(product.id===Number(id)) cat = item.category_id;
        });
      }
    });
    if(cat) this.props.updateAdCat(cat)
  }

  getCategory(){

    this.setState({dataLoading: true});
    var url = devconfig.userV1+"/subcategory/list";
    var data = JSON.stringify({
      "category_id" : Number(this.state.categorySelected)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data: response.data, dataLoading: false});
        if(response.data&&response.data.length) this.findCat(this.props.id, response.data);
      
      }
    }.bind(this))
  }

  productFilter(tempId, tempType, itemName, category_id){
    this.setState({productSelected: tempId, name: itemName});
    this.props.filterChange(tempId);
    this.props.updateAdCat(category_id)
    console.log(category_id);
    if(tempType == 2){
      this.mobDropDownSwap();
    }
  }

  mobDropDownSwap(){
    this.setState({mobDDownSwap: !this.state.mobDDownSwap});
  }

  render() {
    return (
      <div className="e-product-accordion">

        <div className="d-block d-lg-none">
          <div className="e-selected-prod-wrap d-flex justify-content-between">
            <h6>Selected Product: <span>{this.state.name}</span></h6>

            <div className="e-filter-select" onClick={this.mobDropDownSwap.bind(this)}>Select Product</div>
          </div>
          {
            this.state.mobDDownSwap ?
              <div className="e-product-dropdown">
                <div className="accordion" id="accordionExample">
                  {
                    this.state.data && this.state.data.map( (item, index) => {
                      if(item.product_data.length){
                        return(
                          <div className={item.product_data.length == index+1 ? "card pb-4" : "card"}>
                            <div className="card-header " id="headingOne">
                              <h5 className="mb-0">
                                <button className={this.state.subcategorySelected == item.id ? "btn btn-link e-faq text-uppercase" : "btn btn-link collapsed e-faq text-uppercase" }  type="button" data-toggle="collapse" data-target={"#e-prod-"+item.id} aria-expanded={this.state.subcategorySelected == item.id ? "true" : "false" } aria-controls={"e-prod-"+item.id}>
                                  {item.sub_category}
                                </button>
                              </h5>
                            </div>
                            <div id={"e-prod-"+item.id} className={this.state.subcategorySelected == item.id ? "collapse show" : "collapse" }aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div className="card-body pl-5 ml-2">
                                {
                                  item.product_data.map( (item1, index) => {
                                    return(
                                      <p onClick={()=>this.productFilter(item1.id, 2, item1.product, item.category_id)}><a className={this.state.productSelected == item1.id ? "text-uppercase e-active" : "text-uppercase"}>{item1.product}</a></p>
                                    );
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        );
                      }
                      else{
                        return(
                          null
                        )
                      }
                    })
                  }
                </div>
              </div>
            :
              null
          }
        </div>
        


        <div className="accordion d-none d-sm-none d-md-none d-lg-block" id="accordionExample">

          {
            this.state.data && this.state.data.map( (item, index) => {
              if(item.product_data.length){
                return(
                  <div className={item.product_data.length == index+1 ? "card pb-4" : "card"}>
                    <div className="card-header " id="headingOne">
                      <h5 className="mb-0">
                        <button className={this.state.subcategorySelected == item.id ? "btn btn-link e-faq text-uppercase" : "btn btn-link collapsed e-faq text-uppercase" }  type="button" data-toggle="collapse" data-target={"#e-prod-"+item.id} aria-expanded={this.state.subcategorySelected == item.id ? "true" : "false" } aria-controls={"e-prod-"+item.id}>
                          {item.sub_category}
                        </button>
                      </h5>
                    </div>
                    <div id={"e-prod-"+item.id} className={this.state.subcategorySelected == item.id ? "collapse show" : "collapse" }aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body pl-5 ml-2">
                        {
                          item.product_data.map( (item1, index) => {
                            return(
                              <p onClick={()=>this.productFilter(item1.id, 1, item1.product, item.category_id)}><a className={this.state.productSelected == item1.id ? "text-uppercase e-active" : "text-uppercase"}>{item1.product}</a></p>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                );
              }
              else{
                return(
                  null
                )
              }
            })
          }
        </div>
      </div>

    );
  }
}

export default ProductAccordion;
