/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';


/*Components*/

import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/TextArea.js';
import PrimaryButton from '../../components/buttons/primaryButton';
import ButtonLoader from '../common/buttonLoader';

/*Assets*/

/*Services*/
import devconfig from '../../config/config.js';
import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';
import APIPost from '../../services/apiCall/post';


class BestOfferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',

    }
  }

  componentDidMount() {

  }

  async sendMessage() {
    this.setState({ error: null, apiLoading: true });
    let url = devconfig.localUser + "/product/get-best-offer";
    let data = JSON.stringify({
      "variant_id": this.props.variant_id,
      "choice_id": this.props.choice_id,
      "product_id": this.props.id,
      "name": this.state.name,
      "email": this.state.email,
      "mobile": this.state.phone,
      "message": this.state.message
    });
    const EmailValid = EmailValidation(this.state.email);
    const mobileValid = PhoneValidation(this.state.phone);
    if(EmailValid && mobileValid){
      console.log(data)
      APIPost(url, data).then(function (response) {
        if (response.status === "ok") {
          this.setState({ apiLoading: false });
          this.setState({success: true});
          setTimeout(() => {
            $("#offerModal").modal("hide");
            this.setState({success: false, name: "", phone: "", email: "", message: ""});
          }, 1000);
        }
        else {
          this.setState({ error: response.message, apiLoading: false });
        }
      }.bind(this));
    }
    else if(mobileValid){
      this.setState({ error: "Enter a valid e-mail ID", apiLoading: false });
    }
    else if(EmailValid){
      this.setState({ error: "Enter a valid mobile number", apiLoading: false });
    }
    else{
      this.setState({ error: "Enter a valid e-mail ID and mobile number", apiLoading: false });
    }
  }

  render() {

    return (
      <div className="modal fade e-best-ofer-modal" id="offerModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content e-ofer">
            <div className="modal-header pb-0">
              <h6 className="e-modal-header text-uppercase mb-0">get the best offer</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {
              this.state.success?
              <>
              <div className="row ml-1 py-4">
                <div className="col-lg-12 d-flex align-items-center justify-content-center flex-column">
                  
                <svg className="mb-3" style={{width: "30px"}} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 507.2 507.2" >
                <circle style={{fill:"#32BA7C"}} cx="253.6" cy="253.6" r="253.6"/>
                <path style={{fill:"#0AA06E"}} d="M188.8,368l130.4,130.4c108-28.8,188-127.2,188-244.8c0-2.4,0-4.8,0-7.2L404.8,152L188.8,368z"/>
                <g>
                  <path style={{fill:"#FFFFFF"}} d="M260,310.4c11.2,11.2,11.2,30.4,0,41.6l-23.2,23.2c-11.2,11.2-30.4,11.2-41.6,0L93.6,272.8
                    c-11.2-11.2-11.2-30.4,0-41.6l23.2-23.2c11.2-11.2,30.4-11.2,41.6,0L260,310.4z"/>
                  <path style={{fill:"#FFFFFF"}} d="M348.8,133.6c11.2-11.2,30.4-11.2,41.6,0l23.2,23.2c11.2,11.2,11.2,30.4,0,41.6l-176,175.2
                    c-11.2,11.2-30.4,11.2-41.6,0l-23.2-23.2c-11.2-11.2-11.2-30.4,0-41.6L348.8,133.6z"/>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                </svg>

                  <h6>Response Noted</h6>
                </div>
              </div>
              </>
              :
              <form className="">
              <div className="row  ml-1">
                <div className="col-lg-6 col-md-6 pr-0">
                  <label>Name</label>
                  <div>
                    <Input className="e-phone" type="text" valueChange={(tempVal) => this.setState({ name: tempVal })} autoComplete="nope" />

                  </div>
                </div>

                <div className="col-lg-6 col-md-6 pr-0">
                  <label>Phone</label>
                  <div>
                    <Input className="e-phone" type="number" valueChange={(tempVal) => this.setState({ phone: tempVal })} autoComplete="nope" />
                  </div>
                </div>
              </div>
              <div className="row ml-3 pl-1">
                <div className="col-lg-12 pl-0 pt-3">
                  <label>Email Address</label>
                  <div>
                    <Input className="" type="text" valueChange={(tempVal) => this.setState({ email: tempVal })} autoComplete="nope" />
                  </div>
                </div>
              </div>
              <div className="row  ml-3 pl-1">
                <div className="col-lg-12 pl-0 pt-3">
                  <label>Message</label>
                  <div>
                    <Textarea className="" rows="5" cols="57" valueChange={(tempVal) => this.setState({ message: tempVal })} />
                  </div>
                </div>
              </div>
              {
                this.state.error ?
                  <div className="row ml-2 pt-3">
                    <div className="col-lg-12">
                      <div id="e-error" className="error pt-1">{this.state.error}</div>
                    </div>
                  </div>
                  :
                  null
              }
              <div className="row ml-2 pt-3">
                <div className="col-lg-6">
                  {
                    this.state.name.length && this.state.phone.length && this.state.email.length && this.state.message.length ?
                      <>
                        {
                          this.state.apiLoading ?
                            <ButtonLoader />
                            :
                            <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn" name="Send Message" handleClick={() => this.sendMessage()} />
                        }
                      </>
                      :
                      <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn e-inactive" name="Send Message" disabled={true} />
                  }
                </div>
              </div>
            </form>
            }


            <div className="modal-footer">

            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default BestOfferModal;
