
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

/*Assets*/
import LocalSecondaryHeading from '../../components/navigations/secondaryHeading-local';
import AdsHeroBanner from '../../components/ads/banners/hero-banner.js';
import AdsFourColumn_One from '../../components/ads/list/four-column-style-1.js';
import LocalStoreStrip from '../../components/ads/strip-banners/localstore-strips.js';
import AdsSectionBanner_1 from '../../components/ads/banners/section-banner-1.js';
import AdsSectionBanner_2 from '../../components/ads/banners/section-banner-2.js';
import RecentlyViewed from '../../components/sliders/recently-viewed.js';
import Testimonials from '../../components/sliders/testimonials.js';
import Subscription from '../../components/common/subscription.js';
import LogoSlider from '../../components/ads/sliders/logo.js';
import H2 from '../../components/headings/h2.js';
import CardFourColumn from '../../components/cards/column-4.js';
import ThreeBanner from '../../components/ads/banners/three-banner.js';
import FiveBanner from '../../components/ads/banners/five-banner.js';
import LogoHeading from '../../components/headings/logo-heading.js';
import CardSixColumn from '../../components/cards/column-6.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import FindBannerType from '../../components/ads/banners/findBannerType';
import CardTwoColumn from '../../components/cards/column-2';
import CardThreeColumn from '../../components/cards/column-3';
import OffersModal from '../../components/modals/offersPopup';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import GetURLVar from '../../services/getParams/get-url-var';
import NewCardThreeColumn from '../../components/cards/newColumn-3';
import NewCardFourColumnOne from '../../components/cards/newColumn-4-1';
import NewCardFourColumnTwo from '../../components/cards/newColumn-4-2';

class LocalCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      id: 0
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    ReactGA.pageview(window.location.pathname);
    GetURLVar().then(function (query) {
      if (query) {
        this.setLocation(query.id);
        this.setState({ id: Number(query.id) });
        this.setVisited(query.id);
      }
    }.bind(this));
  }

  async setLocation(id) {
    let temp = await JSON.parse(localStorage.getItem("truekhoj_user_location"));
    this.setState({ userLocation: temp }, () => this.getLayout(id));
  }


  setVisited(id) {
    if (localStorage.getItem('truekhoj_user')) this.setVisitedId(id);
    if (localStorage.getItem("truekhoj_recents_local")) {
      let recent = JSON.parse(localStorage.getItem("truekhoj_recents_local"));
      let i = recent.indexOf(Number(id));
      if (i === -1) recent.push(Number(id));
      if (recent.length > 4) recent.shift();
      localStorage.setItem("truekhoj_recents_local", JSON.stringify(recent));
    }
    else {
      let recent = [Number(id)];
      localStorage.setItem("truekhoj_recents_local", JSON.stringify(recent));
    }
  }

  setVisitedId(id) {
    var url = devconfig.localUser + "/page/recentlyviewed/add";
    var data = JSON.stringify({
      "local": true,
      "department_id": Number(id)
    })
    APIPost(url, data).then(function (response) {

    }.bind(this))
  }

  getLayout(tempId) {
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/page/detail";
    var data = JSON.stringify({
      "id": Number(tempId),
      "type": 1,
      "location_id": this.state.userLocation.value
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.page_data.ads || response.data.page_data.layout) {
          this.setState({ data: response.data, dataLoading: false });
        }
        else {
          this.setState({ dataLoading: false });
        }
        if (response.data && response.data.layout_id) this.getPopup(response.data.layout_id);
      }
    }.bind(this))
  }

  getPopup(id) {
    let viewed = [];
    if (sessionStorage.getItem('local_viewed_pages')) viewed = JSON.parse(sessionStorage.getItem('local_viewed_pages'));
    if (viewed.indexOf(Number(id)) === -1 && (localStorage.getItem('Login_promt') || localStorage.getItem('truekhoj_user'))) {
      var url = devconfig.localUser + "/popupbanner/view";
      var data = JSON.stringify({
        "local": true,
        "layout_id": id,
        "location_id": this.state.userLocation.value
      });
      APIPost(url, data).then(function (response) {
        if (response && response.status === "ok" && response.data && response.data.image) {
          viewed.push(Number(id));
          sessionStorage.setItem('local_viewed_pages', JSON.stringify(viewed));
          this.setState({ popup: response.data });
          $('#offersPopupModal').modal('show');
        }
      }.bind(this))
    }
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    var tempCat1 = [
      { title: "Men", image: "../../assets/images/online/clothing-1.svg" },
      { title: "Women", image: "../../assets/images/online/clothing-2.svg" },
      { title: "Kids", image: "../../assets/images/online/clothing-3.svg" },
      { title: "Sports", image: "../../assets/images/online/clothing-4.svg" },
      { title: "Men", image: "../../assets/images/online/clothing-1.svg" },
      { title: "Women", image: "../../assets/images/online/clothing-2.svg" },
    ]

    var tempCat2 = [
      { title: "watches", image: "../../assets/images/online/clothing-1.svg" },
      { title: "GLASSES", image: "../../assets/images/online/clothing-2.svg" },
      { title: "BAGS & ACCESSORIES", image: "../../assets/images/online/clothing-3.svg" },
      { title: "jewellery", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat3 = [
      { title: "WOMEN", image: "../../assets/images/online/clothing-1.svg" },
      { title: "MEN", image: "../../assets/images/online/clothing-2.svg" },
      { title: "AYURVEDIC", image: "../../assets/images/online/clothing-3.svg" },
      { title: "PERFUMES & FRAGNANCE", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat4 = [
      { title: "JEWELLERY", image: "../../assets/images/online/clothing-1.svg" },
      { title: "WEDDING PLANNER", image: "../../assets/images/online/clothing-2.svg" },
      { title: "GROOM APPAREL", image: "../../assets/images/online/clothing-3.svg" },
      { title: "BRIDE APPAREL", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat5 = [
      { title: "men", image: "../../assets/images/online/clothing-1.svg" },
      { title: "WOMEN", image: "../../assets/images/online/clothing-2.svg" },
      { title: "KIDS", image: "../../assets/images/online/clothing-3.svg" },
      { title: "SPORTS", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat6 = [
      { title: "GOLD", image: "../../assets/images/online/clothing-1.svg" },
      { title: "DIAMOND", image: "../../assets/images/online/clothing-2.svg" },
      { title: "PLATINUM", image: "../../assets/images/online/clothing-3.svg" },
      { title: "PEARLS", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat7 = [
      { title: "televisions", image: "../../assets/images/online/clothing-1.svg" },
      { title: "washing machines", image: "../../assets/images/online/clothing-2.svg" },
      { title: "refrigerators", image: "../../assets/images/online/clothing-3.svg" },
      { title: "air conditioners", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat8 = [
      { title: "flights", image: "../../assets/images/online/clothing-1.svg" },
      { title: "buses", image: "../../assets/images/online/clothing-2.svg" },
      { title: "hotels", image: "../../assets/images/online/clothing-3.svg" },
      { title: "tours", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat9 = [
      { title: "food & beverages", image: "../../assets/images/online/clothing-1.svg" },
      { title: "food products", image: "../../assets/images/online/clothing-2.svg" },
      { title: "groceries", image: "../../assets/images/online/clothing-3.svg" },
      { title: "pet supplies", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat10 = [
      { title: "cars", image: "../../assets/images/online/clothing-1.svg" },
      { title: "bikes", image: "../../assets/images/online/clothing-2.svg" },
      { title: "scooters", image: "../../assets/images/online/clothing-3.svg" },
      { title: "luxury cars", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat11 = [
      { title: "smartphones", image: "../../assets/images/online/clothing-1.svg" },
      { title: "laptops", image: "../../assets/images/online/clothing-2.svg" },
      { title: "game consoles", image: "../../assets/images/online/clothing-3.svg" },
      { title: "accessories", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat12 = [
      { title: "bags", image: "../../assets/images/online/clothing-1.svg" },
      { title: "watches", image: "../../assets/images/online/clothing-2.svg" },
      { title: "jewellery", image: "../../assets/images/online/clothing-3.svg" },
      { title: "vintage", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat13 = [
      { title: "HEADPHONES", image: "../../assets/images/online/clothing-1.svg" },
      { title: "POWERBANKS", image: "../../assets/images/online/clothing-2.svg" },
      { title: "COVERS", image: "../../assets/images/online/clothing-3.svg" },
      { title: "SMART WATCHES", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat14 = [
      { title: "AT HOME", image: "../../assets/images/online/clothing-1.svg" },
      { title: "WORK", image: "../../assets/images/online/clothing-2.svg" },
      { title: "SLEEPWEAR & LINGERIE", image: "../../assets/images/online/clothing-3.svg" },
      { title: "CASUAL", image: "../../assets/images/online/clothing-4.svg" }
    ]

    var tempCat15 = [
      { title: "WORK", image: "../../assets/images/online/clothing-1.svg" },
      { title: "BEAUTY & CARE", image: "../../assets/images/online/clothing-2.svg" },
      { title: "NEON", image: "../../assets/images/online/clothing-3.svg" },
      { title: "CASUAL", image: "../../assets/images/online/clothing-4.svg" }
    ]
    if (!this.state.data) {
      return (
        <div>
          {
            this.state.dataLoading ?
              <PageLoader />
              :
              <NothingFound />
          }
          <OffersModal data={this.state.popup} />
        </div>
      )
    }
    else {
      var data = this.state.data.page_data;
      return (
        <div className="container-fluid p-0">

          {
            this.state.data ?
              <LocalSecondaryHeading data={this.state.data} category_page={true} category_id={this.state.id}/>
              :
              null
          }
          <div className="row">
            <div className="col-lg-12 py-5">
              <H2 data={`${this.state.data.name}`} type={29} bg_color="#0adcf3" font_color="#484848" />
            </div>
          </div>

          {
            data.ads && data.ads.hero ?
              <div className="row pb-3 e-mb-15 pb-lg-5">
                {
                  data.ads.hero.data[0].content ?
                    <div className="col-lg-12 px-0">
                      <FindBannerType content={data.ads.hero.data} details={data.ads.hero} defaultType={1} />
                      {/*<AdsHeroBanner content={data.ads.hero.data}/>*/}
                    </div>
                    :
                    null
                }
                {
                  data.ads.hero.listing && data.ads.hero.listing.content && data.ads.hero.listing.content.length ?
                    <div className="col-12 pt-3 pt-lg-4">
                      <div className="container px-0">
                        {
                          data.ads.hero.listing.type === 1 ?
                            <NewCardThreeColumn data={data.ads.hero.listing.content} />
                            :
                            data.ads.hero.listing.type === 2 ?
                              <NewCardFourColumnOne data={data.ads.hero.listing.content} />
                              :
                              data.ads.hero.listing.type === 3 ?
                                <NewCardFourColumnTwo data={data.ads.hero.listing.content} />
                                :
                                null
                        }
                      </div>
                    </div>
                    :
                    null
                }
              </div>
              :
              null
          }

          {
            data.ads && data.ads.ad1 && (data.ads.ad1.data[0].content || (data.ads.ad1.listing && data.ads.ad1.listing.content)) ?
              <div className="container px-0 pt-lg-4">
                <div className="row pb-3 e-mb-15 pb-lg-5">
                  {
                    data.ads.ad1.data[0].content ?
                      <div className="col-lg-12">
                        <FindBannerType content={data.ads.ad1.data} details={data.ads.ad1} defaultType={1} />
                        {/*<AdsHeroBanner content={data.ads.ad1.data}/>*/}
                      </div>
                      :
                      null
                  }
                  {
                    data.ads.ad1.listing && data.ads.ad1.listing.content && data.ads.ad1.listing.content.length ?
                      <div className="col-12 pt-3 pt-lg-4">
                        <div className="container px-0">
                          {
                            data.ads.ad1.listing.type === 1 ?
                              <NewCardThreeColumn data={data.ads.ad1.listing.content} />
                              :
                              data.ads.ad1.listing.type === 2 ?
                                <NewCardFourColumnOne data={data.ads.ad1.listing.content} />
                                :
                                data.ads.ad1.listing.type === 3 ?
                                  <NewCardFourColumnTwo data={data.ads.ad1.listing.content} />
                                  :
                                  null
                          }
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
              </div>
              :
              null
          }

          {
            data.ads && data.ads.ad2 && (data.ads.ad2.data[0].content || (data.ads.ad2.listing && data.ads.ad2.listing.content)) ?
              <div className="container px-0 pt-lg-4">
                <div className="row pb-3 e-mb-15 pb-lg-5">
                  {
                    data.ads.ad2.data[0].content ?
                      <div className="col-lg-12">
                        <FindBannerType content={data.ads.ad2.data} details={data.ads.ad2} defaultType={1} />
                        {/*<AdsHeroBanner content={data.ads.ad2.data}/>*/}
                      </div>
                      :
                      null
                  }
                  {
                    data.ads.ad2.listing && data.ads.ad2.listing.content && data.ads.ad2.listing.content.length ?
                      <div className="col-12 pt-3 pt-lg-4">
                        <div className="container px-0">
                          {
                            data.ads.ad2.listing.type === 1 ?
                              <NewCardThreeColumn data={data.ads.ad2.listing.content} />
                              :
                              data.ads.ad2.listing.type === 2 ?
                                <NewCardFourColumnOne data={data.ads.ad2.listing.content} />
                                :
                                data.ads.ad2.listing.type === 3 ?
                                  <NewCardFourColumnTwo data={data.ads.ad2.listing.content} />
                                  :
                                  null
                          }
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
              </div>
              :
              null
          }

          {
            data.ads && data.ads.ad3 && (data.ads.ad3.data[0].content || (data.ads.ad3.listing && data.ads.ad3.listing.content)) ?
              <div className="container px-0 pt-lg-4">
                <div className="row pb-3 e-mb-15 pb-lg-5">
                  {
                    data.ads.ad3.data[0].content ?
                      <div className="col-lg-12">
                        <FindBannerType content={data.ads.ad3.data} details={data.ads.ad3} defaultType={1} />
                        {/*<AdsHeroBanner content={data.ads.ad3.data}/>*/}
                      </div>
                      :
                      null
                  }
                  {
                    data.ads.ad3.listing && data.ads.ad3.listing.content && data.ads.ad3.listing.content.length ?
                      <div className="col-12 pt-3 pt-lg-4">
                        <div className="container px-0">
                          {
                            data.ads.ad3.listing.type === 1 ?
                              <NewCardThreeColumn data={data.ads.ad3.listing.content} />
                              :
                              data.ads.ad3.listing.type === 2 ?
                                <NewCardFourColumnOne data={data.ads.ad3.listing.content} />
                                :
                                data.ads.ad3.listing.type === 3 ?
                                  <NewCardFourColumnTwo data={data.ads.ad3.listing.content} />
                                  :
                                  null
                          }
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
              </div>
              :
              null
          }

          {
            data.ads && data.ads.ad4 && (data.ads.ad4.data[0].content || (data.ads.ad4.listing && data.ads.ad4.listing.content)) ?
              <div className="container px-0 pt-lg-4">
                <div className="row pb-3 e-mb-15 pb-lg-5">
                  {
                    data.ads.ad4.data[0].content ?
                      <div className="col-lg-12">
                        <FindBannerType content={data.ads.ad4.data} details={data.ads.ad4} defaultType={1} />
                        {/*<AdsHeroBanner content={data.ads.ad4.data}/>*/}
                      </div>
                      :
                      null
                  }
                  {
                    data.ads.ad4.listing && data.ads.ad4.listing.content && data.ads.ad4.listing.content.length ?
                      <div className="col-12 pt-3 pt-lg-4">
                        <div className="container px-0">
                          {
                            data.ads.ad4.listing.type === 1 ?
                              <NewCardThreeColumn data={data.ads.ad4.listing.content} />
                              :
                              data.ads.ad4.listing.type === 2 ?
                                <NewCardFourColumnOne data={data.ads.ad4.listing.content} />
                                :
                                data.ads.ad4.listing.type === 3 ?
                                  <NewCardFourColumnTwo data={data.ads.ad4.listing.content} />
                                  :
                                  null
                          }
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
              </div>
              :
              null
          }

          {
            data.ads && data.ads.ad5 && (data.ads.ad5.data[0].content || (data.ads.ad5.listing && data.ads.ad5.listing.content)) ?
              <div className="container px-0 pt-lg-4">
                <div className="row pb-3 e-mb-15 pb-lg-5">
                  {
                    data.ads.ad5.data[0].content ?
                      <div className="col-lg-12">
                        <FindBannerType content={data.ads.ad5.data} details={data.ads.ad5} defaultType={1} />
                        {/*<AdsHeroBanner content={data.ads.ad5.data}/>*/}
                      </div>
                      :
                      null
                  }
                  {
                    data.ads.ad5.listing && data.ads.ad5.listing.content && data.ads.ad5.listing.content.length ?
                      <div className="col-12 pt-3 pt-lg-4">
                        <div className="container px-0">
                          {
                            data.ads.ad5.listing.type === 1 ?
                              <NewCardThreeColumn data={data.ads.ad5.listing.content} />
                              :
                              data.ads.ad5.listing.type === 2 ?
                                <NewCardFourColumnOne data={data.ads.ad5.listing.content} />
                                :
                                data.ads.ad5.listing.type === 3 ?
                                  <NewCardFourColumnTwo data={data.ads.ad5.listing.content} />
                                  :
                                  null
                          }
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
              </div>
              :
              null
          }


          {
            this.state.data && this.state.data.page_data.layout.map((item, index) => {

              if (item.bg_color) {
                var tempStyle = { background: item.bg_color };
              }


              return (
                <React.Fragment>
                  <section className="container-fluid position-relative py-3" style={tempStyle}>
                    <div className="container">
                      <div className="row pt-5">
                        {
                          item.header.content && !item.header.link ?
                            <div className={tempStyle ? "col-lg-12 pb-4 pt-5 e-no-p" : "col-lg-12 pb-4 e-no-p"}>
                              <H2 data={item.header.content} type={item.header.type} bg_color={item.header.bg_color} font_color={item.header.font_color} />
                            </div>
                            :
                            <div className={tempStyle ? "col-lg-12 mb-4 pt-5 e-no-p" : "col-lg-12 mb-4 e-no-p"}>
                              <LogoHeading data={item.header} />
                            </div>
                        }
                        {
                          item.ads ?
                            <>
                              {
                                item.ads.banner_type ?
                                  <div className="col-lg-12 pb-4 e-no-p">
                                    {/*<AdsSectionBanner_1 data={item.ads.content} link={item.ads.link}/>*/}
                                    <FindBannerType content={item.ads.data} details={item.ads} />
                                  </div>
                                  :
                                  <div className="col-lg-12 pb-4 e-no-p">
                                    <FindBannerType content={[item.ads]} details={item.ads} defaultType={1} />
                                  </div>
                              }
                            </>
                            :
                            null
                        }

                        <div className="col-lg-12 mb-5 e-no-p">
                            {
                              item.logo_slider && item.logo_slider.data ?
                              <LogoSlider data={item.logo_slider.data}/>
                            :
                              null
                            }
                         
                        </div> 
                      </div>
                    </div>
                    <span className="e-section-bg-half" style={{ background: "#fff" }}></span>
                  </section>

                  <section className="container-fluid position-relative" style={tempStyle}>
                    <div className="container">
                      <div className="row pb-5 ">
                        {
                          item.data.content && item.data.type == 1 ?
                            <div className="col-lg-12 mb-3 e-no-p">
                              <CardFourColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} product={true} local={true} />
                            </div>
                            : item.data.content && item.data.type == 2 ?
                              <div className="col-lg-12 mb-4 e-no-p">
                                <CardSixColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} product={true} local={true} />
                              </div>
                              : item.data.content && item.data.type == 3 ?
                                <div className="col-lg-12 mb-4 e-no-p">
                                  <CardTwoColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} product={true} local={true} />
                                </div>
                                : item.data.content && item.data.type == 4 ?
                                  <div className="col-lg-12 mb-4 e-no-p">
                                    <CardThreeColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} product={true} local={true} />
                                  </div>
                                  :
                                  null
                        }
                      </div>
                    </div>
                    <span className="e-section-bg-half" style={{ background: "#fff" }}></span>
                  </section>

                </React.Fragment>
              );
            })
          }
           
          {
            data.ads && data.ads.topSellers && data.ads.topSellers.data && data.ads.topSellers.data.content ?
            <section className="container-fluid position-relative e-testimonials-outer">
                        <div className="container">
                        <div className="row pt-5 mb-5">
                            <div className="col-lg-12 mb-5">
                            <H2 data="Top Sellers" type={29} bg_color="#FF4A31"/>
                            </div>
                            {
                          data.ads.topSellers.data.content && data.ads.topSellers.data.type == 1 ?
                            <div className="col-lg-12 mb-3 e-no-p">
                              <CardFourColumn data={data.ads.topSellers.data.content} type={data.ads.topSellers.data.style.value} vendor={true}/>
                            </div>
                            : data.ads.topSellers.data.content && data.ads.topSellers.data.type == 2 ?
                              <div className="col-lg-12 mb-4 e-no-p">
                                <CardSixColumn data={data.ads.topSellers.data.content} type={data.ads.topSellers.data.style.value} vendor={true}/>
                              </div>
                              : data.ads.topSellers.data.content && data.ads.topSellers.data.type == 3 ?
                                <div className="col-lg-12 mb-4 e-no-p">
                                  <CardTwoColumn data={data.ads.topSellers.data.content} type={data.ads.topSellers.data.style.value}  vendor={true}/>
                                </div>
                                : data.ads.topSellers.data.content && data.ads.topSellers.data.type == 4 ?
                                  <div className="col-lg-12 mb-4 e-no-p">
                                    <CardThreeColumn data={data.ads.topSellers.data.content} type={data.ads.topSellers.data.style.value} vendor={true}/>
                                  </div>
                                  :
                                  null
                        }
                        </div>
               
                        </div>
                    </section>
                    :
                    null
          }

{
                data.ads.topSellers.favStore &&  data.ads.topSellers.favStore.data &&  data.ads.topSellers.favStore.data.length?
                <section className="container-fluid position-relative e-testimonials-outer">
                        <div className="container">
                <div className='row mb-4'>
                {
                  data.ads && data.ads.topSellers && data.ads.topSellers.data && data.ads.topSellers.data.content ?
                    null 
                    :
                  <div className="col-lg-12 mb-5">
                  <H2 data="Top Sellers" type={29} bg_color="#FF4A31"/>
                  </div>
                }
                {
                   data.ads.topSellers.favStore.data.map((item, key)=>{
                    
                  return(
                    <div className='col-lg-6 mt-3'>
                      <Link to={`/local/store?id=${item.vendor.value}`}>
                      <div className='card e-favitem-card'>
                          <img  src={item.content}/>
                          <p className='mt-3 text-center '><b>{item.vendor.label}</b></p>
                      </div>
                      </Link>
                    </div>
                    )
                })
              }
                </div>
                </div>
               </section>
                :
                null
            }

          {/* Subscription */}
          <Subscription />
          <OffersModal data={this.state.popup} />


        </div>
      );
    }

  }
}

export default LocalCategory;
