import React, { Component } from 'react'
import { GoogleLogin, GoogleLogout } from 'react-google-login';


const CLIENT_ID = '995020205240-fjoc9gr79meinojlvfhal8qcq7reppd7.apps.googleusercontent.com';


class GoogleBtn extends Component {
   constructor(props) {
    super(props);

    this.state = {
      isLogined: false,
      accessToken: ''
    };
  }

  login (response) {
    this.props.login(response);
  }

  handleLoginFailure (response) {
    console.log(response);
    this.props.onFailure(response);
  }

  render() {
    return (
    <div className="e-google-btn">
        <GoogleLogin
          clientId={ CLIENT_ID }
          buttonText={this.props.text}
          onSuccess={(response)=>this.login(response)}
          onFailure={(response)=>this.handleLoginFailure(response)}
          cookiePolicy={ 'single_host_origin' }
        />
    </div>
    )
  }
}

export default GoogleBtn;