
/*Package importing*/
import React, { Component } from 'react';
import VideoPlayer from '../../../components/common/video';
/*Assets*/
import AdsHeroBanner from './hero-banner.js';
import FiveBanner from './five-banner.js';
import AdsSectionBanner_1 from './section-banner-1.js';
import AdsSectionBanner_2 from './section-banner-2.js';
import ThreeBanner from './three-banner.js';
import FourBanner from './four-banner.js';
import LogoSlider from '../sliders/logo';
import ThreeFourthCategory from './threeFourth-banner-withCategory.js';

class FindBannerType extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }
 
  componentWillMount() {
    console.log('Inside type banner');
    console.log(this.props.content);


    console.log("this.props.defaultType");
    console.log(this.props.defaultType);
    
  }

  render() {

    const renderAdType = (item)=>{
      console.log("itemitemitemitemitemitem");
      console.log(item);
      switch(item) {
        case 1:{
          return  <AdsHeroBanner content={this.props.content} noSlide={this.props.noSlide} defaultSlidesNumber={this.props.defaultSlidesNumber}/>
        };
        case 2:{
          return  <AdsSectionBanner_2 data={this.props.content} singleCol={this.props.singleCol}/>
        };
        case 3:{
          return  <ThreeBanner data={this.props.content}/>
        };
        case 4:{
          return  <FourBanner data={this.props.content}/>
        };
        case 5:{
          return  <ThreeFourthCategory data={this.props.content}/>
        };
        case 6:{
          return  <FiveBanner 
                        adLeftTop={this.props.content[0]}
                        adLeftBottom={this.props.content[1]}
                        adCenter={this.props.content[2]}
                        adRightTop={this.props.content[3]}
                        adRightBottom={this.props.content[4]}/>
        };
        case 7:{
          return  <AdsHeroBanner content={this.props.content}/>
        };
        case 8:{
          return  <LogoSlider data={this.props.content}/>
        };
        case 9:{
          return  <AdsSectionBanner_2 data={this.props.content}/>
        };
      }
    }



    return (
      <React.Fragment>
        {
          this.props.details && this.props.details.banner_type ?
            <React.Fragment>
              {renderAdType(this.props.details.banner_type)}
            </React.Fragment>
          :
            <React.Fragment>
              {renderAdType(this.props.defaultType)}
            </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

export default FindBannerType;
