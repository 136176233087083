
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
/*Assets*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import Toast from '../../components/common/toast';

class Subscription extends Component {
  constructor(props){
    super(props);
    this.state = {
      inputValue: "",
      toast: "",
      toastType: "success"
    }
  }

  componentDidMount() {
    
  }

  submit(tempPagenum){
    this.setState({dataLoading: true});
    var url = devconfig.localUser+"/newsletter/subscribe";
    var data = JSON.stringify({
      "email" : this.state.inputValue
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({toast: "Thank you for subscribing", toastType: "success", inputValue: ""});
        setTimeout( function() { this.setState({ toast: "", inputValue: ""}); }.bind(this), 4000);
      }
      else{
        this.setState({toast: response.message, toastType: "fail"});
        setTimeout( function() { this.setState({ toast: ""}); }.bind(this), 4000);
      }
    }.bind(this))
  }

  valueChange(e){
    this.setState({inputValue: e.target.value});
  }


  render() {
    var settings = {
      infinite: false,
      slidesToShow: 4,
      arrow: true
    };

    return (
      <section className="container-fluid position-relative e-subscription-wrap text-center">
        <h6>sign up for newsletter</h6>
        <p>Subscribe to our newsletter for weekly new updates</p>
        <div className="position-relative e-subscription-input-wrap mt-2">
          <input type="text" value={this.state.inputValue} onChange={this.valueChange.bind(this)} className="e-header-search" placeholder="Email Address"/>
          {
            this.state.inputValue.length ?
              <span className="e-p-btn" onClick={this.submit.bind(this)}>Subscribe</span>
            :
              <span className="e-p-btn e-inactive">Subscribe</span>
          }
          
        </div>

        {
          this.state.toast.length ?
            <Toast type={this.state.toastType} data={this.state.toast}/>
          :
            null
        }
      </section>
    );
  }
}

export default Subscription;
