
/*Package importing*/
import React, { Component } from 'react';

/*Assets*/
import logo from '../../assets/images/online/brand-logo-head-1.png';



class LogoHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    console.log("LogoHeading");
    console.log(this.props.data)
    return (
      <div className="row e-brand-logo-heading">
        {
          this.props.data && this.props.data.link ?
            <div className="col-lg-12 col-12">
              <a href={this.props.data.link} taregt="_blank">
                <img src={this.props.data.content} alt="Brand Logo" />
              </a>
            </div>
            :
            null
        }
      </div>
    );
  }
}

export default LogoHeading;
