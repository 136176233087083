
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import $ from 'jquery';
import ReactGA from 'react-ga';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*Components*/
import SecondaryHeading from '../../components/navigations/secondary-heading';
import ProductBanner from '../../components/sliders/product-banner.js';
import ProductAccordion from '../../components/accordion/product-accordion.js';
import Subscription from '../../components/common/subscription.js';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import GetURLVar from '../../services/getParams/get-url-var';
import AdsHeroBanner from '../../components/ads/banners/hero-banner.js';
import FindBannerType from '../../components/ads/banners/findBannerType';

/*Assets*/
import Img1 from '../../assets/images/online/img1.png';
import Img2 from '../../assets/images/online/img2.png';
import Img3 from '../../assets/images/online/img3.png';
import Img4 from '../../assets/images/online/img4.png';
import Img5 from '../../assets/images/online/img5.png';
import Img7 from '../../assets/images/online/img7.png';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import OffersModal from '../../components/modals/offersPopup';


class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: 0,
      pageNum: true,
      pageNo: 1,
      pagination: false,
      sort: null,
      sortOrder: null,
      popularity: { value: null, label: "Select" },
      offer: { value: null, label: "Select" },
      new: { value: null, label: "Select" },
      discount: { value: null, label: "Select" },
      layout: null,
      details: null,
      ad_category: null,
    }
  }

  async componentWillMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    ReactGA.pageview(window.location.pathname);

    this.getLayout();

    await GetURLVar().then(function (query) {
      if (query.id) {
        this.setState({ filter: query.id, subcategory: query.sub_category, category: query.category, product_name: query.product });
        this.getData(1);

      }
      else {
        this.getData(1);
      }
    }.bind(this));
  }

  getLayout(tempId) {
    this.setState({ dataLoading: true });
    var url = devconfig.userV1 + "/page/detail";
    var data = JSON.stringify({
      "id": 156,
      "type": 3
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ layout: response.data.page_data, details: response.data });
      }
      this.getPopup(156);
    }.bind(this))
  }

  getPopup(id){
    let viewed = [];
    if(sessionStorage.getItem('viewed_pages')) viewed= JSON.parse(sessionStorage.getItem('viewed_pages'));
    if(viewed.indexOf(Number(id))===-1&& (localStorage.getItem('Login_promt')||localStorage.getItem('truekhoj_user'))){
      var url = devconfig.userV1+"/popupbanner/view";
      var data = JSON.stringify({
        "local" : false,
        "layout_id" : id
      });
      APIPost(url, data).then(function(response){
        if(response &&response.status === "ok"&& response.data && response.data.image){
          viewed.push(Number(id));
          sessionStorage.setItem('viewed_pages', JSON.stringify(viewed));
          this.setState({popup: response.data});
          $('#offersPopupModal').modal('show');
        }
      }.bind(this))
    }
  }

  getData(tempPagenum) {
    this.setState({ dataLoading: true });
    var url = devconfig.userV1 + "/product/detail";
    var data = JSON.stringify({
      "page_num": tempPagenum,
      "product_id": Number(this.state.filter),
      "sort_value": this.state.sort,
      "order": this.state.sortOrder
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        var tempArry = [];
        if (this.state.pagination) {
          tempArry = $.merge(this.state.data, response.data);
        }
        else {
          tempArry = response.data;
        }
        if (response.data.length <= 39) {
          this.setState({ pageNum: false });
        }
        this.setState({ data: tempArry, dataLoading: false, pagination: false });
      }
    }.bind(this))
  }

  async filterChange(tempId) {
    await this.setState({ filter: tempId });
    this.getData(1);
  }

  async loadMore() {
    this.getData(this.state.pageNo + 1);
    await this.setState({ pageNo: this.state.pageNo + 1, pagination: true });

  }

  async popularityChange(tempVal) {
    await this.setState({
      popularity: tempVal,
      sort: 1,
      sortOrder: tempVal.value,
      pageNo: 1,
      offer: { value: null, label: "Select" },
      new: { value: null, label: "Select" },
      discount: { value: null, label: "Select" }
    });
    this.getData(1);
  }

  async offerChange(tempVal) {
    await this.setState({
      offer: tempVal,
      sort: 2,
      sortOrder: tempVal.value,
      pageNo: 1,
      popularity: { value: null, label: "Select" },
      new: { value: null, label: "Select" },
      discount: { value: null, label: "Select" }
    });
    this.getData(1);
  }

  async newChange(tempVal) {
    await this.setState({
      new: tempVal,
      sort: 3,
      sortOrder: tempVal.value,
      pageNo: 1,
      popularity: { value: null, label: "Select" },
      offer: { value: null, label: "Select" },
      discount: { value: null, label: "Select" }
    });
    this.getData(1);
  }

  async discountChange(tempVal) {
    await this.setState({
      discount: tempVal,
      sort: 4,
      sortOrder: tempVal.value,
      pageNo: 1,
      popularity: { value: null, label: "Select" },
      offer: { value: null, label: "Select" },
      new: { value: null, label: "Select" }
    });
    this.getData(1);
  }

  checkIfInLayout(type){
    let inLayout =false;
    let out;
    this.state.layout.layout.forEach((item, i) => {
      if (item.header && item.header.category && item.header.category === Number(this.state.ad_category) && item.id === type && (item.ads.banner_type || item.ads.content)) {
        out = <section className="container-fluid position-relative p-0 e-prod-banner">
        <div className="row mb-5">
          {
            item.ads.banner_type ?
              <div className="col-lg-12 mb-5">
                {/*<AdsSectionBanner_1 data={item.ads.content} link={item.ads.link}/>*/}
                <FindBannerType content={item.ads.data} details={item.ads} />
              </div>
              :
              <div className="col-lg-12 mb-5">
                <FindBannerType content={[item.ads]} details={item.ads} defaultType={1} />
              </div>
          }
        </div>
      </section>;
      inLayout = true;
      } 
    });
    if(inLayout){
      return out;
    }
    else if((type==56 &&this.state.layout.ads.hero.data) || (type==57 &&this.state.layout.ads.intermediate.data)){
      return (
          <section className="container-fluid position-relative p-0 e-prod-banner">
            <div className="row mb-5">
              <div className="col-lg-12 mb-5">

                {/*<AdsHeroBanner content={this.state.layout.ads.hero.data}/>*/}
                <FindBannerType content={type==56? this.state.layout.ads.hero.data: this.state.layout.ads.intermediate.data} details={type==56? this.state.layout.ads.hero: this.state.layout.ads.intermediate} defaultType={1} />
              </div>
            </div>
          </section>
      )
    }
    else return null;
  }

  render() {

    var tempPopularity = [
      { value: 1, label: 'Least popular first' }, // 'Higt - Low' 
      { value: 2, label: 'Most popular first' }, //Low - High 
    ]

    var tempOffers = [
      { value: 1, label: 'Lowest first' }, //High - Low 
      { value: 2, label: 'Highest first' } //Low - High 
    ]

    var tempNew = [
      { value: 1, label: 'Latest first' }, //High - Low 
      { value: 2, label: 'Oldest first' } //Low - High 
    ]

    var tempDiscount = [
      { value: 2, label: 'High - Low' },
      { value: 1, label: 'Low - High' }
    ]

    const linkVerify = (item) => {
      var tempLink = item;
      if (item && !item.match(/^http([s]?):\/\/.*/)) {
        tempLink = 'http://' + item;
        return tempLink
      }
      else {
        return item
      }
    }


    if (!this.state.data) {
      return (
        <div>
          {
            this.state.dataLoading && !this.state.pagination ?
              <PageLoader />
              :
              <NothingFound />
          }
        </div>
      );
    }
    else {
      return (
        <div className="container-fluid p-0">
          {
            this.state.details ?
              <SecondaryHeading data={this.state.details} />
              :
              null
          }
          {
            this.state.ad_category ?
              <>
                {
                  this.state.layout && this.state.layout.layout && this.state.layout.layout.length ?
                    <>
                      {
                        this.checkIfInLayout(56)
                      }
                    </>
                    : this.state.layout && this.state.layout.ads.hero.data ?
                      <section className="container-fluid position-relative p-0 e-prod-banner">
                        <div className="row mb-5">
                          <div className="col-lg-12 mb-5">

                            {/*<AdsHeroBanner content={this.state.layout.ads.hero.data}/>*/}
                            <FindBannerType content={this.state.layout.ads.hero.data} details={this.state.layout.ads.hero} defaultType={1} />
                          </div>
                        </div>
                      </section>
                      :
                      null
                }
              </>
              :
              <>
                {
                  this.state.layout && this.state.layout.ads.hero.data ?
                    <section className="container-fluid position-relative p-0 e-prod-banner">
                      <div className="row mb-5">
                        <div className="col-lg-12 mb-5">

                          {/*<AdsHeroBanner content={this.state.layout.ads.hero.data}/>*/}
                          <FindBannerType content={this.state.layout.ads.hero.data} details={this.state.layout.ads.hero} defaultType={1} />
                        </div>
                      </div>
                    </section>
                    :
                    null
                }
              </>
          }

          <section>
            <div className="container e-product-page-wrap">
              <div className="row">
                <div className="col-lg-3">
                  {
                    this.state.category && this.state.product_name ?
                      <ProductAccordion id={this.state.filter} adCategory={this.state.ad_category} updateAdCat={cat => this.setState({ ad_category: cat })} name={this.state.product_name} subcategory={this.state.subcategory} category={this.state.category} filterChange={this.filterChange.bind(this)} />
                      :
                      <ProductAccordion filterChange={this.filterChange.bind(this)} adCategory={this.state.ad_category} updateAdCat={cat => this.setState({ ad_category: cat })} />
                  }
                </div>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <h6>Sort by</h6>
                    </div>
                    <div className="col-lg-3 col-6 col-sm-4 mb-3 e-product-sort">
                      <label>Popularity</label>
                      <SelectBox options={tempPopularity} placeholder="Select" value={this.state.popularity} selectchange={this.popularityChange.bind(this)} />
                    </div>
                    <div className="col-lg-3 col-6 col-sm-4 mb-3 e-product-sort">
                      <label>Offers</label>
                      <SelectBox options={tempOffers} placeholder="Select" value={this.state.offer} selectchange={this.offerChange.bind(this)} />
                    </div>
                    <div className="col-lg-3 col-6 col-sm-4 mb-3 e-product-sort">
                      <label>What’s New </label>
                      <SelectBox options={tempNew} placeholder="Select" value={this.state.new} selectchange={this.newChange.bind(this)} />
                    </div>
                    <div className="col-lg-3 col-6 col-sm-4 mb-3 e-product-sort">
                      <label>Discount</label>
                      <SelectBox options={tempDiscount} placeholder="Select" value={this.state.discount} selectchange={this.discountChange.bind(this)} />
                    </div>
                  </div>
                  {
                    this.state.dataLoading ?
                      <div className="row pt-3">
                        <PageLoader />
                      </div>
                      :
                      <React.Fragment>
                        {
                          this.state.data && this.state.data.length && this.state.data.length < 6 ?
                            <>
                              <div className="row pt-1 mb-5">
                                {
                                  this.state.data.map((item1, index) => {


                                    return (
                                      <div className="col-lg-4 col-6 pt-3">
                                        <a href={linkVerify(item1.website)} target="_blank">
                                          <div className="w-100 e-vendor-logo-wrap position-relative">
                                            <img className="e-prod-list-logo e-vendor-list-logo position-absolute" src={item1.logo} alt="Truekhoj brand logo" />
                                          </div>
                                        </a>
                                      </div>
                                    );
                                  })
                                }
                              </div>

                              {
                                this.state.ad_category ?
                                  <>
                                    {
                                      this.state.layout && this.state.layout.layout && this.state.layout.layout.length ?
                                        <>
                                        {
                        this.checkIfInLayout(57)
                      }
                                        </>
                                        : this.state.layout && this.state.layout.ads.intermediate.data ?
                                          <div className="row">
                                            <div className="col-lg-12 py-3">

                                              {/*<AdsHeroBanner content={this.state.layout.ads.intermediate.data}/>*/}
                                              <FindBannerType content={this.state.layout.ads.intermediate.data} details={this.state.layout.ads.intermediate} defaultType={1} />
                                            </div>
                                          </div>
                                          :
                                          null
                                    }
                                  </>
                                  :
                                  <>
                                    {
                                      this.state.layout && this.state.layout.ads.intermediate.data ?
                                        <div className="row">
                                          <div className="col-lg-12 py-3">

                                            {/*<AdsHeroBanner content={this.state.layout.ads.intermediate.data}/>*/}
                                            <FindBannerType content={this.state.layout.ads.intermediate.data} details={this.state.layout.ads.intermediate} defaultType={1} />
                                          </div>
                                        </div>
                                        :
                                        null
                                    }
                                  </>
                              }
                            </>
                            :
                            this.state.data && this.state.data.length && this.state.data.length >= 6 ?
                              <>
                                <div className="row pt-1">
                                  {
                                    this.state.data.map((item, index) => {
                                      if (index < 6) {
                                        return (
                                          <div className="col-lg-4 col-6 pt-3">
                                            <a href={linkVerify(item.website)} target="_blank">
                                              <div className="w-100 e-vendor-logo-wrap position-relative">
                                                <img className="e-prod-list-logo e-vendor-list-logo position-absolute" src={item.logo} alt="Truekhoj brand logo" />
                                              </div>
                                            </a>
                                          </div>
                                        );
                                      }
                                    })
                                  }
                                </div>
                                {
                                  this.state.ad_category ?
                                    <>
                                      {
                                        this.state.layout && this.state.layout.layout && this.state.layout.layout.length ?
                                         <>
                                         {
                        this.checkIfInLayout(57)
                      }
                                         </>
                                          : this.state.layout && this.state.layout.ads.intermediate.data ?
                                            <div className="row">
                                              <div className="col-lg-12 py-3">

                                                {/*<AdsHeroBanner content={this.state.layout.ads.intermediate.data}/>*/}
                                                <FindBannerType content={this.state.layout.ads.intermediate.data} details={this.state.layout.ads.intermediate} defaultType={1} />
                                              </div>
                                            </div>
                                            :
                                            null
                                      }
                                    </>
                                    :
                                    <>
                                      {
                                        this.state.layout && this.state.layout.ads.intermediate.data ?
                                          <div className="row">
                                            <div className="col-lg-12 py-3">

                                              {/*<AdsHeroBanner content={this.state.layout.ads.intermediate.data}/>*/}
                                              <FindBannerType content={this.state.layout.ads.intermediate.data} details={this.state.layout.ads.intermediate} defaultType={1} />
                                            </div>
                                          </div>
                                          :
                                          null
                                      }
                                    </>
                                }
                                <div className="row mb-5">
                                  {
                                    this.state.data.map((item, index) => {
                                      if (index >= 6) {
                                        return (
                                          <div className="col-lg-4 col-6 pt-3">
                                            <a href={linkVerify(item.website)} target="_blank">
                                              <div className="w-100 e-vendor-logo-wrap position-relative">
                                                <img className="e-prod-list-logo e-vendor-list-logo position-absolute" src={item.logo} alt="Truekhoj brand logo" />
                                              </div>
                                            </a>
                                          </div>
                                        );
                                      }
                                    })
                                  }
                                </div>
                              </>
                              :
                              <div className="row pt-3">
                                <NothingFound />
                              </div>
                        }
                      </React.Fragment>

                  }

                  {
                    this.state.pageNum ?
                      <div className="row mb-5">
                        <PrimaryButton handleClick={this.loadMore.bind(this)} className="e-p-btn e-load-more" name="Load More" />
                        {
                          this.state.dataLoading && this.state.pagination ?
                            <span className="e-lazy-loading"></span>
                            :
                            null
                        }

                      </div>
                      :
                      null
                  }



                </div>

              </div>
            </div>
          </section>
          <Subscription />
          <OffersModal data={this.state.popup}/>
        </div>
      );
    }
  }
}

export default Product;
