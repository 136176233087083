
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Assets*/


/*Services*/
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';

/*Components*/

class SecondaryHeading extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null
    }
  }

  componentDidMount() {
    this.getPages();
  }

  getPages(){
    var url = devconfig.userV1+"/page/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({data: response.data});
      }
    }.bind(this))
  }

  render() {

    return (
      <div className="e-sec-head-wrap">

        <div className="container-fluid d-sm-block d-md-block d-lg-none p-0">
          <div className="scrollmenu">
              <span className="e-page-name">{this.props.data.name}</span>
              {
                this.props.data.topmenu_data.map((item, index) => {
                  var tempUrl = "";

                  if(item.is_category){
                    tempUrl = "./category?id="+item.category_id+"&category="+item.name
                  }
                  else if(item.is_subcategory){
                    tempUrl = "./sub-category?id="+item.sub_category_id+"&sub_category="+item.sub_category_id+"&category="+item.category_id;
                  }
                  else if(item.is_other){
                    if(item.id == 51){
                      tempUrl = "./";
                    }
                    else if(item.id == 156){
                      tempUrl = "./product-listing";
                    }
                    else{
                      tempUrl = "./deals?id="+item.id;
                    }
                    
                  }
                  return(
                    <a className="e-sec-head-link" href={tempUrl}>{item.name}</a>
                  );
                })
              }
          </div>
        </div>

        <div className="container d-none d-sm-none d-md-none d-lg-block">
          <div className="row">
            <div className="col-lg-12">
              <div className="e-page-name">{this.props.data.name}</div>
              {
                this.props.data.topmenu_data.map((item, index) => {
                  var tempUrl = "";

                  if(item.is_category){
                    tempUrl = "./category?id="+item.category_id+"&category="+item.name
                  }
                  else if(item.is_subcategory){
                    tempUrl = "./sub-category?id="+item.sub_category_id+"&sub_category="+item.sub_category_id+"&category="+item.category_id;
                  }
                  else if(item.is_other){
                    if(item.id == 51){
                      tempUrl = "./";
                    }
                    else if(item.id == 156){
                      tempUrl = "./product-listing";
                    }
                    else{
                      tempUrl = "./deals?id="+item.id;
                    }
                    
                  }
                  return(
                    <a className="e-sec-head-link" href={tempUrl}>{item.name}</a>
                  );
                })
              }

              {/*
                this.state.data && this.state.data.home_data.map((item, index) => {
                  return(
                    <Link className="e-sec-head-link">{item.name}</Link>
                  );
                })
              */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SecondaryHeading;
