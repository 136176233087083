import React, { Component } from 'react';
import ReactGA from 'react-ga';

import CardFourColumn from '../../components/cards/column-4';
import NothingFound from '../../components/common/nothing';
import PageLoader from '../../components/common/page-loader';
import H2 from '../../components/headings/h2';
import LocalSecondaryHeading from '../../components/navigations/secondaryHeading-local';

import recents1 from '../../assets/images/local/recentSample/Rectangle162.png';
import recents2 from '../../assets/images/local/recentSample/Rectangle163.png';
import recents3 from '../../assets/images/local/recentSample/Rectangle164.png';
import recents4 from '../../assets/images/local/recentSample/Rectangle165.png';

import devconfig from '../../config/config';
import APIGet from '../../services/apiCall/get';

class LocalCategoryList extends Component {
    constructor(props){
      super(props);
      this.state = {
        data: null,
        dataLoading: false
      }
    }

    async getList(){
        this.setState({dataLoading: true});
        let url = devconfig.localUser+"/page/department/list";
        let response = await APIGet(url);
        if(response && response.status ==="ok" && response.data && response.data.length){
            let data ={content: [], "type": 1,
            "style": {
                "value": 13,
                "label": "Style - 13"
            }};
            response.data.forEach(item=>data.content.push({img: item.image, title: item.name, id: item.id}));
            this.setState({data});
        }
        this.setState({dataLoading: false});
    }

    componentDidMount(){
      ReactGA.pageview(window.location.pathname);
        this.getList();    
    }


    render(){

        let sampleCategories = {
            "content": [
                {
                    "img": recents1,
                    "title": "smartphone",
                    "id": 1
                },
                {
                    "img": recents2,
                    "title": "Sceneries",
                    "id": 3
                },
                {
                    "img": recents3,
                    "title": "Normal",
                    "id": 2
                },
                {
                    "img": recents4,
                    "title": "smartphone",
                    "id": 1
                },
                {
                    "img": recents1,
                    "title": "smartphone",
                    "id": 1
                },
                {
                    "img": recents2,
                    "title": "Sceneries",
                    "id": 3
                },
                {
                    "img": recents3,
                    "title": "Normal",
                    "id": 2
                },
                {
                    "img": recents4,
                    "title": "smartphone",
                    "id": 1
                },
                {
                    "img": recents1,
                    "title": "smartphone",
                    "id": 1
                },
                {
                    "img": recents2,
                    "title": "Sceneries",
                    "id": 3
                },
                {
                    "img": recents3,
                    "title": "Normal",
                    "id": 2
                },
                {
                    "img": recents4,
                    "title": "smartphone",
                    "id": 1
                }
            ],
            "type": 1,
            "style": {
                "value": 13,
                "label": "Style - 13"
            }
        };

        if(this.state.data){
            return (
              <div className="container-fluid p-0">
                  {
                        this.state.details ?
                        <LocalSecondaryHeading data={this.state.details} />
                        :
                        null
                    }
                    <section className="container-fluid position-relative e-testimonials-outer">
                        <div className="container">
                        <div className="row pt-5 mb-5">
                            <div className="col-lg-12 mb-5">
                            <H2 data="Explore Categories" type={31} bg_color="#FF4A31"/>
                            </div>
                            {
                            this.state.data?
                            <div className="col-lg-12 mb-3 e-no-p mt-lg-3">
                                <CardFourColumn data={this.state.data.content} type={this.state.data.style.value} category={true} local={true}/>
                            </div>
                            :
                            null
                            }
                        </div>
                        </div>
                    </section>
              </div>
            )
        }
        else{
            return(
              <div>
                {
                  this.state.dataLoading ?
                    <PageLoader/>
                  :
                    <NothingFound/>
                }
              </div>
            );
        }
    }
}

export default LocalCategoryList;