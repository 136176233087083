
/*Package importing*/
import React, { Component } from 'react';

/*Assets*/
import type1 from '../../assets/images/online/men-subcategory-1.svg';
import type2 from '../../assets/images/online/men-subcategory-2.svg';
import type3 from '../../assets/images/online/beauty-1.svg';
import type4 from '../../assets/images/online/wedding-1.svg';
import type5 from '../../assets/images/online/footwear-1.svg';
import type6 from '../../assets/images/online/gold-1.svg';
import type7 from '../../assets/images/online/tv-1.svg';
import type8 from '../../assets/images/online/travel-1.png';
import type9 from '../../assets/images/online/food-1.png';
import type10 from '../../assets/images/online/bike-1.svg';
import type11 from '../../assets/images/online/mobile-1.svg';
import type12 from '../../assets/images/online/bag-1.svg';
import type13 from '../../assets/images/online/headphone-1.png';
import type14 from '../../assets/images/online/home-1.svg';
import type15 from '../../assets/images/online/work-1.svg';

class CardThreeAdColumn extends Component {
  constructor(props){
    super(props);
    this.state = {
      type: 1,

    }
  }

  componentDidMount() {
    this.setState({ type: this.props.type});
  }

  render() {

    var tempStyle = {};
    var tempClass = "row e-card-six-column";

    switch (this.props.type) {
      case 1: {
        tempStyle = {background: "#fff", borderLeft: "4px solid #FFDCD7", color: "#000"};
        tempClass = tempClass + " e-card-six-style-1";
        break;
      }
      case 2: {
        tempStyle = {background: "#000", color: "#fff"};
        tempClass = tempClass + " e-card-six-style-2";
        break;
      }
    }


    return (

      <div className={tempClass}>
        {
          this.props.data.map( (data, index) => {
            if(data.content){
              return(
                <div className={ index == 2 ? "col-lg-4 col-6 offset-3 e-card-item-wrap mb-3 col-sm-4 col-md-4 offset-sm-0" : "col-lg-4 col-6 e-card-item-wrap mb-3 col-sm-4 col-md-4 offset-sm-0"} key={index}>
                  <a href={data.link} target="_blank">
                    <div className="position-relative">
                     <img className="e-card-3-ad-img" src={data.content} alt="ads banner"/>
                    </div>
                  </a>
                </div>
              );
            }
            else{
              return(
                null
              );
            }
            
          })
        }
      </div>
    );
  }
}

export default CardThreeAdColumn;
