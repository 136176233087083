
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*Assets*/
import Banner1 from '../../assets/images/online/RecentlyViewed-1.png';
import Image1 from '../../assets/images/Testimonials/testimonial (1).png';
import Image2 from '../../assets/images/Testimonials/testimonial (2).png';
import Image3 from '../../assets/images/Testimonials/testimonial (3).png';
import Image4 from '../../assets/images/Testimonials/testimonial (4).png';
import Image5 from '../../assets/images/Testimonials/testimonial (5).png';
import Image6 from '../../assets/images/Testimonials/testimonial (6).png';
import Image7 from '../../assets/images/Testimonials/testimonial (7).png';
import Image8 from '../../assets/images/Testimonials/testimonial (8).png';
import Image9 from '../../assets/images/Testimonials/testimonial (9).png';



const testimonialsData=[
  {
    message: `There has been a lot of benefit from the use of true khoj, earlier everyone used to buy
    Clothing by ordering from home online, which was affecting our sales, we have also
    digitalized our shop online with true khoj, now we are Very easy to do online, true khoj are
    very easy and not expensive.`,
    name: `Ramesh (Clothing Vendor)`,
    image: Image1
  },
  {
    message: `Truekhoj is fabulous concept, its announcement feature is very good, it gave me a way to promote my
    designs directly my consumers, from here I can connect as many as customers and can sell my things
    directly to them.`,
    name: `Sanchita (Boutique owner)`,
    image: Image2
  },
  {
    message: `As the online work was increasing, our work was decreasing in the same way, we can also
    give offers, but we did not have any way to give, now all the work is easily done online with
    the true khoj and We also take orders from customers, and I don’t have to pay any
    commission for this.`,
    name: `Amit kumar (Grocery Vendor)`,
    image: Image3
  },
  {
    message: `I was facing issue each time but the problem of customers was always there. With the help
    of TrueKhoj, my store got online and now I was sell my products easy to the customers .`,
    name: `Vasudha- Footwear Seller`,
    image: Image4
  },
  {
    message: `I have launched my clothing brand website with good discount but the product selling was
    not good, then I started my promotion with true khoj. Now my product is also sold is very
    good and easy at my own webportal.`,
    name: `zeba- CEO (Byshaa)`,
    image: Image5
  },
  {
    message: `We started our hotel business a few months back but there was a shortage of customers,
    then we listed our hotel on truekhoj, now customers are getting to us easily all time.`,
    name: `Rohit Sharma (Hotel Manager)`,
    image: Image6
  },
  {
    message: `I was open a restaurant last months but due to being new, people do not like to come, then
    I started listing my food on truekhoj, now it works better. It is a better marketing platform.`,
    name: `Mahesh ( resutant Vendor)`,
    image: Image7
  },
  {
    message: `I created my jewelry website & launch my brand so that people buy my product online but
    users are not getting, then I started my advertisement on truekhoj, now my work became
    very easy now. Customers find me very easy.`,
    name: `C.K.Kapadia ( jewellery vendor)`,
    image: Image8
  },
  {
    message: `I had opened a hostel last 1 year for work, if students come from outside, they also get
    accommodation facilities and I also have profit, but i don’t have good way for my promotion,
    then I created hostel profile on truekhoj. Now my hostel working properly.`,
    name: `Mr. Shyam (Hostel & PG Worker)`,
    image: Image9
  }
];

class Testimonials extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      autoplay: true,
      autoplaySpeed: 3000,
      arrow: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          }
        }
      ]
    };

    return (
      <Slider className="e-testimonials-wrap" {...settings}>
        {
          testimonialsData.map(testimonial=>{

            return(
              <div className="e-testimonials-item" >
                <span className="e-testimonials-bg"></span>
                <p className="d-flex flex-column justify-content-between">
                  {testimonial.message}
                  <div className="e-testimonials-user text-right">
                    <img src={testimonial.image}/>
                    <span>{testimonial.name}</span>
                  </div>
                </p>
              </div>
                  )
          })
        }
      </Slider>
    );
  }
}

export default Testimonials;
