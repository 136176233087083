/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton.js';
import TextButton from '../../components/buttons/textButton.js';
import Input from '../../components/formComponents/input';
import ButtonLoader from '../common/buttonLoader.js';



/*Assets*/
import Img from '../../assets/images/online/pass-img.svg';

/*Services*/
import devconfig from '../../config/config.js';
import PhoneValidation from '../../services/validation/phone';
import APIPost from '../../services/apiCall/post';



class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      newPassword:"",
      confirmPassword:"",
      apiLoading: false,
      error: ""
    }
  }
  validateForm=()=> {
    // alert("hi");

    if(!this.state.newPassword.length){
       this.setState({error:"New Password missing"});
    }
    else if(!this.state.confirmPassword.length){
       this.setState({error:"Confirm Password missing"});
    }
    else {
      this.setState.success();
    }

  }



  render() {

    return (

      <div className="row e-login-modal e-change-password">
        <div className="col-lg-6 col-6">
          <div className="e-left-banner">
            <div className="text-center"><img src={Img} className="e-login-img e-change-passwrd-img"/></div>
          </div>
        </div>
        <div className="col-lg-6 col-6">
          <div className="e-login-content-wrap">

            <div className="row pt-3">
              <div className="col-lg-12 col-md-12 pr-0">
                <label>new password</label>
                <div>
                  <Input className=""  type="password" value={this.state.newPassword} valueChange={(tempVal) => this.setState({newPassword: tempVal})} autoComplete="nope"/>
                  <div id="e-error" className="error pt-1">{this.state.error}</div>
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12 col-md-12 pr-0">
                <label>confirm password</label>
                <div>
                  <Input className=""  type="password" value={this.state.confirmPassword} valueChange={(tempVal) => this.setState({confirmPassword: tempVal})} autoComplete="nope"/>
                  <div id="e-error" className="error pt-1">{this.state.error}</div>
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-lg-12 pr-0">
                {
                  this.state.apiLoading?
                  <div className="float-right">
                    <ButtonLoader/>
                  </div>
                  :
                  <div className="">
                  {
                    this.state.newPassword && this.state.confirmPassword ?
                    <PrimaryButton  className="e-p-btn e-contact-btn e-ofer-btn e-login-btn" name="Set New Password" handleClick={this.validateForm.bind(this)}/>
                    :
                    <PrimaryButton  className="e-inactive e-p-btn e-p-btn e-contact-btn e-ofer-btn e-login-btn" name="Set New Password" handleClick={this.validateForm.bind(this)} />
                  }
                </div>
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;