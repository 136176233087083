/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Slider from "react-slick";


/*Components*/

/*Assets*/
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*Services*/

class ImageViewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',

    }
  }

  componentDidMount() {
    this.setState({
        nav1: this.slider1,
        nav2: this.slider2
      });
  }

  render() {

    var settings = {
        asNavFor: this.state.nav2,
        ref: (slider) => this.slider1 = slider,
       dots: false,
       infinite: true,
       speed: 500,
       slidesToShow: 1,
       autoplay: false,
       arrows: true,
       swipeToSlide: true,
       slidesToScroll: 1,
       className: "center",
     };

     var settings2 = {
        asNavFor: this.state.nav1,
        ref: (slider) => this.slider2 = slider,
       dots: false,
       infinite: true,
       slidesToShow: this.props.images? this.props.images.length: 1,
       autoplay: false,
       swipeToSlide: true,
       focusOnSelect: true,
       className: "center",
       focusOnSelect: true,
     };

    return (
      <div className="modal fade e-image-viewer" id="imageViewModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content e-ofer p-3">
             <div className="modal-header pb-0">
              <h6 className="e-modal-header text-uppercase mb-0">{this.props.data? this.props.data.name: null}</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div className="container pt-3">
                  <div className="row">
                    <div className="col-lg-8">
                      <Slider className="e-product-wrap" {...settings}>
                        {
                            this.props.images && this.props.images.map(image=>(
                                <div className="container">
                                    <img src={image} alt="i" className="w-100"/>
                                </div>
                            ))
                        }
                    </Slider>
                    </div>
                    <div className="col-lg-4 pt-3 pt-lg-0">
                      <div className="row d-none d-lg-block">
                        
                      </div>
                      <div className="row h-100 d-flex align-items-center">
                        <div className="col-lg-12">
                        <Slider className="e-product-wrap" {...settings2}>
                        {
                            this.props.images && this.props.images.map(image=>(
                                <div className="container e-product-thumb" style={{maxWidth: "140px"}}>
                                    <img src={image} alt="i" className="w-100 e-product-image-thumb"/>
                                </div>
                            ))
                        }
                    </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default ImageViewModal;
