/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton.js';
import TextButton from '../../components/buttons/textButton.js';
import Input from '../../components/formComponents/input';
import ButtonLoader from '../common/buttonLoader.js';



/*Assets*/
import Img from '../../assets/images/online/pass-img.svg';
import Success from '../../assets/images/online/confirm.svg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';



class PasswordChanged extends Component {
  constructor(props){
    super(props);
    this.state = {
      number: 0,
      apiLoading: false,
      error: ""
    }
  }



  render() {

    return (

      <div className="row e-login-modal e-change-password">
        <div className="col-lg-6 col-md-6">
          <div className="e-left-banner">
            <div className="text-center"><img src={Img} className="e-login-img e-change-passwrd-img"/></div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="e-login-content-wrap">
            <div className="modal-header pb-0">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 pr-0">
                 <div className="text-center"><img src={Success} className="e-success-img"/></div>
              </div>
            </div>


          </div>
        </div>
      </div>
    );
  }
}

export default PasswordChanged;