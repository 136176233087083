
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGA from 'react-ga';

/*Assets*/

import SecondaryHeading from '../../components/navigations/secondary-heading';
import AdsHeroBanner from '../../components/ads/banners/hero-banner.js';
import AdsFourColumn_One from '../../components/ads/list/four-column-style-1.js';
import LocalStoreStrip from '../../components/ads/strip-banners/localstore-strips.js';
import AdsSectionBanner_1 from '../../components/ads/banners/section-banner-1.js';
import AdsSectionBanner_2 from '../../components/ads/banners/section-banner-2.js';
import RecentlyViewed from '../../components/sliders/recently-viewed.js';
import Testimonials from '../../components/sliders/testimonials.js';
import Subscription from '../../components/common/subscription.js';
import LogoSlider from '../../components/ads/sliders/logo.js';
import H2 from '../../components/headings/h2.js';
import CardFourColumn from '../../components/cards/column-4.js';
import ThreeFourthCategory from '../../components/ads/banners/threeFourth-banner-withCategory.js';
import ThreeBanner from '../../components/ads/banners/three-banner.js';
import FiveBanner from '../../components/ads/banners/five-banner.js';
import LogoHeading from '../../components/headings/logo-heading.js';
import CardSixColumn from '../../components/cards/column-6.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import FindBannerType from '../../components/ads/banners/findBannerType';
import OffersModal from '../../components/modals/offersPopup.js';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import GetURLVar from '../../services/getParams/get-url-var'; 
import CardTwoColumn from '../../components/cards/column-2';
import CardThreeColumn from '../../components/cards/column-3';

class Category extends Component {
  constructor(props){
    super(props);
    this.state = {
      data:null,
      id: 0
    }
  }

  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    ReactGA.pageview(window.location.pathname);
    GetURLVar().then(function(query){
      if(query){
        this.getLayout(query.id);
        this.setState({id: query.id});
        this.setVisited(query.id);
      }
    }.bind(this));
  }

  setVisited(id){
    if(localStorage.getItem('truekhoj_user')) this.setVisitedId(id);
    if(localStorage.getItem("truekhoj_recents_online")){
      let recent = JSON.parse(localStorage.getItem("truekhoj_recents_online"));
      let i = recent.indexOf(Number(id));
      if(i===-1) recent.push(Number(id));
      if(recent.length>4) recent.shift(); 
      localStorage.setItem("truekhoj_recents_online", JSON.stringify(recent));
    }
    else{
      let recent = [Number(id)];
      localStorage.setItem("truekhoj_recents_online", JSON.stringify(recent));
    }
  }

  setVisitedId(id){
    var url = devconfig.userV1+"/page/recentlyviewed/add";
    var data = JSON.stringify({
      "local" : false,
      "category_id" : Number(id)
    })
    APIPost(url, data).then(function(response){

    }.bind(this))
  }

  getLayout(tempId){
    this.setState({dataLoading: true});
    var url = devconfig.userV1+"/page/detail";
    var data = JSON.stringify({
      "id" : Number(tempId),
      "type" : 1
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data.page_data.ads || response.data.page_data.layout){
          this.setState({data: response.data, dataLoading: false});
        }
        else{
          this.setState({dataLoading: false});
        }
        if(response.data&&response.data.layout_id) this.getPopup(response.data.layout_id);
      }
    }.bind(this))
  }

  getPopup(id){
    let viewed = [];
    if(sessionStorage.getItem('viewed_pages')) viewed= JSON.parse(sessionStorage.getItem('viewed_pages'));
    if(viewed.indexOf(Number(id))===-1&& (localStorage.getItem('Login_promt')||localStorage.getItem('truekhoj_user'))){
      var url = devconfig.userV1+"/popupbanner/view";
      var data = JSON.stringify({
        "local" : false,
        "layout_id" : id
      });
      APIPost(url, data).then(function(response){
        if(response &&response.status === "ok"&& response.data && response.data.image){
          viewed.push(Number(id));
          sessionStorage.setItem('viewed_pages', JSON.stringify(viewed));
          this.setState({popup: response.data});
          $('#offersPopupModal').modal('show');
        }
      }.bind(this))
    }
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    var tempCat1 = [
      {title: "Men", image: "../../assets/images/online/clothing-1.svg"},
      {title: "Women", image: "../../assets/images/online/clothing-2.svg"},
      {title: "Kids", image: "../../assets/images/online/clothing-3.svg"},
      {title: "Sports", image: "../../assets/images/online/clothing-4.svg"},
      {title: "Men", image: "../../assets/images/online/clothing-1.svg"},
      {title: "Women", image: "../../assets/images/online/clothing-2.svg"},
    ]

    var tempCat2 = [
      {title: "watches", image: "../../assets/images/online/clothing-1.svg"},
      {title: "GLASSES", image: "../../assets/images/online/clothing-2.svg"},
      {title: "BAGS & ACCESSORIES", image: "../../assets/images/online/clothing-3.svg"},
      {title: "jewellery", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat3 = [
      {title: "WOMEN", image: "../../assets/images/online/clothing-1.svg"},
      {title: "MEN", image: "../../assets/images/online/clothing-2.svg"},
      {title: "AYURVEDIC", image: "../../assets/images/online/clothing-3.svg"},
      {title: "PERFUMES & FRAGNANCE", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat4 = [
      {title: "JEWELLERY", image: "../../assets/images/online/clothing-1.svg"},
      {title: "WEDDING PLANNER", image: "../../assets/images/online/clothing-2.svg"},
      {title: "GROOM APPAREL", image: "../../assets/images/online/clothing-3.svg"},
      {title: "BRIDE APPAREL", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat5 = [
      {title: "men", image: "../../assets/images/online/clothing-1.svg"},
      {title: "WOMEN", image: "../../assets/images/online/clothing-2.svg"},
      {title: "KIDS", image: "../../assets/images/online/clothing-3.svg"},
      {title: "SPORTS", image: "../../assets/images/online/clothing-4.svg"}
    ]
    
    var tempCat6 = [
      {title: "GOLD", image: "../../assets/images/online/clothing-1.svg"},
      {title: "DIAMOND", image: "../../assets/images/online/clothing-2.svg"},
      {title: "PLATINUM", image: "../../assets/images/online/clothing-3.svg"},
      {title: "PEARLS", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat7 = [
      {title: "televisions", image: "../../assets/images/online/clothing-1.svg"},
      {title: "washing machines", image: "../../assets/images/online/clothing-2.svg"},
      {title: "refrigerators", image: "../../assets/images/online/clothing-3.svg"},
      {title: "air conditioners", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat8 = [
      {title: "flights", image: "../../assets/images/online/clothing-1.svg"},
      {title: "buses", image: "../../assets/images/online/clothing-2.svg"},
      {title: "hotels", image: "../../assets/images/online/clothing-3.svg"},
      {title: "tours", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat9 = [
      {title: "food & beverages", image: "../../assets/images/online/clothing-1.svg"},
      {title: "food products", image: "../../assets/images/online/clothing-2.svg"},
      {title: "groceries", image: "../../assets/images/online/clothing-3.svg"},
      {title: "pet supplies", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat10 = [
      {title: "cars", image: "../../assets/images/online/clothing-1.svg"},
      {title: "bikes", image: "../../assets/images/online/clothing-2.svg"},
      {title: "scooters", image: "../../assets/images/online/clothing-3.svg"},
      {title: "luxury cars", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat11 = [
      {title: "smartphones", image: "../../assets/images/online/clothing-1.svg"},
      {title: "laptops", image: "../../assets/images/online/clothing-2.svg"},
      {title: "game consoles", image: "../../assets/images/online/clothing-3.svg"},
      {title: "accessories", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat12 = [
      {title: "bags", image: "../../assets/images/online/clothing-1.svg"},
      {title: "watches", image: "../../assets/images/online/clothing-2.svg"},
      {title: "jewellery", image: "../../assets/images/online/clothing-3.svg"},
      {title: "vintage", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat13 = [
      {title: "HEADPHONES", image: "../../assets/images/online/clothing-1.svg"},
      {title: "POWERBANKS", image: "../../assets/images/online/clothing-2.svg"},
      {title: "COVERS", image: "../../assets/images/online/clothing-3.svg"},
      {title: "SMART WATCHES", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat14 = [
      {title: "AT HOME", image: "../../assets/images/online/clothing-1.svg"},
      {title: "WORK", image: "../../assets/images/online/clothing-2.svg"},
      {title: "SLEEPWEAR & LINGERIE", image: "../../assets/images/online/clothing-3.svg"},
      {title: "CASUAL", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat15 = [
      {title: "WORK", image: "../../assets/images/online/clothing-1.svg"},
      {title: "BEAUTY & CARE", image: "../../assets/images/online/clothing-2.svg"},
      {title: "NEON", image: "../../assets/images/online/clothing-3.svg"},
      {title: "CASUAL", image: "../../assets/images/online/clothing-4.svg"}
    ]
    if(!this.state.data ){
      return(
        <div>
          {
            this.state.dataLoading ?
              <PageLoader/>
            :
              <NothingFound/>
          }
          <OffersModal data={this.state.popup}/>
        </div>
      )
    }
    else{
      var data = this.state.data.page_data;
      return (
        <div className="container-fluid p-0">
          <SecondaryHeading data={this.state.data}/>
 
          {
            data.ads && data.ads.hero && data.ads.hero.data && data.ads.hero.data[0].content ?
              <div className="row pb-5 e-mb-15">
                <div className="col-lg-12 p-0">
                  <FindBannerType content={data.ads.hero.data} details={data.ads.hero} defaultType={1}/>
                  {/*<AdsHeroBanner content={data.ads.hero.data}/>*/}
                </div> 
              </div>
            :
              null
          }
          
          {
            data.ads && data.ads.logoSlider && data.ads.logoSlider.data[0].content ?
              <div className="container">
                <div className="pb-5 e-no-mb">
                  {/*<LogoSlider data={data.ads.logoSlider.data}/>*/}
                  <FindBannerType content={data.ads.logoSlider.data} details={data.ads.logoSlider} defaultType={8}/>
                </div>
              </div>
            :
              null
          }

          <section className="container-fluid position-relative e-no-p">
            <div className="container">
              <div className="row pb-5 e-no-p e-no-m">
                <div className="col-lg-12 mb-4 e-no-p">
                  <H2 data="BUY OF THE MOMENT SPECIAL" type={17}/>
                </div>
                {
                  data.ads && data.ads.buyOfMomentBanner && data.ads.buyOfMomentBanner.data[0].content ?
                    <div className="col-lg-12 col-md-12 mb-4 e-no-p e-mb-15">
                      {/*<ThreeFourthCategory/>*/}
                      <FindBannerType content={data.ads.buyOfMomentBanner.data} details={data.ads.buyOfMomentBanner} defaultType={1}/>
                      {/*<AdsSectionBanner_1 link={data.ads.buyOfMomentBanner.data[0].link} data={ data.ads.buyOfMomentBanner.data[0].content}/>*/}
                    </div>
                  :
                    null
                }
                {
                  data.ads && data.ads.buyOfMomentBanner && data.ads.buyOfMomentSubBanners.data[0].content ?
                    <div className="col-lg-12 mb-4 e-no-p">
                      <FindBannerType content={data.ads.buyOfMomentSubBanners.data} details={data.ads.buyOfMomentBanner} defaultType={3}/>
                      {/*<ThreeBanner data={data.ads.buyOfMomentSubBanners.data}/>*/}
                    </div>
                  :
                    null
                }
                
              </div>
            </div>
            <span className={data.ads.buyOfMomentBanner && data.ads.buyOfMomentBanner.bg_color? "e-section-bg-half e-buyofmomemt-bg" :"e-section-bg-half"} style={data.ads.buyOfMomentBanner && data.ads.buyOfMomentBanner.bg_color? {background: data.ads.buyOfMomentBanner.bg_color}: {background: "#fff"}}></span>
          </section>
          {
            this.state.data && data.ads.brandAds1 && data.ads.brandAds1.data[0].content ?
              <section className="container-fluid position-relative">
                <div className="container">
                  <div className="row pb-5 e-no-mb e-no-p e-no-m">
                    {
                      data.ads.brandLogo && data.ads.brandLogo.data[0].content ?
                        <div className="col-lg-12 mb-4 e-no-p">
                          <LogoHeading data={data.ads.brandLogo.data[0]}/>
                        </div>
                      :
                        null
                    }
                    
                    <div className="col-lg-12 mb-4 e-no-p">
                      {/*<FiveBanner 
                        adLeftTop={data.ads.brandAds1.data[0]}
                        adLeftBottom={data.ads.brandAds1.data[1]}
                        adCenter={data.ads.brandAds1.data[2]}
                        adRightTop={data.ads.brandAds1.data[3]}
                        adRightBottom={data.ads.brandAds1.data[4]}
                      />*/}
                      <FindBannerType content={data.ads.brandAds1.data} details={data.ads.brandAds1} defaultType={6}/>
                    </div>
                  </div>
                </div>
                <span className={data.ads.buyOfMomentBanner && data.ads.buyOfMomentBanner.bg_color? "e-section-bg-half e-buyofmomemt-bg e-bg-bottom" :"e-section-bg-half"} style={data.ads.buyOfMomentBanner && data.ads.buyOfMomentBanner.bg_color? {background: data.ads.buyOfMomentBanner.bg_color}: {background: "#fff"}}></span>
              </section>
            :
              null

          }
          

          {/*<section className="container-fluid position-relative">
            <div className="container">
              <div className="row mb-5 e-no-mb">
                <div className="col-lg-12 mb-4 e-no-p">
                  <LogoHeading/>
                </div>
                <div className="col-lg-12 mb-4 e-no-p">
                  <AdsSectionBanner_2/>
                </div>
              </div>
            </div>
            <span className="e-section-bg-half" style={{background: "#fff"}}></span>
          </section>*/}


          {
            this.state.data && this.state.data.page_data.layout.map( (item, index) => {

              if(item.bg_color){
                var tempStyle = {background: item.bg_color};
              }


              return(
                <React.Fragment>
                  <section className="container-fluid position-relative" style={tempStyle}>
                    <div className="container">
                      <div className="row pt-5">
                        <div className={tempStyle ? "col-lg-12 pb-4 pt-5 e-no-p" : "col-lg-12 pb-4 e-no-p"}>
                          <H2 data={item.header.content} type={item.header.type} bg_color={item.header.bg_color} font_color={item.header.font_color}/>
                        </div>
                        {
                          item.ads.banner_type ?
                            <div className="col-lg-12 pb-4 e-no-p ">
                              {/*<AdsSectionBanner_1 data={item.ads.content} link={item.ads.link}/>*/}
                              <FindBannerType content={item.ads.data} details={item.ads}/>
                            </div>
                          :
                            <div className="col-lg-12 pb-4 e-no-p">
                              <FindBannerType content={[item.ads]} details={item.ads} defaultType={1}/>
                            </div>
                        }
                        
                        <div className="col-lg-12 mb-5 e-no-p">
                        {
                         item.logo_slider &&item.logo_slider.data&& item.logo_slider.data.length && item.logo_slider.data[0].content ?
                            <div className="container">
                              <div className="pb-5 e-no-mb">
                                <FindBannerType content={item.logo_slider.data} details={item.logoSlider} defaultType={8}/>
                              </div>
                            </div>
                          :
                            null
                        }
                        </div>
                      </div>
                    </div>
                    <span className="e-section-bg-half" style={{background: "#fff"}}></span>
                  </section>

                  <section className="container-fluid position-relative" style={tempStyle}>
                    <div className="container">
                      <div className="row pb-5 ">
                        <div className="col-lg-12 mb-4 e-no-p">
                          <H2 data="SHOP BY CATEGORY" type={6}/>
                        </div>
                        {
                        item.data.content && item.data.type && item.data.type === 1 ?
                          <div className="col-lg-12 mb-3 e-no-p">
                            <CardFourColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} id={this.state.id} product={true} />
                          </div>
                          : item.data.content && item.data.type && item.data.type === 2 ?
                            <div className="col-lg-12 mb-3 e-no-p">
                              <CardSixColumn data={item.data.content} type={item.data.style.value}bg_color={item.header.bg_color} id={this.state.id} product={true} />
                            </div>
                            : item.data.content && item.data.type && item.data.type === 3 ?
                              <div className="col-lg-12 mb-3 e-no-p">
                                <CardTwoColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} id={this.state.id} product={true} />
                              </div>
                              : item.data.content && item.data.type && item.data.type === 4 ?
                                <div className="col-lg-12 mb-3 e-no-p">
                                  <CardThreeColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} id={this.state.id} product={true} />
                                </div>
                                :
                                null
                      }
                      </div>
                    </div>
                    <span className="e-section-bg-half" style={{background: "#fff"}}></span>
                  </section>

                </React.Fragment>
              );
            })
          }

          {/* Subscription */}
          <Subscription/>
          <OffersModal data={this.state.popup}/>


        </div>
      );
    }
    
  }
}

export default Category;
