
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton';



/*Assets*/

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/apiCall/post';

class CareerAccordion extends Component {
  constructor(props){
    super(props);
    this.state = {
      data:''

    }
  }

  componentWillMount() {


  }
  mail=()=> {
     window.open("mailto:trukhoj@gmail.com");
  }



  render() {
    const id = this.props.accordionId;
    const idwithhash = id ? `#${id}` : "nope";


    return (

      <div class="accordion pt-3" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-link text-left" type="button" data-toggle="collapse" data-target={idwithhash} aria-expanded="false" aria-controls={this.props.accordionId}>

                <p className="mb-2">{this.props.designation}</p>
                <p className="e-place mb-0">{this.props.location}</p>

              </button>
            </h5>
          </div>
          <div id={this.props.accordionId} class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body pl-3 m-1 pr-5 pt-4">
            <h6>{this.props.designation}</h6>
              {ReactHtmlParser(this.props.description)}
              <div className="text-right pt-4 pb-3">
                <PrimaryButton className="e-p-btn e-career-btn" name="Apply Now" handleClick={this.mail.bind()}/>
              </div>
            </div>

          </div>
        </div>


      </div>


    );
  }
}


export default CareerAccordion;