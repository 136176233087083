/*Package importing*/
import React, { useEffect, useState }  from 'react';
import $, { timers } from 'jquery';


/*Components*/

import Input from '../../../components/formComponents/input';
import Textarea from '../../../components/formComponents/TextArea.js';
import PrimaryButton from '../../../components/buttons/primaryButton';



/*Assets*/
import ModalImg from '../../../assets/images/local/wholesale/modal-img.svg';
import devconfig from '../../../config/config';
import APIPost from '../../../services/apiCall/post';
import ButtonLoader from '../../common/buttonLoader';

/*Services*/


const SetLocationsModal = (props)=>{

    const [selectedItem, setSelected] = useState(null);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [searchResults, setResults] = useState(false);
    const [suggestedIds, setSuggestedIds] = useState([]);
    const [nearbyLocations, setNearby] = useState(false);
    const [apiLoading, setLoading] = useState(true);

    useEffect(()=>{
        setTempLocations();
    },[props.locations]);

    useEffect(()=>{
        setSuggested();
    },[props.suggestedLocs, suggestedIds]);

    const setSuggested=()=>{
        if(props.suggestedLocs && props.locations){
            let results = [];
            let ids = [];
            props.suggestedLocs.forEach(async (item) => {
                props.locations.forEach(loc=>{
                    if(loc.label.includes(item)&&ids.indexOf(loc.value)==-1){
                        results.push(loc);
                        ids.push(loc.value);
                    } 
                })
            });
            if(!results.length) results.push({ value: null, label:  props.suggestedLocs[0]});
            setNearby(true);
            setResults(false);
            setLoading(false);
            setOptions(results);
        }
        else if(suggestedIds.length && props.locations){
            let results = [];
            let ids = [];
            suggestedIds.forEach(async (item) => {
                props.locations.forEach(loc=>{
                    if(loc.label.includes(item)&&ids.indexOf(loc.value)==-1){
                        results.push(loc);
                        ids.push(loc.value);
                    }
                })
            });
            if(!results.length) results.push({ value: null, label:  suggestedIds[0]});
            setLocation(results[0]);
            setNearby(true);
            setResults(false);
            setLoading(false);
            setOptions(results);
        }
    }

    const setTempLocations=()=>{
        if(props.locations){
            let locations=[];
            let maxCount = 5;
            if(window.innerWidth<575) maxCount=3;
            props.locations.forEach((element, i) => {
                if(i<maxCount) locations.push(element);
            });
            console.log('locations', locations);
            locations.push({ value: null, label: "Others" });
            setResults(false);
            setLoading(false);
            setOptions(locations);
        }
    }

    const searchAction = async () =>{
        if(search.length){
            const query = search.toLowerCase();
            const locations = props.locations;
            let results = []
            results = await locations.filter(location => {
                return (location.label.toLowerCase().includes(query));
            });
            results.push({ value: null, label: "Others" });
            setNearby(false);
            setResults(true);
            setOptions(results);
        }
        else{
            setTempLocations();
        }
    }

    const setLocation=(loc)=> {
        if(localStorage.getItem("truekhoj_user_location")){
            let currentLoc = localStorage.getItem("truekhoj_user_location");
            localStorage.setItem("truekhoj_prev_location", currentLoc);
        }
        if(loc) localStorage.setItem("truekhoj_user_location", JSON.stringify(loc));
        else localStorage.setItem("truekhoj_user_location", JSON.stringify(selectedItem));
        window.location.reload(); 
    }

    const getCurrentLocation=()=>{
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((p) =>{
                setLoading(true);
                let url = devconfig.localUser + "/location/reverse-geocode";
                let data = JSON.stringify({
                "latitude": p.coords.latitude,
                "longitude": p.coords.longitude
                });
                // let data = JSON.stringify({
                //     "latitude": 12.85968,
                //     "longitude": 77.539
                //     });
                APIPost(url, data).then((response)=> {
                if (response.status === "ok") {
                    let places=[];
                    if(response.data && response.data.length){
                        let temp = response.data[0].address_components;
                        console.log('places',temp);
                        temp.forEach((item, i)=>{
                            if(i<temp.length-2) places.push(item.long_name);
                        })
                        setSuggestedIds(places);
                        console.log(places);
                    }
                    // if(response.data && response.data.length){
                    // let temp = [];
                    // response.data.forEach(element => {
                    //     temp.push(element.id);
                    // });
                    // setSuggestedIds(temp);
                    // }
                }
                else {
                    console.log(response.error);
                }
                });
            });
         }
    }

    return(
        <div className="modal fade e-best-ofer-modal e-location-modal" id="setLocationsModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content e-ofer">
                        <div className="modal-header pb-2 pt-3">
                            <h6 className="e-modal-header text-uppercase mb-0 e-location-header">Select Your Location</h6>
                        </div>
                        <div className="row pt-3 px-3">
                            <div className="col-lg-12">
                            <div className="e-input-wrap position-relative">
                                <input type="text" value={search} id="location-search" onChange={(e)=>setSearch(e.target.value)} onKeyDown={(e)=>{searchAction();}}  placeholder="Search for locations" autoComplete={false}/>
                                {
                                    search.length?
                                    <span className="e-search-close position-absolute" onClick={()=>{setSearch(""); setTempLocations();}}>&times;</span>
                                    :
                                    null
                                }
                            </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-end pt-2 container px-0">
                                    <span className="e-get-location" onClick={()=>getCurrentLocation()}>Detect current location</span>
                                </div>
                            </div>
                        </div>
                        <div className="row py-4 px-3">
                            <div className="col-lg-12 pt-2 pb-3">
                                <h6 className="text-uppercase mb-0">{nearbyLocations&&!searchResults? "Your Locations": searchResults? "Search Reasults" : "Popular Locations"}</h6> 
                            </div>
                            {
                                apiLoading?
                                <div className="col-lg-12 pt-5">
                                    <div className="d-flex container pt-4 justify-content-center">
                                        <ButtonLoader/>
                                    </div>
                                </div>
                                :
                                options && options.map((item, index) => {
                                    let loc = item.label
                                    const temp = item.label.search(",");
                                    if(temp!=-1){
                                        loc = item.label.slice(0, temp);
                                    }                                    
                                    return (
                                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                                            <div className={selectedItem == item ? "e-outer-wrap e-active" : "e-outer-wrap"} onClick={(e) => setSelected(item)}>
                                                <span className="text-truncate d-block" style={{maxWidth:"100%"}}>{loc}</span>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>

                        <div className="row px-3">
                            <div className="col-lg-12">
                                <span className="float-right">
                                    {
                                        selectedItem ?
                                            <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn" name="Select Location" handleClick={() => setLocation()} />
                                            :
                                            <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn e-inactive" name="Select Location" />
                                    }

                                </span>
                            </div>
                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>

            </div>
        
    )
}

export default SetLocationsModal;
