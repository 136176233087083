
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';


/*Components*/

import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/TextArea.js';



/*Assets*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';


class EnquiryForm extends Component {
  constructor(props){
    super(props);
    this.state = {

      name: "",
      search: "",
      phone: "",
      quantity:"",
      error:"",
      data:"",
      success:""


    }
  }

  componentDidMount() {


  }







  render() {


      return (
        <div className="e-enquiry-form-wrapper">
          <h5>One Request Multiple Quotes!!</h5>
          <div className="row pt-3">
            <div className="col-lg-6 col-md-6">
              <label>Name</label>
              <div>
                <Input className="e-text e-content e-text-transform"  type="text" value={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} placeholder="Name" autoComplete="nope"/>

              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <label>Phone</label>
              <div>
                <Input className="e-text e-content"  type="number" value={this.state.phone} valueChange={(tempVal) => this.setState({phone: tempVal})} placeholder="Phone Number" autoComplete="nope"/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4">
              <label>what you looking for ?</label>
              <div>
                <Input className="e-content" type="text" value={this.state.search} valueChange={(tempVal) => this.setState({search: tempVal})} placeholder="Search for products or categories" autoComplete="nope"/>

              </div>
            </div>
          </div>
          <div className="row pt-1">
            <div className="col-lg-3 pt-3 pr-0">
              <label>Quantity</label>
              <div>
                <Input className="e-content" type="number" value={this.state.quantity} valueChange={(tempVal) => this.setState({quantity: tempVal})} placeholder="Quantity" autoComplete="nope"/>

              </div>
            </div>
            <div className="col-lg-9 pt-4 pl-2">
              <p className="mt-4 pt-2">Pcs</p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-5">

              <PrimaryButton  className="e-p-btn e-contact-btn e-enquiry-btn" name="Request for Quotation"/>
            </div>
          </div>
        </div>

      );
    }
  }


export default EnquiryForm;

// <div className="mb-5 pt-3 pb-5">
//                       <PrimaryButton  className="e-p-btn e-contact-btn" name="Send Message" handleClick={this.submitForm.bind(this)}/>
//                     </div>
