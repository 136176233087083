/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';


/*Components*/

import Input from '../../formComponents/input';
import Textarea from '../../formComponents/TextArea.js';
import PrimaryButton from '../../buttons/primaryButton';
import TextButton from '../../buttons/textButton';
import SelectBox from '../../formComponents/selectBox';
import ButtonLoader from '../../common/buttonLoader.js';
import RangeSelector from '../../slider/slider';


/*Assets*/
import ModalImg from '../../../assets/images/local/wholesale/modal-img.svg';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';




class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {price: {status: false, lower: 0, upper: 100000}, subcategory: null, brand: null},
      filters: "Shop",
      error: "",
    }
  }

  componentDidMount() {
    let filter = {price: this.props.filter.price, subcategory: this.props.filter.subcategory, brand: this.props.filter.brand}
    this.setState({filter: filter, department: this.props.department, category: this.props.category});      
  }




  render() {

    const filters = [
        {
            name:"Shop", 
            data: <div className="container pb-4">
                {
                    this.props.shop && this.props.shop.map(item=>{
                        return(
                            <span className="d-block py-2 pl-2 font-weight-normal text-uppercase" style={{cursor: "pointer"}} onClick={async ()=>{let temp= this.state.filter; temp.brand = item; this.setState({filter: temp})}}>
                                          <div class="d-flex">
                                            <div className="e-checkbox">{item.name}
                                              <input type="checkbox" value={{item}} id={`checkbox-${item.id}`} checked={this.state.filter.brand===item? true: false} />
                                              <span class="checkmark"></span>
                                            </div>
                                           </div>
                                        </span>
                        );
                    })
                }
            </div>
        
        },
        {
            name: "Price",
            data: <div className="container pb-4 position-relative">
            <span className="pb-4 d-block">
            Price Range
            </span>
            {
              this.state.filters==="Price"?
              <RangeSelector min={0} max={100000} defaultValue={[this.state.filter.price.lower, this.state.filter.price.upper]} onChange={async (val)=>{let temp = this.state.filter; temp.price.status=true; temp.price.lower=val[0]; temp.price.upper=val[1]; this.setState({filter: temp});}}/>
              :
              null
            }
            <span className="position-absolute px-1 pt-3" style={{bottom: 0, left: 0, zIndex: 1}}><TextButton name={`₹${this.state.filter.price.lower}`} className="e-t-btn" noBackground={true}/></span>
          <span className="position-absolute px-1 pt-3" style={{bottom: 0, right: 0, zIndex: 1}}><TextButton name={`₹${this.state.filter.price.upper}`} className="e-t-btn" noBackground={true}/></span>
          </div>
        },
        {
            name: "Category",
            data: <div className="container pb-4">
            {
              this.props.data?
              this.props.data.map(department=>(
                <>
                <span className={this.state.department==department.name? "e-filter-btn e-filter-active d-block py-3 pl-2 font-weight-bold" : "e-filter-btn d-block py-3 pl-2 font-weight-bold"} style={{cursor: "pointer"}} onClick={()=>{if(this.state.department!=department.name){this.setState({department: department.name})}else{this.setState({department: ''})}}}>{department.name}</span>
                <div className={this.state.department==department.name? "container": "container d-none"}>
                  {
                    department.category.map(category=>(
                      <>
                        <span className={this.state.category==category.name? "e-filter-btn e-filter-active d-block py-3 pl-2 font-weight-bold" : "e-filter-btn d-block py-3 pl-2 font-weight-bold"} style={{cursor: "pointer"}} onClick={()=>{if(this.state.category!=category.name){this.setState({category: category.name})}else{this.setState({category: ''})}}}>{category.name}</span>
                        <div className={this.state.category==category.name? "container": "container d-none"}>
                          {
                            category.subcategory.map(sub=>(
                              <span className="d-block py-3 pl-2 font-weight-normal text-uppercase" style={{cursor: "pointer"}} onClick={async ()=>{let temp = this.state.filter; temp.subcategory = sub; this.setState({filter: temp})}}>
                                <div class="d-flex">
                                  <div className="e-checkbox">{sub.name}
                                    <input type="checkbox"  value={sub} id={`checkbox-${sub.name}`} checked={this.state.filter.subcategory=== sub? true: false} />
                                    <span class="checkmark"></span>
                                  </div>
                                </div>
                              </span>
                            ))
                          }
                        </div>
                      </>
                    ))
                  }
                </div>
                </>
              ))
              :
              null
            }
            </div>
        }
    ]

    return (
      <div className="modal fade e-best-ofer-modal e-filter-modal" id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="filterModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content e-ofer">
            <div className="modal-header pb-0">
              <h6 className="e-modal-header text-uppercase mb-0">Filters</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.close()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <div className="container px-0">
            <div className="row">
                <div className="col-lg-12">
                    <span className="float-right d-flex align-items-center">
                    <span className="mr-2">
                    <TextButton name="Clear All" toggle="modal" target="#filterModal" className="e-t-btn" noBackground={true} handleClick={async ()=> {this.props.setFilters({price: {status: false, lower: 0, upper: 100000}, subcategory: null, brand: null})}}/>
                    </span>
                    <PrimaryButton toggle="modal" target="#filterModal" className="e-p-btn e-contact-btn" name="Apply" handleClick={()=>this.props.setFilters(this.state.filter)}/>
                    </span>
                </div>
            </div>
            <div className="row py-2">
                <div className="col-4 px-0 border-right">
                    {
                        filters.map(filter=>(
                            <span className={this.state.filters===filter.name? "font-weight-bold d-block container py-2 e-mob-filter-btn position-relative e-filter-on" : "d-block container py-2 e-mob-filter-btn position-relative font-weight-bold"} onClick={()=>this.setState({filters: filter.name})}>
                              {filter.name}
                              {(filter.name=="Price"&& this.state.filter.price.status) || (filter.name=="Shop" && this.state.filter.brand) || (filter.name=="Category" && this.state.filter.subcategory)? <span class="badge badge-pill badge-dark position-absolute ml-3" style={{right: "5px"}}>1</span>: null}
                            </span>
                        ))
                    }
                </div>
                <div className="col-8 px-0">
                    {
                        filters.map(filter=>{
                            if(this.state.filters === filter.name){
                                return(
                                    <div className="container px-0 e-local-productlist">
                                        {
                                            filter.data
                                        }
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
            </div>
            </div>
            <div className="modal-footer">
                    
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default FilterModal;
