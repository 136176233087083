/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*assets*/
import Ad from '../../assets/images/local/megaHeaderAd.png';


/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';


class MegaHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      department: '',
      category: '',
      menu: false,
      banner: null,
      wholesaleList: [],
      superValueList: []
    }
  }

  componentDidMount() {
    this.getDepartments();
    this.setLocation();
    this.getPages();
  }

  getPages() {
    var url = devconfig.localUser + "/page/list";
    APIGet(url).then(function (response) {
      if (response.status === "ok") {
        this.setWholesale(response.data);
      }
    }.bind(this))
  }

  async setWholesale(data) {
    let temp = [];
    let superValue= [];
    if (data.category_data) {
      data.category_data.map(category => {
        if (category.wholesale_data && category.wholesale_data.id) {
          let item = { name: category.wholesale_data.name, id: category.wholesale_data.id, department_id: category.wholesale_data.wholesale_department_id };
          temp.push(item);
        }
        if (category.super_value_store_data && category.super_value_store_data.id) {
          let item = { name: category.super_value_store_data.name, id: category.super_value_store_data.id, department_id: category.super_value_store_data.super_value_store_department_id };
          superValue.push(item);
        }
      });
      this.setState({ wholesaleList: temp, superValueList: superValue});
    }
  }

  async setLocation() {
    let temp = await JSON.parse(localStorage.getItem("truekhoj_user_location"));
    if (temp) this.setState({ userLocation: temp }, () => this.getAd());
  }

  getAd() {
    var url = devconfig.localUser + "/page/detail";
    var data = JSON.stringify({
      "id": 202,
      "type": 4,
      "location_id": this.state.userLocation.value
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok" && response.data && response.data.page_data && response.data.page_data.ads && response.data.page_data.ads.megamenu && response.data.page_data.ads.megamenu.data) {
        let bannerData = response.data.page_data.ads.megamenu.data[0] ? response.data.page_data.ads.megamenu.data[0] : null;
        this.setState({ banner: bannerData });
      }
    }.bind(this))
    // let url = devconfig.localUser + "/megamenubanner/view";
    // APIGet(url).then(function (response) {
    // if (response.status === "ok") {
    //     if(response.data && response.data.image){
    //         let bannerData = { content: response.data.image, link: response.data.redirection_url };
    //         this.setState({ banner: bannerData });
    //     }
    // }
    // }.bind(this));
  }

  getLayout() {

  }

  getDepartments() {
    let url = devconfig.localUser + '/department/list';
    APIGet(url).then(function (response) {
      if (response.status === "ok") {
        if(response.data && response.data.length){
          this.setState({ details: response.data });
          this.checkWidth(response.data);
        }
      }
      else {
        this.setState({ error: response.message });
      }
    }.bind(this));
  }

  checkWidth(data) {
    console.log('check width-->',data);
    if (window.innerWidth > 991 ) {
      this.setState({ department: { index: 0, name: data && data[0].name }, category: { index: 0, name:  data.category && data[0].category[0].name } });
    }
  }

  render() {

    return (
      <div className="dropdown d-inline-block e-mega-header pt-1">
        <button className="btn e-dropdown-btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" onClick={() => this.setState({ menu: !this.state.menu })}>
          Explore Categories
        </button>
        <div className={this.state.menu ? "position-fixed d-block" : "d-none"} style={{ zIndex: "1000", width: "100vw", height: "100vh", top: "0", left: "0", backgroundColor: "#00000005" }} onClick={() => this.setState({ menu: false })}></div>
        <div class={this.state.menu ? "dropdown-menu show mx-3" : "dropdown-menu mx-3"} aria-labelledby="dropdownMenuButton">
          <div className="w-100 e-drop-down-scroll-wrapper">
            {
              this.state.details ?
                <div className="row mx-0 d-none d-lg-flex">
                  <div className="col-lg-3 px-0 border-right">
                    {
                      this.state.details.map((department, i) => (
                        <Link to={`/local/category?id=${department.id}`}>
                          <span className={this.state.department && this.state.department.name === department.name ? "e-header-btn d-block py-2 pl-3 e-btn-on" : "e-header-btn d-block py-2 pl-3"} onMouseEnter={() => this.setState({ department: { index: i, name: department.name } })} onClick={() => window.location.href = `/local/category?id=${department.id}`}>{department.name}</span>
                        </Link>
                      ))
                    }
                    {
                      this.state.wholesaleList && this.state.wholesaleList.length?
                        <div className="w-100 pt-4">
                          <h5 className="px-3 font-weight-bold">Wholesale Pages</h5>
                          {
                            this.state.wholesaleList.map((wholesale, i) => (
                              <Link to={`/local/deals?id=${wholesale.id}&wholesale=true&department=${wholesale.department_id}`}>
                                <span className={"e-header-btn d-block py-2 pl-3"} onClick={() => window.location.href = `/local/deals?id=${wholesale.id}&wholesale=true&department=${wholesale.department_id}`}>{wholesale.name}</span>
                              </Link>
                            ))
                          }
                        </div>
                        :
                        null
                    }
                    {
                      this.state.superValueList && this.state.superValueList.length ?
                        <div className="w-100 pt-4">
                          <h5 className="px-3 font-weight-bold">Super Value Stores</h5>
                          {
                            this.state.superValueList.map((supervalue, i) => (
                              <Link to={`/local/deals?id=${supervalue.id}&supervalue=true`}>
                                <span className={"e-header-btn d-block py-2 pl-3"} onClick={() => window.location.href = `/local/deals?id=${supervalue.id}&supervalue=true`}>{supervalue.name}</span>
                              </Link>
                            ))
                          }
                        </div>
                        :
                        null
                    }
                  </div>
                  <div className="col-lg-3 px-0 border-right">
                    {
                      this.state.department.name && this.state.department.index > -1 ?
                        <>
                          {
                            this.state.details[this.state.department.index].category.map((category, i) => (
                              <Link to={`/local/sub-category?id=${category.id}`}>
                                <span className={this.state.category && this.state.category.name === category.name ? "e-header-btn d-block py-2 pl-3 e-btn-on" : "e-header-btn d-block py-2 pl-3"} onMouseEnter={() => this.setState({ category: { index: i, name: category.name } })} onClick={() => window.location.href = `/local/sub-category?id=${category.id}`}>{category.name}</span>
                              </Link>
                            ))
                          }
                        </>
                        :
                        null
                    }
                  </div>
                  <div className="col-lg-3 px-0 border-right">
                    {
                      this.state.department.name && this.state.category.name && this.state.category.index < this.state.details[this.state.department.index].category.length && this.state.details[this.state.department.index].category[this.state.category.index].name === this.state.category.name ?
                        <>
                          {
                            this.state.details[this.state.department.index].category[this.state.category.index].subcategory.map((subcategory, i) => (
                              <Link to={`/local/products?id=${subcategory.id}&product=${subcategory.name}`}>
                                <span className="w-100 d-block py-2 pl-3" style={{ cursor: "pointer" }} onClick={() => window.location.href = `/local/products?id=${subcategory.id}&product=${subcategory.name}`}>{subcategory.name}</span>
                              </Link>
                            ))
                          }
                        </>
                        :
                        null
                    }
                  </div>
                  <div className="col-lg-3 px-3 py-3 text-center">
                    {
                      this.state.banner && this.state.banner.content ?
                        <a href={this.state.banner.link} target="_blank" className="w-100 h-100">
                          <img src={this.state.banner.content} alt='Ad' className="e-mega-header-ad" />
                        </a>
                        :
                        null
                    }
                  </div>
                </div>
                :
                null
            }
            <div className="row mx-0 d-flex d-lg-none">
              <div className="col-12 px-0">
                {
                  this.state.details ?
                    this.state.details.map(department => (
                      <>
                        <Link to={`/local/category?id=${department.id}`}>
                        <span className={this.state.department == department.name ? "e-header-btn d-block py-2 pl-3 e-btn-on" : "e-header-btn d-block py-2 pl-3"} 
                        onClick={() => window.location.href = `/local/category?id=${department.id}`}
                        // onClick={() => { if (this.state.department != department.name) { this.setState({ department: department.name }) } else { this.setState({ department: '' }) } }}
                        >{department.name}</span>
                        {/* <div className={this.state.department == department.name ? "container" : "container d-none"}>
                          {
                            department.category.map(category => (
                              <>
                                <span className="e-header-btn d-block py-2 pl-3" onClick={() => { if (this.state.category != category.name) { this.setState({ category: category.name }) } else { this.setState({ category: '' }) } }}>{category.name}</span>
                                <div className={this.state.category == category.name ? "container" : "container d-none"}>
                                  {
                                    category.subcategory.map(subcategory => (
                                      <Link to={`/local/products?id=${subcategory.id}&product=${subcategory.name}`}>
                                        <span className="d-block py-2 pl-2 font-weight-light" style={{ cursor: "pointer" }} onClick={() => this.setState({ subcategory: subcategory.name, menu: false, department: '', category: '' })}>{subcategory.name}</span>
                                      </Link>
                                    ))
                                  }
                                </div>
                              </>
                            ))
                          }
                        </div> */}
                        </Link>
                      </>
                    ))
                    :
                    null
                }
                {
                  this.state.wholesaleList && this.state.wholesaleList.length ?
                    <div className="w-100 pt-4">
                      <h5 className="px-3 font-weight-bold">Wholesale Pages</h5>
                      {
                        this.state.wholesaleList.map((wholesale, i) => (
                          <Link to={`/local/deals?id=${wholesale.id}&wholesale=true&department=${wholesale.department_id}`}>
                            <span className={"e-header-btn d-block py-2 pl-3"} onClick={() => window.location.href = `/local/deals?id=${wholesale.id}&wholesale=true&department=${wholesale.department_id}`}>{wholesale.name}</span>
                          </Link>
                        ))
                      }
                    </div>
                    :
                    null
                }
                {
                      this.state.superValueList && this.state.superValueList.length ?
                        <div className="w-100 pt-4">
                          <h5 className="px-3 font-weight-bold">Super Value Stores</h5>
                          {
                            this.state.superValueList.map((supervalue, i) => (
                              <Link to={`/local/deals?id=${supervalue.id}&supervalue=true`}>
                                <span className={"e-header-btn d-block py-2 pl-3"} onClick={() => window.location.href = `/local/deals?id=${supervalue.id}&supervalue=true`}>{supervalue.name}</span>
                              </Link>
                            ))
                          }
                        </div>
                        :
                        null
                    }
              </div>
              <div className="col-12 py-3">
                {
                  this.state.banner && this.state.banner.content ?
                    <a href={this.state.banner.link} target="_blank" className="w-100 h-100">
                      <img src={this.state.banner.content} alt='Ad' className="e-mega-header-ad" />
                    </a>
                    :
                    null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MegaHeader;
