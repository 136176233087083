
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import $ from 'jquery';


/*Components*/

import Subscription from '../../components/common/subscription.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';

/*Assets*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import ReactGA from 'react-ga';


class Terms extends Component {
  constructor(props){
    super(props);
    this.state = {



    }
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

  }



  render() {


      return (
        <div className="container-fluid p-0">

          <section className="e-terms pb-5 mb-4">
            <div className="container">
              <h4 className="text-uppercase pt-5 mt-4">Terms & Conditions.</h4>
              <div className="row">
                <div className="col-lg-12">
                  <p className="e-about-content mt-4">
                    Advertise as a merchant, you shall list product(s)/service(s) by image or via web link for
                    being offered on the Platform in accordance with the policies which are incorporated by
                    way of reference in the Terms of Use. You must be legally able to offer the
                    product(s)/service(s) you list for sale on our Platform and must have all the necessary
                    licences and permits required for such offering. You must ensure that the listed items do
                    not infringe upon the intellectual property, trade secret or other proprietary rights or
                    rights of publicity or privacy rights of third parties. Listings may only include text
                    descriptions, graphics, pictures advertisement or videos advertisement that describe your
                    item for offer. All items must be listed in an appropriate category on the Platform. All
                    listed items must be kept in stock for successful fulfilment of services. The listing
                    description of the item must not be misleading and must describe actual condition of the
                    product/service. truekhoj.com reserves the right to delete listings of such product /
                    services listed by you in its sole discretion.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <h5>declaration</h5>
                  <p className="e-about-content mt-4">
                    In relation to the aforesaid, you declare that
                  </p>
                  <ol>
                    <li>You have carefully read the requisite fields on truekhoj.com Platform and have
                    provided accurate information to the best of you knowledge, and You understand that
                    any misrepresentation or omission of facts may be justification for refusal of payment, if
                    any or termination of your contract with truekhoj.com. You therefore agree to provide to
                    truekhoj.com, all persons or entities affiliated to truekhoj.com in my or my agent’s
                    possession in considering you as a merchant for its Platform.
                    </li>
                    <li> You acknowledge that any statements or information or documents provided by you
                    do not create an expressed or implied contract with truekhoj.com. Any premise to the
                    contrary will be relied upon by you and You shall be liable for the same.
                    </li>
                    <li>You hereby declare that the information or documents provided by you to
                    truekhoj.com are true and correct to the best of your knowledge. You, understand and
                    agree that truekhoj.com, at its discretion, may institute and conduct a background check
                    to verify the information furnished by you herein at the truekhoj.com Site or Platform,
                    which may require disclosure of information to third parties and You authorize
                    truekhoj.com to carry out such background verification and agree to provide any/all
                    information required by truekhoj.com in this regard. You acknowledge and agree that
                    providing any false information may result in truekhoj.com delisting you from the Platform
                    and take such action as truekhoj.com may deem appropriate. In addition, You agree that
                    truekhoj.com has rights to seek any legal remedies including indemnification and
                    damages from you for any loss caused to truekhoj.com as a result of any false
                    information provided by you to truekhoj.com.</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <Subscription/>
        </div>
      );
    }
  }


export default Terms;

