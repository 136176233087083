
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Assets*/
import Logo from '../../assets/images/logo.svg';
import SelectBox from '../../components/formComponents/selectBox';


class Footer extends Component {
  constructor(props){
    super(props);
    this.state = {
      filterDefault: {value: null, label: 'Explore Categories'},
      userType: ""
    }
  }

  componentDidMount() {
    this.findUserType();
  }

  findUserType(){
    var tempUrl = [];
    tempUrl = window.location.href.split("/");
    this.setState({ userType: tempUrl[3] });
  }

  render() {

    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-4 mb-3">
              <h6 className="mb-4 text-left">sitemap</h6>
              <a className="d-block mb-3" href="/about">About</a>
              {
                this.state.userType === 'local'?
                <>
                <a className="d-block mb-3" href="/local">Home</a>
                <a className="d-block mb-3" href="/local/deals?id=13">Luxury Offers</a>
                <a className="d-block mb-3" href="/local/deals?id=33">Shop Of The Day</a>
                <a className="d-block mb-3" href="/local/deals?id=32">Exclusive Brands</a>
                <a className="d-block mb-3" href="/local/dealsid=31">Today's Deal</a>
                <a className="d-block mb-3" href="/local/deals?id=30">Whats New</a>
                <a className="d-block mb-3" href="/local/deals?id=12">Special Offers</a>
                <a className="d-block mb-3" href="/local/category-list">All Categories</a>
                </>
                :
                <>
                <a className="d-block mb-3" href="/">Home</a>
                <a className="d-block mb-3" href="/deals?id=54">Luxury Offers</a>
                <a className="d-block mb-3" href="/deals?id=52">Brand Of The Day</a>
                <a className="d-block mb-3" href="/deals?id=53">Exclusive Offers</a>
                <a className="d-block mb-3" href="/deals?id=55">Special Offers</a>
                <a className="d-block mb-3" href="/category-list">All Categories</a>
                </>
                
              }
              
            </div>
            <div className="col-lg-2 col-md-4 mb-3">
              <h6 className="mb-4 text-left">quick links</h6>
              {/* <Link className="d-block mb-3" to="/">Login / Register</Link> */}
              <a className="d-block mb-3" href={this.state.userType==='local'? "/local/search?keyword=" :"/search?keyword="}>Search</a>
              <a className="d-block mb-3" href="https://vendor.truekhoj.com" target="_blank">Become A vendor</a>
              <a className="d-block mb-3" href="/career">We are hiring</a>
              <a className="d-block mb-3" href="https://vendor.truekhoj.com" target="_blank">Advertise your product</a>
            </div>
            <div className="col-lg-2 col-md-4 mb-3">
              <h6 className="mb-4 text-left">help & support</h6>
              <a className="d-block mb-3" href="/faq">FAQ’s</a>
              <a className="d-block mb-3" href="/contact">Customer Support</a>
              {/* <a className="d-block mb-3" target="_blank" href="https://vendor.truekhoj.com">Ad's Pricing Plans</a> */}
              <a className="d-block mb-3" href="/contact" >Contact Us</a>
              {
                this.state.userType==="local" && localStorage.getItem("truekhoj_user_data")?
                <a className="d-block mb-3" data-toggle="modal" data-target="#feedbackModal" style={{cursor: "pointer"}}>Feedback</a>
                :
                null
              }
            </div>
            <div className="col-lg-3 offset-lg-1 col-md-6 mb-3">
              <h6 className="mb-4 text-left">connect with us</h6>
              <a className="e-social-icon e-fb mr-3" href="https://www.facebook.com/True-khoj-102457262394797/" target="_blank"></a>
              <a className="e-social-icon e-insta mr-3" href="https://www.instagram.com/tv/CZmXwQ8JVBc/?utm_medium=share_sheet" target="_blank"></a>
              {/* <a className="e-social-icon e-twitter mr-3" href="/"></a> */}
              <a className="e-social-icon e-youtube mr-3" href="https://youtube.com/channel/UCGCw-6nkgBagABDBLkZWFHw" target="_blank"></a>
              <a className="e-social-icon e-linkedin mr-3" href="https://www.linkedin.com/in/true-khoj-a215431a7" target="_blank"></a>

              <h6 className="mb-4 mt-4 text-left ">we accept</h6>
              <a className="e-payment-gatway e-visa mr-3" href="/"></a>
              <a className="e-payment-gatway e-master mr-3" href="/"></a>
              <a className="e-payment-gatway e-rupay mr-3" href="/"></a>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12">
              <span className="e-copywrite">© 2020 Truekhoj All rights reserved.
                {/* Powered by <a target="_blank" href="www.evoqueinnovativelab.com">Evoque Innovative Lab</a> */}
              </span>
              <span className="e-copywrite ml-5 e-privacy-wrap">
                <a href="/policy" className="mr-0">Privacy</a> • <a className="mr-0" href="/terms">Terms & Condition</a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
