
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Assets*/
import VideoPlayer from '../../../components/common/video';

import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';

class FourBanner extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  adAnalytics(tempId){
    if(tempId){
        var url = devconfig.userV1+"/ads/update-analytics";
        var data = JSON.stringify({
          "ads_id" : Number(tempId),
          "clicks" : 1,
          "views" : 1
        })
        APIPost(url, data).then(function(response){
          if(response.status === "ok"){
            
          }
        }.bind(this))
    }
  }

  render() {
    console.log("this.props.data");
    console.log(this.props.data);

    const linkVerify = (item)=>{
      var tempLink = item;
      if (item && !item.match(/^http([s]?):\/\/.*/)){
        tempLink = 'http://' + item;
        return tempLink
      }
      else{
        return item
      }
    }
    {console.log('inside renderer')}

    return (
      
      <div className="row e-three-banner-wrap">
        {
          this.props.data && this.props.data.map( (item, index) => {
            var tempId = null;
            if(item.id){
              tempId = item.id;
            }
            return(
              <div className="col-lg-3 col-6 col-sm-4 offset-sm-0  mb-2 mb-lg-4 e-mob-pl-5 e-mob-pr-5">
                <a href={linkVerify(item.link) } onClick={this.adAnalytics.bind(this, tempId)}>
                  {
                    item.content && item.content.indexOf(".mp4") > -1 ?
                      <VideoPlayer url={this.props.data} />
                    :
                      <img className="e-banner-wrap" src={item.content} alt="ads banner"/>
                  }
                </a>
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default FourBanner;
