
/*Import Packages*/
import React, { Component, useEffect } from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

/*Import pages*/
import OnlineHome from './container/online/home.js';
import LocalHome from './container/local/local.js';
import Category from './container/online/category.js';
import SubCategory from './container/online/subcategory.js';
import Product from './container/online/product.js';
import Deals from './container/online/deals.js';
import Search from './container/online/search.js';
import Contact from './container/online/contact.js';
import About from './container/online/about.js';
import Profile from './container/online/profile.js';
import Faq from './container/online/faq.js';
import Career from './container/online/career.js';
import Terms from './container/online/terms.js';
import Policy from './container/online/policy.js';
import ProductDetail from './container/local/product-detail.js';
import LocalProfile from './container/local/profile.js';
import Favourites from './container/local/favourites.js';
import Collections from './container/local/store/collections.js';
import Store from './container/local/store/home.js';
import Wholesale from './container/local/wholesale.js';
import Sample from './container/online/sample.js';
import LocalProduct from './container/local/product.js';
import LocalCategory from './container/local/category';
import LocalSubcategory from './container/local/subcategory';
import LocalDeals from './container/local/deals';
import SetLocation from './container/local/setLocaton.js';
import PageBuilder from './container/local/builder.js';
import OnlineCategoryList from './container/online/category-list.js';
import CategoryList from './container/local/category-list.js';

/*Import global components like header, footers*/
import Header from './components/navigations/header.js';
import Footer from './components/navigations/footer.js';
import PWAFooter from './components/navigations/pwa-footer.js';
import PWAHeader from './components/navigations/pwa-header.js';

const ONESIGNAL_APP_ID = "fc0818ad-8142-4225-a8a3-c960a6a37c89";
const ONESIGNAL_WEB_ID = "web.onesignal.auto.195e7e66-9dea-4e11-b56c-b4a654da5ab7";

/* localhost test*/
// const ONESIGNAL_APP_ID = "b1f8a562-1ef7-4128-b97c-a4f01464e2bf";
// const ONESIGNAL_WEB_ID = "web.onesignal.auto.162dc776-ab2c-42c2-8206-1135116280d4";

const App = () => {
  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;
  // const location = useLocation();
  // console.log(location);

  useEffect(() => {
    OneSignal.push(function () {
      OneSignal.init({
        appId: ONESIGNAL_APP_ID,
        safari_web_id: ONESIGNAL_WEB_ID,
        // allowLocalhostAsSecureOrigin: true,
      });
    });
  }, []);

  useEffect(() => {
    ReactGA.initialize('G-V4C5KPJTK6');
  }, []);

  return (
    <AppChild />
  )
}


class AppChild extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  setGA = () => {
    ReactGA.initialize('UA-xxxxxx-xx');
    ReactGA.pageview('Init page view');
  };
  componentDidMount(){
    this.setGA();
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          {
            window.innerWidth > 767 ?
              <div className="container-fluid d-none d-md-block">
                <div className="row">
                  <Header />
                </div>
              </div>
              :
              <div className="d-md-none">
                <PWAHeader />
              </div>
          }
          <div className="e-main-content">
            <Route path="/" exact component={OnlineHome} />
            <Route path="/category" component={Category} />
            <Route path="/sub-category" component={SubCategory} />
            <Route path="/products" exact component={Product} />
            <Route path="/deals" exact component={Deals} />
            <Route path="/search" exact component={Search} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/about" exact component={About} />
            <Route path="/profile" exact component={Profile} />
            <Route path="/faq" exact component={Faq} />
            <Route path="/career" exact component={Career} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/policy" exact component={Policy} />
            <Route path="/category-list" exact component={OnlineCategoryList} />
            {
              localStorage.getItem("truekhoj_user_location") ?
                <>
                  <Route path="/local" exact component={LocalHome} />
                  <Route path="/local/product-detail" exact component={ProductDetail} />
                  <Route path="/local/search" exact component={Search} />
                  <Route path="/local/category" component={LocalCategory} />
                  <Route path="/local/sub-category" component={LocalSubcategory} />
                  <Route path="/local/deals" exact component={LocalDeals} />
                  <Route path="/local/products" exact component={LocalProduct} />
                  {/* <Route path="/local/favourites" exact component={Favourites} /> */}
                  <Route path="/local/store/collections" exact component={Collections} />
                  <Route path="/local/wholesale" exact component={Wholesale} />
                  <Route path="/local/store" exact component={Store} />
                  <Route path="/local/custom" exact component={PageBuilder} />
                  <Route path="/local/category-list" exact component={CategoryList} />
                  {
                    localStorage.getItem("truekhoj_user_data") ?
                      <>
                        <Route path="/local/profile" exact component={LocalProfile} />
                        <Route path="/local/favourites" render={() => (<LocalProfile tab={2} />)} />
                      </>
                      :
                      <>
                        <Route path="/local/profile" exact render={() => (<Redirect to="/local" />)} />
                        <Route path="/local/favourites" exact render={() => (<Redirect to="/local" />)} />
                      </>
                  }
                </>
                :

                <Route path="/local" component={SetLocation} />
            }

            {/* <Route path="/sample" exact component={Sample} /> */}
          </div>

          <div className="d-none d-md-block">
            <Footer />
          </div>

          <div className="d-md-none">
            <PWAFooter />
          </div>
        </BrowserRouter>
      </div>

    )
  }
}

export default App;
