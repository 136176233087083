
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';

/*Assets*/
import Logo from '../../assets/images/logo.svg';
import Close from '../../assets/images/icons/close.svg';
import SelectBox from '../../components/formComponents/selectBox';


class PWAFooter extends Component {
  constructor(props){
    super(props);
    this.state = {
      menuToggle: false,
      activeItem: "",
      nextItem: "",
      year: ""
    }
  }

  componentDidMount() {
    this.findActiveItem();
    this.findLocation();
    this.getYear();
  }

  findLocation(){
    if(localStorage.getItem("truekhoj_user_location")){
      let temp = JSON.parse(localStorage.getItem("truekhoj_user_location"));
      let loc = temp.label;
      const k = temp.label.search(",");
      if(k!=-1){
          loc = temp.label.slice(0, k);
      } 
      this.setState({location: loc});
    }
  }

  menuToggle(){
    this.setState({menuToggle: !this.state.menuToggle});
  }

  findActiveItem(){
    var tempUrl = [];
    tempUrl = window.location.href.split("/");
    this.setState({ activeItem: tempUrl[3], nextItem: tempUrl[4] });
    // this.setState({activeItem: tempUrl[tempUrl.length-1]});
  }

  getYear(){
    let today = new Date();
    let year = today.getFullYear();
    this.setState({year: year});
  }

  render() {
    return (
      <footer className="e-pwa-footer">
        <div className="container">
          <div className="row">
            <div className="col-3 text-center">
              <a onClick={(e) => { this.setState({activeItem: "" }) }} className={this.state.activeItem!="local"&&this.state.activeItem!="search"? "e-pwa-footer-item e-pwa-footer-item-online e-active" : "e-pwa-footer-item e-pwa-footer-item-online"} href="/">
                Online
              </a>
            </div>
            <div className="col-3 text-center">
              <a onClick={(e) => { this.setState({activeItem: "local" }) }} className={this.state.activeItem == "local" && this.state.nextItem != "search"? "e-pwa-footer-item e-pwa-footer-item-local e-active" : "e-pwa-footer-item e-pwa-footer-item-local"} href="/local">
                Local
              </a>
            </div>
            <div className="col-3 text-center">
              <Link onClick={(e) => { this.setState({activeItem: "search" }) }} className={this.state.activeItem == "search" ||(this.state.activeItem == "local" && this.state.nextItem == "search") ? "e-pwa-footer-item e-pwa-footer-item-search e-active" : "e-pwa-footer-item e-pwa-footer-item-search"} to={this.state.activeItem=="local"? "/local/search":"/search"}>
                Search
              </Link>
            </div>
            <div className="col-3 text-center" onClick={this.menuToggle.bind(this)}>
              <a className="e-pwa-footer-item e-pwa-footer-item-more">
                More
              </a>
            </div>
          </div>
          {/*<div className="row mt-5">
            <div className="col-lg-12">
              <span className="e-copywrite">© 2020 Truekhoj All rights reserved. Powered by <a href="/">Evoque Innovative Lab</a></span>
              <span className="e-copywrite ml-5 e-privacy-wrap">
                <a href="/">Privacy</a>
                <a href="/">Terms & Condition</a>
              </span>
            </div>
          </div>*/}
        </div>
        {
          this.state.menuToggle ?
            <div>
              <div className="e-pwa-more-menu">
                <div className="d-flex justify-content-between mb-3">
                  <img className="e-close-icon" onClick={this.menuToggle.bind(this)} src={Close} alt="Close"/>
                  {
                    this.state.activeItem=="local"?
                    <span className="e-location" onClick={()=>$("#setLocationsModal").modal("show")}>{this.state.location}</span>
                    :
                    null
                  }
                </div>
                {
                  this.state.activeItem=="local"?
                  null
                  :
                  <div className={this.state.activeItem == "search" ? "e-pwa-more-menu-item e-active" : "e-pwa-more-menu-item"}>
                  <a className="e-search" onClick={(e) => { this.setState({activeItem: "search" }) }} href={"./search"}>Search</a>
                </div>
                }
                <div className={this.state.activeItem == "about" ? "e-pwa-more-menu-item e-active" : "e-pwa-more-menu-item"}>
                  <a className="e-about" onClick={(e) => { this.setState({activeItem: "about" }) }} href="/about">About</a>
                </div>
                {
                  this.state.activeItem==="local"?
                  <>
                  <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/local/deals?id=13">Luxury Offers</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/local/deals?id=33">Shop Of The Day</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/local/deals?id=32">Exclusive Brands</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/local/dealsid=31">Today's Deal</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/local/deals?id=30">Whats New</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/local/deals?id=12">Special Offers</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/local/category-list">All Categories</a>
                </div>
                  </>
                  :
                  <>
                  <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/deals?id=54">Luxury Offers</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/deals?id=52">Brand Of The Day</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/deals?id=53">Exclusive Offers</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/deals?id=55">Special Offers</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-testimonial" href="/category-list">All Categories</a>
                </div>
                  </>
                }
                <div className={this.state.activeItem == "faq" ? "e-pwa-more-menu-item e-active" : "e-pwa-more-menu-item"}>
                  <a className="e-faq" onClick={(e) => { this.setState({activeItem: "faq" }) }} href="/faq">FAQ</a>
                </div>
                {/*<div className="e-pwa-more-menu-item">
                  <a className="e-vendor" href="https://vendor.truekhoj.com" target="_blank">Become a vendor</a>
                </div>*/}
                <div className={this.state.activeItem == "career" ? "e-pwa-more-menu-item e-active" : "e-pwa-more-menu-item"}>
                  <a className="e-career" href="/career">Career</a>
                </div>
                <div className="e-pwa-more-menu-item">
                  <a className="e-advertise" href="https://vendor.truekhoj.com" target="_blank">Advertise</a>
                </div>
                <div className={this.state.activeItem == "contact" ? "e-pwa-more-menu-item e-active" : "e-pwa-more-menu-item"}>
                  <a className="e-contact" href="/contact">Contact us</a>
                </div>
                <div className={"e-pwa-more-menu-item"}>
                  <a className="e-language" data-toggle="modal" data-target="#changeLanguageModal">Language</a>
                </div>
                {
                  this.state.activeItem==="local" && localStorage.getItem("truekhoj_user_data")?
                  <div className={this.state.activeItem == "local" && this.state.nextItem=="favourites" ? "e-pwa-more-menu-item e-active" : "e-pwa-more-menu-item"}>
                    <a className="e-favourite" href="/local/favourites" >Favourites</a>
                  </div>
                  :
                  null
                }
                {
                  this.state.activeItem==="local" && localStorage.getItem("truekhoj_user_data")?
                  <div className="e-pwa-more-menu-item">
                    <a className="e-contact" data-toggle="modal" data-target="#feedbackModal">Feedback</a>
                  </div>
                  :
                  null
                }
                <div className="e-powered-wrap">
                  {/*<div>
                    <a href="/">Privacy</a>
                  </div>*/}
                  <div className="mb-3">
                    <a href="/terms">Terms & Condition</a>
                  </div>
                  <span className="e-copywrite">© {this.state.year} Truekhoj All rights reserved. 
                    {/* Powered by <a target="_blank" href="www.evoqueinnovativelab.com">Evoque Innovative Lab</a> */}
                  </span>
                </div>
                
              </div>
              <div className="e-pwa-more-menu-overlay" onClick={this.menuToggle.bind(this)}></div>
            </div>
          :
            null
        }
        
      </footer>
    );
  }
}

export default PWAFooter;
