import React, { Component } from 'react';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} %`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

class RangeSelector extends Component {
    constructor(props){
      super(props);
      this.state = {
  
      }
    } 
  
  
    render() {
      return (
        <div className="container px-0 e-range-selector">
          <Range min={this.props.min} max={this.props.max} defaultValue={this.props.defaultValue} onAfterChange={this.props.onChange} tipFormatter={value => `${value}`}/>
        </div>  
      );
    }
}
  
export default RangeSelector;

