/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton.js';
import TextButton from '../../components/buttons/textButton.js';
import Input from '../../components/formComponents/input';
import ButtonLoader from '../common/buttonLoader.js';



/*Assets*/
import Img from '../../assets/images/online/pass-img.svg';

/*Services*/
import devconfig from '../../config/config.js';
import PhoneValidation from '../../services/validation/phone';
import APIPost from '../../services/apiCall/post';



class ConfirmPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      newPassword:"",
      confirmPassword:"",
      apiLoading: false,
      error: ""
    }
  }

  async setPassword(){
    this.setState({ error: "", apiLoading: true});
    if(this.state.newPassword===this.state.confirmPassword){
      let url = devconfig.localUser + "/auth/reset-forgot-password";
      let data = JSON.stringify({
        "otp" : this.props.otp,
        "mobile" : this.props.mobile,
        "password" : this.state.confirmPassword
      });
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.setState({apiLoading: false});
          this.props.success();
        }
        else{
          this.setState({ error: response.message, apiLoading: false});
        }
      }.bind(this));
    }
    else{
      this.setState({apiLoading: false, error: "Passwords do not match"});
    }
  }

  render() {

    return (

      <div className="row e-login-modal e-change-password">
        <div className="col-lg-6 col-6">
          <div className="e-left-banner">
            <div className="text-center"><img src={Img} className="e-login-img e-change-passwrd-img"/></div>
          </div>
        </div>
        <div className="col-lg-6 col-6">
          <div className="e-login-content-wrap">
            <div className="modal-header pb-0">
              <h6 className="e-modal-header text-uppercase mb-0">change password</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 pr-0">
                <label>new password</label>
                <div>
                  <Input className=""  type="password" value={this.state.newPassword} valueChange={(tempVal) => this.setState({newPassword: tempVal})} autoComplete="nope"/>
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12 col-md-12 pr-0">
                <label>confirm password</label>
                <div>
                  <Input className=""  type="password" value={this.state.confirmPassword} valueChange={(tempVal) => this.setState({confirmPassword: tempVal})} autoComplete="nope"/>
                  <div id="e-error" className="error pt-1">{this.state.error}</div>
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-lg-12 pr-0">
                {
                  this.state.apiLoading?
                  <div className="float-right">
                    <ButtonLoader/>
                  </div>
                  :
                  <div className="">
                  {
                    this.state.newPassword && this.state.confirmPassword ?
                    <PrimaryButton  className="e-p-btn e-contact-btn e-ofer-btn e-login-btn" name="Change Password" handleClick={()=> this.setPassword()}/>
                    :
                    <PrimaryButton  className="e-inactive e-p-btn e-p-btn e-contact-btn e-ofer-btn e-login-btn" name="Change Password" />
                  }
                </div>
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmPassword;