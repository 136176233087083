
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGA from 'react-ga';

/*Components*/
import LocalSecondaryHeading from '../../components/navigations/secondaryHeading-local';
import AdsHeroBanner from '../../components/ads/banners/hero-banner.js';
import AdsFourColumn_One from '../../components/ads/list/four-column-style-1.js';
import LocalStoreStrip from '../../components/ads/strip-banners/localstore-strips.js';
import AdsSectionBanner_1 from '../../components/ads/banners/section-banner-1.js';
import AdsSectionBanner_2 from '../../components/ads/banners/section-banner-2.js';
import RecentlyViewed from '../../components/sliders/recently-viewed.js';
import Testimonials from '../../components/sliders/testimonials.js';
import Subscription from '../../components/common/subscription.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import LogoSlider from '../../components/ads/sliders/logo.js';
import FindBannerType from '../../components/ads/banners/findBannerType';
import CardSixColumn from '../../components/cards/column-6.js';
import CardTwoColumn from '../../components/cards/column-2.js';
import CardThreeColumn from '../../components/cards/column-3.js';
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/TextArea.js';
import PrimaryButton from '../../components/buttons/primaryButton';
import LogoHeading from '../../components/headings/logo-heading.js';
import SelectBox from '../../components/formComponents/selectBox';
import FavouriteStores from '../../components/sliders/favouriteStores.js';


import H2 from '../../components/headings/h2.js';
import CardFourColumn from '../../components/cards/column-4.js';
import CardThreeAdColumn from '../../components/cards/column-3-ad.js';
import OffersModal from '../../components/modals/offersPopup';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import GetURLVar from '../../services/getParams/get-url-var';
import Toast from '../../components/common/toast';

class LocalDeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      data: null,
      highlights: {},
      productList: [],
      dataLoading: false,
      name: null,
      phone: null,
      product: null,
      quantity: null
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    ReactGA.pageview(window.location.pathname);
    GetURLVar().then(async function (query) {
      if (query) {
        if(query.wholesale && query.wholesale == "true"){
         this.setState({wholesale: true, departmentId: Number(query.department)}, ()=>this.getProductList());
        }
        else if(query.supervalue && query.supervalue == "true"){
          this.setState({superValue: true, departmentId: Number(query.department)});
        }
        this.setLocation(query.id);
        this.setState({ id: query.id });
      }
    }.bind(this));
  }

  async setLocation(id) {
    let temp = await JSON.parse(localStorage.getItem("truekhoj_user_location"));
    this.setState({ userLocation: temp }, ()=>this.getLayout(id));
  }

  async quotation() {
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/quotation/new";
    var data = JSON.stringify({
      "name": this.state.name,
      "mobile": this.state.phone,
      "item_id": this.state.product.id,
      "item_type": 3,
      "quantity": this.state.quantity,
      "location_id": this.state.userLocation.value
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.triggerToast("Request send Successfully!", "success");
        this.setState({name: null, phone: null, product: null, quantity: null});
      }
      else {
        this.setState({ dataLoading: false, error: response.message });
        this.triggerToast(response.message, "fail");
      }
    }.bind(this))
  }

  getLayout(tempId) {
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/page/detail";
    var data = JSON.stringify({
      "id": tempId,
      "type": 4,
      "location_id": this.state.userLocation.value
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ data: response.data.page_data, dataLoading: false, details: response.data });
      }
      else{
        this.setState({ data: null, dataLoading: false});
      }
      this.getPopup(tempId);
    }.bind(this))
  }

  getProductList() {
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/product/product-names";
    var data = JSON.stringify({
      "department_id": this.state.departmentId
    })
    APIPost(url, data).then(async function (response) {
      if (response.status === "ok") {
        let temp = []
        response.data.forEach(element => {
          temp.push({ id: element.id, label: element.name });
        });
        await this.setState({ productList: temp, dataLoading: false });
      }
    }.bind(this))
  }

  triggerToast(message, type){
    this.setState({toast: message, toastType: type});
    setTimeout(() => {
      this.setState({toast: null});
    }, 5000);
  }

  getPopup(id){
    let viewed = [];
    if(sessionStorage.getItem('local_viewed_pages')) viewed= JSON.parse(sessionStorage.getItem('local_viewed_pages'));
    if(viewed.indexOf(Number(id))===-1&& (localStorage.getItem('Login_promt')||localStorage.getItem('truekhoj_user'))){
      var url = devconfig.localUser+"/popupbanner/view";
      var data = JSON.stringify({
        "local" : true,
        "layout_id" : id,
        "location_id": this.state.userLocation.value
      });
      APIPost(url, data).then(function(response){
        if(response &&response.status === "ok"&& response.data && response.data.image){
          viewed.push(Number(id));
          sessionStorage.setItem('local_viewed_pages', JSON.stringify(viewed));
          this.setState({popup: response.data});
          $('#offersPopupModal').modal('show');
        }
      }.bind(this))
    }
  }

  render() {

    var data = this.state.data;
    let name = "";
    if(this.state.details && this.state.details.name && this.state.details.name.length && this.state.wholesale){
      name = this.state.details.name.slice(10);
    }
    if (data && (data.layout || data.ads)) {

      return (
        <>
        <div className="container-fluid p-0">
          {
            this.state.details && this.state.id != 34 && !this.state.wholesale && !this.state.superValue?
              <LocalSecondaryHeading data={this.state.details} />
              :
              null
          }

          {
            this.state.id == 34 || this.state.wholesale || this.state.superValue?
              <div className="container">
                <div className="row py-5">
                  {
                    this.state.wholesale ?
                      <div className="col-lg-12 mt-lg-3 mb-lg-3">
                        <H2 data={this.state.superValue? "Super Value Store":"WholeSale"} type={1} bg_color={"#F3E11100"} font_color={"#000"} />
                      </div>
                      :
                      null
                  }
                  <div className="col-lg-12 mt-3 mb-lg-3">
                    <H2 data={this.state.id == 34 ? "Luxuary Hotels & Resorts" : name? name : this.state.details.name} type={this.state.id == 34 ? 30 : 21} bg_color={this.state.id == 34 ? "#F3E111" : "#FF4B4D"} font_color={"#000"} />
                  </div>
                </div>
              </div>
              :
              null
          }


          <div className="row">
            <div className="col-lg-12 p-0">
              {
                data.ads && data.ads.hero && data.ads.hero.data[0].content ?
                  <FindBannerType content={data.ads.hero.data} details={data.ads.hero} defaultType={1} />
                  :
                  null
              }
            </div>
          </div>
          {/*
            
            this.state.data.ads.ad1.data.length ?
              <div className="container">
                <div className="mb-5 e-no-mb">
                  <AdsFourColumn_One layout={this.state.data.ads.ad1.data}/>
                </div>
              </div>
            :
              null
          */}


          {
            data.ads && data.ads.logo_slider.data.length && data.ads.logo_slider.data[0].content ?
              <div className="container">
                <div className="pb-5 e-no-mb">
                  <FindBannerType content={data.ads.logo_slider.data} details={data.ads.logo_slider} defaultType={8} />
                </div>
              </div>
              :
              null
          }


          {
            data.ads &&

            <React.Fragment>
              {
                data.ads.deals && data.ads.deals.data[0] && data.ads.deals.data[0].content ?
                  <section className="container-fluid position-relative">
                    <div className="container">
                      <div className="row pb-5 pt-5 e-no-mb">
                        {
                          data.ads.deals.header && data.ads.deals.header.length && !data.ads.deals.header.content ?
                            <div className="col-lg-12 mb-4 e-no-p">
                              <H2 data={data.ads.deals.header} type={27} bg_color={"#FF4A31"} font_color={"#000"} />
                            </div>
                            :
                            <div className="col-lg-12 mb-4 e-no-p">
                              <LogoHeading data={data.ads.deals.header} />
                            </div>
                        }
                        {
                          !this.state.wholesale && !this.state.superValue && this.state.id != 34 && data.ads.deals && data.ads.deals_banner.data[0] ?
                            <div className="col-lg-12 mb-4 e-no-p">
                              {/*<AdsSectionBanner_1 data={data.ads.deals.banner[0].content} link={data.ads.deals.banner[0].link}/>*/}
                              <FindBannerType content={data.ads.deals_banner.data} details={data.ads.deals_banner} defaultType={1} />
                            </div>
                            :
                            null
                        }
                        <div className="col-lg-12 mb-4 e-no-p">
                          {/*<CardThreeAdColumn data={data.ads.deals.data} type={1} subcategory={true}/>*/}
                          <FindBannerType content={data.ads.deals.data} details={data.ads.deals} defaultType={this.state.wholesale || this.state.superValue || this.state.id == 34 ? 2 : 3} />
                        </div>
                        {
                          (this.state.wholesale|| this.state.superValue || this.state.id == 34) && data.ads.deals && data.ads.deals_banner.data[0] ?
                            <div className="col-lg-12 mb-4 e-no-p">
                              {/*<AdsSectionBanner_1 data={data.ads.deals.banner[0].content} link={data.ads.deals.banner[0].link}/>*/}
                              <FindBannerType content={data.ads.deals_banner.data} details={data.ads.deals_banner} defaultType={1} />
                            </div>
                            :
                            null
                        }
                      </div>
                    </div>
                  </section>
                  :
                  null
              }


              {
                data.ads.ad_section_1.data && data.ads.ad_section_1.data[0] && data.ads.ad_section_1.data[0].content && ((!this.state.wholesale && !this.state.superValue)|| (data.ads.ad_section_1.vendors && data.ads.ad_section_1.vendors[0])) ?
                  <section className="container-fluid position-relative">
                    <div className="container">
                      <div className="row pb-5 e-no-mb">
                        {
                          data.ads.ad_section_1.header && data.ads.ad_section_1.header.length && !data.ads.ad_section_1.header.content ?
                            <div className="col-lg-12 mb-4 e-no-p">
                              <H2 data={data.ads.ad_section_1.header} type={27} bg_color={"#FF4A31"} font_color={"#000"} />
                            </div>
                            :
                            <div className="col-lg-12 mb-4 e-no-p">
                              <LogoHeading data={data.ads.ad_section_1.header} />
                            </div>
                        }
                        <div className="col-lg-12 mb-4 e-no-p">
                          {
                            this.state.wholesale || this.state.superValue?
                            <FavouriteStores stores={data.ads.ad_section_1} />
                            :
                            <FindBannerType content={data.ads.ad_section_1.data} details={data.ads.ad_section_1} defaultType={this.state.wholesale||this.state.superValue ? 1 : 3} defaultSlidesNumber={2} />
                          }
                          {/*<CardThreeAdColumn data={data.ads.ad_section_1.data} type={1} subcategory={true}/>*/}
                        </div>
                      </div>
                    </div>
                  </section>
                  :
                  null
              }

              {
                data.ads.ad_section_2.data && data.ads.ad_section_2.data[0] && data.ads.ad_section_2.data[0].content ?
                  <section className="container-fluid position-relative">
                    <div className="container">
                      <div className="row pb-5 e-no-mb">
                        {this.state.id != 34 ?
                          <>
                            {
                              data.ads.ad_section_2.header && data.ads.ad_section_2.header.length && !data.ads.ad_section_2.header.content ?
                                <div className="col-lg-12 mb-4 e-no-p">
                                  <H2 data={data.ads.ad_section_2.header} type={23} bg_color={"#FF4A31"} font_color={"#000"} />
                                </div>
                                :
                                <div className="col-lg-12 mb-4 e-no-p">
                                  <LogoHeading data={data.ads.ad_section_2.header} />
                                </div>
                            }
                          </>
                          : null}
                        <div className="col-lg-12 mb-4 e-no-p">
                          {/*<CardThreeAdColumn data={data.ads.ad_section_2.data} type={1} subcategory={true}/>*/}
                          <FindBannerType content={data.ads.ad_section_2.data} details={data.ads.ad_section_2} defaultType={this.state.wholesale || this.state.superValue ? 2 : this.state.id == 34 ? 1 : 3} />
                        </div>
                      </div>
                    </div>
                  </section>
                  :
                  null
              }

              {
                data.ads.ad_section_3.data && data.ads.ad_section_3.data[0] && data.ads.ad_section_3.data[0].content ?
                  <section className="container-fluid position-relative">
                    <div className="container">
                      <div className="row pb-5 e-no-mb">
                        {(!this.state.wholesale && !this.state.superValue) ?
                          <>
                            {
                              data.ads.ad_section_3.header && data.ads.ad_section_3.header.length && !data.ads.ad_section_3.header.content ?
                                <div className="col-lg-12 mb-4 e-no-p">
                                  <H2 data={data.ads.ad_section_3.header} type={3} bg_color={"#FF4A31"} font_color={"#000"} />
                                </div>
                                :
                                <div className="col-lg-12 mb-4 e-no-p">
                                  <LogoHeading data={data.ads.ad_section_3.header} />
                                </div>
                            }
                          </>
                          : null}
                        <div className="col-lg-12 mb-4 e-no-p">
                          {/*<CardThreeAdColumn data={data.ads.ad_section_3.data} type={1} subcategory={true}/>*/}
                          {
                            this.state.id != 34 ?
                              <FindBannerType content={data.ads.ad_section_3.data} details={data.ads.ad_section_3} defaultType={this.state.wholesale || this.state.superValue ? 1 : 3} />
                              :
                              <>
                                <FindBannerType content={[data.ads.ad_section_3.data[0]]} details={data.ads.ad_section_3} defaultType={1} />
                                <FindBannerType content={[data.ads.ad_section_3.data[1], data.ads.ad_section_3.data[2]]} details={data.ads.ad_section_3} defaultType={2} />
                                <FindBannerType content={[data.ads.ad_section_3.data[3]]} details={data.ads.ad_section_3} defaultType={1} />
                                <FindBannerType content={[data.ads.ad_section_3.data[4], data.ads.ad_section_3.data[5]]} details={data.ads.ad_section_3} defaultType={2} />
                              </>
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                  :
                  null
              }

              {
                data.ads.ad_section_4.data && data.ads.ad_section_4.data[0] && data.ads.ad_section_4.data[0].content ?
                  <section className="container-fluid position-relative">
                    <div className="container">
                      <div className="row pb-5 e-no-mb e-no-pb">
                        {(!this.state.wholesale  && !this.state.superValue)?
                          <>
                            {
                              data.ads.ad_section_4.header && data.ads.ad_section_4.header.length && !data.ads.ad_section_4.header.content ?
                                <div className="col-lg-12 mb-4 e-no-p">
                                  <H2 data={data.ads.ad_section_4.header} type={3} bg_color={"#FF4A31"} font_color={"#000"} />
                                </div>
                                :
                                <div className="col-lg-12 mb-4 e-no-p">
                                  <LogoHeading data={data.ads.ad_section_4.header} />
                                </div>
                            }
                          </>
                          : null}
                        <div className="col-lg-12 mb-4 e-no-p e-no-mb">
                          {/*<CardThreeAdColumn data={data.ads.ad_section_4.data} type={1} subcategory={true}/>*/}
                          <FindBannerType content={data.ads.ad_section_4.data} details={data.ads.ad_section_4} defaultType={this.state.wholesale||this.state.superValue ? 2 : 3} />
                        </div>
                      </div>
                    </div>
                  </section>
                  :
                  null
              }

              {
                this.state.wholesale && data.ads.wholesalePrice && data.ads.wholesalePrice.data && data.ads.wholesalePrice.data[0] && data.ads.wholesalePrice.data[0].content ?
                  <section className="container-fluid position-relative">
                    <div className="container">
                      <div className="row pt-3">
                        <div className="col-lg-5">
                          <div className="row pb-5 e-no-mb e-no-pb">
                            <div className="col-lg-12 mb-4 e-no-p text-center">
                              <h2 className="e-wholesale-price-heading">
                              <svg className="mr-3" width="18" height="7" viewBox="0 0 18 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.4802 0.806152H0.240234V6.19363H17.4802V0.806152Z" fill="#FF4A31"/>
                                </svg>
                                Wholesale Price
                                <svg className="ml-3" width="18" height="7" viewBox="0 0 18 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.4802 0.806152H0.240234V6.19363H17.4802V0.806152Z" fill="#FF4A31"/>
                                </svg>
                                </h2>
                            </div>
                            <div className="col-lg-12">
                              <h5 className="e-wholesale-subheading mb-4">No sale, No gimmick only wholesale prices!!</h5>
                            </div>
                            <div className="col-lg-12 mb-4">
                              {/*<CardThreeAdColumn data={data.ads.wholesalePrice.data} type={1} subcategory={true}/>*/}
                              <FindBannerType content={data.ads.wholesalePrice.data} details={data.ads.wholesalePrice} defaultType={1} />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 pt-3 pt-lg-0">
                          <div className="container e-wholesale-queries">
                            <div className="row pb-4">
                              <div className="col-lg-12">
                                <h5>One Request, Multiple Quotes!!</h5>
                              </div>
                            </div>
                            <div className="row e-best-ofer-modal e-wholesale-query">
                              <div className="col-lg-6 col-md-6 col-6 pb-3">
                                <label>Name*</label>
                                <div>
                                  <Input className="e-phone w-100" type="text" valueChange={(tempVal) => this.setState({ name: tempVal })} autoComplete="nope" placeholder="Name" />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-6 pb-3">
                                <label>Phone*</label>
                                <div>
                                  <Input className="e-phone w-100" type="number" valueChange={(tempVal) => this.setState({ phone: tempVal })} autoComplete="nope" placeholder="Phone Number" />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 py-3 e-product-search">
                                <label>What are you looking for?*</label>
                                <div>
                                  <SelectBox options={this.state.productList} isSearchable={true} placeholder={<span className="e-search-placeholder">Search for products</span>} selectchange={(tempVal) => this.setState({ product: tempVal })} />
                                  {/* <Input className="e-phone w-100" type="text" valueChange={(tempVal) => this.setState({ phone: tempVal })} autoComplete="nope" /> */}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-5 pt-3">
                                <label>Quantity*</label>
                                <div className="d-flex align-items-center">
                                  <Input className="e-phone w-100" type="number" valueChange={(tempVal) => this.setState({ quantity: tempVal })} autoComplete="nope" placeholder="Quantity"/>
                                  <span className="pl-2 e-qnt-label">Pcs</span>
                                </div>
                              </div>
                            </div>
                            {
                              this.state.error ?
                                <div className="row pt-3">
                                  <div className="col-lg-12">
                                    <div id="e-error" className="error pt-1">{this.state.error}</div>
                                  </div>
                                </div>
                                :
                                null
                            }
                            <div className="row pt-3">
                              <div className="col-lg-12 pt-4">
                                {
                                  this.state.name && this.state.phone && this.state.product && this.state.product.id && this.state.quantity ?
                                    <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn" name="Request for Quotation" handleClick={() => this.quotation()} />
                                    :
                                    <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn e-inactive" name="Request for Quotation" disabled={true} />
                                }
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </section>
                  :
                  null
              }
            </React.Fragment>
          }

          {
            this.state.data && this.state.data.layout.map((item, index) => {

              if (item.bg_color) {
                var tempStyle = { background: item.bg_color };
              }

              return (
                <section className="container-fluid position-relative" style={tempStyle}>
                  <div className="container">
                    <div className="row pb-5 e-no-mb">
                      {
                        item.header.content && !item.header.link ?
                          <div className={tempStyle ? "col-lg-12 pb-4 pt-5" : "col-lg-12 pb-4 pt-3"}>
                            <H2 data={item.header.content} type={item.header.type} bg_color={item.header.bg_color} font_color={item.header.font_color} />
                          </div>
                          : item.header.link ?
                          <div className={tempStyle ? "col-lg-12 mb-4 pt-5" : "col-lg-12 mb-4 pt-3"}>
                            <LogoHeading data={item.header} />
                          </div>
                          :
                          null
                      }
                      {
                        item.ads.banner_type ?
                          <div className="col-lg-12 mb-4 e-no-p">
                            {/*<AdsSectionBanner_1 data={item.ads.content} link={item.ads.link} />*/}
                            <FindBannerType content={item.ads.data} details={item.ads} />
                          </div>
                          :
                          <div className="col-lg-12 mb-4 e-no-p">
                            {/*<AdsSectionBanner_1 data={item.ads.content} link={item.ads.link} /> */}
                            <FindBannerType content={[item.ads]} details={item.ads} defaultType={1} />
                          </div>
                      }
                      {/*<div className="col-lg-12 mb-4 e-no-p">
                        <CardFourColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} subcategory={true}/>
                      </div>*/}
                      {
                        item.data.content && item.data.type && item.data.type === 1 ?
                          <div className="col-lg-12 mb-3 e-no-p">
                            <CardFourColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} subcategory={(!this.state.wholesale && !this.state.superValue)? true: false} product={true} local={true} />
                          </div>
                          : item.data.content && item.data.type && item.data.type === 2 ?
                            <div className="col-lg-12 mb-3 e-no-p">
                              <CardSixColumn data={item.data.content} type={item.data.style.value} subcategory={(!this.state.wholesale && !this.state.superValue)? true: false} product={true} local={true}/>
                            </div>
                            : item.data.content && item.data.type && item.data.type === 3 ?
                              <div className="col-lg-12 mb-3 e-no-p">
                                <CardTwoColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} subcategory={(!this.state.wholesale && !this.state.superValue)? true: false} product={true} local={true} />
                              </div>
                              : item.data.content && item.data.type && item.data.type === 4 ?
                                <div className="col-lg-12 mb-3 e-no-p">
                                  <CardThreeColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} subcategory={(!this.state.wholesale && !this.state.superValue)? true: false} product={true} local={true}/>
                                </div>
                                :
                                null
                      }
                    </div>
                  </div>
                  {/* <span className="e-section-bg-half" style={{
                    background:
                      item.data.type == 2 ||
                        item.data.type == 4 ||
                        item.data.type == 6 ||
                        item.data.type == 8 ||
                        item.data.type == 10 ||
                        item.data.type == 12 ||
                        item.data.type == 14 ?
                        item.header.bg_color :
                        "#fff"
                  }}></span> */}
                </section>
              
              );
            })
          }

          {/* Recently viewed */}
          {/*<section className="container-fluid position-relative">
            <div className="container">
              <div className="row mb-5 e-no-mb">
                <div className="col-lg-12 mb-4">
                  <H2 data="recently viewed" type={15}/>
                </div>
                <div className="col-lg-12 mb-5">
                 <RecentlyViewed/>
                </div>
              </div>
            </div>
            <span className="e-section-bg-half" style={{background: "#fff"}}></span>
          </section>*/}



          {/* Subscription */}
          <Subscription />
          <OffersModal data={this.state.popup}/>


        </div>
        {
          this.state.toast?
          <Toast data={this.state.toast} type={this.state.toastType}/>
          :
          null
        }
        </>
      );
    }
    else {
      return (
        <div>
          {
            this.state.dataLoading ?
              <PageLoader />
              :
              <NothingFound />
          }
          <OffersModal data={this.state.popup}/>
        </div>
      );
    }
  }
}

export default LocalDeals;
