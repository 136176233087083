/*Package importing*/
import React, { useEffect, useState }  from 'react';
import $, { timers } from 'jquery';


/*Components*/

import Input from '../../../components/formComponents/input';
import Textarea from '../../../components/formComponents/TextArea.js';
import PrimaryButton from '../../../components/buttons/primaryButton';



/*Assets*/
import ModalImg from '../../../assets/images/local/wholesale/modal-img.svg';

/*Services*/


const ChangeLanguage = (props)=>{

    const [selectedItem, setSelected] = useState(null);
    const [options, setOptions] = useState(
        [{ value: 'en', label: 'English' }, 
        { value: 'hi', label: 'Hindi' }, 
        { value: 'kn', label: 'Kannada' }
    ]);


    const translate=()=> {
        let value= selectedItem.value;
        $(`.goog-te-combo option[value="${value}"]`).attr("selected", "selected");
        const db = $(".goog-te-combo").get(0);
        fireEvent(db, "change");
        $("#changeLanguageModal").modal("hide");
    }

    const fireEvent=(el, e)=>{
        if (document.createEventObject) {
          //for IE
          var evt = document.createEventObject();
          return el.fireEvent("on" + e, evt);
        } else {
          // For other browsers
          var evt = document.createEvent("HTMLEvents");
          evt.initEvent(e, true, true);
          return !el.dispatchEvent(evt);
        }
      }

    return(
        <div className="modal fade e-best-ofer-modal" id="changeLanguageModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content e-ofer">
                        <div className="modal-header pt-3">
                            <h6 className="e-modal-header text-uppercase mb-0">Select language</h6>
                        </div>
                        <div className="row py-4 px-3">
                            <div className="col-lg-12 pt-2">
                                <div id="google_translate_element" className="d-none"></div>
                            </div>
                            {
                                options && options.map((item, index) => {
                                    // let loc = item.label
                                    // const temp = item.label.search(",");
                                    // if(temp!=-1){
                                    //     loc = item.label.slice(0, temp)
                                    // }                                    
                                    return (
                                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                                            <div className={selectedItem == item ? "e-outer-wrap e-active" : "e-outer-wrap"} onClick={(e) => setSelected(item)}>
                                                <span className="pl-4">{item.label}</span>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>

                        <div className="row px-3">
                            <div className="col-lg-12">
                                <span className="float-right">
                                    {
                                        selectedItem ?
                                            <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn" name="Translate" handleClick={() => translate()} />
                                            :
                                            <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn e-inactive" name="Translate" />
                                    }

                                </span>
                            </div>
                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>

            </div>
        
    )
}

export default ChangeLanguage;
