/*Package importing*/
import React, { Component, createRef } from 'react';
import ReactPlayer from 'react-player';

class VideoPlayer extends Component {
  constructor(props){
    super(props);
    this.state = {
      value: "",
      playing: false,
    }
    this.componentRef=createRef();
  }

  componentDidMount(){
    this.scrollListner();
  }
  
  handleOnReady(){
    this.setState({playerReady: true});
    let player = this.componentRef.current;
    let playerPosition = player.wrapper.getBoundingClientRect().top;
    if(playerPosition<window.innerHeight){
      setTimeout(() => {
        this.setState({ playing: true })
      }, 100)
    }
  };

  scrollListner(){
    window.addEventListener("scroll", ()=>{
      if(this.state.playerReady){
        let player = this.componentRef.current;
        if(player){
          let playerPosition = player.wrapper.getBoundingClientRect().top;
          if(playerPosition<0){
            this.setState({playing: false});
          }
          else if(playerPosition<window.innerHeight){
              this.setState({ playing: true });
          }
          else{
            this.setState({playing: false});
          }
        }
      }
    });
  }



  render() {
    return (
      <React.Fragment>
        <ReactPlayer ref={this.componentRef} className="e-video-player" volume={1} loop={true} onReady={()=>this.handleOnReady()} playing={this.state.playing} url={this.props.url} />
      </React.Fragment>
    );
  }
}

export default VideoPlayer;


{/*<ReactPlayer 
          onReady={this.handlePlay}
          controls={true}
          onPlay={this.handlePlay}
          onStart={() => console.log('onStart')}
          playing={true}
          className="e-video-player"
          loop={true}
          url={this.state.url}
          
        />*/}


// url={"https://truekhojonlinebucket.s3.ap-south-1.amazonaws.com//admin//images/1057596880-preview_2020-12-31_07:14:30.209060.mp4"}

//{...this.props}