
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';

/*Components*/

import TextButton from '../../components/buttons/textButton';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import GetURLVar from '../../services/getParams/get-url-var';
import BestOfferModal from '../../components/modals/local/bestOfferModal';
import EnquiryForm from '../../components/form/enquiryForm';
import Subscription from '../../components/common/subscription';


/*Assets*/
import Offer1 from '../../assets/images/local/wholesale/offer1.svg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';


class Wholesale extends Component {
  constructor(props){
    super(props);
    this.state = {




    }
  }

  componentDidMount() {

    ReactGA.pageview(window.location.pathname);
  }



  render() {


      return (
        <div className="container-fluid p-0">
          <section className="e-featured-list pt-4 mt-2">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-6">
                  <img src={Offer1} className="e-offer-img" data-toggle="modal" data-target="#bestofferModal"/>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <img src={Offer1} className="e-offer-img" data-toggle="modal" data-target="#bestofferModal"/>
                </div>

              </div>
            </div>
          </section>
          <section className="e-enquiry-form mt-5 mb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-12 col-12">
                  <EnquiryForm/>
                </div>
              </div>
            </div>
          </section>

         <Subscription/>
         <BestOfferModal/>
        </div>
      );
    }
  }



export default Wholesale;

