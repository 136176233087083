
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import VideoPlayer from '../../../components/common/video';

import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';

/*Assets*/
import banner1 from '../../../assets/images/online/section-banner-6.svg';
import banner2 from '../../../assets/images/online/section-banner-7.svg';
import banner3 from '../../../assets/images/online/section-banner-8.svg';
import banner4 from '../../../assets/images/online/section-banner-9.svg';
import banner5 from '../../../assets/images/online/section-banner-10.svg';

class FiveBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }

  adAnalytics(tempId) {
    if (tempId) {
      var url = devconfig.userV1 + "/ads/update-analytics";
      var data = JSON.stringify({
        "ads_id": Number(tempId),
        "clicks": 1,
        "views": 1
      })
      APIPost(url, data).then(function (response) {
        if (response.status === "ok") {

        }
      }.bind(this))
    }
  }

  render() {

    const linkVerify = (item) => {
      var tempLink = item;
      if (item && !item.match(/^http([s]?):\/\/.*/)) {
        tempLink = 'http://' + item;
        return tempLink
      }
      else {
        return item
      }
    }

    var tempId1 = null;
    if (this.props.adLeftTop) {
      if (this.props.adLeftTop.id) {
        tempId1 = this.props.adLeftTop.id;
      }
    }

    var tempId2 = null;
    if (this.props.adLeftBottom) {
      if (this.props.adLeftBottom.id) {
        tempId2 = this.props.adLeftBottom.id;
      }
    }

    var tempId3 = null;
    if (this.props.adCenter) {
      if (this.props.adCenter.id) {
        tempId3 = this.props.adCenter.id;
      }
    }

    var tempId4 = null;
    if (this.props.adRightTop) {
      if (this.props.adRightTop.id) {
        tempId4 = this.props.adRightTop.id;
      }
    }

    var tempId5 = null;
    if (this.props.adRightTop) {
      if (this.props.adRightTop.id) {
        tempId5 = this.props.adRightTop.content.id;
      }
    }


    return (
      <div className="row e-five-banner-wrap">
        <div className="col-12">
          <div className="row mx-n1">
            <div className="col-6 offset-md-0 px-1">
              <a href={linkVerify(this.props.adLeftTop.link)} onClick={this.adAnalytics.bind(this, tempId1)} target="_blank">
                {
                  this.props.adLeftTop.content && this.props.adLeftTop.content.indexOf(".mp4") > -1 ?
                    <VideoPlayer url={this.props.adLeftTop.content} />
                    :
                    <img className="e-banner-wrap e-mob-p-3" src={this.props.adLeftTop.content} alt="ads banner" />
                }

              </a>
            </div>
            <div className="col-6 offset-md-0 px-1">
              <a href={linkVerify(this.props.adRightTop.link)} onClick={this.adAnalytics.bind(this, tempId4)} target="_blank">
                {
                  this.props.adRightTop.content && this.props.adRightTop.content.indexOf(".mp4") > -1 ?
                    <VideoPlayer url={this.props.adRightTop.content} />
                    :
                    <img className="e-banner-wrap e-mob-p-3" src={this.props.adRightTop.content} alt="ads banner" />
                }
              </a>
            </div>
          </div>
        </div>

        <div className="col-12 e-5-banner-center e-no-p py-2">
          <a href={linkVerify(this.props.adCenter.link)} onClick={this.adAnalytics.bind(this, tempId3)} target="_blank">
            {
              this.props.adCenter.content && this.props.adCenter.content.indexOf(".mp4") > -1 ?
                <VideoPlayer url={this.props.adCenter.content} />
                :
                <img className="e-banner-wrap e-mob-p-3" src={this.props.adCenter.content} alt="ads banner" />
            }
          </a>
        </div>

        <div className="col-12">
          <div className="row mx-n1">
            <div className="col-6 offset-md-0 px-1">
              <a href={linkVerify(this.props.adLeftBottom.link)} onClick={this.adAnalytics.bind(this, tempId2)} target="_blank">
                {
                  this.props.adLeftBottom.content && this.props.adLeftBottom.content.indexOf(".mp4") > -1 ?
                    <VideoPlayer url={this.props.adLeftBottom.content} />
                    :
                    <img className="e-banner-wrap e-mob-p-3" src={this.props.adLeftBottom.content} alt="ads banner" />
                }
              </a>
            </div>
            <div className="col-6 offset-md-0 px-1">
              <a to={linkVerify(this.props.adRightBottom.link)} onClick={this.adAnalytics.bind(this, tempId5)} target="_blank">
                {
                  this.props.adRightBottom.content && this.props.adRightBottom.content.indexOf(".mp4") > -1 ?
                    <VideoPlayer url={this.props.adRightBottom.content} />
                    :
                    <img className="e-banner-wrap e-mob-p-3" src={this.props.adRightBottom.content} alt="ads banner" />
                }
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FiveBanner;
