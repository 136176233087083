/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import MapContainer from '../../../components/map/direction.js';
import H2 from '../../../components/headings/h2.js';
import devconfig from '../../../config/config';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';



/*Assets*/
import Img from '../../../assets/images/online/login-img.svg';

/*Services*/



class About extends Component {
  constructor(props){
    super(props);
    this.state = {

      data: null,

    }
  }

  componentDidMount() {
    this.getDetails();    
  }



  getDetails(){
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/vendor/detail";
    var data = JSON.stringify({
      "vendor_id" : this.props.id
    })
    APIPost(url, data).then(function (response) {

      console.log("details",response)
      if (response.status_code === 200) {      
        this.setState({vendorInfo: response.data});       
      }
    }.bind(this))
  }




  render() {

    return (
      <>
      {
        this.props.info.data?
        <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12 mb-4">
            <div className="py-3">
              <H2 data={this.props.info.header.content} type={this.props.info.header.type} bg_color={this.props.info.header.bg_color} font_color={this.props.info.header.font_color}/>
            </div>                     
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-lg-12">
                <h6>About</h6>
                <p className="pt-1">{this.props.info.data.about}</p>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-2">Social Media</h6>
                <div className="pt-1">
                  <a className="e-social-icon e-fb mr-3" target="_blank" href={this.props.info.data.socialmedia_links.facebook}></a>
                  <a className="e-social-icon e-insta mr-3" target="_blank" href={this.props.info.data.socialmedia_links.instagram}></a>
                  <a className="e-social-icon e-twitter mr-3" target="_blank" href={this.props.info.data.socialmedia_links.twitter}></a>
                  <a className="e-social-icon e-pin mr-3" target="_blank" href={this.props.info.data.socialmedia_links.pinterest}></a>
                  <a className="e-social-icon e-youtube mr-3" target="_blank" href={this.props.info.data.socialmedia_links.youtube}></a>
                </div>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-3">Accepts</h6>
                <p className="pt-1">{this.props.info.data.payment_methods}</p>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-2">Return & Exchanges</h6>
                <p className="pt-1"><a target="_blank" href={this.props.info.data.other_links.return}>View Return Policy</a> <a target="_blank" href={this.props.info.data.other_links.exchange}>View Exchange Policy</a> <a target="_blank" href={this.props.info.data.other_links.refund}>View Refund Policy</a></p>
              </div>
              {/* <div className="col-lg-12">
                <h6 className="pt-2">Other Stores in Bangalore</h6>
                <div className="e-store-wrap mt-3">
                  <h6>Levi’s</h6>
                  <p className="mb-1 pt-2">UG 57 MANTRI SQUARE, Sampige Rd, Malleshwaram, Bengaluru, Karnataka</p>
                  <p className="mb-1">560003</p>
                  <p className="mb-0">Phone: 08022667090</p>
                </div>
                <div className="e-store-wrap mt-2">
                  <h6>Levi's Exclusive Store</h6>
                  <p className="mb-1 pt-2">UG 57 MANTRI SQUARE, Sampige Rd, Malleshwaram, Bengaluru, Karnataka</p>
                  <p className="mb-1">560003</p>
                  <p className="mb-0">Phone: 08022667090</p>
                </div>
              </div> */}
            </div>

          </div>
          <div className="col-lg-4  offset-lg-1 offset-md-1 offset-sm-0 pt-sm-3">
            <div className="row">
              <div className="col-lg-12 col-sm-11">
                <h6 className="">Directions</h6>
                <div className="e-map-height">
                  <MapContainer/>
                </div>

              </div>
              <div className="col-lg-12 pt-5">
                <h6 className="pt-2">Address</h6>
                <p className="pt-1">{this?.state?.vendorInfo?.address}</p>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-2">Phone</h6>
                <p className="pt-1"><a href="tel:08043761136">{this?.state?.vendorInfo?.mobile}</a></p>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-2">Mail address</h6>
                <p className="pt-1"><a a href="mailto:customercare@levi.in">{this?.state?.vendorInfo?.email}</a></p>
              </div>
            </div>
          </div>

        </div>

      </div>
      :
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12 mb-4">
            <h4 className="text-uppercase text-center">about the store</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-lg-12">
                <h6>About</h6>
                <p className="pt-1">Levi Strauss & Co. is one of the world’s largest apparel companies and a global leader in jeans. The Levi’s® brand is just part of our story. We’re also home to Dockers® and Denizen®. We have some 500 stores, and our products are available in 110 countries around the world.</p>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-2">Social Media</h6>
                <div className="pt-1">
                  <a className="e-social-icon e-fb mr-3" href="/"></a>
                  <a className="e-social-icon e-insta mr-3" href="/"></a>
                  <a className="e-social-icon e-twitter mr-3" href="/"></a>
                  <a className="e-social-icon e-pin mr-3" href="/"></a>
                  <a className="e-social-icon e-youtube mr-3" href="/"></a>
                </div>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-3">Accepts</h6>
                <p className="pt-1">Cash, Cards & UPI</p>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-2">Return & Exchanges</h6>
                <p className="pt-1"><a>View Return Policy</a> <a>View Exchange Policy</a> <a>View Refund Policy</a></p>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-2">Other Stores in Bangalore</h6>
                <div className="e-store-wrap mt-3">
                  <h6>Levi’s</h6>
                  <p className="mb-1 pt-2">UG 57 MANTRI SQUARE, Sampige Rd, Malleshwaram, Bengaluru, Karnataka</p>
                  <p className="mb-1">560003</p>
                  <p className="mb-0">Phone: 08022667090</p>
                </div>
                <div className="e-store-wrap mt-2">
                  <h6>Levi's Exclusive Store</h6>
                  <p className="mb-1 pt-2">UG 57 MANTRI SQUARE, Sampige Rd, Malleshwaram, Bengaluru, Karnataka</p>
                  <p className="mb-1">560003</p>
                  <p className="mb-0">Phone: 08022667090</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-4  offset-lg-1 offset-md-1 offset-sm-0 pt-sm-3">
            <div className="row">
              <div className="col-lg-12 col-sm-11">
                <h6 className="">Directions</h6>
                <div className="e-map-height">
                  <MapContainer/>
                </div>

              </div>
              <div className="col-lg-12 pt-5">
                <h6 className="pt-2">Address</h6>
                <p className="pt-1">#16, Ground Floor, New BEL Rd, Jaladarshini Layout, Bengaluru, Karnataka 560054</p>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-2">Phone</h6>
                <p className="pt-1"><a href="tel:08043761136">08043761136</a></p>
              </div>
              <div className="col-lg-12">
                <h6 className="pt-2">Mail address</h6>
                <p className="pt-1"><a a href="mailto:customercare@levi.in">customercare@levi.in</a></p>
              </div>
               <div className="col-lg-12">
                <h6 className="pt-2">Website</h6>
                <p className="pt-1"><a>LeviStrauss.com</a></p>
              </div>
            </div>
          </div>

        </div>

      </div>
      }
      </>
    );
  }
}

export default About;

