
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

/*Assets*/
import Logo from '../../assets/images/logo.svg';
import LoginModal from '../../components/modals/loginModal';

/*Services*/
import devconfig from '../../config/config.js';
import APIGet from '../../services/apiCall/get';
import APIPost from '../../services/apiCall/post';
import GetURLVar from '../../services/getParams/get-url-var';

/*Components*/
import SelectBox from '../../components/formComponents/selectBox';
import MegaHeader from './megaHeader';
import SetLocationsModal from '../modals/local/setLocationModal';
import FeedbackModal from '../modals/feedback';
import Toast from '../common/toast';


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDefault: null,
      categories: [],
      search: "",
      hideSearch: false,
      user: null,
      locationOptions: [],
      location: null
    }
  }

  componentDidMount() {
    this.findActiveItem(); /* Checks URL to see if in Local or Online Modal */
    this.getLocations();   /* For localusers */
    if (localStorage.getItem("truekhoj_user_data")) {
      const user = JSON.parse(localStorage.getItem("truekhoj_user_data"));
      this.setUser(user);
    }
    GetURLVar().then(function (query) {
      if (query.keyword) {
        this.setState({ hideSearch: true });
      }
    }.bind(this));
  }

  showNotificationPrompt(){
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;
    OneSignal.push(function() {
      OneSignal.showNativePrompt();
    });
  }

  showPosition(position) {
    console.log("Latitude: ", position.coords.latitude, "Longitude: ", position.coords.longitude);
    let url = devconfig.localUser + "/location/reverse-geocode";
    let data = JSON.stringify({
      "latitude": position.coords.latitude,
      "longitude": position.coords.longitude
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        let places=[];
        if(response.data && response.data.length){
            let temp = response.data[0].address_components;
            temp.forEach((item, i)=>{
                if(i<temp.length-2) places.push(item.long_name);
            })
            this.setState({suggestedLocs: places});
        }
        // if(response.data && response.data.length){
        //   let temp = [];
        //   response.data.forEach(element => {
        //     temp.push(element.id);
        //   });
        //   this.setState({suggestedLocs: temp});
        // }
      }
      else {
        this.setState({ error: response.message});
      }
    }.bind(this));
  }

  setUser(user) {
    if (user) {
      const name = user.name;
      const matches = name.match(/\b(\w)/g);
      const res = matches[0]+matches[matches.length-1];
      this.setState({ user: res });
    }
  }

  async findActiveItem() {
    var tempUrl = [];
    tempUrl = window.location.href.split("/");
    await this.setState({ activeItem: tempUrl[3] });
    /* Local and location not selected (Gets current location) */
    if (tempUrl[3] === "local" && !localStorage.getItem("truekhoj_user_location")) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((p) => this.showPosition(p));
      }
    }
    else if (tempUrl[3] === "local" && localStorage.getItem("truekhoj_user_location")) {
      if (!localStorage.getItem("Login_promt") && !localStorage.getItem("truekhoj_user_data")) {
        $("#loginModal").modal("show");
      }
      if (localStorage.getItem("truekhoj_prev_location")) this.setFavLoc();
      let temp = JSON.parse(localStorage.getItem("truekhoj_user_location"));
      const k = temp.label.search(",");
      if(k!=-1){
          let loc = temp.label.slice(0, k);
          temp.label = loc;
      }  
      this.setState({ location: temp });
    }
    else if (!localStorage.getItem("Login_promt") && !localStorage.getItem("truekhoj_user_data")) {
      $("#loginModal").modal("show");
    }
    else{
      this.showNotificationPrompt();
    }
    this.getCategory();
  }

  async setFavLoc() {
    if (localStorage.getItem("truekhoj_user_data")) {
      let temp = await JSON.parse(localStorage.getItem("truekhoj_prev_location"))
      this.setState({ error: "", favLoading: true });
      let url = devconfig.localUser + "/favourite/add";
      let data = JSON.stringify({
        "id": temp.value,
        "type": 4
      });
      APIPost(url, data).then(function (response) {
        if (response.status === "ok") {
          this.setState({ favLoading: false });
        }
        else {
          this.setState({ error: response.message, favLoading: false });
        }
        localStorage.removeItem("truekhoj_prev_location");
      }.bind(this));
    }
  }

  getCategory() {
    this.setState({ dataLoading: true });
    var url = devconfig.userV1 + "/category/list";
    if (this.state.activeItem != 'local') {
      APIGet(url).then(function (response) {
        if (response.status === "ok") {

          // console.log(response);
          var tempArry = []
          response.data.map(function (item, index) {
            var temp = { value: item.id, label: item.category };
            tempArry.push(temp);
          });
          this.setState({ categories: tempArry, filterDefault: { value: null, label: 'Explore Categories' } });
        }
      }.bind(this))
    }
  }

  getLocations() {
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/location/list";
    APIGet(url).then(function (response) {
      if (response.status === "ok") {
        var tempArry = []
        response.data.map(function (item, index) {
          const location = { value: item.id, label: item.name };
          tempArry.push(location);
        });
        this.setState({ locationOptions: tempArry });
      }
    }.bind(this))
  }

  filterChange = async (filter) => {
    await this.setState({ filter: filter });
    if(filter.value==="*") window.location.href="/category-list"
    else window.location.href = './category?id=' + filter.value + '&category=' + filter.label;
  }

  locationChange = async (filter) => {
    let current = localStorage.getItem("truekhoj_user_location");
    localStorage.setItem("truekhoj_prev_location", current);
    await this.setState({ location: filter });
    localStorage.setItem("truekhoj_user_location", JSON.stringify(filter));
    window.location.reload();
  }

  languageChange(value) {
    $(`.goog-te-combo option[value="${value}"]`).attr("selected", "selected");
    const db = $(".goog-te-combo").get(0);
    this.fireEvent(db, "change");
  }

  fireEvent(el, e) {
    if (document.createEventObject) {
      //for IE
      var evt = document.createEventObject();
      return el.fireEvent("on" + e, evt);
    } else {
      // For other browsers
      var evt = document.createEvent("HTMLEvents");
      evt.initEvent(e, true, true);
      return !el.dispatchEvent(evt);
    }
  }

  searchKeyDown(e) {
    if (e.keyCode == 13 && this.state.search.length && this.state.search.length && this.state.activeItem != "local" ) {
      window.location.href = "./search?keyword=" + this.state.search;
    }
    else if (e.keyCode == 13 && this.state.search.length && this.state.search.length && this.state.activeItem == "local") {
      window.location.href = "/local/search?keyword=" + this.state.search;
    }
  }


  render() {
    const filterBy = [
      { value: 1, label: 'Clothing' },
      { value: 2, label: 'Fashion' },
      { value: 3, label: 'Beauty & personal care' },
    ]
    const location = [
      { value: 1, label: 'India' },
      { value: 2, label: 'Banglore' },
    ]

    const color = ["#F16522", "#12B1F4", "#2250F1", "#22F150", "#C822F1", "#F12285"];

    return (
      <React.Fragment>
        <header>
          <div className="e-sec-header">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-md-12 e-header-location e-location-img">
                  {
                    this.state.activeItem != "local" ?
                      <span className="e-loc-static-txt ml-4">India</span>
                      :
                      <>
                        {
                          this.state.location ?
                            <span className="e-loc-static-txt ml-4" data-target="#setLocationsModal" data-toggle="modal">{this.state.location.label}</span>
                            // <SelectBox options={this.state.locationOptions} selectchange={this.locationChange} defaultValue={this.state.location} placeholder="Location" className="" isSearchable={true} />
                            :
                            null
                        }
                      </>
                  }
                </div>
                <div className="col-lg-4 col-md-6 offset-2">
                  <div className="row e-header-switch">
                    {
                      this.state.activeItem != "local" ?
                        <React.Fragment>
                          <div className="e-header-switch-item e-active col-lg-6 col-md-6 text-center" onClick={(e) => { window.location.href = "/" }}>Online Market</div>
                          <div className="e-header-switch-item col-lg-6 col-md-6 text-center" onClick={(e) => { window.location.href = "/local" }}>Local Market</div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <div className="e-header-switch-item col-lg-6 col-md-6 text-center" onClick={(e) => { window.location.href = "/" }}>Online Market</div>
                          <div className="e-header-switch-item e-active col-lg-6 col-md-6 text-center" onClick={(e) => { window.location.href = "/local" }}>Local Market</div>
                        </React.Fragment>
                    }

                  </div>
                </div>
                <div className="col-lg-4 col-md-6 e-sec-header-nav d-flex align-items-center justify-content-end">
                  <a href="https://vendor.truekhoj.com/" className="px-2 mt-1" target="_blank">List Your Business</a>
                  <a href="https://vendor.truekhoj.com/" className="px-2 mt-1" target="_blank">Advertise</a>
                  {/* <a className="px-2 mt-1">English</a> */}
                  <span className="e-header-location e-header-language mt-1"><SelectBox options={[{ value: 'en', label: 'English' }, { value: 'hi', label: 'Hindi' }, { value: 'kn', label: 'Kannada' }]} selectchange={(val) => this.languageChange(val.value)} defaultValue={{ value: 'en', label: 'English' }} />
                    <div id="google_translate_element" className="d-none"></div></span>

                </div>
              </div>
            </div>
          </div>
          <div className="row e-nav-header">
            <div className="container">
              <div className={this.state.activeItem != "local" ? "row" : "row position-relative"}>
                <div className="col-lg-5 col-md-7 pt-1">
                  {
                    this.state.activeItem != 'local' ?
                      <a href="/">
                        <img className="mr-5" src={Logo} alt="True Khoj Logo" />
                      </a>
                      :
                      <a href="/local">
                        <img className="mr-5" src={Logo} alt="True Khoj Logo" />
                      </a>
                  }
                  {
                    this.state.activeItem != 'local' ?
                      <React.Fragment>
                        {
                          this.state.filterDefault ?
                            <SelectBox defaultValue={this.state.filterDefault} options={[{label: "All Categories", value: "*"},...this.state.categories]} selectchange={this.filterChange} placeholder="Filter" />
                            :
                            null
                        }
                      </React.Fragment>
                      :
                      <MegaHeader />
                  }
                </div>

                <div className="col-lg-5 col-xl-6 d-md-none d-lg-block text-right position-relative">

                  {
                    !this.state.hideSearch ?
                      <React.Fragment>
                        <input onKeyDown={this.searchKeyDown.bind(this)} value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }) }} type="text" className="e-header-search" placeholder="Search for products, categories & sub categories" />
                        {
                          this.state.search.length && this.state.activeItem != "local" ?
                            <a href={"./search?keyword=" + this.state.search}>
                              <span className="e-header-search-btn active"></span>
                            </a>
                            : this.state.search.length && this.state.activeItem == "local" ?
                              <a href={"/local/search?keyword=" + this.state.search}>
                                <span className="e-header-search-btn active"></span>
                              </a>
                              :
                              <span className="e-header-search-btn"></span>
                        }
                      </React.Fragment>
                      :
                      null
                  }


                </div>

                <div className="col-md-3 d-md-block d-sm-none d-lg-none">
                  {this.state.activeItem != "local" ?
                    <a href={"./search?keyword=" + this.state.search}>
                      <span className="e-header-search-btn"></span>
                    </a>
                    :
                    <a href={"/local/search?keyword=" + this.state.search}>
                      <span className="e-header-search-btn"></span>
                    </a>
                  }
                </div>
                <div className="col-lg-2 col-2 col-xl-1 d-flex justify-content-end">
                  {
                    !this.state.user ?
                      <>
                        <Link className="e-favourite-icon" data-toggle="modal" data-target="#loginModal"></Link>
                        <span className="e-user-icon" data-toggle="modal" data-target="#loginModal"></span>
                      </>
                      :
                      <>
                        <Link to="/local/favourites" className="e-favourite-icon mx-0"></Link>
                        <Link to="/local/profile" className="e-name-span mt-1 ml-2 d-block">
                          <span className="e-name-span d-flex align-items-center justify-content-center font-weight-bold">{this.state.user}</span>
                        </Link>
                      </>
                  }
                </div>
              </div>
            </div>
          </div>
        </header>
        <FeedbackModal 
        showToast={(message, type)=>{
          this.setState({toast: message, toastType: type});
          setTimeout(() => {
            this.setState({toast: null});
          }, 5000);
        }}/>
        {
          this.state.toast?
          <Toast data={this.state.toast} type={this.state.toastType}/>
          :
          null
        }
        {
          window.innerWidth > 767 ?
            <SetLocationsModal locations={this.state.locationOptions} suggestedLocs={this.state.suggestedLocs}/>
            :
            null
        }
        {
          window.innerWidth > 767 ?
            <LoginModal />
            :
            null
        }
      </React.Fragment>
    );
  }
}

export default Header;
