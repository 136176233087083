/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';

/*Components*/
import Subscription from '../../components/common/subscription';
import PrimaryButton from '../../components/buttons/primaryButton';
import BestOfferModal from '../../components/modals/offerModal';
import SelectBox from '../../components/formComponents/selectBox';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import ImageViewModal from '../../components/modals/imageViewer';

/*Assets*/

import Before from '../../assets/images/local/before.png';
import After from '../../assets/images/local/before.png';
import Product1 from '../../assets/images/local/product1.png';
import Product2 from '../../assets/images/local/product2.png';
import Product3 from '../../assets/images/local/product3.png';
import Product4 from '../../assets/images/local/product4.png';
import Product5 from '../../assets/images/local/product5.png';
import Product6 from '../../assets/images/local/product6.png';
import Product7 from '../../assets/images/local/black.png';
import Product8 from '../../assets/images/local/blue.png';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import GetURLVar from '../../services/getParams/get-url-var';
import ButtonLoader from '../../components/common/buttonLoader';
import ProductDetailSlider from '../../components/sliders/productDetail';
import Toast from '../../components/common/toast';
import { Link } from 'react-router-dom';



class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
      color: "",
      size: "",
      id: 0,
      data: null,
      variant: 0,
      choice: 0,
      variantOptions: [],
      choiceOptions: [],
      choices: [],
      preview: 0,
      apiLoading: false,
      toast: null,
      toastType: ""
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
    GetURLVar().then(function (query) {
      if (query) {
        this.setState({ id: query.id })
        this.getData(query.id);
        this.relatedProducts(query.id);
      }
    }.bind(this));
  }

  async getData(id) {
    this.setState({ apiLoading: true, error: "" });
    let url = devconfig.localUser + '/product/detail';
    let data = JSON.stringify({
      "page_num": 1,
      "product_id": id
    });
    APIPost(url, data).then(function (response) {
      if (response.status == "ok") {
        this.setState({ data: response.data });
        this.setOptions(response.data);
        this.setState({ apiLoading: false, error: "" });
      }
      else {
        this.setState({ apiLoading: false, error: response.message });
      }
    }.bind(this))
  }

  setOptions(data) {
    let variants = [];
    let choices = [];
    data.variants.forEach((variant, i) => {
      const option = { value: i, label: variant.name, id: variant.variant_id }
      let choiceGroup = []
      variant.choices.forEach((item, j) => {
        const options = { value: j, label: item.name, id: item.variant_id }
        choiceGroup.push(options);
      });
      variants.push(option);
      choices.push(choiceGroup);
    })
    this.setState({ variantOptions: variants, choices: choices, variant_id: variants[0].id, choice_id: choices[0].id });
  }

  tabItemClick(tempItem) {
    this.setState({ tabIndex: tempItem });
  }
  colorChange = async (color) => {
    await this.setState({ color: color });
  }
  sizeChange = async (size) => {
    await this.setState({ size: size });
  }

  async addFav() {
    if (localStorage.getItem("truekhoj_user_data")) {
      this.setState({ error: "", favLoading: true });
      let url = devconfig.localUser + "/product/favourite";
      let data = JSON.stringify({
        "product_id": Number(this.state.id)
      });
      APIPost(url, data).then(function (response) {
        if (response.status === "ok") {
          this.setState({ favLoading: false });
          if(this.state.data.is_favourite) this.setToast("Product removed from Favourites!", "success");
          else this.setToast("Product added to Favourites!", "success");
          this.getData(this.state.id);
          
        }
        else {
          this.setState({ error: response.message, favLoading: false });
        }
      }.bind(this));
    }
    else {
      $("#loginModal").modal("show");
      this.setToast("Login to Add Product to Favourites!", "fail");
    }
  }

  async relatedProducts(id) {
    this.setState({ apiLoading: true, error: "" });
    let url = devconfig.localUser + '/product/related-list';
    let data = JSON.stringify({
      "product_id": id
    });
    APIPost(url, data).then(function (response) {
      if (response.status == "ok") {
        this.setState({ relatedProducts: response.data, apiLoading: false, error: "" });
      }
      else {
        this.setState({ apiLoading: false, error: response.message });
      }
    }.bind(this))
  }

  setToast(message, type){
    this.setState({toast: message, toastType: type});
    setTimeout(() => {
      this.setState({toast:null});
    }, 4000);
  }

  render() {
    const color = [
      { value: 1, label: 'Blue' },
      { value: 2, label: 'Black' },
    ]
    const size = [
      { value: 1, label: '32' },
      { value: 2, label: '36' },
    ]

    return (
      <>
        <div className="container-fluid e-local-product-detail pl-0 pr-0">
          {
            this.state.data ?
              <>
                <section className="e-product-detail">
                  <div className="container pt-5 pb-3 py-lg-5">
                    <div className="row">
                      <div className="col-lg-1 d-none d-lg-block">
                        <div className="row">
                          {
                            this.state.data.images.map((image, i) => (
                              <div className="col-lg-12 col-3">
                                <img src={image} alt="i" className="e-thumbnail" onClick={() => this.setState({ preview: i })} />
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className="col-lg-5 d-none d-lg-block">
                        <img src={this.state.data.images[this.state.preview]} alt="image" className="rounded" style={{ maxWidth: "100%", cursor: "pointer" }} data-toggle="modal" data-target="#imageViewModal" />
                      </div>
                      <div className="col-12 d-lg-none pb-4">
                        <ProductDetailSlider images={this.state.data.images} data={this.state.data} />
                        <span className="position-absolute d-flex e-add-fav-btn" onClick={!this.state.favLoading && !this.state.apiLoading? () => this.addFav(): null}>
                        <svg className="mr-2" style={{ top: '15px', left: '18px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0)">
                            <path d="M20.8413 4.60987C20.3306 4.09888 19.7242 3.69352 19.0567 3.41696C18.3892 3.14039 17.6738 2.99805 16.9513 2.99805C16.2289 2.99805 15.5134 3.14039 14.846 3.41696C14.1785 3.69352 13.5721 4.09888 13.0613 4.60987L12.0013 5.66987L10.9413 4.60987C9.90965 3.57818 8.51037 2.99858 7.05134 2.99858C5.59231 2.99858 4.19303 3.57818 3.16134 4.60987C2.12965 5.64156 1.55005 7.04084 1.55005 8.49987C1.55005 9.9589 2.12965 11.3582 3.16134 12.3899L4.22134 13.4499L12.0013 21.2299L19.7813 13.4499L20.8413 12.3899C21.3523 11.8791 21.7577 11.2727 22.0343 10.6052C22.3108 9.93777 22.4532 9.22236 22.4532 8.49987C22.4532 7.77738 22.3108 7.06198 22.0343 6.39452C21.7577 5.72706 21.3523 5.12063 20.8413 4.60987V4.60987Z" fill={this.state.data.is_favourite ? "#FF6031" : "#ffffff"} stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect x="0.000976562" width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        </span>
                      </div>
                      <div className="col-lg-6 pb-2 pb-lg-0">
                        <div className="col-lg-12">
                          <h2 className="h2 font-weight-bold">{this.state.data.name}</h2>
                          <p className="pt-3 mb-0">Brand: <span className="h5 font-weight-bold">{this.state.data.brand? this.state.data.brand: "--"}</span></p>
                          <p className="pt-3 mb-0">Seller: <span className="h5 font-weight-bold pr-2">{this.state.data.vendor_data.company? this.state.data.vendor_data.company: "--"}</span><Link className="font-weight-bold e-seller-link" to={`/local/store?id=${this.state.data.vendor_data.id}`}>view store</Link></p>
                          <p className="pt-4">Price: <span className="h5 font-weight-bold pl-2" style={{ opacity: "0.77" }}>{`₹${this.state.data.variants[this.state.variant].choices[this.state.choice].selling_price}`}</span><span className="h5 pl-3 font-weight-light" style={{ opacity: "0.47" }}><strike>{`₹${this.state.data.variants[this.state.variant].choices[this.state.choice].mrp}`}</strike></span></p>
                          <div className="row pt-5 pr-4">
                            <div className="col-lg-12">
                              <div className="d-flex align-items-center justify-content-center">
                                <p className="pt-3" style={{ minWidth: "56px" }}>Variant:</p>
                                <div className="container"><SelectBox
                                  selectchange={async (item) => {
                                    let temp = this.state.choices;
                                    await this.setState({ variant: item.value, choiceOptions: temp[item.value], variant_id: item.id, choice: 0 });
                                  }}
                                  options={this.state.variantOptions} placeholder="Variant" /></div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="d-flex align-items-center justify-content-center">
                                <p className="pt-3" style={{ minWidth: "56px" }}>Choice:</p>
                                <div className="container"><SelectBox
                                  selectchange={(item) => {
                                    this.setState({ choice: item.value, choice_id: item.id })
                                  }} options={this.state.choiceOptions} placeholder="Choice" /></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-10 pt-4 d-flex align-items-start">
                          {
                            this.state.data.button_text ?
                              <PrimaryButton className={this.state.variant_id && this.state.choice_id? "e-p-btn e-contact-btn e-offer-btn" : "e-p-btn e-contact-btn e-offer-btn e-inactive"} name={this.state.data.button_text} toggle="modal" target={this.state.variant_id && this.state.choice_id? "#offerModal": null} handleClick={!this.state.variant_id || !this.state.choice_id? ()=>this.setToast("Select a variant type and choice", "fail") :null}/>
                              :
                              null
                          }
                          {
                            this.state.favLoading || this.state.apiLoading ?
                              <div className="d-none d-lg-flex container px-0">
                                <ButtonLoader />
                              </div>
                              :
                              <PrimaryButton className="e-p-btn e-contact-btn e-favourite-btn ml-3 ml-sm-4 position-relative d-none d-lg-block" name={
                                <span>
                                  <svg className="mr-2 position-absolute" style={{ top: '15px', left: '18px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0)">
                                      <path d="M20.8413 4.60987C20.3306 4.09888 19.7242 3.69352 19.0567 3.41696C18.3892 3.14039 17.6738 2.99805 16.9513 2.99805C16.2289 2.99805 15.5134 3.14039 14.846 3.41696C14.1785 3.69352 13.5721 4.09888 13.0613 4.60987L12.0013 5.66987L10.9413 4.60987C9.90965 3.57818 8.51037 2.99858 7.05134 2.99858C5.59231 2.99858 4.19303 3.57818 3.16134 4.60987C2.12965 5.64156 1.55005 7.04084 1.55005 8.49987C1.55005 9.9589 2.12965 11.3582 3.16134 12.3899L4.22134 13.4499L12.0013 21.2299L19.7813 13.4499L20.8413 12.3899C21.3523 11.8791 21.7577 11.2727 22.0343 10.6052C22.3108 9.93777 22.4532 9.22236 22.4532 8.49987C22.4532 7.77738 22.3108 7.06198 22.0343 6.39452C21.7577 5.72706 21.3523 5.12063 20.8413 4.60987V4.60987Z" fill={this.state.data.is_favourite ? "#FF6031" : null} stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0">
                                        <rect x="0.000976562" width="24" height="24" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  {this.state.data.is_favourite ? "Remove Favourite" : "Add Favourite"}
                                </span>
                              } handleClick={() => this.addFav()} />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="e-product-detail-tab my-3">
                  <div className="container py-3">
                    <div className="row">
                      <div className="col-lg-12">

                        <div className="row">
                          <div className="col-md-12">
                            {
                              this.state.tabIndex === 1 ?
                                <span className="e-tabs-item e-active">Overview</span>
                                :
                                <span onClick={this.tabItemClick.bind(this, 1)} className="e-color-inactive">Overview</span>
                            }

                            {
                              this.state.tabIndex === 2 ?
                                <span className="e-tabs-item e-active">Description</span>
                                :
                                <span onClick={this.tabItemClick.bind(this, 2)} className="e-color-inactive">Description</span>
                            }
                            {
                              this.state.tabIndex === 3 ?
                                <span className="e-tabs-item e-active">Return Policy</span>
                                :
                                <span onClick={this.tabItemClick.bind(this, 3)} className="e-color-inactive">Return Policy</span>
                            }
                          </div>
                        </div>
                        <div className="row pt-3">
                          <div className="col-md-12">
                            <div className="e-border-bottom"></div>
                          </div>
                        </div>

                        {
                          this.state.tabIndex === 1 ?
                            <React.Fragment>
                              <div className="row pb-5">
                                <div className="col-lg-4 pt-3">
                                  <h5 className="e-tab-content-header">Overview</h5>
                                  <p className="e-tab-content-paragraph pt-3">{this.state.data.overview}</p>
                                </div>
                                <div className="col-lg-4 pt-3">
                                  <h5 className="e-tab-content-header">Instructions</h5>
                                  <ul className="e-tab-content-paragraph pt-3">
                                    {
                                      this.state.data.instructions.map(instruction => (
                                        <li>{instruction}</li>
                                      ))
                                    }
                                  </ul>
                                </div>
                                <div className="col-lg-4 pt-3">
                                  <h5 className="e-tab-content-header">Other</h5>
                                  <ul className="e-tab-content-paragraph pt-3">
                                    {
                                      this.state.data.other_things_1.map(item => (
                                        <li>{item}</li>
                                      ))
                                    }
                                  </ul>
                                </div>
                              </div>
                            </React.Fragment>
                            :
                            null
                        }
                        {
                          this.state.tabIndex === 2 ?
                            <React.Fragment>
                              <div className="row pb-5">
                                <div className="col-lg-4 pt-3">
                                  <h5 className="e-tab-content-header">Highlights</h5>
                                  <ul className="e-tab-content-paragraph pt-3">
                                    {
                                      this.state.data.highlights.map(item => (
                                        <li>{item}</li>
                                      ))
                                    }
                                  </ul>
                                  <p className="e-tab-content-paragraph">Made in Italy</p>
                                </div>
                                <div className="col-lg-4 pt-3">
                                  <h5 className="e-tab-content-header">Specifications</h5>
                                  <ul className="e-tab-content-paragraph pt-3">
                                    {
                                      this.state.data.specifications.map(item => (
                                        <li>{item}</li>
                                      ))
                                    }
                                  </ul>
                                </div>
                                <div className="col-lg-4 pt-3">
                                  <h5 className="e-tab-content-header">Other</h5>
                                  <ul className="e-tab-content-paragraph pt-3">
                                    {
                                      this.state.data.other_things_2.map(item => (
                                        <li>{item}</li>
                                      ))
                                    }
                                  </ul>
                                </div>
                              </div>
                            </React.Fragment>
                            :
                            null
                        }
                        {
                          this.state.tabIndex === 3 ?
                            <React.Fragment>
                              <div className="row pb-5">
                                <div className="col-lg-12 pt-3">
                                  <h5 className="e-tab-content-header">Policies</h5>
                                  <p className="e-tab-content-paragraph pt-3 mb-0">{this.state.data.return_policy}</p>
                                </div>
                                <div className="col-lg-12 pt-3">
                                  <h5 className="e-tab-content-header">Others</h5>
                                  <p className="e-tab-content-paragraph pt-3 mb-0">{this.state.data.other_things_3}</p>
                                </div>

                              </div>
                            </React.Fragment>
                            :
                            null
                        }

                      </div>
                    </div>
                  </div>
                </section>

                <section className="e-reated-products pb-5">
                  <div className="container">
                    <div className="row py-5">
                      <div className="col-lg-12">
                        <div className="">
                          <img src={Before} className="e-before" /><h4 className="text-uppercase text-center">related products</h4> <img src={After} className="e-after" />
                        </div>
                      </div>
                      {
                        this.state.relatedProducts && this.state.relatedProducts.map((product, i) => {
                          if (i < 4) {
                            return (
                              <div className="col-lg-3 col-md-4 col-sm-6 col-6 pt-3" onClick={() => window.location.href = `/local/product-detail?id=${product.product_id}`} style={{ cursor: "pointer" }}>
                                <img src={product.images[0]} className="e-prod-list-logo" />
                                <h6 className="pt-3">{`${product.product_name? `${product.product_name} `:""}${product.variant_name?`${product.variant_name} `:""}${product.choice_name?product.choice_name:""}`}</h6>
                                <p className="pt-1">{product.selling_price}<span className="pl-1"><strike>{product.mrp}</strike></span></p>
                              </div>
                            )
                          }
                        })
                      }

                    </div>
                  </div>
                </section>


              </>
              : this.state.apiLoading ?
                <div className="row py-5">
                  <div className="col-lg-12 py-3">
                    <PageLoader />
                  </div>
                </div>

                :
                <div className="row py-5">
                  <div className="col-lg-12 py-3">
                    <NothingFound />
                  </div>
                </div>


          }
        </div>
        {
          this.state.data && this.state.data.images && this.state.data.images.length ?
            <ImageViewModal images={this.state.data.images} data={this.state.data} />
            :
            null
        }
        {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType} />
            :
            null
        }
        <Subscription />
        <BestOfferModal id={this.state.id} variant_id={this.state.variant_id} choice_id={this.state.choice_id} />
      </>
    );
  }
}

export default ProductDetail;
