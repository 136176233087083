
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*Assets*/
import Banner1 from '../../assets/images/online/banner4.jpg';
import Banner2 from '../../assets/images/online/banner5.jpg';




class ProductBanner extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    var settings = {
       dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrow: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          }
        }
      ]
    };

    return (
      <Slider className="e-product-wrap" {...settings}>
        <div>
          <img src={Banner1} alt="Truekhoj banner"/>
        </div>
        <div>
          <img src={Banner2} alt="Truekhoj banner"/>
        </div>
        <div>
          <img src={Banner1} alt="Truekhoj banner"/>
        </div>
        <div>
          <img src={Banner2} alt="Truekhoj banner"/>
        </div>
        <div>
          <img src={Banner1} alt="Truekhoj banner"/>
        </div>

      </Slider>
    );
  }
}

export default ProductBanner;
