/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton.js';
import TextButton from '../../components/buttons/textButton.js';
import Input from '../../components/formComponents/input';



/*Assets*/
import Img from '../../assets/images/online/signup-img.svg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import PhoneValidation from '../../services/validation/phone';
import ButtonLoader from '../common/buttonLoader.js';



class VerifyOtp extends Component {
  constructor(props){
    super(props);
    this.state = {
      number: "",
      otp: "",
      apiLoading: false
    }
  }
  validateForm() {
    // alert("hi");
     if(!this.state.otp.length){
      this.setState({error:"OTP missing"});
    }
    else{
    this.verifyOtp();
    }
  }

  async verifyOtp(){
    this.setState({apiLoading: true, error: ""});
    let tempValid = true;
    if(tempValid){
      let url = devconfig.localUser + "/auth/verify-otp";
      let data = JSON.stringify({
        "otp" : this.state.otp,
        "mobile" : this.props.number,
        "type" : 1
      })
      if(this.props.forgotPassword){
        data = JSON.stringify({
          "otp" : this.state.otp,
          "mobile" : this.props.number,
          "type" : 2
        })
      }
      let temp = {otp: this.state.otp, number: this.props.number}
      // console.log(data)
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.props.verifyOtp(temp);
          this.setState({apiLoading: false});
        }
        else{
          this.setState({ error: response.message, apiLoading: false});
        }
      }.bind(this))
    }
    else{
      this.setState({ error: "Add a valid mobile number.", apiLoading: false});
    }
  }



  render() {

    return (

      <div className="row e-signup-modal e-verify-otp">
        <div className="col-lg-6 col-md-6">
          <div className="e-left-banner">
            <h6 className="text-uppercase">sign up</h6>
            <div className="text-center"><img src={Img} className="e-login-img"/></div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="e-login-content-wrap">

             <div className="row">
              <div className="col-lg-12 col-md-12 pr-0">
                <label>OTP</label>
                <div>
                  <Input className=""  type="number" value={this.state.otp} valueChange={(tempVal) => this.setState({otp: tempVal})} autoComplete="nope"/>
                </div>
                <div id="e-error" className="error pt-1">{this.state.error}</div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-lg-12 pr-0">
                {
                  this.state.apiLoading?
                  <div className="float-right">
                    <ButtonLoader/>
                  </div>
                  :
                  <div className="">
                 {
                   this.state.otp &&this.props.number ?
                   <PrimaryButton  className="e-p-btn e-contact-btn e-ofer-btn e-login-btn" name="Verify OTP" handleClick={this.validateForm.bind(this)}/>
                   :
                   <PrimaryButton  className="e-p-btn e-inactive e-contact-btn e-ofer-btn e-login-btn" name="Verify OTP" />
                 }
                </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7 pr-0">
               <p>Already Member?<TextButton className="e-sign-up pl-1" name="LOGIN" handleClick={()=>this.props.back()}/></p>
              </div>
              <div className="col-lg-5 pr-0 text-right">
               <p className="e-back"><TextButton className="e-sign-up pl-1" name="Back" handleClick={()=>this.props.backMobile()}/></p>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default VerifyOtp;