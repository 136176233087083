import React, { Component } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';
/*Components*/
import LocalSecondaryHeading from '../../components/navigations/secondaryHeading-local';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import FindBannerType from '../../components/ads/banners/findBannerType';
import CardSixColumn from '../../components/cards/column-6.js';
import CardTwoColumn from '../../components/cards/column-2.js';
import CardThreeColumn from '../../components/cards/column-3.js';
import CardFourColumn from '../../components/cards/column-4.js';
import H2 from '../../components/headings/h2.js';
import LogoHeading from '../../components/headings/logo-heading.js';
import Toast from '../../components/common/toast';
import FindVendors from '../../container/local/store/findVendor';


/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import GetURLVar from '../../services/getParams/get-url-var';
import OffersModal from '../../components/modals/offersPopup';

class PageBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
          id: 0,
          data: null
        }
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        ReactGA.pageview(window.location.pathname);
        GetURLVar().then(function (query) {
          if (query) {
            this.setLocation(query.id);
            this.setState({ id: query.id });
          }
        }.bind(this));
    }

    async setLocation(id) {
        this.setState({ pageLoading: true });
        let temp = await JSON.parse(localStorage.getItem("truekhoj_user_location"));
        this.setState({ userLocation: temp });
        this.getLayout(id);
    }
      
    getLayout(tempId) {
        var url = devconfig.localUser + "/page/detail";
        var data = JSON.stringify({
          "id": tempId,
          "type": 4,
          "location_id": this.state.userLocation.value
        })
        APIPost(url, data).then(function (response) {
          if (response.status === "ok") {
            if(response.data.page_data){
                this.setState({ data: response.data.page_data, pageLoading: false, details: response.data, pageName: response.data.name});
            }
            else{
                this.setState({ pageLoading: false });
            }
            if(response.data && response.data.layout_id) this.getPopup(response.data.layout_id);
          }
          else{
            this.setState({ pageLoading: false });
          }
        }.bind(this))
    }

    getPopup(id){
        let viewed = [];
        if(sessionStorage.getItem('local_viewed_pages')) viewed= JSON.parse(sessionStorage.getItem('local_viewed_pages'));
        if(viewed.indexOf(Number(id))===-1&& (localStorage.getItem('Login_promt')||localStorage.getItem('truekhoj_user'))){
          var url = devconfig.localUser+"/popupbanner/view";
          var data = JSON.stringify({
            "local" : true,
            "layout_id" : id
          });
          APIPost(url, data).then(function(response){
            if(response &&response.status === "ok"&& response.data && response.data.image){
              viewed.push(Number(id));
              sessionStorage.setItem('local_viewed_pages', JSON.stringify(viewed));
              this.setState({popup: response.data});
              $('#offersPopupModal').modal('show');
            }
          }.bind(this))
        }
      }
    

    render() {
        return (
            <div className="container-fluid p-0 pt-3">
                {
                    this.state.pageLoading?
                    <div className="container pt-5 d-flex align-items-center justify-content-center mt-5">
                        <PageLoader/>
                    </div>
                    :this.state.data && this.state.data.layout ?
                    <>
                            <div className="container">
                            <div className="row py-5">
                                <div className="col-lg-12 mt-lg-3 mb-lg-3">
                                    <H2 data={this.state.pageName} type={1} bg_color={"#F3E11100"} font_color={"#000"} />
                                </div>
                            </div>
                        </div>
                        {
                            this.state.data.layout.map((item, index) => {
                                if (item.bg_color) {
                                    var tempStyle = { background: item.bg_color };
                                }
                                {console.log('daat', item.data)}
                                return (
                                    <section className="container-fluid position-relative" style={tempStyle}>
                                        <div className="container">
                                            <div className="row pb-5 e-no-mb">
                                                {
                                                    item.header.content && !item.header.link ?
                                                        <div className={tempStyle ? "col-lg-12 pb-4 pt-5" : "col-lg-12 pb-4 pt-3"}>
                                                            <H2 data={item.header.content} type={item.header.type} bg_color={item.header.bg_color} font_color={item.header.font_color} />
                                                        </div>
                                                        : item.header.link ?
                                                            <div className={tempStyle ? "col-lg-12 mb-4 pt-5" : "col-lg-12 mb-4 pt-3"}>
                                                                <LogoHeading data={item.header} />
                                                            </div>
                                                            :
                                                            null
                                                }
                                    
                                                {
                                                    item.ads && item.ads.banner_type ?
                                                        <div className="col-lg-12 mb-4 e-no-p">
                                                            <FindBannerType content={item.ads.banners} details={item.ads} />
                                                        </div>
                                                        : item.ads ?
                                                            <div className="col-lg-12 mb-4 e-no-p">
                                                                <FindBannerType content={[item.ads]} details={item.ads} defaultType={1} />
                                                            </div>
                                                            :
                                                            null
                                                }
                                                {
                                                    item.data.content && item.data.type && item.data.type === 1 ?
                                                        <div className="col-lg-12 mb-3 e-no-p">
                                                            <CardFourColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} />
                                                        </div>
                                                        : item.data.content && item.data.type && item.data.type === 2 ?
                                                            <div className="col-lg-12 mb-3 e-no-p">
                                                                <CardSixColumn data={item.data.content} type={item.data.style.value} />
                                                            </div>
                                                        : item.data.content && item.data.type && item.data.type === 3 ?
                                                            <div className="col-lg-12 mb-3 e-no-p">
                                                                <CardTwoColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} />
                                                            </div>
                                                        : item.data.content && item.data.type && item.data.type === 4 ?
                                                            <div className="col-lg-12 mb-3 e-no-p">
                                                                <CardThreeColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} />
                                                            </div>
                                                        :
                                                            null
                                                }
                                                    {
                                                       item.favStore &&  item.favStore.data && item.favStore.data.length?
                                                        <div >
                                                            <FindVendors  stores={item.favStore}/>
                                                        </div>
                                                        :
                                                        null
                                                     }
                                            </div>
                                        </div>
                                    </section>
                                )
                            })
                        }
                        </>
                        :
                        <NothingFound />
                }
                <OffersModal data={this.state.popup}/>

            </div>
        )
    }
}

export default PageBuilder;