/*Package importing*/
import React, { Component } from 'react';

/*Assets*/
import Sample from '../../assets/images/PopupSample.png';




class OffersModal extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render() {

    return (
      <div className="modal fade e-offer-popup" id="offersPopupModal" tabIndex="-1" role="dialog" aria-labelledby="offersModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content p-0">
            <div className="w-100 position-relative">
                {
                  this.props.data?
                  <a href={this.props.data.redirection_url} target="_blank" rel="noreferrer">
                    <img src={this.props.data.image} alt="offer Image" className="e-offer-popup-image"/>
                  </a>
                  :
                  null
                }
                <svg className="e-offers-close-icon position-absolute" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-dismiss="modal">
                <circle cx="24" cy="24" r="24" fill="white"/>
                <path className="e-tick-mark" fill-rule="evenodd" clip-rule="evenodd" d="M22.9287 23.3516L14.0591 32.2212L15.7793 33.9414L24.6489 25.0718L33.5185 33.9414L35.2388 32.2212L26.3691 23.3516L35.2388 14.4819L33.5185 12.7617L24.6489 21.6313L15.7793 12.7617L14.0591 14.4819L22.9287 23.3516Z"/>
                </svg>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default OffersModal;