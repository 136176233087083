/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton.js';
import TextButton from '../../components/buttons/textButton.js';

import Input from '../../components/formComponents/input';
import SignUp from './signup';
import VerifyOtp from './verifyotp';
import SuccessSignUp from './signupSuccess';
import ForgotPassword from './fogotPassword';



/*Assets*/
import Img from '../../assets/images/online/login-img.svg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import ButtonLoader from '../common/buttonLoader.js';
import GoogleBtn from '../socialMediaLogin/googleLogin.js';
import FacebookBtn from '../socialMediaLogin/facebookLogin.js';



class LoginModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      number:"",
      password:"",
      login: true,
      apiLoading: false,
      error:"",
      success:"",
      otp: false,
      signup: false,
      forgotPassword: false,
      data: {},
      googleLogin: true
    }
  }

  componentDidMount(){
    this.setState({error: ""});
  }

  googleSignUp(response){
    this.setState({gApiLoading: true, error: ""});
    if(this.state.googleLogin && response && response.profileObj){
      let url = devconfig.localUser + "/auth/social-login";
      let info ={
        "name" : `${response.profileObj.givenName} ${response.profileObj.familyName}`,
        "email" : response.profileObj.email,
        "social_user_id" : response.profileObj.googleId,
        "image" : null
      }
      let data = JSON.stringify(info);
      APIPost(url, data).then(function(response){
        if(response.status === "ok" && response.access_token){
          localStorage.setItem("truekhoj_user", response.access_token);
          localStorage.setItem("truekhoj_user_data", JSON.stringify(response.data));
          this.setState({error: "", apiLoading: false})
          $("#loginModal").modal("hide");
          window.location.reload(); 
        }
        else if(response.message && response.message.includes("Success")){
          this.setState({ login: false, socialData: info, socialLogin:true, gApiLoading: false});
        }
        else{
          this.setState({ error: response.message, gApiLoading: false});
        }
      }.bind(this));
    }
    else if(response && response.name && response.email && response.id){
      let url = devconfig.localUser + "/auth/social-login";
      let info = {
        "name" : response.name,
        "email" : response.email,
        "social_user_id" : response.id,
        "image" : null
      }
      let data = JSON.stringify(info)
      APIPost(url, data).then(function(response){
        if(response.status === "ok" && response.access_token){
          localStorage.setItem("truekhoj_user", response.access_token);
          localStorage.setItem("truekhoj_user_data", JSON.stringify(response.data));
          this.setState({error: "", apiLoading: false, googleLogin: true})
          $("#loginModal").modal("hide");
          window.location.reload(); 
        }
        else if(response.message && response.message.includes("Success")){
          this.setState({ login: false, socialData: info, socialLogin:true, gApiLoading: false, googleLogin: true});
        }
        else{
          this.setState({ error: response.message, gApiLoading: false, googleLogin: true});
        }
      }.bind(this));
    }
    else{
      this.setState({error: "Something went wrong", gApiLoading: false, googleLogin: true});
    }
  }


  loginAction(){
    this.setState({apiLoading: true, error: ""});
    let tempEncodeData = `${this.state.number}:${this.state.password}`;
    let encodedData = btoa(tempEncodeData);
    $.ajax({
      type: "GET",
      url: devconfig.localUser+ '/auth/login',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Basic " + encodedData,
      }
    })
    .then(function (response) {
      if(response.status === "ok"){
        localStorage.setItem("truekhoj_user", response.access_token);
        localStorage.setItem("truekhoj_user_data", JSON.stringify(response.data));
        this.setState({error: "", apiLoading: false})
        $("#loginModal").modal("hide");
        window.location.reload(); 
      }
      else{
        this.setState({error: response.message, apiLoading: false});
      }
    }.bind(this))
    .catch(function(response){
      this.setState({error: response.responseJSON.message, apiLoading: false});
    }.bind(this))
  }

  handleSocialLogin(){
    let url = devconfig.localUser + "/auth/social-login";
      let info = this.state.socialData;
      let data = JSON.stringify(info)
      APIPost(url, data).then(function(response){
        if(response.status === "ok" && response.access_token){
          localStorage.setItem("truekhoj_user", response.access_token);
          localStorage.setItem("truekhoj_user_data", JSON.stringify(response.data));
          this.setState({error: "", apiLoading: false, googleLogin: true})
          $("#loginModal").modal("hide");
          window.location.reload(); 
        }
        else{
          this.setState({ error: response.message, gApiLoading: false, googleLogin: true, login: true});
        }
      }.bind(this));
  }

  handleLoginLater(){
    localStorage.setItem("Login_promt", true); 
    $("#loginModal").modal("hide");
  }


  render() {

    return (
      <div className="modal fade e-best-ofer-modal e-login-modal" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content e-ofer">
          {
            this.state.login ?
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="e-left-banner">
                    <h6 className="text-uppercase">login</h6>
                    <div className="text-center"><img src={Img} className="e-login-img"/></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="e-login-content-wrap">

                    <div className="row">
                      <div className="col-lg-12 col-md-12 pr-0">
                        <label>Mobile Number</label>
                        <div>
                          <Input className=""  type="number" value={this.state.number} valueChange={(tempVal) => this.setState({number: tempVal, error: ""})} autoComplete="nope"/>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-lg-12 col-md-12 pr-0">
                        <label>Password</label>
                        <div>
                          <Input className=""  type="password" value={this.state.password} valueChange={(tempVal) => this.setState({password: tempVal, error: ""})} autoComplete="nope"/>
                        </div>

                      </div>
                    </div>
                    <div className="row pt-1">
                      <div className="col-lg-6 pr-0">
                        <div id="e-error" className="error pt-1">{this.state.error}</div>
                      </div>
                      <div className="col-lg-6 pr-0 pl-4">
                        <div className="d-flex float-right">
                          <TextButton name="Forgot Password?" handleClick={()=>this.setState({forgotPassword: true, login: false, error:""})}/>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-4">
                      {
                        this.state.apiLoading?
                        <div className="col-lg-12">
                          <div className="float-right pr-3">
                            <ButtonLoader/>
                          </div>
                        </div>
                        :
                        <div className="col-lg-12 pr-0">

                        {
                          this.state.number.length && this.state.password  ?
                            <PrimaryButton  className="e-p-btn e-contact-btn e-ofer-btn e-login-btn" name="Login" handleClick={()=>this.loginAction()}/>
                          :
                           <PrimaryButton  className="e-inactive e-p-btn e-contact-btn e-ofer-btn e-login-btn " name="Login"/>
                        }

                      </div>
                      }

                      <div id="e-succes" className="success pt-2 pl-3">{this.state.succes}</div>

                      </div>
                      <div className="row pt-3">
                      <div className="col-lg-12 pr-0">
                        <div className="d-flex container justify-content-center">
                        <label>Or Login with</label>
                        </div>
                      </div>
                      <div className="col-lg-12 pr-0 pt-3">
                      {
                        this.state.gApiLoading?
                        <div className="float-right pr-3">
                            <ButtonLoader/>
                          </div>
                        :
                        <div className="container px-0">
                          <div className="row mx-n2">
                            <div className="col-6 px-2">
                            <GoogleBtn text="Google"
                              login={(response)=>this.googleSignUp(response)} onFailure={(response) => {if(response && response.error && !response.error.includes("idpiframe_initialization_failed"))this.setState({error: response.error});}}/>
                          
                            </div>
                            <div className="col-6 px-2">
                              <FacebookBtn handleResponse={(res)=>this.googleSignUp(res)} onClick={()=>this.setState({googleLogin: false})}/>
                            </div>
                          </div>
                        </div>
                      
                      }
                      </div>
                      {
                        !localStorage.getItem("Login_promt")?
                        <div className="col-lg-12 pt-4 pr-0">
                        <div className="d-flex container justify-content-center">
                        <TextButton  className="e-sign-up pl-1" name="May be later" handleClick={this.handleLoginLater.bind(this)}/>
                        </div>
                      </div>
                      :
                      null
                      }
                    </div>
                    </div>
                     <div className="row">
                      <div className="col-lg-12 pr-0">
                        <p>New Member?<TextButton  className="e-sign-up pl-1" name="SIGN UP" handleClick={()=>this.setState({login: false, forgotPassword: false})}/></p>
                      </div>
                    </div>

                  </div>
                </div>

            :
            <React.Fragment>
            {
              this.state.otp ?
                <React.Fragment>
                  {
                    this.state.signup ?
                      <SuccessSignUp back={()=>this.setState({login:true, password: "", number: "", error: "", otp: false})} forgotPassword={this.state.forgotPassword} data={this.state.data} socialLogin={this.state.socialLogin} socialData={this.state.socialData} login={()=>this.handleSocialLogin()}/>
                  :
                  <VerifyOtp verifyOtp={async (val)=> await this.setState({signup:true, data: val })} back={()=>this.setState({login:true,otp:false, password: "", number: "", error: ""})}  backMobile={()=>this.setState({login:false,otp:false})} forgotPassword={this.state.forgotPassword} number={this.state.mob}/>
                  }
                  </React.Fragment>

              :
                <SignUp setOtp={(number)=>this.setState({otp:true, mob:number})} back={()=>this.setState({login:true, password: "", number: "", error: ""})} forgotPassword={this.state.forgotPassword} socialLogin={this.state.socialLogin}/>


            }
           </React.Fragment>
          }


          </div>
        </div>

      </div>
    );
  }
}

export default LoginModal;