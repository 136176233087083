
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery';
import ReactGA from 'react-ga';


/*Components*/

import TextButton from '../../components/buttons/textButton';
import PageLoader from '../../components/common/page-loader';
import ButtonLoader from '../../components/common/buttonLoader';
import NothingFound from '../../components/common/nothing.js';
import GetURLVar from '../../services/getParams/get-url-var';
import ChangePasswordModal from '../../components/modals/changePasswordModal';


/*Assets*/
import Product1 from '../../assets/images/local/product1.png';
import Product2 from '../../assets/images/local/product2.png';
import Close from '../../assets/images/local/close.png';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';


class LocalProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
      favouritesTab: 1,
      close: true,
      data: null,
      user: null,
      otpLoading: false,
      favourites: []
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.getData();
    this.getFavourites();
    if (this.props.tab) {
      this.setState({
        tabIndex: this.props.tab
      })
    }
  }

  getFavourites() {
    this.setState({ favLoading: true, error: "" });
    let url = devconfig.localUser + '/favourite/list';
    APIGet(url).then(function (response) {
      if (response.status == "ok") {
        this.setState({ favLoading: false, error: "", favourites: response.data });
        console.log(response);
      }
      else {
        this.setState({ favLoading: false, error: response.message });
      }
    }.bind(this))
  }

  getData() {
    this.setState({ apiLoading: true, error: "" });
    let url = devconfig.localUser + '/auth/profile';
    APIGet(url).then(function (response) {
      if (response.status == "ok") {
        this.setUser(response.data);
        this.setState({ apiLoading: false, error: "", data: response.data });
      }
      else {
        this.setState({ apiLoading: false, error: response.message });
      }
    }.bind(this))
  }

  async removefav(id) {
    this.setState({ error: "", apiLoading: true });
    let url = devconfig.localUser + "/product/favourite";
    let data = JSON.stringify({
      "product_id": id
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ apiLoading: false });
        this.getData();
      }
      else {
        this.setState({ error: response.message, apiLoading: false });
      }
    }.bind(this));
  }

  async removeFavShop(id) {
    this.setState({ error: "", favLoading: true });
    let url = devconfig.localUser + "/favourite/add";
    let data = JSON.stringify({
      "id": id,
      "type": 2
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ favLoading: false });
        // this.getData();
        this.getFavourites();
      }
      else {
        this.setState({ error: response.message, favLoading: false });
      }
    }.bind(this));
  }

  async removeFavLocation(id) {
    this.setState({ error: "", favLoading: true });
    let url = devconfig.localUser + "/favourite/remove";
    let data = JSON.stringify({
      "id": id
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ favLoading: false });
        // this.getData();
        this.getFavourites();
      }
      else {
        this.setState({ error: response.message, favLoading: false });
      }
    }.bind(this));
  }

  async getOtp() {
    this.setState({ error: "", otpLoading: true });
    let url = devconfig.localUser + "/auth/verify-mobile";
    let data = JSON.stringify({
      "mobile": this.state.data.mobile,
      "type": 2
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ otpLoading: false });
        $("#changePasswordModal").modal("show");
      }
      else {
        this.setState({ error: response.message, otpLoading: false });
      }
    }.bind(this));
  }

  setUser(user) {
    if (user) {
      const name = user.name;
      const matches = name.match(/\b(\w)/g);
      const res = matches[0] + matches[matches.length - 1];
      this.setState({ user: res });
    }
  }

  tabItemClick(tempItem) {
    this.setState({ tabIndex: tempItem });
  }

  favTabItemClick(tempItem) {
    this.setState({ favouritesTab: tempItem });
  }

  handleClick = () => {
    this.setState({ close: false });
  }
  // handleClick1=()=>{
  //   this.setState({close1:false});
  // }

  setLocation(e, location) {
    if (e.target.classList.contains("e-fav-close")) {
      this.removeFavLocation(location.value);
    }
    else {
      localStorage.setItem("truekhoj_user_location", JSON.stringify(location));
      window.location.reload();
    }
  }


  render() {
    if (this.state.apiLoading) {
      return (
        <div className="container">
          <div className="row pt-3">
            <PageLoader />
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="container-fluid p-0">
          {
            this.state.data ?
              <>
                <section className="e-local-profile pt-4 mt-2">
                  <div className="container">
                    <div className="row">
                      <div className="col-6 mb-4">
                        <h5 className="text-uppercase">account details</h5>
                      </div>
                      <div className="col-6 mb-4">
                        <span className="float-right e-logout d-flex align-items-center">
                          <TextButton name="Logout" className="e-t-btn" handleClick={() => {
                            localStorage.removeItem("truekhoj_user_data");
                            sessionStorage.removeItem("truekhoj_user_data");
                            localStorage.removeItem("truekhoj_user");
                            sessionStorage.removeItem("truekhoj_user");
                            window.location.reload();
                          }} />
                          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 512 512">
                            <g>
                              <g>
                                <path d="M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15
                            c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724
                            c0,35.168,28.619,63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262
                            C276.412,478.129,266.908,468.625,255.15,468.625z"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173
                            H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173
                            c-8.377,8.25-8.441,21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575
                            c4.04-3.997,6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z"/>
                              </g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div className="col-lg-12">
                        <div className="e-profile-wrap">
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-4 col-4 ml-4 mt-4 pt-2 pr-0">
                              <div>
                                <span className="e-name">{this.state.user}</span>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-7 mt-4 pl-0">
                              <div className="e-profile-address">
                                <h5 className="text-uppercase pt-5">{this.state.data.name}</h5>
                                <p className="text-truncate" style={{ maxWidth: "100%" }}>{this.state.data.email}</p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="e-local-profile-tab e-product-detail-tab">
                  <div className="container">
                    <div className="row mt-5  mb-5 pb-5">
                      <div className="col-md-12">

                        <div className="row">
                          <div className="col-md-12">
                            {
                              this.state.tabIndex === 1 ?
                                <span className="e-tabs-item e-active">Overview</span>
                                :
                                <span onClick={this.tabItemClick.bind(this, 1)} className="e-color-inactive">Overview</span>
                            }

                            {
                              this.state.tabIndex === 2 ?
                                <span className="e-tabs-item e-active">Favourites</span>
                                :
                                <span onClick={this.tabItemClick.bind(this, 2)} className="e-color-inactive ">Favourites</span>
                            }
                          </div>
                        </div>

                        {
                          this.state.tabIndex === 1 ?
                            <React.Fragment>
                              <div className="row pt-4">
                                <div className="col-lg-12 col-md-12 col-sm-10 col-10">
                                  <div className="e-local-profile-detail-wrap">
                                    <div className="e-local-profile-content">
                                      <div className="row">
                                        <div className="col-lg-3 col-md-4 col-3">
                                          <p>Name:</p>
                                          <p>Email:</p>
                                          <p>Phone:</p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-9">
                                          <p><span>{this.state.data.name}</span></p>
                                          <p><span className="text-truncate d-block" style={{ maxWidth: "100%" }}>{this.state.data.email}</span></p>
                                          <p><span>{this.state.data.mobile}</span></p>
                                        </div>
                                      </div>
                                      <div className="row pt-2">
                                        <div className="col-lg-4 pl-0">
                                          {
                                            this.state.otpLoading ?
                                              <ButtonLoader />
                                              :
                                              <TextButton name="Change Password" className="e-t-btn" handleClick={() => this.getOtp()} />
                                          }
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                            :
                            null
                        }


                        {
                          this.state.tabIndex === 2 ?
                            <div className="row e-reated-products pt-3 pb-4 pb-lg-0">
                              {
                                this.state.favLoading ?
                                  <PageLoader />
                                  :
                                  <>
                                    <div className="col-lg-12" >
                                      <div className="e-border-bottom"></div>
                                      <div className="d-flex align-items-center e-fav-tab-items pt-4">
                                        <span className="e-tabs-item e-active  mr-4">Products</span>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 pt-4">
                                      <div className="row">
                                        {
                                          this.state.favourites && this.state.favourites.products && this.state.favourites.products.length ?
                                            <>
                                              {
                                                this.state.favourites.products.map(product => (
                                                  <div className="col-lg-3 col-md-4 col-sm-6 pt-3">
                                                    <Link to={`/local/product-detail?id=${product.product_id}`}><img src={product.images[0]} className="e-prod-list-logo" /></Link>
                                                    <img src={Close} className="e-fav-close" onClick={() => this.removefav(product.product_id)} />
                                                    <Link to={`/local/product-detail?id=${product.product_id}`}><h6 className="pt-3">{`${product.product_name? `${product.product_name} `:""}${product.variant_name?`${product.variant_name} `:""}${product.choice_name?product.choice_name:""}`}</h6></Link>
                                                    <p className="pt-1">{`₹${product.selling_price}`}<span className="pl-1"><strike>{`₹${product.mrp}`}</strike></span></p>
                                                  </div>
                                                ))
                                              }
                                            </>
                                            :
                                            <NothingFound />
                                        }
                                      </div>
                                    </div>
                                    <div className="col-lg-12 pt-5">
                                      <div className="d-flex align-items-center e-fav-tab-items">
                                        <span className="e-tabs-item e-active  mr-4">Shops</span>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 pt-4">
                                      <div className="row e-fav-card pb-0">
                                        {
                                          this.state.favourites && this.state.favourites.shops && this.state.favourites.shops.length ?
                                            <>
                                              {
                                                this.state.favourites.shops.map(shop => {
                                                  return (
                                                    <div className="col-lg-4 col-md-6 col-12 pt-3">
                                                      <img src={Close} className="e-fav-close" onClick={() => this.removeFavShop(shop.id)} />
                                                      <Link className="container px-0" to={`/local/store?id=${shop.id}`}>
                                                        <div className="container e-card-wrap p-5" style={{ width: "unset", height: "auto" }}>
                                                          <div className="d-flex flex-column">
                                                            <div className="d-flex align-items-center mb-3">
                                                              <img src={shop.logo} alt="" className="e-fav-shop-logo" />
                                                              <h5 className="ml-4 mr-0">{shop.company}</h5>
                                                            </div>
                                                            <p className="mb-3 pl-3"><span>Email: </span>{shop.email}</p>
                                                            <p className="mb-3 pl-3"><span>Website: </span>{shop.website}</p>
                                                          </div>
                                                        </div>
                                                      </Link>
                                                    </div>
                                                  )
                                                })
                                              }
                                            </>
                                            :
                                            <NothingFound />
                                        }
                                      </div>
                                    </div>
                                    <div className="col-lg-12 pt-5">
                                      <div className="d-flex align-items-center e-fav-tab-items">
                                        <span className="e-tabs-item e-active  mr-4">Locations</span>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 pt-4">
                                      <div className="row">
                                        {
                                          this.state.favourites && this.state.favourites.locations && this.state.favourites.locations.length ?
                                            <>
                                              {
                                                this.state.favourites.locations.map(location => {
                                                  let loc = location.name
                                                  const temp = location.name.search(",");
                                                  if (temp != -1) {
                                                    loc = location.name.slice(0, temp)
                                                  }
                                                  return (
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 pt-3">
                                                      <span className="e-outer-wrap container d-flex justify-content-between" onClick={(e) => this.setLocation(e, { value: location.id, label: loc })}>
                                                        <img src={Close} className="e-fav-close" style={{ top: 0, right: 0 }} />
                                                        {loc}
                                                      </span>
                                                    </div>
                                                  )
                                                })
                                              }
                                            </>
                                            :
                                            <NothingFound />
                                        }
                                      </div>
                                    </div>

                                  </>
                              }
                            </div>
                            :
                            null
                        }


                      </div>
                    </div>
                  </div>
                </section>
              </>
              :
              null
          }
          <ChangePasswordModal mobile={this.state.data ? this.state.data.mobile : null} />
        </div>
      );
    }
  }
}



export default LocalProfile;


