
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoPlayer from '../../../components/common/video';
import $ from 'jquery';

/*Assets*/
import Banner1 from '../../../assets/images/online/full-banner-1.svg';
import Banner2 from '../../../assets/images/online/full-banner-2.svg';

import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';

class AdsHeroBanner extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    console.log(this.props.content)
  }


  adAnalytics(tempId){
    if(tempId){
        var url = devconfig.userV1+"/ads/update-analytics";
        var data = JSON.stringify({
          "ads_id" : Number(tempId),
          "clicks" : 1,
          "views" : 1
        })
        APIPost(url, data).then(function(response){
          if(response.status === "ok"){
            
          }
        }.bind(this))
    }
  }


  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
    };
    if(this.props.noSlide){
      settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1.1,
              autoplay: false,
              swipeToSlide: true,
              arrows: false,
              dots: false,
            }
          }
        ]
      };
    }
    if(this.props.defaultSlidesNumber){
      settings = {
        slidesToShow: this.props.defaultSlidesNumber,
        arrows: true
      }
    }
    

    const linkVerify = (item)=>{
      var tempLink = item;
      if (item && !item.match(/^http([s]?):\/\/.*/)){
        tempLink = 'http://' + item;
        return tempLink
      }
      else{
        return item
      }
    }
    
    return (

      <Slider className="e-hero-banner-wrap" {...settings}>
        {
          this.props.content && this.props.content.map( (item, index) => {
            if(item.content) {

              var tempId = null;
              if(item.id){
                tempId = item.id;
              }

              return(
                <a href={item.link ?  linkVerify(item.link) : null } onClick={item.link ? this.adAnalytics.bind(this, tempId) : null} className="px-0 w-100" target={ item.link ? "_blank" : null}>
                  
                  {
                    item.content && item.content.indexOf(".mp4") > -1 ?
                      <VideoPlayer url={item.content} />
                    :
                      <div className="e-hero-banner-img-wrapper position-relative">
                        <img className="e-hero-banner-img position-absolute" src={item.content} alt="Truekhoj banner"/>
                      </div>
                  }
                </a>
              );
            }
            else{
              return(
                null
              );
            }
            
          })

        }
      </Slider>
    );
  }
}

export default AdsHeroBanner;
