/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../buttons/primaryButton.js';




/*Assets*/
import Img1 from '../../assets/images/store-sample.jpg';
import Star from '../../assets/images/local/4star.svg';

/*Services*/



class FavouritesCard extends Component {
  constructor(props){
    super(props);
    this.state = {
        
    }
  }



  componentDidMount(){
    console.log('vensor card-->', this.props.vendorData);
  }
  render() {

    return (

      <div className="container px-0 e-fav-card d-flex align-items-center justify-content-center">
        <div className="position-relative e-fav-store-card">
          <img src={this.props.data.content} className="e-fav-card-img"/>
          <div className="e-card-wrap position-absolute d-flex flex-column justify-content-between">
            <div className="d-flex flex-column">
              <div className='d-flex'>
              <h5 className="text-uppercase d-inline-block">{this.props.vendorData.company} -</h5><span className='e-vendor mt-1'>{this.props.vendorData.name}</span>
              </div>
              
              <p className="pt-3 mb-1"><span>Address:</span>{this.props.vendorData.address}</p>
              {this.props.vendorData.map_link? <a className="" href={this.props.vendorData.map_link}>View Directions</a>: null}
              <p className="pt-3 mb-1"><span>Phone:</span> {this.props.vendorData.mobile}</p>
              <p className="pt-3 mb-1"><span>Hours:</span> {this.props.vendorData.hours}</p>
            </div>
            <div className="row">
              <div className="col-12 col-sm-7 col-md-8 pt-3 pt-sm-4 mt-2 order-2 order-sm-1 d-sm-flex w-100 h-100 align-items-end">
                <PrimaryButton  className="e-p-btn e-contact-btn e-view-store-btn" name="View Store" handleClick={()=>window.location.href=`/local/store?id=${this.props.vendorData.id}`}/>
              </div>
              {
                this.props.vendorData.rating?
                <div className="col-12 col-sm-5 col-md-4 order-1 order-sm-2">
                <span className="e-rating pl-5">{this.props.vendorData.rating? this.props.vendorData.rating.toFixed(1): `0.0`}</span>
                <div className="container e-review d-flex align-items-center pt-2">
                              <div class="progress w-100">
                                <div class="progress-bar" role="progressbar" aria-valuenow="0" style={{ width: `${(this.props.vendorData.rating/5) * 100}%` }} aria-valuemin="0" aria-valuemax="5"></div>
                              </div>
                            </div>
                            <svg width="84" height="12" viewBox="0 0 84 12" className="position-absolute" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                            <clipPath id="svgPath">
                              <path d="M6 0L7.58702 3.81565L11.7063 4.1459L8.56785 6.83435L9.52671 10.8541L6 8.7L2.47329 10.8541L3.43215 6.83435L0.293661 4.1459L4.41298 3.81565L6 0Z" fill="#D4D4D4" />
                              <path d="M6 0L7.58702 3.81565L11.7063 4.1459L8.56785 6.83435L9.52671 10.8541L6 8.7L2.47329 10.8541L3.43215 6.83435L0.293661 4.1459L4.41298 3.81565L6 0Z" stroke="#F1C644" />
                              <path d="M23.333 0L24.92 3.81565L29.0393 4.1459L25.9009 6.83435L26.8597 10.8541L23.333 8.7L19.8063 10.8541L20.7652 6.83435L17.6267 4.1459L21.746 3.81565L23.333 0Z" fill="#D4D4D4" />
                              <path d="M23.333 0L24.92 3.81565L29.0393 4.1459L25.9009 6.83435L26.8597 10.8541L23.333 8.7L19.8063 10.8541L20.7652 6.83435L17.6267 4.1459L21.746 3.81565L23.333 0Z" stroke="#F1C644" />
                              <path d="M42 0L43.587 3.81565L47.7063 4.1459L44.5679 6.83435L45.5267 10.8541L42 8.7L38.4733 10.8541L39.4321 6.83435L36.2937 4.1459L40.413 3.81565L42 0Z" fill="#D4D4D4" />
                              <path d="M42 0L43.587 3.81565L47.7063 4.1459L44.5679 6.83435L45.5267 10.8541L42 8.7L38.4733 10.8541L39.4321 6.83435L36.2937 4.1459L40.413 3.81565L42 0Z" stroke="#F1C644" />
                              <path d="M59.333 0L60.92 3.81565L65.0393 4.1459L61.9009 6.83435L62.8597 10.8541L59.333 8.7L55.8063 10.8541L56.7652 6.83435L53.6267 4.1459L57.746 3.81565L59.333 0Z" fill="#D4D4D4" />
                              <path d="M59.333 0L60.92 3.81565L65.0393 4.1459L61.9009 6.83435L62.8597 10.8541L59.333 8.7L55.8063 10.8541L56.7652 6.83435L53.6267 4.1459L57.746 3.81565L59.333 0Z" stroke="#F1C644" />
                              <path d="M78 0L79.587 3.81565L83.7063 4.1459L80.5679 6.83435L81.5267 10.8541L78 8.7L74.4733 10.8541L75.4321 6.83435L72.2937 4.1459L76.413 3.81565L78 0Z" fill="#D4D4D4" />
                              </clipPath>
                              </defs>
                            </svg>
                <p className="e-reviews pl-5 mb-0">{this.props.vendorData.reviews_count} reviews</p>
              </div>
              :
              null
              }
            </div>
            </div>
        </div>
      
      </div>

      //  <div className="row e-fav-card">
      //   <div className="col-lg-6">
      //     

      //     <div className="row">
      //     <div className="col-lg-7 offset-4 pl-2">

          // <div className="e-card-wrap">
          //   <h5 className="text-uppercase">levis exclusive store, indranagar</h5>
          //   <p className="pt-3 mb-1"><span>Address:</span> 216, Brigade Rd, Shanthala Nagar, Ashok Nagar, Bengaluru, Karnataka 560025</p>
          //   <a className="" href="#">View Directions</a>
          //   <p className="pt-3 mb-1"><span>Phone:</span> 080 4111 6985</p>
          //   <p className="pt-3 mb-1"><span>Hours:</span> Open 9.30 AM - Closes 9:30PM</p>
          //   <div className="row ">
          //     <div className="col-8 pt-4 mt-2">
          //       <PrimaryButton  className="e-p-btn e-contact-btn e-view-store-btn" name="View Store"/>
          //     </div>
          //     <div className="col">
          //       <span className="e-rating pl-5">4.4</span>
          //       <img src={Star} className="pl-4 ml-2"/>
          //       <p className="e-reviews pl-5">72 reviews</p>
          //     </div>
          //   </div>

      //     </div>

      //     </div>
      //     </div>

      //   </div>
      //  </div>



    );
  }
}

export default FavouritesCard;