import React from 'react';

export default class input extends React.Component{
	constructor(props){
		super(props);
		this.state ={
			inputValue: ""
		}
	}

	componentDidMount(){
    if(this.props.defaultValue){
      console.log(this.props.defaultValue);
      this.setState({value: this.props.defaultValue});
    }
  }

	valueChange(e){
		this.setState({inputValue: e.target.value});
		this.props.valueChange(e.target.value);
	}

	render(){

		return(
			<div className="e-input-wrap">
				<input type={this.props.type} value={this.props.value} defaultValue={this.props.default} name={this.state.name} id={this.props.id} className={this.props.className} onChange={this.valueChange.bind(this)} placeholder={this.props.placeholder} autoComplete={this.props.autoComplete}/>
			</div>
		)
	}
}