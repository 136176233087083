
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





class ProductDetailSlider extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    var settings = {
       dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: false,
      arrow: false,
      swipeToSlide: true,
      slidesToScroll: 1,
      className: "center",
    };

    return (
      <>
      <Slider className="e-product-wrap" {...settings}>
       {
           this.props.images && this.props.images.map(image=>(
            <div className="container">
                <img src={image} alt="i" className="w-100" data-toggle="modal" data-target="#imageViewModal"/>
            </div>
           ))
       }
      </Slider>
      </>
    );
  }
}

export default ProductDetailSlider;
