
/*Package importing*/
import React, { Component } from 'react';

/*Assets*/

class PageLoader extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  render() {

    return (
      <div className="e-page-loader text-center">
        Loading the best offers & deals for you....
      </div>
    );
  }
}

export default PageLoader;
