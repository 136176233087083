
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Assets*/


/*Services*/
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';

/*Components*/

class LocalSecondaryHeading extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      wholesaleIds: [],
      superValueIds: [],
      customPages: [],
      showWholesale: false,
      selectedWholesale: -1,
      selectedSuperValue: -1,
      showSuperValue: false
    }
  }

  componentDidMount() {
    this.getPages();
  }

  getPages(){
    var url = devconfig.localUser+"/page/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        console.log(response);
        this.setState({data: response.data});
        this.setWholesale(response.data);
        this.setCustom(response.data);
      }
    }.bind(this))
  }

  async setCustom(data){
    let ids = [];
    if(data.custom_page_layouts && data.custom_page_layouts.length){
      data.custom_page_layouts.forEach(element => {
        ids.push(element.id)
      });
    }
    this.setState({customPages: ids});
  }

  async setWholesale(data){
    let temp = [];
    let temp1 = [];
    let superValue = [];
    if(data.category_data){
      data.category_data.map(category=>{
        if(category.wholesale_data && category.wholesale_data.id){
          temp.push(category.wholesale_data.id);
          temp1.push(category.wholesale_data.wholesale_department_id);
          if(this.props.category_page && this.props.category_id===category.wholesale_data.wholesale_department_id) this.setState({showWholesale: true, selectedWholesale: category.wholesale_data.id});
        }
        if(category.super_value_store_data && category.super_value_store_data.id){
          superValue.push(category.super_value_store_data.id);
          if(this.props.category_page && this.props.category_id===category.super_value_store_data.super_value_store_department_id) this.setState({showSuperValue: true, selectedSuperValue: category.super_value_store_data.id});
        }
      });
    }
    await this.setState({wholesaleIds: temp, wholesaleDepartments: temp1});
  }

  render() {

    return (
      <div className="e-sec-head-wrap">

        <div className="container-fluid d-sm-block d-md-block d-lg-none p-0">
          <div className="scrollmenu">
              <span className="e-page-name">{this.props.data.name}</span>
              {
                this.state.showWholesale && this.state.selectedWholesale!=-1?
                <a className="e-sec-head-link" href={`/local/deals?id=${this.state.selectedWholesale}&wholesale=true&department=${this.props.category_id}`}>Wholesale Page</a>
                :
                null
              }
              {
                this.state.showSuperValue && this.state.selectedSuperValue!=-1?
                <a className="e-sec-head-link" href={`/local/deals?id=${this.state.selectedSuperValue}&supervalue=true`}>Super Value Store</a>
                :
                null
              }
              {
                this.props.data.topmenu_data.map((item, index) => {
                  var tempUrl = "";
                  let wholesale = this.state.wholesaleIds.indexOf(item.id);
                  let superValue = this.state.superValueIds.indexOf(item.id);
                  let custom = this.state.customPages.indexOf(item.id);
                  if(item.is_department){
                    tempUrl = "/local/category?id="+item.department_id+"&category="+item.name
                  }
                  else if(item.is_category){
                    tempUrl = "/local/sub-category?id="+item.category_id+"&sub_category="+item.category_id+"&category="+item.department_id;
                  }
                  else if(item.is_subcategory){
                    tempUrl = `/local/products?id=${item.sub_category_id}&product=${item.name}`
                  }
                  else if(item.is_other){
                    if(item.id == 3){
                      tempUrl = "/local";
                    }
                    else if(item.id == 156){
                      tempUrl = "/local/product-listing";
                    }
                    else if(wholesale!=-1){
                      tempUrl = `/local/deals?id=${item.id}&wholesale=true&department=${this.state.wholesaleDepartments[wholesale]}`;
                    }
                    else if(superValue!=-1){
                      tempUrl = `/local/deals?id=${item.id}&upervalue=true`;
                    }
                    else if(custom!=-1){
                      tempUrl = `/local/custom?id=${item.id}`
                    }
                    else{
                      tempUrl = "/local/deals?id="+item.id;
                    }
                    
                  }
                  return(
                    <a className="e-sec-head-link" href={tempUrl}>{item.name}</a>
                  );
                })
              }
          </div>
        </div>

        <div className="container d-none d-sm-none d-md-none d-lg-block">
          <div className="row">
            <div className="col-lg-12">
              <div className="e-lg-scrollmenu">
              <div className="e-page-name">{this.props.data.name}</div>
              {
                this.state.showWholesale && this.state.selectedWholesale!=-1?
                <a className="e-sec-head-link" href={`/local/deals?id=${this.state.selectedWholesale}&wholesale=true&department=${this.props.category_id}`}>Wholesale Page</a>
                :
                null
              }
              {
                this.state.showSuperValue && this.state.selectedSuperValue!=-1?
                <a className="e-sec-head-link" href={`/local/deals?id=${this.state.selectedSuperValue}&supervalue=true`}>Super Value Store</a>
                :
                null
              }
              {
                this.props.data.topmenu_data.map((item, index) => {
                    var tempUrl = "";
                    let wholesale = this.state.wholesaleIds.indexOf(item.id);
                    let superValue = this.state.superValueIds.indexOf(item.id);
                    let custom = this.state.customPages.indexOf(item.id);
                    if(item.is_department){
                      tempUrl = "/local/category?id="+item.department_id+"&category="+item.name
                    }
                    else if(item.is_category){
                      tempUrl = "/local/sub-category?id="+item.category_id+"&sub_category="+item.category_id+"&category="+item.department_id;
                    }
                    else if(item.is_subcategory){
                      tempUrl = `/local/products?id=${item.sub_category_id}&product=${item.name}`
                    }
                    else if(item.is_other){
                      if(item.id == 3){
                        tempUrl = "/local";
                      }
                      else if(item.id == 156){
                        tempUrl = "/local/product-listing";
                      }
                      else if(wholesale!=-1){
                        tempUrl = `/local/deals?id=${item.id}&wholesale=true&department=${this.state.wholesaleDepartments[wholesale]}`;
                      }
                      else if(superValue!=-1){
                        tempUrl = `/local/deals?id=${item.id}&upervalue=true`;
                      }
                      else if(custom!=-1){
                        tempUrl = `/local/custom?id=${item.id}`
                      }
                      else{
                        tempUrl = "/local/deals?id="+item.id;
                      }
                    
                  }
                  return(
                    <a className="e-sec-head-link" href={tempUrl}>{item.name}</a>
                  );
                })
              }

              {/*
                this.state.data && this.state.data.home_data.map((item, index) => {
                  return(
                    <Link className="e-sec-head-link">{item.name}</Link>
                  );
                })
              */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LocalSecondaryHeading;
