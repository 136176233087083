

const stagingUrl= "https://58gmeu3tb4.execute-api.ap-south-1.amazonaws.com/staging/api/v1";
const url= "https://iigqgbxnqj.execute-api.ap-south-1.amazonaws.com/dev/api/v1";

const liveUrl = "https://iwu5u49n5d.execute-api.ap-south-1.amazonaws.com/prod/api/v1"

var devconfig = {
  userV1: liveUrl + "/user",
  localUser: liveUrl + "/local/user",
  devUser: url + "/local/user",
}

module.exports = devconfig;