
/*Package importing*/
import React, { Component } from 'react';
import ReactGA from 'react-ga';


/*Components*/


import H2 from '../../components/headings/h2.js';


class Sample extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      highlights: {},
      dataLoading: false
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }


  render() {



      return (

        <section className="container-fluid position-relative">
            <div className="container">
              <div className="row mb-5 e-no-mb">
                <div className="col-lg-12 mb-4 e-no-p">
                  <H2 data="beauty & personal care" type={30} bg_color={"red"}/>
                </div>


              </div>
            </div>

          </section>


      );

  }
}

export default Sample;
