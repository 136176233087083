import React from 'react';
import FacebookLogin from 'react-facebook-login';
import PrimaryButton from '../buttons/primaryButton';

const FacebookBtn = (props)=>{

    const onClick=(e)=>{
        console.log("clicked");
        props.onClick();
    }

    const responseFacebook=(res)=>{
        props.handleResponse(res);
    }

    return(
        <div className="e-fb-btn">
            <FacebookLogin
            appId="777734932930574"
            autoLoad={false}
            fields="name,email,picture"
            onClick={(e)=>onClick(e)}
            callback={(res)=>responseFacebook(res)}
            icon={<span className="d-flex e-fb-icon"></span>}
            textButton="Facebook" />
        </div>
    )
}

export default FacebookBtn;