
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import $ from 'jquery';
import ReactGA from 'react-ga';


/*Components*/


import FaqAccordion from '../../components/accordion/faq-accordion.js';
import Subscription from '../../components/common/subscription.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import GetURLVar from '../../services/getParams/get-url-var';



/*Assets*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';


class Faq extends Component {
  constructor(props){
    super(props);
    this.state = {




    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

  }


  async validate(){
    if(!this.state.name.length){
      this.setState({error:"First Name missing"});
    }
    else if(!this.state.email.length){
      this.setState({error:"Email missing"});
    }
    else if(!this.state.phone.length){
      this.setState({error:"Phone Number missing"});
    }

  };




  render() {


      return (
        <div className="container-fluid p-0">
          <section className="container-fluid position-relative p-0 e-faq-banner">
            <div className="container">
              <div className="row mb-5">
                <div className="col-lg-6 mb-5">
                  <h1 className="text-uppercase">frequently asked questions.</h1>
                </div>
              </div>
            </div>
          </section>

          <section className="e-faq-form pb-5 mb-4">
            <div className="container">
              <h4 className="text-uppercase pt-5 mt-4">queries & answers</h4>
              <FaqAccordion/>
            </div>
          </section>

          <Subscription/>
        </div>
      );
    }
  }


export default Faq;

