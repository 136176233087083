/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton.js';
import TextButton from '../../components/buttons/textButton.js';
import Input from '../../components/formComponents/input';
import ButtonLoader from '../common/buttonLoader.js';



/*Assets*/
import Img from '../../assets/images/online/signup-img.svg';

/*Services*/
import devconfig from '../../config/config.js';
import PhoneValidation from '../../services/validation/phone';
import APIPost from '../../services/apiCall/post';



class SignUp extends Component {
  constructor(props){
    super(props);
    this.state = {
      number: null,
      apiLoading: false,
      error: ""
    }
  }

  async getOtp(){
    this.setState({apiLoading: true, error: ""});
    let tempValid = await PhoneValidation(this.state.number);
    if(tempValid){
      let url = devconfig.localUser + "/auth/verify-mobile";
      let data = JSON.stringify({
        "mobile" : this.state.number,
        "type" : 1
      })
      if(this.props.forgotPassword){
        data = JSON.stringify({
          "mobile" : this.state.number,
          "type" : 2
        })
      }
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.props.setOtp(this.state.number);
          this.setState({apiLoading: false});
        }
        else{
          this.setState({ error: response.message, apiLoading: false});
        }
      }.bind(this))
    }
    else{
      this.setState({ error: "Add a valid mobile number.", apiLoading: false});
    }
  }




  render() {

    return (

      <div className="row e-signup-modal">
        <div className="col-lg-6 col-md-6">
          <div className="e-left-banner">
            <h6 className="text-uppercase">sign up</h6>
            <div className="text-center"><img src={Img} className="e-login-img"/></div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="e-login-content-wrap">
            {
              this.props.socialLogin?
              <div className="row pb-3">
                <div className="col-lg-12">
                  <p className="pt-3">Verify mobile number to continue.</p>
                </div>
              </div>
              :
              null
            }
            <div className="row">
              <div className="col-lg-12 col-md-12 pr-0">
                <label>Mobile Number</label>
                <div>
                  <Input className=""  type="number" value={this.state.number} valueChange={(tempVal) => this.setState({number: tempVal})}autoComplete="nope"/>
                  <div id="e-error" className="error pt-1">{this.state.error}</div>
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-lg-12 pr-0">
                {
                  this.state.apiLoading?
                  <div className="float-right">
                    <ButtonLoader/>
                  </div>
                  :
                  <div className="">
                  {
                    this.state.number?
                    <PrimaryButton  className="e-p-btn e-contact-btn e-ofer-btn e-login-btn" name="Get OTP" handleClick={()=> this.getOtp()}/>
                    :
                    <PrimaryButton  className="e-inactive e-p-btn e-p-btn e-contact-btn e-ofer-btn e-login-btn" name="Get OTP" />
                  }
                </div>
                }
              </div>
            </div>
             {
               !this.props.socialLogin?
               <div className="row">
                <div className="col-lg-12 pr-0">
                <p>Already Member?<TextButton name="SIGN UP" className="e-sign-up pl-1" name="LOGIN" handleClick={()=>this.props.back()}/></p>
                </div>
              </div>
              :
              null
             }

          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;