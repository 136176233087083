
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Assets*/
import VideoPlayer from '../../../components/common/video';
import banner1 from '../../../assets/images/online/half-banner-1.svg';
import banner2 from '../../../assets/images/online/half-banner-2.svg';


import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';

class AdsSectionBanner_2 extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {


  }

  adAnalytics(tempId){
    if(tempId){
        var url = devconfig.userV1+"/ads/update-analytics";
        var data = JSON.stringify({
          "ads_id" : Number(tempId),
          "clicks" : 1,
          "views" : 1
        })
        APIPost(url, data).then(function(response){
          if(response.status === "ok"){
            
          }
        }.bind(this))
    }
  }

  render() {
    var tempClass = "row section-banner-1-wrap e-section-banner-2-wrap"
    var tempType = 1;
    if(this.props.type){
      tempType = this.props.type;
    }
    switch (tempType) {
      case 1: {
        tempClass = tempClass + " e-section-banner-2-style-1";
        break;
      }
      case 2: {
        tempClass = tempClass + " e-section-banner-2-style-2";
        break;
      }
    }

    const linkVerify = (item)=>{
      var tempLink = item;
      if (item && !item.match(/^http([s]?):\/\/.*/)){
        tempLink = 'http://' + item;
        return tempLink
      }
      else{
        return item
      }
    }
 
    return (

      

      <div className={tempClass}>
        {
          this.props.data && this.props.data.map( (item, index) => {

            var tempLink = item.link;
            if (item.link && !item.link.match(/^http([s]?):\/\/.*/)){
              tempLink = 'http://' + item.link;
            }



            var tempId = null;
            if(item.id){
              tempId = item.id;
            }


            return(
              <>
              {
                this.props.singleCol?
                <div className="col-lg-6 col-12 px-0 px-lg-3 e-mb-15 mb-4">
                <a href={ linkVerify(item.link) } onClick={this.adAnalytics.bind(this, tempId)} target="_blank">
                  {
                    item.content && item.content.indexOf(".mp4") > -1 ?
                      <VideoPlayer url={item.content} />
                    :
                      <img className="e-banner-wrap" src={item.content} alt="ads banner"/>
                  }
                  
                </a>
              </div>
              :
              <div className="col-lg-6 col-6 e-mb-15 mb-4">
                <a href={ linkVerify(item.link) } onClick={this.adAnalytics.bind(this, tempId)} target="_blank">
                  {
                    item.content && item.content.indexOf(".mp4") > -1 ?
                      <VideoPlayer url={item.content} />
                    :
                      <img className="e-banner-wrap" src={item.content} alt="ads banner"/>
                  }
                  
                </a>
              </div>
              }
              </>
            );
          })
        }
      </div>
    );
  }
}

export default AdsSectionBanner_2;
