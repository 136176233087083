import React from 'react';

import FavouritesCard from '../../../components/cards/favourites-card';

import devconfig from '../../../config/config';
import APIPost from '../../../services/apiCall/post';

export default class FindVendor extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount() {
        // this.setVendors();
        this.getDetails();
        
      }
    
    //   async setVendors(){
    //     let temp = [];
    //     this.props.stores.vendors.forEach(vendor=>{
    //       temp.push(vendor.value);
    //     });
    //     await this.getDetails(temp);
    //   }
    
      getDetails(){
        this.setState({ dataLoading: true });
        var url = devconfig.localUser + "/stores/list";
        var data = JSON.stringify({
          "vendor_ids" : this.props.stores.vendors
        })
        APIPost(url, data).then(function (response) {
          if (response.status === "ok") {
            this.setState({vendorInfo: response.data});
          }
        }.bind(this))
      }


    render(){
        return(
            <div  className='row'>
                {
               this.state.vendorInfo && this.props.stores && this.props.stores.data.map((store, i)=>{
                   console.log('store data--->',store)
                let vendorData = {};
                this.state.vendorInfo.forEach(vendor=>{
                    console.log('vendor data ---->', )
                  if (vendor.id == this.props.stores.vendors[i]){
                    vendorData = vendor;
                    console.log('vendorData-->', vendorData)
                  }
                })
                return(
                  <div className="col-lg-6">
                    <FavouritesCard data={store} vendorData={vendorData}/>
                  </div>
                )
            })
           }
            </div>
        )
    }
}