/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';


/*Components*/

import Input from '../../../components/formComponents/input';
import Textarea from '../../../components/formComponents/TextArea.js';
import PrimaryButton from '../../../components/buttons/primaryButton';



/*Assets*/
import ModalImg from '../../../assets/images/local/wholesale/modal-img.svg';

/*Services*/




class BestOfferModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',

    }
  }

   componentDidMount() {

    }



  render() {

    return (
      <div className="modal fade e-best-ofer-modal e-wholesale-modal" id="bestofferModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content e-ofer">
            <div className="modal-header pb-0">
              <h6 className="e-modal-header text-uppercase mb-0">get the best offer</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form className="">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-4">
                  <div className="row  ml-1">
                    <div className="col-lg-6 col-md-6 pr-0">
                      <label>Name</label>
                      <div>
                        <Input className="e-phone"  type="text" valueChange={(tempVal) => this.setState({name: tempVal})} autoComplete="nope"/>

                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 pr-0">
                      <label>Phone</label>
                      <div>
                        <Input className="e-phone"  type="number"  valueChange={(tempVal) => this.setState({phone: tempVal})} autoComplete="nope"/>
                      </div>
                    </div>
                  </div>
                  <div className="row ml-3 pl-1">
                    <div className="col-lg-12 pl-0 pt-3">
                      <label>Email Address</label>
                      <div>
                        <Input className="" type="text" valueChange={(tempVal) => this.setState({email: tempVal})} autoComplete="nope"/>
                      </div>
                    </div>
                  </div>
                  <div className="row  ml-3 pl-1">
                    <div className="col-lg-12 pl-0 pt-3">
                      <label>Message</label>
                      <div>
                         <Textarea className="" rows="5" cols="57"  valueChange={(tempVal) => this.setState({message: tempVal})}/>
                      </div>
                    </div>
                  </div>
                  <div className="row ml-2 pt-3">
                    <div className="col-lg-6">
                      {
                        this.state.name.length && this.state.phone.length && this.state.email.length && this.state.message.length?
                        <PrimaryButton  className="e-p-btn e-contact-btn e-ofer-btn" name="Send Message"/>
                        :
                        <PrimaryButton  className="e-p-btn e-contact-btn e-ofer-btn e-inactive" name="Send Message" disabled={true}/>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-4">
                  <img src={ ModalImg} className="e-modal-right-img"/>
                </div>
              </div>
            </form>


            <div className="modal-footer">

            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default BestOfferModal;
