
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';


/*Assets*/
import VideoPlayer from '../../../components/common/video';
import banner1 from '../../../assets/images/online/section-banner-3.svg';
import banner2 from '../../../assets/images/online/section-banner-4.svg';
import banner3 from '../../../assets/images/online/section-banner-5.svg';

class ThreeBanner extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  adAnalytics(tempId){
    if(tempId){
        var url = devconfig.userV1+"/ads/update-analytics";
        var data = JSON.stringify({
          "ads_id" : Number(tempId),
          "clicks" : 1,
          "views" : 1
        })
        APIPost(url, data).then(function(response){
          if(response.status === "ok"){
            
          }
        }.bind(this))
    }
  }

  render() {


    const linkVerify = (item)=>{
      var tempLink = item;
      if (item && !item.match(/^http([s]?):\/\/.*/)){
        tempLink = 'http://' + item;
        return tempLink
      }
      else{
        return item
      }
    }

    return (
      <div className="row e-three-banner-wrap">
        {
          this.props.data && this.props.data.map( (item, index) => {
            if(item.content){
              var tempId = null;
              if(item.id){
                tempId = item.id;
              }
              return(
                <div className="col-lg-4 col-4 col-sm-4 offset-sm-0 mb-2 mb-lg-4 e-mob-pl-5 e-mob-pr-5 ">
                  <a href={linkVerify(item.link)} onClick={this.adAnalytics.bind(this, tempId)} target="_blank">
                    {
                      item.content && item.content.indexOf(".mp4") > -1 ?
                        <VideoPlayer url={this.props.data} />
                      :
                        <img className="e-banner-wrap e-no-m" src={item.content} alt="ads banner"/>
                    }
                  </a>
                </div>
              );
            }
            else{
              return(
                null
              );
            }
            
          })
        }
        
        {/*<div className="col-lg-4 col-12">
          <Link to="/">
            <img src={banner2} alt="ads banner"/>
          </Link>
        </div>
        <div className="col-lg-4 col-12">
          <Link to="/">
            <img src={banner3} alt="ads banner"/>
          </Link>
        </div>*/}
      </div>
    );
  }
}

export default ThreeBanner;
