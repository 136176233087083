
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

/*Components*/
import SecondaryHeading from '../../components/navigations/secondary-heading';
import AdsHeroBanner from '../../components/ads/banners/hero-banner.js';
import AdsFourColumn_One from '../../components/ads/list/four-column-style-1.js';
import LocalStoreStrip from '../../components/ads/strip-banners/localstore-strips.js';
import AdsSectionBanner_1 from '../../components/ads/banners/section-banner-1.js';
import AdsSectionBanner_2 from '../../components/ads/banners/section-banner-2.js';
import RecentlyViewed from '../../components/sliders/recently-viewed.js';
import Testimonials from '../../components/sliders/testimonials.js';
import Subscription from '../../components/common/subscription.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import LogoSlider from '../../components/ads/sliders/logo.js';

import H2 from '../../components/headings/h2.js';
import CardFourColumn from '../../components/cards/column-4.js';
import CardThreeAdColumn from '../../components/cards/column-3-ad.js';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import GetURLVar from '../../services/getParams/get-url-var'; 

class Search extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      dataLoading: false,
      search: "",
      tab: 1,
      userType: ''
    }
  }

  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    ReactGA.pageview(window.location.pathname);
    this.findUserType();
    GetURLVar().then(function(query){
      if(query){
        this.getData(query.keyword);
        this.setState({search: query.keyword ? query.keyword : ""});
      }
    }.bind(this));
  }

  findUserType(){
    var tempUrl = [];
    tempUrl = window.location.href.split("/");
    this.setState({ userType: tempUrl[3] });
  }
  
  async getData(tempSearch){
    this.setState({dataLoading: true, data: null});
    var url = devconfig.userV1+"/search/list";
    var data = JSON.stringify({
      "text" : tempSearch ? tempSearch : ""
    })
    if(this.state.userType==='local') url = devconfig.localUser + "/search/list"; 
    await APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        let temp = {category_result: response.data.department_result? response.data.department_result: null,subcategory_result: response.data.category_result? response.data.category_result: null, product_result: response.data.subcategory_result? response.data.subcategory_result: null}
        this.setState({data: temp, dataLoading: false, details: response.data});
      }
    }.bind(this))
  }

  serachResult(){
    if(this.state.search.length){
      this.getData(this.state.search);
    }
  }

  searchKeyDown(e){
    if(e.keyCode == 13 && this.state.search.length){
      window.location.href = "./search?keyword="+this.state.search;
    }
  }


  render() {

    if(this.state.dataLoading){
      return(
        <div>
          <PageLoader/>
        </div>
      );
    }
    else{
      return (
        <React.Fragment>
          <div className="container-fluid e-serach-section">
            <div className="container py-4">
              <div className="row py-5">
                <div className="col-lg-6 offset-lg-3 text-center">
                  <span className="position-relative e-search-page-input-wrap">
                    <input onKeyDown={this.searchKeyDown.bind(this)} value={this.state.search} onChange={(e) => { this.setState({search: e.target.value}) }} type="text" className="e-header-search" placeholder="Search for products, categories & sub categories"/>
                    {
                      this.state.search.length ?
                        <Link to={"./search?keyword="+this.state.search}>
                          <span className="e-header-search-btn active" onClick={this.serachResult.bind(this)}></span>
                        </Link>
                        
                      :
                        <span className="e-header-search-btn"></span>
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container e-serach-result">
            {
              this.state.data  ?
                <div>
                  <div className="row e-search-page-tab-wrap">
                    <div className="col-lg-2 col-4 text-center offset-lg-3">
                      <h6 onClick={(e) => { this.setState({tab: 1}) }} className={this.state.tab == 1 ? "active" : ""}>Categories</h6>
                    </div>
                    <div className="col-lg-2 col-4 text-center">
                      <h6 onClick={(e) => { this.setState({tab: 2}) }} className={this.state.tab == 2 ? "active" : ""}>Subcategories</h6>
                    </div>
                    <div className="col-lg-2 col-4 text-center">
                      <h6 onClick={(e) => { this.setState({tab: 3}) }} className={this.state.tab == 3 ? "active" : ""}>Products</h6>
                    </div>
                  </div>

                  {
                    this.state.tab == 1 ?
                      <div className="row mb-5">
                        {
                          this.state.data.category_result && this.state.data.category_result.length ? 
                            <React.Fragment>
                              {
                                this.state.data.category_result.map( (item, index) => {
                                  var tempUrl = "./category?id="+item.id+"&category="+item.name;
                                  return(
                                    <div className="col-lg-3 mb-4 col-sm-6">
                                      <Link to={tempUrl}>
                                        <div className="e-serach-cat-tag">{item.name}</div>
                                      </Link>
                                    </div>
                                  );
                                })
                              }
                            </React.Fragment>
                          :
                            <div className="col-12 text-center e-search-no-wrap">
                              <NothingFound/>
                            </div>
                        }
                      </div>
                    :
                      null
                  }

                  {
                    this.state.tab == 2 ?
                      <div className="row mb-5">
                        {
                          this.state.data.subcategory_result && this.state.data.subcategory_result.length ?
                            <React.Fragment>
                              {
                                this.state.data.subcategory_result.map( (item, index) => {
                                  var tempUrl = "./sub-category?id="+item.id+"&sub_category="+item.name+"&category="+item.category_id;
                                  return(
                                    <div className="col-lg-3 mb-4 col-sm-6">
                                      <Link to={tempUrl}>
                                        <div className="e-serach-cat-tag">{item.name}</div>
                                      </Link>
                                    </div>
                                  );
                                })
                              }
                            </React.Fragment>
                          :
                            <div className="col-12 text-center e-search-no-wrap">
                              <NothingFound/>
                            </div>
                        }
                      </div>
                    :
                      null
                  }

                  {
                    this.state.tab == 3 ?
                      <div className="row mb-5">
                        {
                          this.state.data.product_result && this.state.data.product_result.length ?
                            <React.Fragment>
                              {
                                this.state.data.product_result.map( (item, index) => {
                                  var tempUrl = "./products?id="+item.id+"&product="+item.name+"&sub_category="+item.sub_category_id+"&category="+item.category_id;
                                  return(
                                    <div className="col-lg-3 mb-4 col-sm-6">
                                      <Link to={tempUrl}>
                                        <div className="e-serach-cat-tag">
                                          {item.name}
                                        </div>
                                      </Link>
                                    </div>
                                  );
                                })
                              }
                            </React.Fragment>
                          :
                            <div className="col-12 text-center e-search-no-wrap">
                              <NothingFound/>
                            </div>
                        }
                      </div>
                    :
                      null
                  }
                  
                </div>
              :
                <div className="row">
                  <NothingFound/>
                </div>
            }
            <div className="row">

            </div>
          </div>
        </React.Fragment>
      );
    }

    
  }
}

export default Search;
