/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';


/*Components*/

import Input from '../../../components/formComponents/input';
import Textarea from '../../../components/formComponents/TextArea.js';
import PrimaryButton from '../../../components/buttons/primaryButton';
import SelectBox from '../../../components/formComponents/selectBox';
import ButtonLoader from '../../../components/common/buttonLoader.js';


/*Assets*/
import ModalImg from '../../../assets/images/local/wholesale/modal-img.svg';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';




class ReviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: null,
      message: null,
      error: ""
    }
  }

  addModalListenter(){
    $('#reviewModal').on('shown.bs.modal', function (e) {
      if(this.props.review ){
        this.setState({rating: { value: this.props.review.rating, label: this.props.review.rating }, message: this.props.review.review});
        console.log(this.props.review);
      }
    }.bind(this));
  }


  componentDidMount() {
    this.addModalListenter();
  }

  ratingChange = async (rating) => {
    await this.setState({ rating: rating });
  }

  setReviews(e) {
    e.preventDefault();
    this.setState({ apiLoading: true, error: "" });
    var url = devconfig.localUser + "/vendor/add-review";
    var data = JSON.stringify({
      "vendor_id": this.props.id,
      "rating": this.state.rating ? this.state.rating.value : null,
      "review": this.state.message
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        $("#reviewModal").modal("hide");
        this.setState({ review: "", message: "", apiLoading: false, error: "" });
        this.props.success();
      }
      else {
        this.setState({ apiLoading: false, error: response.message });
      }
    }.bind(this))
  }




  render() {
    const rating = [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
    ]

    return (
      <div className="modal fade e-best-ofer-modal e-review-modal" id="reviewModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content e-ofer">
            <div className="modal-header pb-0">
              <h6 className="e-modal-header text-uppercase mb-0">{this.props.review?"Edit Review":"Write a review"}</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ rating: null, message: "" }); this.props.modalClose() }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form className="">
              <div className="row pt-2">
                <div className="col-lg-12 col-md-12 col-12">

                  {
                    this.props.modalOn ?
                      <>
                        <div className="row ml-2">
                          <div className="col-lg-4 pr-0">
                            <p className="mt-2 pt-2">Choose a rating</p>
                          </div>
                          <div className="col-lg-2 mt-1 pl-0">
                            <SelectBox options={rating} selectchange={this.ratingChange} defaultValue={this.props.review ? { value: this.props.review.rating, label: this.props.review.rating } : null} placeholder="0" className="" />
                          </div>
                          <div className="col-lg-3 pl-0">
                            <p className="mt-2 pt-2">Out of 5</p>
                          </div>
                        </div>
                        <div className="row  ml-3 pl-1">
                          <div className="col-lg-12 pl-0 pt-3">
                            <label>{this.props.review?"edit your review":"write your review"}</label>
                            <div>
                              <Textarea className="" rows="8" cols="57" defaultValue={this.props.review ? this.props.review.review : null} valueChange={(tempVal) => this.setState({ message: tempVal })} />
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      null
                  }
                  {
                    this.state.error && this.state.error.length ?
                      <div className="row py-2">
                        <div className="col-lg-12 ml-4">
                          <div id="e-error" className="error pt-1">{this.state.error}</div>
                        </div>
                      </div>
                      :
                      null
                  }
                  <div className="row ml-2 pt-3">
                    {
                      this.state.apiLoading ?
                        <div className="col-lg-6">
                          <div className="float-right">
                            <ButtonLoader />
                          </div>
                        </div>
                        :
                        <div className="col-lg-6">
                          {
                            (this.state.rating && this.state.message)
                            ?
                              <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn" name="Submit Review" handleClick={(e) => this.setReviews(e)} />
                              :
                              <PrimaryButton className="e-inactive e-p-btn e-contact-btn e-ofer-btn" name="Submit Review" handleClick={(e) => e.preventDefault()} />
                          }
                        </div>
                    }
                  </div>
                </div>

              </div>
            </form>


            <div className="modal-footer">

            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default ReviewModal;
