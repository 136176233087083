/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../../components/buttons/primaryButton';
import ReviewModal from '../../../components//modals/local/reviewModal';
import H2 from '../../../components/headings/h2.js';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import ButtonLoader from '../../../components/common/buttonLoader';



class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      ratings: null,
      reviews: [],
      review: null
    }
  }

  componentDidMount() {
    this.getReviews();
  }

  getReviews() {
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/vendor/reviews";
    var data = JSON.stringify({
      "page_num": 1,
      "vendor_id": this.props.id
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setData(response.data);
        this.setState({ dataLoading: false });
      }
    }.bind(this))
  }

  addFav(){
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/favourite/add";
    var data = JSON.stringify({
        "id" : this.props.id,
        "type" : 2
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.props.reloadPage();
        this.setState({ dataLoading: false });
      }
    }.bind(this))
  }

  setData(data) {
    this.setState({ data: data });
    if (data.rating_data) {
      this.setState({ ratings: data.rating_data });
    }
    if (data.reviews) {
      this.setState({ reviews: data.reviews });
    }
    if (localStorage.getItem("truekhoj_user_data")) {
      data.reviews.forEach(review => {
        if (review.name == JSON.parse(localStorage.getItem("truekhoj_user_data")).name) {
          this.setState({ reviewed: true, review: review });
        }
      })
    }
  }

  render() {

    return (
      <div className="container pb-5 pb-lg-0">
        <div className="row">
          <div className="col-lg-12 pb-3">
            <div className="py-3">
              <H2 data="Reviews" type={22} bg_color="#831d0e" font_color="#000000" />
            </div>
          </div>
        </div>
        {
          this.state.data ?
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="container p-3 e-store-rating">
                    <div className="row p-3">
                      <div className="col-lg-2 px-4 py-2 d-flex flex-column align-items-center">
                        <p className="e-review">Store Rating</p>
                        <p className="e-rating">{this.state.data.total_rating ? this.state.data.total_rating.toFixed(1) : 0.0}</p>
                        <p className="m-0">{`${this.state.data.total_review_count} Reviews`}</p>
                      </div>
                      {
                        this.state.ratings ?
                          <div className="col-lg-4">
                            <div className="container e-review d-flex align-items-center">
                              <span className="e-star pr-4 mr-2">5</span>
                              <div class="progress w-100">
                                <div class="progress-bar" role="progressbar" aria-valuenow="0" style={{ width: `${(this.state.ratings[5] / this.state.data.total_review_count) * 100}%` }} aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <span className="pl-2">{this.state.ratings[5]}</span>
                            </div>
                            <div className="container e-review d-flex align-items-center pt-2">
                              <span className="e-star pr-4 mr-2">4</span>
                              <div class="progress w-100">
                                <div class="progress-bar" role="progressbar" aria-valuenow="0" style={{ width: `${(this.state.ratings[4] / this.state.data.total_review_count) * 100}%` }} aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <span className="pl-2">{this.state.ratings[4]}</span>
                            </div>
                            <div className="container e-review d-flex align-items-center pt-2">
                              <span className="e-star pr-4 mr-2">3</span>
                              <div class="progress w-100">
                                <div class="progress-bar" role="progressbar" aria-valuenow="0" style={{ width: `${(this.state.ratings[3] / this.state.data.total_review_count) * 100}%` }} aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <span className="pl-2">{this.state.ratings[3]}</span>
                            </div>
                            <div className="container e-review d-flex align-items-center pt-2">
                              <span className="e-star pr-4 mr-2">2</span>
                              <div class="progress w-100">
                                <div class="progress-bar" role="progressbar" aria-valuenow="0" style={{ width: `${(this.state.ratings[2] / this.state.data.total_review_count) * 100}%` }} aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <span className="pl-2">{this.state.ratings[2]}</span>
                            </div>
                            <div className="container e-review d-flex align-items-center pt-2">
                              <span className="e-star pr-4 mr-2">1</span>
                              <div class="progress w-100">
                                <div class="progress-bar" role="progressbar" aria-valuenow="0" style={{ width: `${(this.state.ratings[1] / this.state.data.total_review_count) * 100}%` }} aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <span className="pl-2">{this.state.ratings[1]}</span>
                            </div>
                          </div>
                          :
                          null
                      }
                      <div className="col-lg-6 pt-3 pt-lg-0">
                        {
                          localStorage.getItem("truekhoj_user_data") ?
                            <div className="d-flex align-items-end flex-column justify-content-between" style={{height:"100%"}}>
                              <PrimaryButton className="e-p-btn e-contact-btn e-review-btn mb-3" name={this.state.reviewed ? "Edit Review" : "Write a Review"} toggle="modal" target="#reviewModal" handleClick={() => this.setState({ modalOn: true })} />
                            {
                              this.state.favLoading || this.state.apiLoading ?
                                <ButtonLoader />
                                :
                                <PrimaryButton className="e-p-btn e-contact-btn e-favourite-btn ml-3 ml-sm-4 position-relative" name={
                                  <span>
                                    <svg className="mr-2 position-absolute" style={{ top: '15px', left: '18px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0)">
                                        <path d="M20.8413 4.60987C20.3306 4.09888 19.7242 3.69352 19.0567 3.41696C18.3892 3.14039 17.6738 2.99805 16.9513 2.99805C16.2289 2.99805 15.5134 3.14039 14.846 3.41696C14.1785 3.69352 13.5721 4.09888 13.0613 4.60987L12.0013 5.66987L10.9413 4.60987C9.90965 3.57818 8.51037 2.99858 7.05134 2.99858C5.59231 2.99858 4.19303 3.57818 3.16134 4.60987C2.12965 5.64156 1.55005 7.04084 1.55005 8.49987C1.55005 9.9589 2.12965 11.3582 3.16134 12.3899L4.22134 13.4499L12.0013 21.2299L19.7813 13.4499L20.8413 12.3899C21.3523 11.8791 21.7577 11.2727 22.0343 10.6052C22.3108 9.93777 22.4532 9.22236 22.4532 8.49987C22.4532 7.77738 22.3108 7.06198 22.0343 6.39452C21.7577 5.72706 21.3523 5.12063 20.8413 4.60987V4.60987Z" fill={this.props.isFavourite ? "#FF6031" : null} stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0">
                                          <rect x="0.000976562" width="24" height="24" fill="white" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    {this.props.isFavourite ? "Remove Favourite" : "Add Favourite"}
                                  </span>
                                } handleClick={() => this.addFav()} />
                            }
                            </div>
                            :
                            null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                this.state.reviews ?
                  <div className="row e-user-reviews pt-4">
                    {
                      this.state.reviews.map(review => {
                        const stars = new Array(review.rating).fill("");
                        const remaing = new Array(5 - review.rating).fill("");
                        const name = review.name;
                        const matches = name.match(/\b(\w)/g);
                        const res = matches[0]+matches[matches.length-1];
                        return (
                          <div className="col-lg-4">
                            <div className="container p-4 e-review-card shadow">
                              <div className="row">
                                <div className="col-lg-2">
                                  <span className="d-flex align-items-center justify-content-center e-avatar">{res}</span>
                                </div>
                                <div className="col-lg-10">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <h5 className="mb-1">{review.name}</h5>
                                    </div>
                                    <div className="col-7">
                                      <span className="e-review-date">{review.date}</span>
                                    </div>
                                    <div className="col-5">
                                      <div className="d-flex align-items-center container px-0 justify-content-between mt-1" style={{maxWidth: "100px"}}>
                                        {
                                          stars.map(star => (
                                            <span className="e-star-active"></span>
                                          ))
                                        }
                                        {
                                          remaing.map(star => (
                                            <span className="e-star-inactive"></span>
                                          ))
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 pt-3">
                                  <p>{review.review}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  :
                  null
              }
            </>
            :
            null
        }

        <ReviewModal id={this.props.id} review={this.state.review} success={() => this.getReviews()} modalOn={this.state.modalOn} modalClose={() => this.setState({ modalOn: false })} />
      </div>


    );
  }
}

export default Review;

