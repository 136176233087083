import React, {useEffect } from 'react';
import ReactGA from 'react-ga';

// import components
import Subscription from '../../components/common/subscription';
// import assets
import AboutImg from '../../assets/images/about/OP_0023_corporate-01.png';
import AboutSlider from '../../components/sliders/aboutSlider';

function About(props) {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  
	return (
		<React.Fragment>
			<div className="container-fluid px-0">
					<div className="container d-md-none">
						<div className="row py-4">
						<div className="col-sm-10 pt-sm-5">
							<h1 className="pt-5 e-about-hdr-sm">ALL ABOUT TRUEKHOJ.</h1>
						</div>
						</div>
					</div>
				<section className=" e-about-bnr">
					<div className="container">
						<div className="row">
						<div className="col-lg-4 ">
							<h2 className="e-about-hdr">ALL ABOUT TRUEKHOJ.</h2>
						</div>
						</div>
					</div>
				</section>
			</div>
			<div className="container py-5 e-about">
				<div className="row">
					<div className="col-md-5 e-about-img-container">
						<div className="container p-0 h-100 d-md-flex align-items-center justify-content-center d-none">
							<img src={AboutImg} alt="about image" />
						</div>
					</div>
					<div className="col-md-7">
						<div className="container h-100 d-flex p-0 flex-column justify-content-center">
						<h4 className="e-about-content-head">WHO WE ARE</h4>
						<p className="e-about-content mt-4">
							True khoj is a India's first
							marketplace discovery platform
							for connects the user to vendor
							and also connects a new vendor
							to the customers.
							<br />
							True khoj dedicated to
							providing online solution for
							various categories online and
							local marketplace stores and
							service platform branding and
							marketing.
						</p>
						</div>
					</div>
					<div className="col-lg-12 pt-4">
						<h4 className="e-about-content-head">Why True Khoj?</h4>
						<p className="e-about-content mt-4">
							Truekhoj Private Limited is a company provides all Online and your nearest local
							market, Wholesale Market and Luxury Market data with discount facilities in india
							over the phone, website and mobile apps. Show your products discounts, features
							deals/ offers Etc. and direct sell your any products to your customers, anytime,
							anywhere all over india. make shopping easier for your nearest local customers by
							collaborating with in the near features.
							<br />
							<strong>Search Online Market & Local Market in One place at <a href="https://truekhoj.com">www.truekhoj.com</a>. 
								After that you don’t have to download many APP.</strong>
						</p>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<AboutSlider/>
					</div>
				</div>
				<div className="row">
				<div className="col-lg-12 pt-4">
						<h4 className="e-about-content-head">Search Everything in a very simple and Easy way</h4>
						<ul className="e-about-content mt-4">
							<li>Search your Shop and Shop’s Products at your mobile.</li>
							<li>Find The Local Market Products discount, Sale and offers very simple.</li>
							<li>Find and Purchase Favorite Brands Discount In Local Market and also Online Market.</li>
							<li>No need to waste your time to find your nearest store and anything products.</li>
							<li>No need to download many apps to your phone. Explore Every Marketplace  Offers, Sale in True Khoj.</li>
							<li>Complete Information about Every Marketplace & Your Local Market in one  place on truekhoj.</li>
						</ul>
					</div>
				</div>
				{/* <div className="row">
					<div className="col-lg-12">
						<h4 className="e-about-content-sub-head mt-4">OFFICES</h4>
					</div>
					<div className="col-lg-6 mt-4">
						<h6>Ahmedabad:</h6>
						<p className="e-about-content">
							5 B colab 5B Vishwabharti Society, Colab Street, Ellisbridge, Behind Navrangpura Telephone Exchange, Off CG Road, I P Gujarat, I P Mission Compound,
							New Commercial Mills Staff Society, Ellisbridge, Ahmedabad, Gujarat- 380006
						</p>
					</div>
					<div className="col-lg-6 mt-4">
						<h6>Bangalore:</h6>
						<p className="e-about-content">
							Ground Floor, Nova Miller Building, Muncipal No. 333, Thimmaiah Road, Bangalore - 560052
						</p>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-lg-6">
						<h6>Ahmedabad:</h6>
						<p className="e-about-content">
							5 B colab 5B Vishwabharti Society, Colab Street, Ellisbridge, Behind Navrangpura Telephone Exchange, Off CG Road, I P Gujarat, I P Mission Compound,
							New Commercial Mills Staff Society, Ellisbridge, Ahmedabad, Gujarat- 380006
						</p>
					</div>
					<div className="col-lg-6">
						<h6>Bangalore:</h6>
						<p className="e-about-content">
							Ground Floor, Nova Miller Building, Muncipal No. 333, Thimmaiah Road, Bangalore - 560052
						</p>
					</div>
				</div> */}
				<div className="row mt-5">
					<div className="col-lg-12">
						<h4 className="e-about-content-head">CONTACT</h4>
					</div>
					<div className="col-lg-12 mt-3"><span className="e-red-span">Phone: </span><a className="e-contact-a" href="Tel:+917022112002">+917022112002</a></div>
					<div className="col-lg-12 mt-3"><span className="e-red-span">Support: </span><a className="e-contact-a" href="mailto:customersupport@truekhoj.com">customersupport@truekhoj.com</a></div>
				</div>
			</div>

			<Subscription />
		</React.Fragment>
	);
}

export default About;