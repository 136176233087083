/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton.js';
import TextButton from '../../components/buttons/textButton.js';
import Input from '../../components/formComponents/input';
import ConfirmPassword from './confirmPassword';
import PasswordChanged from './passwordChanged';
import ButtonLoader from '../common/buttonLoader.js';



/*Assets*/
import Img from '../../assets/images/online/pass-img.svg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';



class ChangePasswordModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      otp:"",
      login: true,
      apiLoading: false,
      error:"",
      success:"",
      changePassword: false,
      success: false
    }
  }

  async getOtp(){
    this.setState({ error: "", otpLoading: true});
    let url = devconfig.localUser + "/auth/verify-mobile";
    let data = JSON.stringify({
      "mobile" : this.props.mobile,
      "type" : 2
    });
    APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.setState({otpLoading: false});
        }
        else{
          this.setState({ error: response.message, otpLoading: false});
        }
    }.bind(this));
  }

  async verifyOtp(){
    this.setState({ error: "", apiLoading: true});
    let url = devconfig.localUser + "/auth/verify-otp";
    let data = JSON.stringify({
      "otp" : this.state.otp,
      "mobile" : this.props.mobile,
      "type" : 2
    });
    APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.setState({apiLoading: false, changePassword: true});
        }
        else{
          this.setState({ error: response.message, apiLoading: false});
        }
    }.bind(this));
  }
  

  render() {

    return (
      <div className="modal fade e-best-ofer-modal e-login-modal e-change-password" id="changePasswordModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content e-ofer">

          {
            !this.state.changePassword ?
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="e-left-banner">
                    <div className="text-center"><img src={Img} className="e-login-img e-change-passwrd-img"/></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="e-login-content-wrap">

                    <div className="modal-header pb-0">
                      <h6 className="e-modal-header text-uppercase mb-0">OTP</h6>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 pr-0">
                        <label>enter the otp</label>
                        <div>
                          <Input className=""  type="number" value={this.state.otp} valueChange={(tempVal) => this.setState({otp: tempVal})}autoComplete="nope"/>
                        </div>
                      </div>
                    </div>

                    <div className="row pt-1">
                      <div className="col-lg-12 pr-0">
                        <div id="e-error" className="error pt-1">{this.state.error}</div>
                      </div>

                    </div>
                    <div className="row pt-4">
                      {
                        this.state.apiLoading?
                        <div className="col-lg-12 pt-2">
                          <ButtonLoader/>
                        </div>
                        :
                        <div className="col-lg-12 pr-0">

                          {
                            this.state.otp.length ?
                              <PrimaryButton  className="e-p-btn e-contact-btn e-ofer-btn e-login-btn" name="Verify OTP" handleClick={()=>this.verifyOtp()}/>
                            :
                            <PrimaryButton  className="e-inactive e-p-btn e-contact-btn e-ofer-btn e-login-btn " name="Verify OTP"/>
                          }

                        </div>
                      }  
                    </div>
                    </div>
                     <div className="row mt-0">
                     {/*<div className="col-lg-7 pr-0 pl-4">
                         <div id="e-succes" className="success pt-1">{this.state.succes}</div>
                      </div>*/}
                     {
                       this.state.otpLoading?
                       <div className="col-lg-12 d-flex justify-content-end">
                         <ButtonLoader/>
                       </div>
                       :
                       <div className="col-lg-5 offset-7 pr-0 pl-4">
                        <div className="">
                          <TextButton name="Resend OTP" handleClick={()=>this.getOtp()}/>
                        </div>
                      </div>
                     }
                    </div>

                  </div>
                </div>

            :
            <React.Fragment>
            {
              this.state.success ?
                <PasswordChanged/>
              :
                <ConfirmPassword success={()=>this.setState({success:true})} mobile={this.props.mobile} otp={this.state.otp}/>

            }
           </React.Fragment>
          }


          </div>
        </div>

      </div>
    );
  }
}

export default ChangePasswordModal;