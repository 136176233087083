/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton.js';
import Textarea from '../formComponents/TextArea.js';
import Input from '../../components/formComponents/input';



/*Assets*/
import Img from '../../assets/images/online/login-img.svg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import ButtonLoader from '../common/buttonLoader.js';



class FeedbackModal extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  sendFeedback(e){
    e.preventDefault();
    this.setState({ apiLoading: true, error: "" });
    var url = devconfig.localUser + "/feedback/submit";
    var data = JSON.stringify({
        "subject" : this.state.subject,
        "message" : this.state.message
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        $("#feedbackModal").modal("hide");
        this.props.showToast("Feedback Send Successfully!", "success");
        this.setState({ subject: "", message: "", apiLoading: false, error: "" });
      }
      else {
        this.setState({ apiLoading: false, error: response.message });
        this.props.showToast(response.message, "fail");
      }
    }.bind(this))
  }

  render() {

    return (
      <div className="modal fade e-best-ofer-modal e-login-modal" id="feedbackModal" tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content e-ofer p-4">
            <div className="container p-4">
                <form className="container px-0 p-5">
                <div className="row">
                    <div className="col-lg-12 pt-3">
                        <div className="d-flex container px-0 justify-content-between align-items-center">
                        <h3>Feedback</h3>
                        </div>
                    </div>
                    <div className="col-lg-12 pt-4">
                        <label>Subject</label>
                        <div>
                          <Input className=""  type="text" value={this.state.subject} valueChange={(tempVal) => this.setState({subject: tempVal})} autoComplete="nope"/>
                        </div>
                    </div>
                    <div className="col-lg-12 pt-4">
                        <label>Message</label>
                        <div>
                            <Textarea className="" rows="8" cols="57" defaultValue={this.state.message} valueChange={(tempVal) => this.setState({ message: tempVal })} />
                        </div>
                    </div>
                    <div className="col-lg-12 pt-4">
                        {
                            this.state.apiLoading?
                            <ButtonLoader/>
                            :
                            this.state.subject && this.state.message?
                            <PrimaryButton name="Submit" className="e-p-btn e-contact-btn e-ofer-btn" type="Submit" handleClick={(e)=>this.sendFeedback(e)}/>
                            :
                            <PrimaryButton name="Submit" className="e-p-btn e-contact-btn e-ofer-btn"/>
                        }
                    </div>
                </div>
                </form>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default FeedbackModal;