
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

/*Assets*/
import Logo from '../../assets/images/logo.svg';
import SelectBox from '../../components/formComponents/selectBox';
import MegaHeader from './megaHeader';
import LoginModal from '../../components/modals/loginModal';
import SetLocationsModal from '../modals/local/setLocationModal';

/*Services*/
import devconfig from '../../config/config.js';
import APIGet from '../../services/apiCall/get';
import APIPost from '../../services/apiCall/post';
import FeedbackModal from '../modals/feedback';
import ChangeLanguage from '../modals/local/changeLanguage';
import Toast from '../common/toast';

class PWAHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      filterDefault: {value: null, label: 'Explore Categories'},
      activeItem: '',
      locationOptions: []
    }
  }

  componentDidMount() {
    this.findActiveItem();
    this.getLocations();
    if(localStorage.getItem("truekhoj_user_data")){
      const user = JSON.parse(localStorage.getItem("truekhoj_user_data"));
      this.setUser(user);
    }
  }

  setUser(user){
    if(user){
      const name = user.name;
      const matches = name.match(/\b(\w)/g);
      const res = matches.join('');
      this.setState({user: res});
    }
  }

  getCategory(){
    this.setState({dataLoading: true});
    var url = devconfig.userV1+"/category/list";
    if(this.state.activeItem !='local'){
      APIGet(url).then(function(response){
        if(response.status === "ok"){
  
          var tempArry = []
          response.data.map(function(item, index){
            var temp = {value: item.id, label: item.category};
            tempArry.push(temp);
          });
          this.setState({categories: tempArry, filterDefault: {value: null, label: 'Explore Categories'} });
        }
      }.bind(this))
    }
  }

  getLocations() {
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/location/list";
    APIGet(url).then(function (response) {
      if (response.status === "ok") {
        var tempArry = []
        response.data.map((item, index)=> {
          const location = { value: item.id, label: item.name };
          tempArry.push(location);
        });
        this.setState({ locationOptions: tempArry });
      }
    }.bind(this))
  }

  filterChange = async (filter) => {
    await this.setState({filter: filter});
    if(filter.value==="*") window.location.href="/category-list"
    else window.location.href = './category?id=' + filter.value + '&category=' + filter.label;
  }

  showPosition(position) {
    console.log("Latitude: ", position.coords.latitude, "Longitude: ", position.coords.longitude);
    let url = devconfig.localUser + "/location/reverse-geocode";
    let data = JSON.stringify({
      "latitude": position.coords.latitude,
      "longitude": position.coords.longitude
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        let places=[];
        if(response.data && response.data.length){
            let temp = response.data[0].address_components;
            temp.forEach((item, i)=>{
                if(i<temp.length-2) places.push(item.long_name);
            })
            this.setState({suggestedLocs: places});
        }
      }
      else {
        this.setState({ error: response.message});
      }
    }.bind(this));
  }

  async findActiveItem(){
    var tempUrl = [];
    tempUrl = window.location.href.split("/");
    await this.setState({activeItem: tempUrl[3]});
    if(tempUrl[3]==="local" && !localStorage.getItem("truekhoj_user_location")){
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((p)=>this.showPosition(p));
      }
      // $("#setLocationsModal").modal({backdrop: 'static', keyboard: false});
    }
    else if(localStorage.getItem("truekhoj_user_location")){
      if(!localStorage.getItem("Login_promt") && !localStorage.getItem("truekhoj_user_data")){
        $("#loginModal").modal("show");
      }
      let temp = JSON.parse(localStorage.getItem("truekhoj_user_location"));
      this.setState({location: temp});
    }
    this.getCategory();
  }


  render() {
    const filterBy = [
      { value: 1, label: 'Clothing' },
      { value: 2, label: 'Fashion' },
      { value: 3, label: 'Beauty & personal care' },
    ]
    return (
      <header>
        {
          this.state.activeItem!='local'?
          <div className="row e-nav-header e-pwa-header">
          <div className="container">
            <div className="row">
              <div className="col-4 pt-1">
                <a href={"./"}>
                  <img className="mr-5" src={Logo} alt="True Khoj Logo"/>
                </a>
              </div>
              <div className="col-8 text-right">
                <SelectBox defaultValue={this.state.filterDefault} options={this.state.categories} selectchange={this.filterChange} placeholder="Filter"/>
              </div>
              {/* <div className="col-lg-1 col-1 text-right">
                <Link to="/" className="e-favourite-icon"></Link>
                <span className="e-user-icon" data-toggle="modal" data-target="#loginModal"></span>
              </div> */}
            </div>
          </div>
        </div>
        :
        <div className="row e-nav-header e-pwa-header">
          <div className="container">
            <div className="row position-relative">
              <div className="col-3 pt-1">
                <a href={"./"}>
                  <img className="mr-5" src={Logo} alt="True Khoj Logo"/>
                </a>
              </div>
              <div className="col-9 position-static d-flex justify-content-end align-items-center">
                <MegaHeader/>
                {
                  !this.state.user?
                  <>
                  <span className="e-user-icon ml-1" data-toggle="modal" data-target="#loginModal"></span>
                  </>
                  :
                  <>
                  <Link to="/local/profile" className="e-name-span d-block ml-1">
                    <span className="e-name-span d-flex align-items-center justify-content-center font-weight-bold">{this.state.user}</span>
                  </Link>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        }

        <FeedbackModal showToast={(message, type)=>{
          this.setState({toast: message, toastType: type});
          setTimeout(() => {
            this.setState({toast: null});
          }, 5000);
        }}/>
        {
          this.state.toast?
          <Toast data={this.state.toast} type={this.state.toastType}/>
          :
          null
        }
        <ChangeLanguage/>
        <SetLocationsModal locations={this.state.locationOptions} suggestedLocs={this.state.suggestedLocs}/>
        <LoginModal />
      </header>
    );
  }
}

export default PWAHeader;
