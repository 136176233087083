
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGA from 'react-ga';

/*Components*/
import SecondaryHeading from '../../components/navigations/secondary-heading';
import AdsHeroBanner from '../../components/ads/banners/hero-banner.js';
import AdsFourColumn_One from '../../components/ads/list/four-column-style-1.js';
import LocalStoreStrip from '../../components/ads/strip-banners/localstore-strips.js';
import AdsSectionBanner_1 from '../../components/ads/banners/section-banner-1.js';
import AdsSectionBanner_2 from '../../components/ads/banners/section-banner-2.js';
import RecentlyViewed from '../../components/sliders/recently-viewed.js';
import Testimonials from '../../components/sliders/testimonials.js';
import Subscription from '../../components/common/subscription.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import LogoSlider from '../../components/ads/sliders/logo.js';
import FindBannerType from '../../components/ads/banners/findBannerType';
import CardSixColumn from '../../components/cards/column-6.js';
import OffersModal from '../../components/modals/offersPopup.js';

import H2 from '../../components/headings/h2.js';
import CardFourColumn from '../../components/cards/column-4.js';
import CardThreeAdColumn from '../../components/cards/column-3-ad.js';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import GetURLVar from '../../services/getParams/get-url-var'; 
import CardTwoColumn from '../../components/cards/column-2';
import CardThreeColumn from '../../components/cards/column-3';

class Deals extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      highlights: {},
      dataLoading: false
    }
  }

  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    ReactGA.pageview(window.location.pathname);
    GetURLVar().then(function(query){
      if(query){
        this.getLayout(query.id);
      }
    }.bind(this));
  }

  getLayout(tempId){
    this.setState({dataLoading: true});
    var url = devconfig.userV1+"/page/detail";
    var data = JSON.stringify({
      "id" : tempId,
      "type" : 3
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data: response.data.page_data, dataLoading: false, details: response.data});
      }
      this.getPopup(Number(tempId));
    }.bind(this))
  }

  getPopup(id){
    let viewed = [];
    if(sessionStorage.getItem('viewed_pages')) viewed= JSON.parse(sessionStorage.getItem('viewed_pages'));
    if(viewed.indexOf(Number(id))===-1&& (localStorage.getItem('Login_promt')||localStorage.getItem('truekhoj_user'))){
      var url = devconfig.userV1+"/popupbanner/view";
      var data = JSON.stringify({
        "local" : false,
        "layout_id" : id
      });
      APIPost(url, data).then(function(response){
        if(response &&response.status === "ok"&& response.data && response.data.image){
          viewed.push(Number(id));
          sessionStorage.setItem('viewed_pages', JSON.stringify(viewed));
          this.setState({popup: response.data});
          $('#offersPopupModal').modal('show');
        }
      }.bind(this))
    }
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    var tempCat1 = [
      {title: "Men", image: "../../assets/images/online/clothing-1.svg"},
      {title: "Women", image: "../../assets/images/online/clothing-2.svg"},
      {title: "Kids", image: "../../assets/images/online/clothing-3.svg"},
      {title: "Sports", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat2 = [
      {title: "watches", image: "../../assets/images/online/clothing-1.svg"},
      {title: "GLASSES", image: "../../assets/images/online/clothing-2.svg"},
      {title: "BAGS & ACCESSORIES", image: "../../assets/images/online/clothing-3.svg"},
      {title: "jewellery", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat3 = [
      {title: "WOMEN", image: "../../assets/images/online/clothing-1.svg"},
      {title: "MEN", image: "../../assets/images/online/clothing-2.svg"},
      {title: "AYURVEDIC", image: "../../assets/images/online/clothing-3.svg"},
      {title: "PERFUMES & FRAGNANCE", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat4 = [
      {title: "JEWELLERY", image: "../../assets/images/online/clothing-1.svg"},
      {title: "WEDDING PLANNER", image: "../../assets/images/online/clothing-2.svg"},
      {title: "GROOM APPAREL", image: "../../assets/images/online/clothing-3.svg"},
      {title: "BRIDE APPAREL", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat5 = [
      {title: "men", image: "../../assets/images/online/clothing-1.svg"},
      {title: "WOMEN", image: "../../assets/images/online/clothing-2.svg"},
      {title: "KIDS", image: "../../assets/images/online/clothing-3.svg"},
      {title: "SPORTS", image: "../../assets/images/online/clothing-4.svg"}
    ]
    
    var tempCat6 = [
      {title: "GOLD", image: "../../assets/images/online/clothing-1.svg"},
      {title: "DIAMOND", image: "../../assets/images/online/clothing-2.svg"},
      {title: "PLATINUM", image: "../../assets/images/online/clothing-3.svg"},
      {title: "PEARLS", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat7 = [
      {title: "televisions", image: "../../assets/images/online/clothing-1.svg"},
      {title: "washing machines", image: "../../assets/images/online/clothing-2.svg"},
      {title: "refrigerators", image: "../../assets/images/online/clothing-3.svg"},
      {title: "air conditioners", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat8 = [
      {title: "flights", image: "../../assets/images/online/clothing-1.svg"},
      {title: "buses", image: "../../assets/images/online/clothing-2.svg"},
      {title: "hotels", image: "../../assets/images/online/clothing-3.svg"},
      {title: "tours", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat9 = [
      {title: "food & beverages", image: "../../assets/images/online/clothing-1.svg"},
      {title: "food products", image: "../../assets/images/online/clothing-2.svg"},
      {title: "groceries", image: "../../assets/images/online/clothing-3.svg"},
      {title: "pet supplies", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat10 = [
      {title: "cars", image: "../../assets/images/online/clothing-1.svg"},
      {title: "bikes", image: "../../assets/images/online/clothing-2.svg"},
      {title: "scooters", image: "../../assets/images/online/clothing-3.svg"},
      {title: "luxury cars", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat11 = [
      {title: "smartphones", image: "../../assets/images/online/clothing-1.svg"},
      {title: "laptops", image: "../../assets/images/online/clothing-2.svg"},
      {title: "game consoles", image: "../../assets/images/online/clothing-3.svg"},
      {title: "accessories", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat12 = [
      {title: "bags", image: "../../assets/images/online/clothing-1.svg"},
      {title: "watches", image: "../../assets/images/online/clothing-2.svg"},
      {title: "jewellery", image: "../../assets/images/online/clothing-3.svg"},
      {title: "vintage", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat13 = [
      {title: "HEADPHONES", image: "../../assets/images/online/clothing-1.svg"},
      {title: "POWERBANKS", image: "../../assets/images/online/clothing-2.svg"},
      {title: "COVERS", image: "../../assets/images/online/clothing-3.svg"},
      {title: "SMART WATCHES", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat14 = [
      {title: "AT HOME", image: "../../assets/images/online/clothing-1.svg"},
      {title: "WORK", image: "../../assets/images/online/clothing-2.svg"},
      {title: "SLEEPWEAR & LINGERIE", image: "../../assets/images/online/clothing-3.svg"},
      {title: "CASUAL", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var tempCat15 = [
      {title: "WORK", image: "../../assets/images/online/clothing-1.svg"},
      {title: "BEAUTY & CARE", image: "../../assets/images/online/clothing-2.svg"},
      {title: "NEON", image: "../../assets/images/online/clothing-3.svg"},
      {title: "CASUAL", image: "../../assets/images/online/clothing-4.svg"}
    ]

    var data = this.state.data;

    if(data && (data.layout || data.ads)){

      return (
        <div className="container-fluid p-0">
          {
            this.state.details ?
              <SecondaryHeading data={this.state.details}/>
            :
              null
          }
          

          <div className="row pb-5 e-mb-15">
            <div className="col-lg-12 p-0">
              {
                data.ads && data.ads.hero && data.ads.hero.data[0].content ?
                  <FindBannerType content={data.ads.hero.data} details={data.ads.hero} defaultType={1}/>
                :
                  null
              }
            </div>
          </div>
          {/*
            
            this.state.data.ads.ad1.data.length ?
              <div className="container">
                <div className="mb-5 e-no-mb">
                  <AdsFourColumn_One layout={this.state.data.ads.ad1.data}/>
                </div>
              </div>
            :
              null
          */}

          <div className="container">
            <div className="pb-5 e-no-mb">
              {
                data.ads && data.ads.logo_slider.data.length &&  data.ads.logo_slider.data[0].content?
                  <FindBannerType content={data.ads.logo_slider.data} details={data.ads.logo_slider} defaultType={8}/>
                :
                  null
              }
            </div>
          </div>

          {
            data.ads &&

              <React.Fragment>
                {
                  data.ads.deals ?
                    <section className="container-fluid position-relative">
                      <div className="container">
                        <div className="row pb-5 e-no-mb">
                          <div className="col-lg-12 mb-4 e-no-p">
                            <H2 data={data.ads.deals.header} type={1} bg_color={"#FF4A31"} font_color={"#000"}/>
                          </div>
                          {
                            data.ads.deals && data.ads.deals_banner.data[0] ?
                            <div className="col-lg-12 px-0 px-md-1">
                              <div className="row m-md-0">
                                <div className="col-lg-12 mb-4 e-no-p">
                                {/*<AdsSectionBanner_1 data={data.ads.deals.banner[0].content} link={data.ads.deals.banner[0].link}/>*/}
                                <FindBannerType content={data.ads.deals_banner.data} details={data.ads.deals_banner} defaultType={5}/>
                              </div>
                              </div>
                            </div>
                              
                            :
                              null
                          }
                          <div className="col-lg-12 mb-4 e-no-p">
                            {/*<CardThreeAdColumn data={data.ads.deals.data} type={1} subcategory={true}/>*/}
                            <FindBannerType content={data.ads.deals.data} details={data.ads.deals} defaultType={3}/>
                          </div>
                        </div>
                      </div>
                    </section>
                  :
                    null
                }
                

                {
                  data.ads.ad_section_1.data && data.ads.ad_section_1.data[0] && data.ads.ad_section_1.data[0].content ?
                    <section className="container-fluid position-relative">
                      <div className="container">
                        <div className="row pb-5 e-no-mb">
                          <div className="col-lg-12 mb-4 e-no-p">
                            <H2 data={data.ads.ad_section_1.header} type={3}  bg_color={"#FF4A31"} font_color={"#000"}/>
                          </div>
                          <div className="col-lg-12 mb-4 e-no-p">
                            {/*<CardThreeAdColumn data={data.ads.ad_section_1.data} type={1} subcategory={true}/>*/}
                            <FindBannerType content={data.ads.ad_section_1.data} details={data.ads.ad_section_1} defaultType={3}/>
                          </div>
                        </div>
                      </div>
                    </section>
                  :
                    null
                }

                {
                  data.ads.ad_section_2.data && data.ads.ad_section_2.data[0] && data.ads.ad_section_2.data[0].content ?
                    <section className="container-fluid position-relative">
                      <div className="container">
                        <div className="row pb-5 e-no-mb">
                          <div className="col-lg-12 mb-4 e-no-p">
                            <H2 data={data.ads.ad_section_2.header} type={3}  bg_color={"#FF4A31"} font_color={"#000"}/>
                          </div>
                          <div className="col-lg-12 mb-4 e-no-p">
                            {/*<CardThreeAdColumn data={data.ads.ad_section_2.data} type={1} subcategory={true}/>*/}
                            <FindBannerType content={data.ads.ad_section_2.data} details={data.ads.ad_section_2} defaultType={4}/>
                          </div>
                        </div>
                      </div>
                    </section>
                  :
                    null
                }

                {
                  data.ads.ad_section_3.data && data.ads.ad_section_3.data[0] && data.ads.ad_section_3.data[0].content ?
                    <section className="container-fluid position-relative">
                      <div className="container">
                        <div className="row pb-5 e-no-mb">
                          <div className="col-lg-12 mb-4 e-no-p">
                            <H2 data={data.ads.ad_section_3.header} type={3}  bg_color={"#FF4A31"} font_color={"#000"}/>
                          </div>
                          <div className="col-lg-12 mb-4 e-no-p">
                            {/*<CardThreeAdColumn data={data.ads.ad_section_3.data} type={1} subcategory={true}/>*/}
                            <FindBannerType content={data.ads.ad_section_3.data} details={data.ads.ad_section_3} defaultType={4}/>
                          </div>
                        </div>
                      </div>
                    </section>
                  :
                    null
                }

                {
                  data.ads.ad_section_4.data && data.ads.ad_section_4.data[0] && data.ads.ad_section_4.data[0].content ?
                    <section className="container-fluid position-relative">
                      <div className="container">
                        <div className="row pb-5 e-no-mb e-no-pb">
                          <div className="col-lg-12 mb-4 e-no-p">
                            <H2 data={data.ads.ad_section_4.header} type={3}  bg_color={"#FF4A31"} font_color={"#000"}/>
                          </div>
                          <div className="col-lg-12 mb-4 e-no-p e-no-mb">
                            {/*<CardThreeAdColumn data={data.ads.ad_section_4.data} type={1} subcategory={true}/>*/}
                            <FindBannerType content={data.ads.ad_section_4.data} details={data.ads.ad_section_4} defaultType={3}/>
                          </div>
                        </div>
                      </div>
                    </section>
                  :
                    null
                }
              </React.Fragment>
          }

          <div className="container">
            <div className="mb-5">
              <LocalStoreStrip/>
            </div>
          </div>

          

          {
            this.state.data && this.state.data.layout.map( (item, index) => {

              if(item.bg_color){
                var tempStyle = {background: item.bg_color};
              }

              return(
                <section className="container-fluid position-relative" style={tempStyle}>
                  <div className="container">
                    <div className="row pb-5 e-no-mb">
                      <div className={tempStyle ? "col-lg-12 mb-4 pt-5 e-no-p" : "col-lg-12 mb-4 e-no-p"}>
                        <H2 data={item.header.content} type={item.header.type} bg_color={item.header.bg_color} font_color={item.header.font_color}/>
                      </div>
                      {
                        item.ads.banner_type ?
                          <div className="col-lg-12 mb-4 e-no-p">
                            {/*<AdsSectionBanner_1 data={item.ads.content} link={item.ads.link} />*/}
                            <FindBannerType content={item.ads.data} details={item.ads}/>
                          </div>
                        :
                          <div className="col-lg-12 mb-4 e-no-p">
                            {/*<AdsSectionBanner_1 data={item.ads.content} link={item.ads.link} /> */}
                            <FindBannerType content={[item.ads]} details={item.ads} defaultType={1}/>
                          </div>
                      }
                      {/*<div className="col-lg-12 mb-4 e-no-p">
                        <CardFourColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} subcategory={true}/>
                      </div>*/}
                      {
                        item.data.content && item.data.type && item.data.type === 1 ?
                          <div className="col-lg-12 mb-3 e-no-p">
                            <CardFourColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} subcategory={true} cat_id={this.state.cat_id} sub_id={this.state.sub_cat_id} />
                          </div>
                          : item.data.content && item.data.type && item.data.type === 2 ?
                            <div className="col-lg-12 mb-3 e-no-p">
                              <CardSixColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} subcategory={true} cat_id={this.state.cat_id} sub_id={this.state.sub_cat_id}/>
                            </div>
                            : item.data.content && item.data.type && item.data.type === 3 ?
                              <div className="col-lg-12 mb-3 e-no-p">
                                <CardTwoColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} subcategory={true} cat_id={this.state.cat_id} sub_id={this.state.sub_cat_id} />
                              </div>
                              : item.data.content && item.data.type && item.data.type === 4 ?
                                <div className="col-lg-12 mb-3 e-no-p">
                                  <CardThreeColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} subcategory={true} cat_id={this.state.cat_id} sub_id={this.state.sub_cat_id} />
                                </div>
                                :
                                null
                      }
                    </div>
                  </div>
                  <span className="e-section-bg-half" style={{ 
                    background: 
                    item.data.type == 2 ||  
                    item.data.type == 4 ||
                    item.data.type == 6 ||
                    item.data.type == 8 ||
                    item.data.type == 10 ||
                    item.data.type == 12 ||
                    item.data.type == 14 ? 
                    item.header.bg_color : 
                    "#fff"}}></span>
                </section>
              );
            })
          }

          {
                  this.state.data.explore_more &&this.state.data.explore_more.data && this.state.data.explore_more.data.content  && this.state.data.explore_more.data.content.length ?
                    <section className="container-fluid position-relative pt-3">
                      <div className="container">
                        <div className="row pb-5 e-no-mb">
                          <div className="col-lg-12 mb-4 e-no-p">
                            <H2 data={this.state.data.explore_more.header} type={31} bg_color="#FF4A31"/>
                          </div>
                          {
                        this.state.data.explore_more.data.content && this.state.data.explore_more.data.type && this.state.data.explore_more.data.type === 1 ?
                          <div className="col-lg-12 mb-3 e-no-p">
                            <CardFourColumn data={this.state.data.explore_more.data.content} type={this.state.data.explore_more.data.style.value}  category={true} />
                          </div>
                          : this.state.data.explore_more.data.content && this.state.data.explore_more.data.type && this.state.data.explore_more.data.type === 2 ?
                            <div className="col-lg-12 mb-3 e-no-p">
                              <CardSixColumn data={this.state.data.explore_more.data.content} type={this.state.data.explore_more.data.style.value}  category={true}/>
                            </div>
                            : this.state.data.explore_more.data.content && this.state.data.explore_more.data.type && this.state.data.explore_more.data.type === 3 ?
                              <div className="col-lg-12 mb-3 e-no-p">
                                <CardTwoColumn data={this.state.data.explore_more.data.content} type={this.state.data.explore_more.data.style.value}  category={true} />
                              </div>
                              : this.state.data.explore_more.data.content && this.state.data.explore_more.data.type && this.state.data.explore_more.data.type === 4 ?
                                <div className="col-lg-12 mb-3 e-no-p">
                                  <CardThreeColumn data={this.state.data.explore_more.data.content} type={this.state.data.explore_more.data.style.value}  category={true} />
                                </div>
                                :
                                null
                      }
                        </div>
                      </div>
                    </section>
                  :
                    null
                }


          

          {/* Recently viewed */}
          {/*<section className="container-fluid position-relative">
            <div className="container">
              <div className="row mb-5 e-no-mb">
                <div className="col-lg-12 mb-4">
                  <H2 data="recently viewed" type={15}/>
                </div>
                <div className="col-lg-12 mb-5">
                 <RecentlyViewed/>
                </div>
              </div>
            </div>
            <span className="e-section-bg-half" style={{background: "#fff"}}></span>
          </section>*/}

          

          {/* Subscription */}
          <Subscription/>
          <OffersModal data={this.state.popup}/>


        </div>
      );
    }
    else{
      return(
        <div>
          {
            this.state.dataLoading ?
              <PageLoader/>
            :
              <NothingFound/>
          }
          <OffersModal data={this.state.popup}/>
        </div>
      );
    }
  }
}

export default Deals;
