import $ from 'jquery';

export default async function APIGet(url) {
  return await $.ajax({
    type: "GET",
    url: url,
    dataType: 'json',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Token': localStorage.getItem("truekhoj_user")
    }
  })
  .then(function(response){ return response }.bind(this))
  .catch(function(response, responseCode, responseMessage){ 
    if(response.responseJSON){
      if(response.responseJSON.status_code === 401){
        localStorage.clear();
         sessionStorage.clear();
         window.location = "/?session=true";
       }else{
        return response.responseJSON
       }
    }else{
       return {status: "error", message: "Network error"} 
    }
   }.bind(this))
}
