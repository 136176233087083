
/*Package importing*/
import React, { Component } from 'react';

/*Assets*/
import ad1 from '../../../assets/images/online/ad-square-1.svg';
import ad2 from '../../../assets/images/online/ad-square-2.svg';
import ad3 from '../../../assets/images/online/ad-square-3.svg';
import ad4 from '../../../assets/images/online/ad-square-4.svg';



class AdsFourColumn_One extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    console.log("test");
    console.log(this.props.layout);
  }

  render() {
    // console.log("this.props.layout.highlightThree.link");
    // console.log(this.props.layout.highlightThree.link);
    return (
      <div className="row e-list-four-column-style1">
        <div className="col-lg-3 col-6">
          <div className="position-relative">
            <a href={this.props.layout[0].link}>
              <img className="e-banner-wrap"  src={this.props.layout[0].content} alt="ads banner"/>
            </a>
            {/*<p>Brand of the day</p>*/}
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="position-relative">
            <a href={this.props.layout[1].link}>
              <img className="e-banner-wrap"  src={this.props.layout[1].content} alt="ads banner"/>
            </a>
            {/*<p>50% off sale</p>*/}
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="position-relative">
            <a href={this.props.layout[2].link}>
              <img className="e-banner-wrap"  src={this.props.layout[2].content} alt="ads banner"/>
            </a>
            {/*<p>Deals of the day</p>*/}
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="position-relative">
            <a href={this.props.layout[3].link}>
              <img className="e-banner-wrap"  src={this.props.layout[3].content} alt="ads banner"/>
            </a>
            {/*<p>SALE OF THE WEEK</p>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default AdsFourColumn_One;
