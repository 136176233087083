/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import ReactGA from 'react-ga';

/*Components*/
import SecondaryHeading from '../../components/navigations/secondary-heading';
import ProductBanner from '../../components/sliders/product-banner.js';
import ProductAccordion from '../../components/accordion/product-accordion.js';
import Subscription from '../../components/common/subscription.js';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import GetURLVar from '../../services/getParams/get-url-var';
import AdsHeroBanner from '../../components/ads/banners/hero-banner.js';
import FindBannerType from '../../components/ads/banners/findBannerType';
import RangeSelector from '../../components/slider/slider';
import FilterModal from '../../components/modals/local/filterModal';
import TextButton from '../../components/buttons/textButton';

/*Assets*/
import Ad from '../../assets/images/local/productListAd.png';
import Ad2 from '../../assets/images/local/productAd2.png';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import OffersModal from '../../components/modals/offersPopup';
import LocalSecondaryHeading from '../../components/navigations/secondaryHeading-local';


class LocalProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: { price: { status: false, lower: 0, upper: 100000 }, subcategory: null, brand: null },
      pageNum: true,
      pageNo: 1,
      apiLoading: true,
      sort: 1,
      sortOrder: 1,
      popularity: { value: null, label: "Select" },
      offer: { value: null, label: "Select" },
      new: { value: null, label: "Select" },
      discount: { value: null, label: "Select" },
      layout: null,
      details: null,
      data: null,
      shop: false,
      price: false,
      badge: 0,
      productBanner1: null,
      productBanner2: null,
      layoutDetails: null,
      ad_category: 0,
      userLocation: {value: null}
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.setLocation();
    this.getDepartments();
    this.getBrands();
  }

  async setLocation(){
    let temp = await JSON.parse(localStorage.getItem("truekhoj_user_location"));
    this.setState({userLocation: temp}, ()=>this.getLayout());
  }

  getLayout() {
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/page/detail"; 
    var data = JSON.stringify({
      "id": 203,
      "type": 4,
      "location_id": this.state.userLocation.value
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if(response.data){
          this.setState({ layoutDetails: response.data });
          if(response.data && response.data.page_data) this.setState({ layout: response.data.page_data});
        }
      }
      this.getPopup(203);
    }.bind(this))
  }

  getPopup(id){
    let viewed = [];
    if(sessionStorage.getItem('local_viewed_pages')) viewed= JSON.parse(sessionStorage.getItem('local_viewed_pages'));
    if(viewed.indexOf(Number(id))===-1&& (localStorage.getItem('Login_promt')||localStorage.getItem('truekhoj_user'))){
      var url = devconfig.localUser+"/popupbanner/view";
      var data = JSON.stringify({
        "local" : true,
        "layout_id" : id,
        "location_id": this.state.userLocation.value
      });
      APIPost(url, data).then(function(response){
        if(response &&response.status === "ok"&& response.data && response.data.image){
          viewed.push(Number(id));
          sessionStorage.setItem('local_viewed_pages', JSON.stringify(viewed));
          this.setState({popup: response.data});
          $('#offersPopupModal').modal('show');
        }
      }.bind(this))
    }
  }


  getBrands() {
    let url = devconfig.localUser + '/brand/list';
    APIGet(url).then(function (response) {
      if (response.status === "ok") {
        this.setState({ brandlist: response.data });
      }
      else {
        this.setState({ error: response.message });
      }
    }.bind(this));
  }

  getDepartments() {
    let url = devconfig.localUser + '/department/list';
    APIGet(url).then(function (response) {
      if (response.status === "ok") {
        this.setState({ details: response.data });
        GetURLVar().then(async function (query) {
          if (query.id) {
            let temp = this.state.filter;
            if (response.data && response.data.length) {
              response.data.forEach(department => {
                department.category.forEach(cat => {
                  cat.subcategory.forEach(async sub => {
                    if (sub.id === Number(query.id)) {
                      temp.subcategory = sub;
                      await this.setState({ filter: temp, categoryFilters: true, department: department.name, category: cat.name, ad_category: department.id });
                    }
                  })
                })
              })
            }
            await this.getData();

          }
          
        else {
          this.getData();
        }
        }.bind(this));
      }
      else {
        this.setState({ error: response.message });
        this.getData();
      }
    }.bind(this));
  }

  async getData() {
    console.log('sort -->', this.state.sort );
    console.log('order --->', this.state.sortOrder);
    this.setState({ apiLoading: true, filterLoading: true, error: "" });
    this.setParams();
    this.setBadge();
    console.log('sort-->', this.state.sort);
    console.log('order-->', this.state.sortOrder);
    let url = devconfig.localUser + '/product/list';
    let data = JSON.stringify({
      "page_num": 1,
      "subcategory_id": this.state.filter.subcategory ? this.state.filter.subcategory.id : null,
      "brand_id": this.state.filter.brand ? this.state.filter.brand.id : null,
      "price_range_filter": this.state.filter.price.status,
      "price_lower_limit": this.state.filter.price.lower,
      "price_upper_limit": this.state.filter.price.upper,
      "sort_by": this.state.sort,
      "order": this.state.sortOrder,
      "location_id": this.state.userLocation.value
    });
    APIPost(url, data).then(function (response) {
      if (response.status == "ok") {
        console.log(this.state.filter);
        this.setState({ apiLoading: false, filterLoading: false, error: "", data: response.data });
      }
      else {
        this.setState({ apiLoading: false, filterLoading: false, error: response.message });
      }
    }.bind(this))
  }

  checkIfInLayout(type) {
    let inLayout = false;
    let out;
    this.state.layout.layout.forEach((item, i) => {
      if (item.header && item.header.category && item.header.category === Number(this.state.ad_category) && item.id === type && (item.ads.banner_type || item.ads.content)) {
        out = <section className="container-fluid position-relative p-0 e-prod-banner">
          <div className="row mb-5">
            {
              item.ads.banner_type ?
                <div className="col-lg-12 mb-5">
                  {/*<AdsSectionBanner_1 data={item.ads.content} link={item.ads.link}/>*/}
                  <FindBannerType content={item.ads.data} details={item.ads} />
                </div>
                :
                <div className="col-lg-12 mb-5">
                  <FindBannerType content={[item.ads]} details={item.ads} defaultType={1} />
                </div>
            }
          </div>
        </section>;
        inLayout = true;
      }
    });
    if (inLayout) {
      return out;
    }
    else if ((type == 185 && this.state.layout.ads && this.state.layout.ads.hero.data) || (type == 186 && this.state.layout.ads && this.state.layout.ads.intermediate.data)) {
      return (
        <section className="container-fluid position-relative p-0 e-prod-banner">
          <div className="row mb-5">
            <div className="col-lg-12 mb-5">

              {/*<AdsHeroBanner content={this.state.layout.ads.hero.data}/>*/}
              <FindBannerType content={type == 185 ? this.state.layout.ads.hero.data : this.state.layout.ads.intermediate.data} details={type == 185 ? this.state.layout.ads.hero : this.state.layout.ads.intermediate} defaultType={1} />
            </div>
          </div>
        </section>
      )
    }
    else return null;
  }

  async setBadge() {
    let badge = 0;
    if (this.state.filter.price.status) badge = badge + 1;
    if (this.state.filter.brand) badge = badge + 1;
    if (this.state.filter.subcategory) badge = badge + 1;
    await this.setState({ badge: badge });
  }

  setParams() {
    let prod = { id: "100" };
    window.history.replaceState(prod, "Products", `products${this.state.filter.subcategory ? `?id=${this.state.filter.subcategory.id}` : ""}${this.state.filter.subcategory ? `&product=${this.state.filter.subcategory.name}` : ""}`);
  }

  render() {

    var tempPopularity = [
      { value: 2, label: 'Most popular first' }, // 'Higt - Low' 
      { value: 1, label: 'Least popular first' }, //Low - High 
    ]

    var tempOffers = [
      { value: 1, label: 'Lowest first' }, //High - Low 
      { value: 2, label: 'Highest first' } //Low - High 
    ]

    var tempNew = [
      { value: 2, label: 'Latest first' }, //High - Low 
      { value: 1, label: 'Oldest first' } //Low - High 
    ]

    var tempDiscount = [
      { value: 1, label: 'High - Low' },
      { value: 2, label: 'Low - High' }
    ]

    let sampleAdData = {
      ad1: [
        {
          "content": Ad,
          "link": "https://google.com"
        }
      ],
      ad2: [
        {
          "content": Ad2,
          "link": "https://google.com"
        }
      ]
    }

    const shops = ['levis', 'brand', 'benetton', 'mose', 'roadster', 'c&k', 'tommy hilfiger'];

    if (!this.state.data) {
      return (
        <div>
          {
            this.state.apiLoading ?
              <PageLoader />
              :
              <NothingFound />
          }
        </div>
      );
    }
    else {
      return (
        <>
          <div className="container-fluid p-0 e-local-productlist">
            {
              this.state.layoutDetails ?
                <LocalSecondaryHeading data={this.state.layoutDetails} />
                :
                null
            }
            {
              this.state.ad_category ?
                <>
                  {
                    this.state.layout && this.state.layout.layout && this.state.layout.layout.length ?
                      <>
                        {
                          this.checkIfInLayout(185)
                        }
                      </>
                      : this.state.layout && this.state.layout.ads&&  this.state.layout.ads.hero.data ?
                        <section className="container-fluid position-relative p-0 e-prod-banner">
                          <div className="row mb-5">
                            <div className="col-lg-12 mb-5">

                              {/*<AdsHeroBanner content={this.state.layout.ads.hero.data}/>*/}
                              <FindBannerType content={this.state.layout.ads.hero.data} details={this.state.layout.ads.hero} defaultType={1} />
                            </div>
                          </div>
                        </section>
                        :
                        null
                  }
                </>
                :
                <>
                  {
                    this.state.layout && this.state.layout.ads&&  this.state.layout.ads.hero.data ?
                      <section className="container-fluid position-relative p-0 e-prod-banner">
                        <div className="row mb-5">
                          <div className="col-lg-12 mb-5">

                            {/*<AdsHeroBanner content={this.state.layout.ads.hero.data}/>*/}
                            <FindBannerType content={this.state.layout.ads.hero.data} details={this.state.layout.ads.hero} defaultType={1} />
                          </div>
                        </div>
                      </section>
                      :
                      null
                  }
                </>
            }
            <section>
              <div className="container e-product-page-wrap">
                <div className="row pt-5 pb-3">
                  <div className="col-12 d-lg-none pb-3 e-product-filter">
                    <span className="float-right position-relative" onClick={async () => { await this.setState({ filterModal: true }); $("#filterModal").modal("show") }}>
                      Filters
                      <svg className="ml-2" enable-background="new 0 0 512 512" height="24" viewBox="0 0 512 512" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <path d="m432.733 112.467h-228.461c-6.281-18.655-23.926-32.133-44.672-32.133s-38.391 13.478-44.672 32.133h-35.661c-8.284 0-15 6.716-15 15s6.716 15 15 15h35.662c6.281 18.655 23.926 32.133 44.672 32.133s38.391-13.478 44.672-32.133h228.461c8.284 0 15-6.716 15-15s-6.716-15-15.001-15zm-273.133 32.133c-9.447 0-17.133-7.686-17.133-17.133s7.686-17.133 17.133-17.133 17.133 7.686 17.133 17.133-7.686 17.133-17.133 17.133z" />
                          <path d="m432.733 241h-35.662c-6.281-18.655-23.927-32.133-44.672-32.133s-38.39 13.478-44.671 32.133h-228.461c-8.284 0-15 6.716-15 15s6.716 15 15 15h228.461c6.281 18.655 23.927 32.133 44.672 32.133s38.391-13.478 44.672-32.133h35.662c8.284 0 15-6.716 15-15s-6.716-15-15.001-15zm-80.333 32.133c-9.447 0-17.133-7.686-17.133-17.133s7.686-17.133 17.133-17.133 17.133 7.686 17.133 17.133-7.686 17.133-17.133 17.133z" />
                          <path d="m432.733 369.533h-164.194c-6.281-18.655-23.926-32.133-44.672-32.133s-38.391 13.478-44.672 32.133h-99.928c-8.284 0-15 6.716-15 15s6.716 15 15 15h99.928c6.281 18.655 23.926 32.133 44.672 32.133s38.391-13.478 44.672-32.133h164.195c8.284 0 15-6.716 15-15s-6.716-15-15.001-15zm-208.866 32.134c-9.447 0-17.133-7.686-17.133-17.133s7.686-17.133 17.133-17.133 17.133 7.685 17.133 17.132-7.686 17.134-17.133 17.134z" />
                        </g>
                      </svg>
                      {this.state.badge ? <span class="badge badge-pill badge-danger position-absolute" style={{ top: "-3px", right: "-5px" }}>{this.state.badge}</span> : null}
                    </span>
                  </div>
                  <div className="col-lg-3 d-none d-lg-block">
                    <div className="row mx-0 d-flex">
                      <div className="e-filter-tab col-lg-12 p-3 position-relative">
                        <span className="position-absolute p-2" style={{ top: 0, right: 0 }}><TextButton name="Clear All" className="e-t-btn" noBackground={true} handleClick={async () => { await this.setState({ filter: { price: { status: false, lower: 0, upper: 100000 }, subcategory: null, brand: null, ad_category:-1 }, price: null, department: null, category: null, categoryFilters: null }); this.getData() }} /></span>
                        <span className={this.state.shop ? "e-filter-btn e-filter-active d-block py-3 pl-2 font-weight-bold" : "e-filter-btn d-block py-3 pl-2 font-weight-bold"} style={{ cursor: "pointer" }} onClick={() => this.setState({ shop: !this.state.shop })}>Shop</span>
                        <div className={this.state.shop ? "container" : "container d-none"}>
                          {
                            this.state.brandlist && this.state.brandlist.map((shop, i) => (
                              <span className="d-block py-2 pl-2 font-weight-normal text-uppercase" style={{ cursor: "pointer" }} onClick={async () => { let temp = this.state.filter; temp.brand = shop; this.setState({ filter: temp }); await this.getData(); }}>
                                <div class="d-flex">
                                  <div className="e-checkbox">{shop.name}
                                    <input type="checkbox" value={{ shop }} id={`checkbox-${shop.id}`} checked={this.state.filter.brand === shop ? true : false} />
                                    <span class="checkmark"></span>
                                  </div>
                                </div>
                              </span>
                            ))
                          }
                        </div>
                        <span className={this.state.price ? "e-filter-btn e-filter-active d-block py-3 pl-2 font-weight-bold" : "e-filter-btn d-block py-3 pl-2 font-weight-bold"} style={{ cursor: "pointer" }} onClick={() => this.setState({ price: !this.state.price })}>Price</span>
                        <div className={this.state.price ? "container pt-3 pb-4 position-relative" : "container d-none position-relative"}>
                          {
                            this.state.price ?
                              <RangeSelector min={0} max={100000} defaultValue={[this.state.filter.price.lower, this.state.filter.price.upper]} onChange={async (val) => { let temp = this.state.filter; temp.price.status = true; temp.price.lower = val[0]; temp.price.upper = val[1]; this.setState({ filter: temp }); await this.getData(); }} />
                              :
                              null
                          }
                          <span className="position-absolute px-1 py-2" style={{ bottom: 0, left: 0, zIndex: 1 }}><TextButton name={`₹${this.state.filter.price.lower}`} className="e-t-btn" noBackground={true} /></span>
                          <span className="position-absolute px-1 py-2" style={{ bottom: 0, right: 0, zIndex: 1 }}><TextButton name={`₹${this.state.filter.price.upper}`} className="e-t-btn" noBackground={true} /></span>
                        </div>
                      </div>
                      <div className="e-filter-tab col-lg-12 px-3 mt-3">
                        <span className={this.state.categoryFilters ? "e-filter-btn e-filter-active d-block py-3 pl-2 font-weight-bold" : "e-filter-btn d-block py-3 pl-2 font-weight-bold"} style={{ cursor: "pointer" }} onClick={() => this.setState({ categoryFilters: !this.state.categoryFilters })}>Categories</span>
                        <div className={this.state.categoryFilters ? "container" : "container d-none"}>
                          {
                            this.state.details ?
                              this.state.details.map(department => (
                                <>
                                  <span className={this.state.department == department.name ? "e-filter-btn e-filter-active d-block py-3 pl-2 font-weight-bold" : "e-filter-btn d-block py-3 pl-2 font-weight-bold"} style={{ cursor: "pointer" }} onClick={() => { if (this.state.department != department.name) { this.setState({ department: department.name }) } else { this.setState({ department: '' }) } }}>{department.name}</span>
                                  <div className={this.state.department == department.name ? "container" : "container d-none"}>
                                    {
                                      department.category.map(category => (
                                        <>
                                          <span className={this.state.category == category.name ? "e-filter-btn e-filter-active d-block py-3 pl-2 font-weight-bold" : "e-filter-btn d-block py-3 pl-2 font-weight-bold"} style={{ cursor: "pointer" }} onClick={() => { if (this.state.category != category.name) { this.setState({ category: category.name }) } else { this.setState({ category: '' }) } }}>{category.name}</span>
                                          <div className={this.state.category == category.name ? "container" : "container d-none"}>
                                            {
                                              category.subcategory.map(sub => (
                                                <span className="d-block py-3 pl-2 font-weight-normal text-uppercase" style={{ cursor: "pointer" }} onClick={async () => { let temp = this.state.filter; temp.subcategory = sub; this.setState({ filter: temp, ad_category: department.id }); await this.getData(); }}>
                                                  <div class="d-flex">
                                                    <div className="e-checkbox">{sub.name}
                                                      <input type="checkbox" value={sub} id={`checkbox-${sub.name}`} checked={this.state.filter.subcategory === sub ? true : false} />
                                                      <span class="checkmark"></span>
                                                    </div>
                                                  </div>
                                                </span>
                                              ))
                                            }
                                          </div>
                                        </>
                                      ))
                                    }
                                  </div>
                                </>
                              ))
                              :
                              null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <h6>Sort by</h6>
                      </div>
                      <div className="col-lg-3 col-6 col-sm-4 mb-3 e-product-sort">
                        <label>Popularity</label>
                        <SelectBox options={tempPopularity} placeholder="Select" value={this.state.popularity} selectchange={async (val) => { await this.setState({ sort: 1, sortOrder: val.value, popularity: val, offer: { value: null, label: "Select" }, new: { value: null, label: "Select" }, discount: { value: null, label: "Select" }, }); await this.getData(); }} />
                      </div>
                      <div className="col-lg-3 col-6 col-sm-4 mb-3 e-product-sort">
                        <label>Offers</label>
                        <SelectBox options={tempOffers} placeholder="Select" value={this.state.offer} selectchange={async (val) => { await  this.setState({ sort: 2, sortOrder: val.value, offer: val, popularity: { value: null, label: "Select" }, new: { value: null, label: "Select" }, discount: { value: null, label: "Select" }, }); await this.getData(); }} />
                      </div>
                      <div className="col-lg-3 col-6 col-sm-4 mb-3 e-product-sort">
                        <label>What’s New </label>
                        <SelectBox options={tempNew} placeholder="Select" value={this.state.new} selectchange={async (val) => { await  this.setState({ sort: 3, sortOrder: val.value, new: val, popularity: { value: null, label: "Select" }, offer: { value: null, label: "Select" }, discount: { value: null, label: "Select" }, }); await this.getData(); }} />
                      </div>
                      <div className="col-lg-3 col-6 col-sm-4 mb-3 e-product-sort">
                        <label>Discount</label>
                        <SelectBox options={tempDiscount} placeholder="Select" value={this.state.discount} selectchange={async (val) => { await  this.setState({ sort: 4, sortOrder: val.value, discount: val, popularity: { value: null, label: "Select" }, offer: { value: null, label: "Select" }, new: { value: null, label: "Select" }, }); await this.getData(); }} />
                      </div>
                    </div>
                    {
                      this.state.filterLoading ?
                        <div className="row pt-3">
                          <PageLoader />
                        </div>
                        :
                        <React.Fragment>
                          {
                            this.state.data && this.state.data.length && this.state.data.length < 6 ?
                              <>
                                <div className="row pt-1 mb-5 e-product-card">
                                  {
                                    this.state.data.map((item) => {


                                      return (
                                        <div className="col-lg-4 col-6 pt-3">
                                          <Link to={`/local/product-detail?id=${item.product_id}`}>
                                            <div className="w-100 e-vendor-logo-wrap position-relative">
                                              <img className="e-prod-list-logo e-vendor-list-logo position-absolute" src={item.images[0]} alt="Truekhoj brand logo" />
                                            </div>
                                            <p className="py-2 m-0 font-weight-bold">{`${item.product_name? `${item.product_name} `:""}${item.variant_name?`${item.variant_name} `:""}${item.choice_name?item.choice_name:""}`}</p>
                                            <h6><span className="font-weight-bold " style={{ opacity: "0.77" }}>{`₹${item.selling_price}`}</span><span className="pl-3 font-weight-light" style={{ opacity: "0.47" }}><strike>{`₹${item.mrp}`}</strike></span></h6>
                                          </Link>
                                        </div>
                                      );
                                    })
                                  }
                                </div>
                                {
                                  this.state.ad_category ?
                                    <>
                                      {
                                        this.state.layout && this.state.layout.layout && this.state.layout.layout.length ?
                                          <>
                                            {
                                              this.checkIfInLayout(186)
                                            }
                                          </>
                                          : this.state.layout && this.state.layout.ads&&  this.state.layout.ads.intermediate.data ?
                                            <div className="row">
                                              <div className="col-lg-12 py-3">

                                                {/*<AdsHeroBanner content={this.state.layout.ads.intermediate.data}/>*/}
                                                <FindBannerType content={this.state.layout.ads.intermediate.data} details={this.state.layout.ads.intermediate} defaultType={1} />
                                              </div>
                                            </div>
                                            :
                                            null
                                      }
                                    </>
                                    :
                                    <>
                                      {
                                        this.state.layout && this.state.layout.ads&&  this.state.layout.ads.intermediate.data ?
                                          <div className="row">
                                            <div className="col-lg-12 py-3">

                                              {/*<AdsHeroBanner content={this.state.layout.ads.intermediate.data}/>*/}
                                              <FindBannerType content={this.state.layout.ads.intermediate.data} details={this.state.layout.ads.intermediate} defaultType={1} />
                                            </div>
                                          </div>
                                          :
                                          null
                                      }
                                    </>
                                }
                              </>
                              :
                              this.state.data && this.state.data.length && this.state.data.length >= 6 ?
                                <>
                                  <div className="row e-product-card">
                                    {
                                      this.state.data.map((item, index) => {
                                        if (index < 6) {
                                          return (
                                            <div className="col-lg-4 col-6 pt-3">
                                              <Link to={`/local/product-detail?id=${item.product_id}`}>
                                                <div className="w-100 e-vendor-logo-wrap position-relative">
                                                  <img className="e-prod-list-logo e-vendor-list-logo position-absolute" src={item.images[0]} alt="Truekhoj brand logo" />
                                                </div>
                                                <p className="py-2 m-0 font-weight-bold">{`${item.product_name? `${item.product_name} `:""}${item.variant_name?`${item.variant_name} `:""}${item.choice_name?item.choice_name:""}`}</p>
                                                <h6><span className="font-weight-bold " style={{ opacity: "0.77" }}>{`₹${item.selling_price}`}</span><span className="pl-3 font-weight-light" style={{ opacity: "0.47" }}><strike>{`₹${item.mrp}`}</strike></span></h6>
                                              </Link>
                                            </div>
                                          );
                                        }
                                      })
                                    }
                                  </div>

                                  {
                                    this.state.ad_category ?
                                      <>
                                        {
                                          this.state.layout && this.state.layout.layout && this.state.layout.layout.length ?
                                            <>
                                              {
                                                this.checkIfInLayout(186)
                                              }
                                            </>
                                            : this.state.layout && this.state.layout.ads&&  this.state.layout.ads.intermediate.data ?
                                              <div className="row">
                                                <div className="col-lg-12 py-3">

                                                  {/*<AdsHeroBanner content={this.state.layout.ads.intermediate.data}/>*/}
                                                  <FindBannerType content={this.state.layout.ads.intermediate.data} details={this.state.layout.ads.intermediate} defaultType={1} />
                                                </div>
                                              </div>
                                              :
                                              null
                                        }
                                      </>
                                      :
                                      <>
                                        {
                                          this.state.layout && this.state.layout.ads && this.state.layout.ads.intermediate.data ?
                                            <div className="row">
                                              <div className="col-lg-12 py-3">

                                                {/*<AdsHeroBanner content={this.state.layout.ads.intermediate.data}/>*/}
                                                <FindBannerType content={this.state.layout.ads.intermediate.data} details={this.state.layout.ads.intermediate} defaultType={1} />
                                              </div>
                                            </div>
                                            :
                                            null
                                        }
                                      </>
                                  }

                                  <div className="row mb-5 e-product-card">
                                    {
                                      this.state.data.map((item, index) => {
                                        if (index >= 6) {
                                          return (
                                            <div className="col-lg-4 col-6 pt-3">
                                              <Link to={`/local/product-detail?id=${item.product_id}`}>
                                                <div className="w-100 e-vendor-logo-wrap position-relative">
                                                  <img className="e-prod-list-logo e-vendor-list-logo position-absolute" src={item.images[0]} alt="Truekhoj brand logo" />
                                                </div>
                                                <p className="py-2 m-0 font-weight-bold">{`${item.product_name? `${item.product_name} `:""}${item.variant_name?`${item.variant_name} `:""}${item.choice_name?item.choice_name:""}`}</p>
                                                <h6><span className="font-weight-bold " style={{ opacity: "0.77" }}>{`₹${item.selling_price}`}</span><span className="pl-3 font-weight-light" style={{ opacity: "0.47" }}><strike>{`₹${item.mrp}`}</strike></span></h6>
                                              </Link>
                                            </div>
                                          );
                                        }
                                      })
                                    }
                                  </div>
                                </>
                                :
                                <div className="row pt-3">
                                  <NothingFound />
                                </div>
                          }
                        </React.Fragment>

                    }
                  </div>

                </div>
              </div>
            </section>
            <Subscription />
          <OffersModal data={this.state.popup}/>
          </div>
          {
            this.state.filterModal ?
              <FilterModal data={this.state.details} shop={this.state.brandlist} filter={this.state.filter} setFilters={async (filter) => { await this.setState({ filter: filter }); this.getData(); this.setState({ filterModal: false }) }} close={() => this.setState({ filterModal: false })} department={this.state.department} category={this.state.category} />
              :
              null
          }
        </>
      );
    }
  }
}

export default LocalProduct;
