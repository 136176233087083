
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import $ from 'jquery';
import ReactGA from 'react-ga';


/*Components*/

import Subscription from '../../components/common/subscription.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';

/*Assets*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';


class Policy extends Component {
  constructor(props){
    super(props);
    this.state = {


    }
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }



  render() {


      return (
        <div className="container-fluid p-0">

          <section className="e-terms e-policy pb-5 mb-4">
            <div className="container">
              <h4 className="text-uppercase pt-5 mt-4">Policy of Use.</h4>
              <div className="row">
                <div className="col-lg-12">
                  <p className="e-about-content mt-4 mb-0">
                    By visiting the Truekhoj Merchant Tools site (the “Site”), you accept these conditions.
                    Please read them carefully. In addition, when you use any current or future truekhoj.com
                    service or business, you also will be subject to the guidelines, terms and agreements
                    applicable to such service or business.
                  </p>
                  <p className="e-about-content">These ‘Policy of Use’ constitute an electronic record within the meaning of the
                  applicable law. This electronic record is generated by a computer system and does not
                  require any physical or digital signatures.</p>
                </div>
              </div>
              <div className="row">

                <div className="col-lg-12">
                  <h5>privacy</h5>
                  <p className="e-about-content mt-4">
                    Please review our Privacy Notice, which also governs your visit to this Site, to
                    understand our practices.
                  </p>
                </div>

                <div className="col-lg-12">
                  <h5>ELECTRONIC COMMUNICATIONS</h5>
                  <p className="e-about-content mt-4">
                    When you visit the Site or send e-mails to us, you are communicating with us
                    electronically. You consent to receive communications from us electronically. We will
                    communicate with you by e-mail or by posting notices on this Site. You agree that all
                    agreements, notices, disclosures and other communications that we provide to you
                    electronically satisfy any legal requirement that such communications be in writing.
                  </p>
                </div>

                <div className="col-lg-12">
                  <h5>COPYRIGHT</h5>
                  <p className="e-about-content mt-4">
                   All content included on this Site, such as text, graphics, logos, button icons, images,
                    audio clips, digital downloads, data compilations, and software, is the property of
                    truekhoj.com and is protected by India and international copyright laws. The compilation
                    of all content on this Site is the exclusive property of truekhoj.com or its affiliates and
                    protected by India and international copyright laws. All software used on this Site is the
                    property of truekhoj.com, its affiliates or its software suppliers and protected by India and
                    international copyright laws.
                  </p>
                </div>

                <div className="col-lg-12">
                  <h5>TRADEMARKS</h5>
                  <p className="e-about-content mt-4">
                   truekhoj.com, the truekhoj.com logo, Truekhoj India, and other marks indicated on our
                    Site are trademarks of truekhoj.com or its affiliates in India and other countries. Other
                    truekhoj.com graphics, logos, page headers, button icons, scripts, and service names are
                    trademarks or trade dress of truekhoj.com or its affiliates. truekhoj.com and its affiliates’
                    trademarks and trade dress may not be used in connection with any product or service
                    that is not truekhoj.com or its affiliates’ as applicable, in any manner that is likely to
                    cause confusion among users, or in any manner that disparages or discredits
                    truekhoj.com or its affiliates. All other trademarks not owned by truekhoj.com or its
                    affiliates that appear on this site are the property of their respective owners, who may
                    or may not be affiliated with, connected to, or sponsored by truekhoj.com or its affiliates.
                  </p>
                </div>

                <div className="col-lg-12">
                  <h5>LICENSE AND SITE ACCESS</h5>
                  <p className="e-about-content mt-4">
                   Truekhoj India Private Limited grants you a limited license to access and make personal
                    use of this Site and not to download (other than page caching) or modify it, or any
                    portion of it, except with express written consent of Truekhoj India Private Limited and /
                    or its affiliates, as may be applicable. This license does not include any resale or
                    commercial use of this site or its contents; any derivative use of this site or its
                    contents; or any use of data mining, robots, or similar data gathering and extraction
                    tools. This site or any portion of this site (including but not limited to any copyrighted
                    material, trademarks, or other proprietary information) may not be reproduced, duplicated,
                    copied, sold, resold, visited, or otherwise exploited for any commercial purpose without
                    express written consent of Truekhoj India Private Limited and / or its affiliates, as may
                    be applicable. You may not frame or utilize framing techniques to enclose any
                    trademark, logo, or other proprietary information (including images, text, page layout, or
                    form) of Truekhoj India Private Limited or its affiliates without their express written
                    consent. You may not use any meta tags or any other “hidden text” utilizing Truekhoj
                    India Private Limited's or its affiliates name or trademarks without their express written
                    consent. Any unauthorized use terminates the permission or license granted by Truekhoj
                    India Private Limited and / or its affiliates. You are granted a limited, non-assignable,
                    revocable, and nonexclusive right to create a hyperlink to the home page of the Site so
                    long as the link does not portray Truekhoj India Private Limited, its affiliates, or their
                    products or services in a false, misleading, derogatory, or otherwise offensive matter.
                    You may not use any logo or other proprietary graphic or trademark of Truekhoj India
                    Private Limited or its affiliates as part of the link without their express written permission.
                  </p>
                </div>

                <div className="col-lg-12">
                  <h5>UR ACTIVITY ON THE <span>truekhoj.com</span> SITE</h5>
                  <p className="e-about-content mt-4">
                   truekhoj.com or the Site is not intended for use by children. If you are a minor i.e.
                  under the age of 18 years, you may use the Site and truekhoj.com only with
                  involvement of a parent or guardian.
                  truekhoj.com and its affiliates reserve the right to refuse service, terminate accounts, or
                  remove or edit content in their sole discretion. You must not use the Site in any way
                  that causes, or is likely to cause, the website or access to it to be interrupted,
                  damaged or impaired in any way. You understand that you, and not truekhoj.com, are
                  responsible for all electronic communications and content sent from your computer to us
                  and you must use the Site for lawful purposes only and only in accordance with the
                  applicable law.
                  </p>
                  <p className="e-about-content mt-4">You must not use the Site for any of the following:</p>
                  <ul>
                    <li>for fraudulent purposes, or in connection with a criminal offence or other unlawfulactivity.</li>
                    <li>to send, use or reuse any material that is illegal, offensive, (including but not limited to
                    material that is sexually explicit or which promotes racism, bigotry, hatred or physical
                    harm), abusive, harassing, misleading, indecent, defamatory, disparaging, obscene or
                    menacing; or in breach of copyright, trademark, confidentiality, privacy or any other
                    proprietary information or right; or is otherwise injurious to third parties; or objectionable
                    or otherwise unlawful in any manner whatsoever; or which consists of or contains
                    software viruses, political campaigning, commercial solicitation, chain letters, mass
                    mailings or any “spam”.
                    </li>
                    <li>• to cause annoyance, inconvenience or needless anxiety.</li>
                  </ul>
                </div>

                 <div className="col-lg-12">
                  <h5>YOUR LICENSE</h5>
                  <p className="e-about-content mt-4">
                   If you submit material, and unless we indicate otherwise, you grant Truekhoj India
                    Private Limited and its affiliates a nonexclusive, royalty-free, irrevocable, perpetual and
                    fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create
                    derivative works from, distribute, and display such content throughout the world in any
                    media for as long as you are permitted to grant the said licence under applicable law.
                    You grant Truekhoj India Private Limited and its affiliates and sublicensees the right to
                    use the name that you submit in connection with such content, if they choose. You
                    represent and warrant that you own or otherwise control all of the rights to the content
                    or material that you post or submit or that you otherwise provide on or through the
                    Site; that the content is accurate; that the content is lawful; that use of the content you
                    supply does not violate this policy and will not cause injury to any person or entity; and
                    that you will indemnify Truekhoj India Private Limited and its affiliates for all claims
                    arising from content you supply. truekhoj.com has the right but not the obligation to
                    monitor and edit or remove any activity or content. Truekhoj India Private Limited takes
                    no responsibility and assumes no liability for any content submitted by you or any third
                    party.
                  </p>
                </div>

                <div className="col-lg-12">
                  <h5>COPYRIGHT COMPLAINTS</h5>
                  <p className="e-about-content mt-4">
                   Truekhoj India Private Limited and its affiliates respect the intellectual property of others.
                    If you believe that your work has been copied in a way that constitutes infringement of
                    your intellectual property, please follow our Trademark and Copyright policy below.
                    SERVICES DESCRIPTION truekhoj.com and its affiliates attempt to be as accurate as
                    possible. However, truekhoj.com does not warrant that descriptions of or other content of
                    this site is accurate, complete, reliable, current, or error-free. Also, your access to the
                    Site may also be occasionally suspended or restricted to allow for repairs, maintenance,
                    or the introduction of new facilities or at any time without prior notice. We will attempt
                    to limit the frequency and duration of any such suspension or restriction. truekhoj.com
                    provides links to other sites over which truekhoj.com has no control. truekhoj.com is not
                    responsible for the availability of such external sites or resources and does not endorse
                    and is not responsible or liable for any content, advertising, products or other material
                    on or available from such sites or resources.
                  </p>
                </div>


                <div className="col-lg-12">
                  <h5>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITIES</h5>
                  <p className="e-about-content mt-4">
                   This site is provided by truekhoj.com on an “as is” and “as available” basis.
                    truekhoj.com makes no representations or warranties of any kind, express or implied, as
                    to the operation of this site or the information, content, materials, or products included
                    on this site. You expressly agree that your use of this site is at your sole risk.
                    truekhoj.com reserves the right to withdraw or delete any information from this site at
                    any time in its discretion. To the full extent permissible by applicable law, truekhoj.com
                    disclaims all warranties, express or implied, including, but not limited to, implied
                    warranties of merchantability and fitness for a particular purpose. truekhoj.com does not
                    warrant that this site, its servers, or e-mail sent from truekhoj.com are free of viruses or
                    other harmful components. truekhoj.com will not be liable for any damages of any kind
                    arising from the use of this site, including, but not limited to direct, indirect, incidental,
                    punitive, and consequential damages.
                  </p>
                </div>

                <div className="col-lg-12">
                  <h5>APPLICABLE LAW</h5>
                  <p className="e-about-content mt-4">
                   By visiting this Site, you agree that the laws of India will govern these Policy of Use
                    and any dispute of any sort that might arise between you and truekhoj.com or its
                    affiliates. DISPUTES Any dispute relating in any way to your visit to this Site shall be
                    submitted to the exclusive jurisdiction of the courts at Delhi, India.
                  </p>
                </div>

                <div className="col-lg-12">
                  <h5>SITE POLICIES, MODIFICATION AND SEVERABILITY</h5>
                  <p className="e-about-content mt-4">
                   Please review our other policies on this Site. These policies also govern your visit to
                  the Site. We reserve the right to make changes to our Site, policies, and these Policy
                  of Use at any time. If any of these conditions shall be deemed invalid, void, or for any
                  reason unenforceable, that condition shall be deemed severable and shall not affect the
                  validity and enforceability of any remaining condition. If you become a merchant on the
                  truekhoj.com, your activities will be governed by the truekhoj.com Conditions of Use or
                  those of the respective other truekhoj.com site and any other applicable policies or
                  agreements.
                  </p>
                </div>

                <div className="col-lg-12">
                  <h5>OUR ADDRESS</h5>
                  <p className="e-about-content mt-4 mb-0 e-address">
                    This Site is owned and operated by
                  </p>
                  <p className="e-about-content mb-0 e-address">Truekhoj Private Limited,</p>
                  <p className="e-about-content e-address">BANGLORE 560065</p>
                </div>




              </div>
            </div>
          </section>

          <Subscription/>
        </div>
      );
    }
  }


export default Policy;

