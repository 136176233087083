
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import $ from 'jquery';
import ReactGA from 'react-ga';


/*Components*/


import CareerAccordion from '../../components/accordion/career-accordion.js';
import Subscription from '../../components/common/subscription.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import GetURLVar from '../../services/getParams/get-url-var';



/*Assets*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';


class Career extends Component {
  constructor(props){
    super(props);
    this.state = {
       data: null,
       apiLoading: true,




    }
  }

  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    ReactGA.pageview(window.location.pathname);
    this.getData();

  }
  getData(){
   var url = devconfig.userV1+"/career/list-all";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({ data: response.data, apiLoading: false});
      }
      else{
        this.setState({ error: response.message, apiLoading: false});
      }
    }.bind(this))
  }



  render() {


      return (
        <div className="container-fluid p-0">
          <section className="container-fluid position-relative p-0 e-career-banner">
            <div className="container">
              <div className="row mb-5">
                <div className="col-lg-6 mb-5">
                  <h1 className="text-uppercase">we are hiring new talents.</h1>
                </div>
              </div>
            </div>
          </section>

          <section className="e-career pb-5 mb-4">
            <div className="container">
              <h4 className="text-uppercase pt-5 mt-4">open positions</h4>
              <div className="row">
                <div className="col-lg-12">
                  {
                    this.state.apiLoading ?
                      <div className="mt-5 pt-5 text-center">
                        <PageLoader/>
                      </div>
                    :
                      this.state.data ?
                      this.state.data.map((item,key) => {
                      const id = "id"+key;
                      return(
                        <div className="col-lg-12 col-md-12 col-sm-12 e-col pl-0" key={item.key}>
                          <div className="e-accordion-col">
                            <CareerAccordion
                              designation={item.designation}
                              location={item.location}
                              accordionId={id}
                              description={item.description}

                            />
                          </div>
                        </div>
                      );
                    })
                  :
                    null
                }
                </div>
              </div>
            </div>
          </section>

          <Subscription/>
        </div>
      );
    }
  }


export default Career;

