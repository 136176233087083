
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*Assets*/
import logo1 from '../../../assets/images/online/brand-logo-1.jpg';

import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';

class LogoSlider extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
    this.wrapperRef=[]
  }

  componentDidMount() {
    setTimeout(()=>{
      this.setDimentions();
    },300);
  }

  setDimentions(){
    let imageRef = document.querySelectorAll('.e-logo-slider-img-ref');
    let wrapperRef=this.wrapperRef;
    let width;
    if(wrapperRef&& wrapperRef.length){
      width = wrapperRef[0].clientWidth;
      imageRef.forEach(element => {
        element.style.height = `${width-20}px`;
        element.style.width = `${width-20}px`;
      });
    }
  }

  adAnalytics(tempId){
    if(tempId){
        var url = devconfig.userV1+"/ads/update-analytics";
        var data = JSON.stringify({
          "ads_id" : Number(tempId),
          "clicks" : 1,
          "views" : 1
        })
        APIPost(url, data).then(function(response){
          if(response.status === "ok"){
            
          }
        }.bind(this))
    }
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      autoplay: true,
      autoplaySpeed: 3000,
      arrow: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          }
        },
        {
          breakpoint: 380,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          }
        }
      ]
    };


    return (
      <Slider className="e-logo-slider-wrap" {...settings}>
        {
          this.props.data && this.props.data.map( (item, index) => {
            var tempId = null;
            if(item.id){
              tempId = item.id;
            }
            if(item){
              return(
                <a href={item.link} onClick={this.adAnalytics.bind(this, tempId)} target="_blank" key={index} ref={ref=>this.wrapperRef[index]=ref}>
                  <div className="e-logo-slider-item">
                    <img src={item.content} alt="Brand logo" className="e-logo-slider-img-ref"/>
                  </div>
                </a>
              );
            }
            else{
              return(
                null
              );
            }
          })

        }
        
        {/*<div className="e-logo-slider-item">
          <img src={logo1} alt="Brand logo"/>
        </div>
        <div className="e-logo-slider-item">
          <img src={logo1} alt="Brand logo"/>
        </div>
        <div className="e-logo-slider-item">
          <img src={logo1} alt="Brand logo"/>
        </div>
        <div className="e-logo-slider-item">
          <img src={logo1} alt="Brand logo"/>
        </div>
        <div className="e-logo-slider-item">
          <img src={logo1} alt="Brand logo"/>
        </div>
        <div className="e-logo-slider-item">
          <img src={logo1} alt="Brand logo"/>
        </div>
        <div className="e-logo-slider-item">
          <img src={logo1} alt="Brand logo"/>
        </div>*/}


      </Slider>
    );
  }
}

export default LogoSlider;
