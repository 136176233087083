
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import $ from 'jquery';
import ReactGA from 'react-ga';


/*Components*/

import Subscription from '../../components/common/subscription.js';
import SelectBox from '../../components/formComponents/selectBox';
import PrimaryButton from '../../components/buttons/primaryButton';
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/TextArea.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import GetURLVar from '../../services/getParams/get-url-var';
import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';


/*Assets*/
import ContactIllustration from '../../assets/images/about/OP_0023_corporate-01 (2).png'

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';


class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {

      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      error: "",
      data: "",
      success: ""


    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

  }
  clearForm() {
    this.setState({
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      nameError: "",
      mailError: "",
      numberError: "",
      error: ""
    });
  }

  async validate() {
    // alert("hi");
    let nameError = "";
    let mailError = "";
    let numberError = "";

    if (this.state.name.length == 0) {
      nameError = 'Invalid name, please check and retry!';
    }
    await EmailValidation(this.state.email).then(function (response) {
      if (!response) {
        mailError = 'Invalid email address, please check and retry!';
      }
    }.bind(this))

    await PhoneValidation(this.state.phone).then(function (response) {
      if (!response) {
        numberError = 'Invalid phone number, please check and retry!';
      }
    }.bind(this))
    // console.log(mailError,nameError,numberError);


    if (mailError || nameError || numberError) {
      this.setState({ mailError, nameError, numberError });
      return false;
    }
    else {
      return true;
    }
  };


  async submitForm(e) {
    e.preventDefault();
    const isValid = await this.validate();
    // await console.log(isValid);
    if (isValid) {
      var url = devconfig.userV1 + "/contactus/sent";
      var data = JSON.stringify({
        "name": this.state.name,
        "email": this.state.email,
        "mobile": this.state.phone,
        "subject": this.state.subject,
        "message": this.state.message

      })
      APIPost(url, data).then(function (response) {
        if (response.status === "ok") {
          this.setState({ data: response.data });
          this.clearForm();
          this.setState({ success: "Form Submission Success!" })

        }
        else {
          this.setState({ error: response.message });
        }
      }.bind(this))
    }

  }

  render() {


    return (
      <div className="container-fluid p-0">
        <section className="container-fluid position-relative p-0 e-contact-banner">
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-12 mb-5">
                <h1 className="text-uppercase">Help & support.</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="e-contact-form">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h4 className="text-uppercase pt-5">connect with us</h4>
                <form className="">
                  <div className="row pt-3">
                    <div className="col-lg-6 col-md-6 pr-0">
                      <label>Name</label>
                      <div>
                        <Input className="e-text e-content e-text-transform" type="text" value={this.state.name} valueChange={(tempVal) => this.setState({ name: tempVal })} autoComplete="nope" />

                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 pr-0">
                      <label>Phone</label>
                      <div>
                        <Input className="e-text e-content" type="number" value={this.state.phone} valueChange={(tempVal) => this.setState({ phone: tempVal })} autoComplete="nope" />


                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 pl-0 pt-3">
                    <label>Email Address</label>
                    <div>
                      <Input className="e-content" type="text" value={this.state.email} valueChange={(tempVal) => this.setState({ email: tempVal })} autoComplete="nope" />

                    </div>
                  </div>

                  <div className="col-lg-12 pl-0 pt-3">
                    <label>Subject</label>
                    <div>
                      <Input className="e-content e-text-transform" value={this.state.subject} valueChange={(tempVal) => this.setState({ subject: tempVal })} />
                    </div>
                  </div>

                  <div className="col-lg-12 pl-0 pt-3">
                    <label>Message</label>
                    <div>
                      <Textarea className="e-content e-text-transform" rows="5" cols="57" value={this.state.message} valueChange={(tempVal) => this.setState({ message: tempVal })} />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-5 pb-5 pt-3 pl-0">
                    <div className="row">

                      <div className="col-lg-4">
                        <div className="pl-0">
                          <PrimaryButton className="e-p-btn e-contact-btn" name="Send Message" handleClick={this.submitForm.bind(this)} />
                        </div>
                      </div>


                      <div className="col-lg-8">
                        <div id="e-error" className="text-left">
                          {this.state.nameError || this.state.mailError || this.state.numberError ? <p className="e-error"><span className="e-alert-span">Alert!</span> {this.state.nameError || this.state.mailError || this.state.numberError}</p> : null}
                        </div>

                        <div id="e-success" className="text-left">

                          {
                            this.state.success.length ?
                              <p className="success"><span>Success!</span> {this.state.success}</p>
                              : null
                          }

                        </div>
                      </div>

                    </div>

                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="contianer d-flex align-items-center justify-content-center h-100 pb-5 p-lg-0">
                  <div className="row mt-5">
                    <div className="col-lg-12">
                    <div className="contianer d-flex justify-content-center justify-content-lg-start px-0">
                      <img src={ContactIllustration} alt="" className="e-contact-illustration" />
                    </div>
                    </div>
                    <div className="col-lg-12 ml-3 ml-lg-0 mt-3"><span className="e-red-span">Phone: </span><a className="e-contact-a" href="Tel:+917022112002">+917022112002</a></div>
                    <div className="col-lg-12 ml-3 ml-lg-0 mt-3"><span className="e-red-span">Support: </span><a className="e-contact-a" href="mailto:customersupport@truekhoj.com">customersupport@truekhoj.com</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Subscription />
      </div>
    );
  }
}


export default Contact;
