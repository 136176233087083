/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FavouritesCard from '../cards/favourites-card';

import devconfig from '../../config/config';
import APIPost from '../../services/apiCall/post';



class FavouriteStores extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.setVendors();
    
  }

  async setVendors(){
    let temp = [];
    this.props.stores.vendors.forEach(vendor=>{
      temp.push(vendor.value);
    });
    await this.getDetails(temp);
  }

  getDetails(ids){
    this.setState({ dataLoading: true });
    var url = devconfig.localUser + "/stores/list";
    var data = JSON.stringify({
      "vendor_ids" : ids
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({vendorInfo: response.data});
      }
    }.bind(this))
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      arrows: true,
      dots: false, 
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
          }
        },
      ]
    };

    return (
      <>
      <div className="container px-0">
      <Slider className="e-favourite-stores" {...settings}>
           {
               this.state.vendorInfo && this.props.stores && this.props.stores.data.map((store, i)=>{
                let vendorData = {};
                this.state.vendorInfo.forEach(vendor=>{
                  if (vendor.id == this.props.stores.vendors[i].value){
                    vendorData = vendor;
                  }
                })
                return(
                  <div className="px-3">
                    <FavouritesCard data={store} vendorData={vendorData}/>
                  </div>
                )
            })
           }
      </Slider>
      </div>
      </>
    );
  }
}

export default FavouriteStores;