/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import PrimaryButton from '../../components/buttons/primaryButton.js';
import TextButton from '../../components/buttons/textButton.js';
import Input from '../../components/formComponents/input';
import SelectBox from '../formComponents/selectBox.js';



/*Assets*/
import Img from '../../assets/images/online/signup-img.svg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import GoogleBtn from '../socialMediaLogin/googleLogin.js';
import FacebookBtn from '../socialMediaLogin/facebookLogin.js';
import ButtonLoader from '../common/buttonLoader.js';


class SuccessSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      cpassword: "",
      newPassword: "",
      confirmPassword: "",
      name: "",
      email: "",
      apiLoading: false,
      success: "",
      error: "",
      locationOptions: [],
      location: null,
      googleLogin: true
    }
  }

  validateForm() {
    // alert("hi");
    if (!this.state.password.length) {
      this.setState({ error: "Password missing" });
    }
    else if (!this.state.cpassword.length) {
      this.setState({ error: "Confirm Password missing" });
    }
    else if (!this.state.name.length && !this.props.forgotPassword) {
      this.setState({ error: "Name missing" });
    }
    else if (!this.state.email.length && !this.props.forgotPassword) {
      this.setState({ error: "Email missing" });
    }
    else {
      this.signup();
    }

  }

  async signup() {
    this.setState({ apiLoading: true, error: "" });
    if (this.state.password === this.state.cpassword) {
      let url = devconfig.localUser + "/auth/signup";
      let data = JSON.stringify({
        "otp": this.props.data.otp,
        "name": this.state.name,
        "mobile": this.props.data.number,
        "email": this.state.email,
        "password": this.state.password,
        "gender" : this.state.gender,
        "location" : {
          "description": this.state.location.label, 
          "place_id": this.state.location.value, 
          "reference": this.state.location.value 
          },
      })
      if (this.props.forgotPassword) {
        url = devconfig.localUser + "/auth/reset-forgot-password";
        data = JSON.stringify({
          "otp": this.props.data.otp,
          "mobile": this.props.data.number,
          "password": this.state.password
        })
      }
      // console.log(data);
      APIPost(url, data).then(function (response) {
        if (response.status === "ok") {
          this.props.back();
          this.setState({ apiLoading: false, error: "" });
        }
        else {
          this.setState({ error: response.message, apiLoading: false });
        }
      }.bind(this))
    }
    else {
      this.setState({ error: "Passwords doesnot match", apiLoading: false });
    }
  }

  googleSignUp(response){
    this.setState({gApiLoading: true, error: ""});
    console.log(response);
    if(this.state.googleLogin && response && response.profileObj){
      let url = devconfig.localUser + "/auth/signup";
      let data = JSON.stringify({
        "is_social" : true,
        "otp": this.props.data.otp,
        "name": `${response.profileObj.givenName} ${response.profileObj.familyName}`,
        "mobile": this.props.data.number,
        "email": response.profileObj.email,
        "gender" : this.state.gender,
        "location" : {
          "description": this.state.location.label, 
          "place_id": this.state.location.value, 
          "reference": this.state.location.value 
          },
        "social_user_id" : response.profileObj.googleId,
      })
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.props.back();
          this.setState({ error: "", gApiLoading: false});
        }
        else{
          this.setState({ error: response.message, gApiLoading: false});
        }
      }.bind(this));
    }
    else if(response && response.name && response.email && response.id){
      let url = devconfig.localUser + "/auth/signup";
      let data = JSON.stringify({
        "is_social" : true,
        "otp": this.props.data.otp,
        "name": response.name,
        "mobile": this.props.data.number,
        "email": response.email,
        "gender" : this.state.gender,
        "location" : {
          "description": this.state.location.label, 
          "place_id": this.state.location.value, 
          "reference": this.state.location.value 
          },
        "social_user_id" : response.id,
      })
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.props.back();
          this.setState({ error: "", gApiLoading: false, googleLogin: true});
        }
        else{
          this.setState({ error: response.message, gApiLoading: false, googleLogin: true});
        }
      }.bind(this));
    }
    else{
      this.setState({error: "Something went wrong", gApiLoading: false, googleLogin: true});
    }
  }

  async search(query){
    this.setState({locationSearch: query});
    if(query && query.length){
      let url = devconfig.localUser + "/location/search";
      let data = JSON.stringify({
        "text" : query
      })
      APIPost(url, data).then(function(response){
        if(response.status==="ok"){
          this.setState({locationOptions: response.data});
        }
        else{
          this.setState({ error: response.message});
        }
      }.bind(this));
    }
    else{
      this.setState({locationOptions: []});
    }
  }

  async handleSocialLogin(){
    let url = devconfig.localUser + "/auth/signup";
      let data = JSON.stringify({
        "is_social" : true,
        "otp": this.props.data.otp,
        "name": this.props.socialData.name,
        "mobile": this.props.data.number,
        "email": this.props.socialData.email,
        "gender" : this.state.gender,
        "location" : {
          "description": this.state.location.label, 
          "place_id": this.state.location.value, 
          "reference": this.state.location.value 
          },
        "social_user_id" : this.props.socialData["social_user_id"],
      })
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.props.login();
          // this.setState({ error: "", gApiLoading: false});
        }
        else{
          this.setState({ error: response.message, gApiLoading: false});
        }
      }.bind(this));
  }



  render() {

    return (

      <div className="row e-signup-modal e-verify-otp e-confirm ">
        <div className="col-lg-6 col-md-6">
          <div className="e-left-banner">
            <h6 className="text-uppercase">sign up</h6>
            <div className="text-center"><img src={Img} className="e-login-img" /></div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          {
            !this.state.next ?
              <div className="e-login-content-wrap">
                <div className="row pt-2">
                  <div className={this.state.locationOptions && this.state.locationOptions.length? "col-lg-12 col-md-12 pr-0 e-search-location e-show-options": "col-lg-12 col-md-12 pr-0 e-search-location"}>
                    <label>Location</label>
                    <div className="position-relative">
                      <SelectBox options={this.state.locationOptions}
                       isSearchable={true}
                       inputValue={this.state.locationSearch}
                       onInputChange={(e) => this.search(e)}
                       placeholder="Search for location"
                       value={this.state.location}
                      selectchange={(val) => this.setState({location: val})}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 pr-0 pt-4">
                    <label>Gender</label>
                    <div>
                      <div className="d-flex flex-column flex-md-row mt-3 container px-0 justify-content-md-between">
                      <div class="d-flex" onClick={()=>this.setState({gender: "male"})}>
                        <div className="e-checkbox"><label>Male</label>
                          <input type="checkbox"  value={"male"} id={`checkbox-$Male`} checked={this.state.gender=== "male"? true: false} />
                          <span class="checkmark"></span>
                        </div>
                      </div>
                      <div class="d-flex" onClick={()=>this.setState({gender: "female"})}>
                        <div className="e-checkbox"><label>Female</label>
                          <input type="checkbox"  value={"female"} id={`checkbox-$Female`} checked={this.state.gender=== "female"? true: false} />
                          <span class="checkmark"></span>
                        </div>
                      </div>
                      <div class="d-flex" onClick={()=>this.setState({gender: "other"})}>
                        <div className="e-checkbox"><label>Other</label>
                          <input type="checkbox"  value={"other"} id={`checkbox-$Other`} checked={this.state.gender=== "other"? true: false} />
                          <span class="checkmark"></span>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  this.state.error.length ?
                    <div className="row pt-2">
                      <div className="col-lg-12 col-md-12 pr-0">
                        <div id="e-error" className="error pt-1">{this.state.error}</div>
                      </div>
                    </div>
                    :
                    null
                }
                <div className="row pt-5 pb-5">
                  <div className="col-lg-12 pr-0">
                    <div className="">
                      {
                        this.state.apiLoading?
                        <div className="float-right pr-3">
                            <ButtonLoader/>
                          </div>
                        :this.state.location && this.state.gender?
                        <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn e-login-btn" name={this.props.socialLogin? "Complete Login":"Next"} handleClick={this.props.socialLogin? ()=>this.handleSocialLogin() : () =>{this.setState({next: true})}} />
                        :
                        <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn e-login-btn e-inactive" name={this.props.socialLogin? "Complete Login":"Next"} />
                      }
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="e-login-content-wrap">
                <div className="row">
                  <div className="col-lg-12 col-md-12 pr-0">
                    <label>Password</label>
                    <div>
                      <Input className="" type="password" value={this.state.password} valueChange={(tempVal) => this.setState({ password: tempVal })} autoComplete="nope" />
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-lg-12 col-md-12 pr-0">
                    <label>confirm password</label>
                    <div>
                      <Input className="" type="password" value={this.state.cpassword} valueChange={(tempVal) => this.setState({ cpassword: tempVal })} autoComplete="nope" />
                    </div>
                  </div>
                </div>
                {
                  !this.props.forgotPassword ?
                    <>
                      <div className="row pt-3">
                        <div className="col-lg-12 col-md-12 pr-0">
                          <label>full name</label>
                          <div>
                            <Input className="" type="text" value={this.state.name} valueChange={(tempVal) => this.setState({ name: tempVal })} autoComplete="nope" />
                          </div>
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-lg-12 col-md-12 pr-0">
                          <label>email address</label>
                          <div>
                            <Input className="" type="text" value={this.state.email} valueChange={(tempVal) => this.setState({ email: tempVal })} autoComplete="nope" />
                          </div>
                          <div id="e-error" className="error pt-1">{this.state.error}</div>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      {
                        this.state.error.length ?
                          <div className="row pt-2">
                            <div className="col-lg-12 col-md-12 pr-0">
                              <div id="e-error" className="error pt-1">{this.state.error}</div>
                            </div>
                          </div>
                          :
                          null
                      }
                    </>
                }
                <div className="row pt-4">
                  <div className="col-lg-12 pr-0">
                    <div className="">
                      <PrimaryButton className="e-p-btn e-contact-btn e-ofer-btn e-login-btn" name={!this.props.forgotPassword ? "Sign Up" : "Set Password"} handleClick={this.validateForm.bind(this)} />
                    </div>
                    {/* <div id="e-succes" className="success pt-2">{this.state.succes}</div> */}
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-lg-12 pr-0">
                    <div className="d-flex container justify-content-center">
                      <label>Or Signup with</label>
                    </div>
                  </div>
                  <div className="col-lg-12 pr-0 pt-1">
                      <div className="row mx-n2">
                            <div className="col-6 px-2">
                            <GoogleBtn text="Google"
                              login={(response)=>this.googleSignUp(response)} onFailure={(response) => {if(response && response.error && !response.error.includes("idpiframe_initialization_failed"))this.setState({error: response.error});}}/>
                          
                            </div>
                            <div className="col-6 px-2">
                              <FacebookBtn handleResponse={(res)=>this.googleSignUp(res)} onClick={()=>this.setState({googleLogin: false})}/>
                            </div>
                          </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 pr-0 text-center pt-3 pb-lg-4">
                    <p className="e-back"><TextButton className="e-sign-up pl-1" name="Back" handleClick={()=>{this.setState({next: false})}}/></p>
                  </div>
                  <div className="col-lg-12 pr-0">
                    <p className="pt-5 e-sign-up">By signing up you agree to our<TextButton className="e-sign-up pl-1" name="Terms & Conditions" /></p>
                  </div>
                </div>
              </div>

          }

        </div>
      </div>
    );
  }
}

export default SuccessSignUp;