
/*Package importing*/
import React, { Component } from 'react';
import VideoPlayer from '../../../components/common/video';
/*Assets*/
import banner from '../../../assets/images/online/section-banner-1.svg';

import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';

class AdsSectionBanner_1 extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <div className="row section-banner-1-wrap">
        <div className="col-lg-12 col-12">
          <a href={this.props.link} target="_blank">
            {
              this.props.data && this.props.data.indexOf(".mp4") > -1 ? 
                <VideoPlayer url={this.props.data} />
              :
                <img className="e-banner-wrap" className="e-banner-wrap" src={this.props.data} alt="ads banner"/>
            }
          </a>
        </div>
      </div>
    );
  }
}

export default AdsSectionBanner_1;
