/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';

/*Components*/
import FavouritesCard from '../../components/cards/favourites-card.js';



/*Assets*/
import Img from '../../assets/images/online/login-img.svg';

/*Services*/



class Favourites extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
   
  }





  render() {

    return (
       <div className="container pl-0 pr-0">
        <FavouritesCard/>
       </div>



    );
  }
}

export default Favourites;