import React from 'react';

function PrimaryButton(props){
	return(
		<button
			type={props.type}
			className={props.className}
			id={props.id}
			disabled={props.disabled}
			data-target={props.target}
			data-toggle={props.toggle}
			onClick={props.handleClick}>{props.name}
		</button>
	)
}

export default PrimaryButton;