/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';

/*Components*/
import LocalSecondaryHeading from '../../components/navigations/secondaryHeading-local';
import WorkingProgress from '../../components/common/working-progress';
import AdsHeroBanner from '../../components/ads/banners/hero-banner.js';
import AdsFourColumn_One from '../../components/ads/list/four-column-style-1.js';
import LocalStoreStrip from '../../components/ads/strip-banners/localstore-strips.js';
import AdsSectionBanner_1 from '../../components/ads/banners/section-banner-1.js';
import AdsSectionBanner_2 from '../../components/ads/banners/section-banner-2.js';
import RecentlyViewed from '../../components/sliders/recently-viewed.js';
import Testimonials from '../../components/sliders/testimonials.js';
import Subscription from '../../components/common/subscription.js';
import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import FindBannerType from '../../components/ads/banners/findBannerType';
import CardSixColumn from '../../components/cards/column-6.js';
import H2 from '../../components/headings/h2.js';
import LogoHeading from '../../components/headings/logo-heading.js';
import CardFourColumn from '../../components/cards/column-4.js';
import CardTwoColumn from '../../components/cards/column-2';
import CardThreeColumn from '../../components/cards/column-3';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';

import recents1 from '../../assets/images/local/recentSample/Rectangle162.png';
import recents2 from '../../assets/images/local/recentSample/Rectangle163.png';
import recents3 from '../../assets/images/local/recentSample/Rectangle164.png';
import recents4 from '../../assets/images/local/recentSample/Rectangle165.png';
import OffersModal from '../../components/modals/offersPopup';

class LocalHome extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      highlights: {},
      dataLoading: false,
      recents: null
    }
  }
  
  componentDidMount() {
    this.setLocation();
    this.getRecents();
    window.scrollTo({top: 0, behavior: 'smooth'});
    ReactGA.pageview(window.location.pathname);
  }

  getRecents(){
    if(localStorage.getItem('truekhoj_user')) this.getRecentCategories();
    else this.getViewed();
  }

  async getViewed(){
    if(localStorage.getItem("truekhoj_recents_local")){
      let viewed = JSON.parse(localStorage.getItem("truekhoj_recents_local"));
      let url = devconfig.localUser+"/page/department/list";
      let response = await APIGet(url);
      if(response && response.status ==="ok" && response.data && response.data.length){
          let recents ={content: [], "type": 1,
          "style": {
              "value": 13,
              "label": "Style - 13"
          }};
          response.data.forEach(item=>{if(viewed.indexOf(item.id)!=-1) recents.content.push({img: item.image, title: item.name, id: item.id});});
          this.setState({recents});
    }
    }
  }

  async getRecentCategories(){
    var url = devconfig.localUser+"/page/recentlyviewed/list";
    APIGet(url).then(function(response){
      if(response && response.status === "ok" && response.data && response.data.local && response.data.local.departments && response.data.local.departments.length){
        let recents = {content: [], "type": 1,
        "style": {
          "value": 40,
          "label": "Style - 40"
        }}
        response.data.local.departments.forEach(item=>recents.content.push({img: item.image, title: item.name, id: item.id}));
        this.setState({recents});
      }
    }.bind(this))
  }

  async setLocation(){
    let temp = await JSON.parse(localStorage.getItem("truekhoj_user_location"));
    this.setState({userLocation: temp}, ()=>this.getLayout());
  }

  getLayout(){
    this.setState({dataLoading: true});
    var url = devconfig.localUser+"/page/detail";
    var data = JSON.stringify({
      "id" : 3,
      "type" : 4,
      "location_id": this.state.userLocation.value
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data.page_data && response.data.page_data.ads){
          this.setState({data: response.data.page_data, dataLoading: false, details: response.data});
        }
        else if(response.data.admin_page_data && response.data.admin_page_data.ads){
          this.setState({data: response.data.admin_page_data, dataLoading: false, details: response.data});
        }
        else{
          this.setState({dataLoading: false});
        }
        // this.setState({data: response.data.admin_page_data, dataLoading: false, details: response.data});
      }
      this.getPopup(3);
    }.bind(this))
  }

  getPopup(id){
    let viewed = [];
    let location = localStorage.getItem("truekhoj_user_location");
    if(location) location = JSON.parse(localStorage.getItem("truekhoj_user_location"));
    if(sessionStorage.getItem('local_viewed_pages')) viewed= JSON.parse(sessionStorage.getItem('local_viewed_pages'));
    if(viewed.indexOf(Number(id))===-1&& (localStorage.getItem('Login_promt')||localStorage.getItem('truekhoj_user'))){
      var url = devconfig.localUser+"/popupbanner/view";
      var data = JSON.stringify({
        "local" : true,
        "layout_id" : id,
        "location_id": location? location.value: null
      });
      APIPost(url, data).then(function(response){
        if(response &&response.status === "ok"&& response.data && response.data.image){
          viewed.push(Number(id));
          sessionStorage.setItem('local_viewed_pages', JSON.stringify(viewed));
          this.setState({popup: response.data});
          $('#offersPopupModal').modal('show');
        }
      }.bind(this))
    }
  }

  render() {

    let sampleRecents = {
          "content": [
              {
                  "img": recents1,
                  "title": "smartphone",
                  "id": 1
              },
              {
                  "img": recents2,
                  "title": "Sceneries",
                  "id": 3
              },
              {
                  "img": recents3,
                  "title": "Normal",
                  "id": 2
              },
              {
                  "img": recents4,
                  "title": "smartphone",
                  "id": 1
              }
          ],
          "type": 1,
          "style": {
              "value": 40,
              "label": "Style - 40"
          }
      };

    if(this.state.data){
      return (
        <div className="container-fluid p-0">

          {
            this.state.details ?
              <LocalSecondaryHeading data={this.state.details} />
              :
              null
          }


          <div className="row pb-5 e-mb-15">
            <div className="col-lg-12 p-0">
              <FindBannerType content={this.state.data.ads.hero.data} details={this.state.data.ads.hero} defaultType={1}/>
              {/*<AdsHeroBanner content={this.state.data.ads.hero.data}/>*/}
            </div>
          </div>
          {

            this.state.data.ads.ad1.data.length ?
              <div className="container">
                <div className="pb-5 e-no-mb e-pb-15">
                  {/*<AdsFourColumn_One layout={this.state.data.ads.ad1.data}/>*/}
                  <FindBannerType content={this.state.data.ads.ad1.data} details={this.state.data.ads.ad1} defaultType={2}/>
                </div>
              </div>
            :
              null
          }
          <div className="container-fluid p-0 py-3 mb-3" style={{backgroundColor: this.state.data.ads.summerCollection&&this.state.data.ads.summerCollection.bg_color? this.state.data.ads.summerCollection.bg_color : "#fff0"}}>
            <div className="container pb-3">
              <div className="row pb-2 e-mb-15">
                <div className="col-lg-12 mb-4 pt-5 ">
                    {
                        this.state.data.ads.summerCollection.header && !this.state.data.ads.summerCollection.header.content ?
                            <H2 data={this.state.data.ads.summerCollection.header} type={16} bg_color="" font_color="#484848"/>
                          :
                          
                            <LogoHeading data={this.state.data.ads.summerCollection.header} />
                      }
                  
                </div>
                <div className="col-lg-12 p-0">
                  <FindBannerType content={this.state.data.ads.summerCollection.data} details={this.state.data.ads.summerCollection} defaultType={1}/>
                </div>
              </div>
            </div>
          </div>
          {
            this.state.data.ads.ad2.data.length ?
              <div className="container">
                <div className="pb-5 e-no-mb e-pb-15">
                  <FindBannerType content={this.state.data.ads.ad2.data} details={this.state.data.ads.ad2} defaultType={2}/>
                </div>
              </div>
            :
              null
            }
            {/* <div className="container">
              <div className="mb-5 pb-4 e-pb-15 e-no-mb">
                <LocalStoreStrip/>
              </div>
            </div> */}

          {
            this.state.data && this.state.data.layout.map( (item, index) => {

              if(item.bg_color){
                var tempStyle = {background: item.bg_color};
              }



              return(
                <section className="container-fluid position-relative" style={tempStyle}>
                  <div className="container">
                    <div className="row pb-5 e-no-mb">
                    {
                        item.header.content && !item.header.link ?
                          <div className={tempStyle ? "col-lg-12 mb-4 pt-5" : "col-lg-12 mb-4 pt-5 pt-lg-2"}>
                            <H2 data={item.header.content} type={item.header.type} bg_color={item.header.bg_color} font_color={item.header.font_color} />
                          </div>
                          :
                          <div className={tempStyle ? "col-lg-12 mb-4 pt-5" : "col-lg-12 mb-4 pt-5 pt-lg-2"}>
                            <LogoHeading data={item.header} />
                          </div>
                      }
                      {
                        item.ads.banner_type ?
                          <div className="col-lg-12 mb-4 e-no-p">
                            {/*<AdsSectionBanner_1 data={item.ads.content} link={item.ads.link}/>*/}
                            <FindBannerType content={item.ads.data} details={item.ads}/>
                          </div>
                        :
                          <div className="col-lg-12 mb-4 e-no-p">
                            <FindBannerType content={[item.ads]} details={item.ads} defaultType={1}/>
                          </div>
                      }
                      {/*<div className="col-lg-12 mb-4 e-no-p">
                        <CardFourColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} subcategory={true}  id={item.header.category} />
                      </div>*/}
                      {
                        item.data.content && item.data.type==1 ?
                          <div className="col-lg-12 mb-3 e-no-p">
                            <CardFourColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} local={true} subcategory={true}/>
                          </div>
                        : item.data.content && item.data.type==2 ?
                          <div className="col-lg-12 mb-4 e-no-p">
                            <CardSixColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} local={true} subcategory={true}/>
                          </div>
                        : item.data.content && item.data.type==3 ?
                        <div className="col-lg-12 mb-4 e-no-p">
                          <CardTwoColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} local={true} subcategory={true}/>
                        </div>
                        : item.data.content && item.data.type==4 ?
                        <div className="col-lg-12 mb-4 e-no-p">
                          <CardThreeColumn data={item.data.content} type={item.data.style.value} bg_color={item.header.bg_color} local={true} subcategory={true}/>
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
                  <span className="e-section-bg-half" style={{
                    background:
                    item.data.type == 2 ||
                    item.data.type == 4 ||
                    item.data.type == 6 ||
                    item.data.type == 8 ||
                    item.data.type == 10 ||
                    item.data.type == 12 ||
                    item.data.type == 14 ?
                    item.header.bg_color :
                    "#fff"}}></span>
                </section>
              );
            })
          }
         {
           this.state.recents && this.state.recents.content && this.state.recents.content.length?
           <section className="container-fluid position-relative e-testimonials-outer">
           <div className="container">
             <div className="row pt-5 mb-5">
               <div className="col-lg-12 mb-5">
                 <H2 data="Recently Viewed" type={15} bg_color="#D9D9D9"/>
               </div>
                 <div className="col-lg-12 mb-3 e-no-p mt-lg-3">
                   <CardFourColumn data={this.state.recents.content} type={this.state.recents.style.value} category={true} local={true}/>
                 </div>
             </div>
           </div>
           {/* <span className="e-section-bg-half" style={{background: "rgba(255, 74, 49, 0.1)"}}></span> */}
         </section>
         :null
         }

          <section className="container-fluid position-relative e-testimonials-outer">
            <div className="container">
              <div className="row pt-5 mb-5">
                <div className="col-lg-12 mb-4">
                  <H2 data="testimonials" type={16}/>
                </div>
                <div className="col-lg-12 mb-5">
                 <Testimonials/>
                </div>
              </div>
            </div>
            <span className="e-section-bg-half" style={{background: "rgba(255, 74, 49, 0.1)"}}></span>
          </section>

          {/* Subscription */}
          <Subscription/>
          <OffersModal data={this.state.popup}/>


        </div>
      );
    }
    else{
      return(
        <div>
          {
            this.state.dataLoading ?
              <PageLoader/>
            :
              // <NothingFound/>
              <WorkingProgress data1={"Local market site in under construction; please check back after some time."} data2={"Thank you for your patience."}/>
          }
          <OffersModal data={this.state.popup}/>
        </div>
      );
    }
  }
}

export default LocalHome;
