
/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*Components*/

import PageLoader from '../../components/common/page-loader.js';
import NothingFound from '../../components/common/nothing.js';
import GetURLVar from '../../services/getParams/get-url-var';
// import PhoneValidation from '../../services/validation/phone';
// import EmailValidation from '../../services/validation/email';


/*Assets*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';


class Profile extends Component {
  constructor(props){
    super(props);
    this.state = {
       tabIndex:1



    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

  }
   tabItemClick(tempItem){
    this.setState({tabIndex: tempItem});
  }


  render() {


      return (
        <div className="container-fluid p-0">
          <section className="container-fluid position-relative p-0 e-profile-banner">
            <div className="container">
              <div className="row mb-5">
                <div className="col-lg-12 mb-5">
                  <h1 className="text-uppercase">john doe.</h1>
                </div>
              </div>
            </div>
          </section>

          <section className="e-profile">
            <div className="container">
              <div className="row mt-5 pt-2">
                 <div className="col-md-12">

                    <div className="row">
                      <div className="col-md-12">
                        {
                          this.state.tabIndex === 1 ?
                            <span className="e-tabs-item e-active text-uppercase mr-4">Profile</span>
                          :
                            <span onClick={this.tabItemClick.bind(this, 1)} className="e-color-inactive text-uppercase mr-4">Profile</span>
                        }

                        {
                          this.state.tabIndex === 2 ?
                            <span className="e-tabs-item e-active text-uppercase mr-4">favourites</span>
                          :
                            <span onClick={this.tabItemClick.bind(this, 2)} className="e-color-inactive text-uppercase">favourites</span>
                        }
                      </div>
                    </div>

                    {
                      this.state.tabIndex === 1 ?
                        <React.Fragment>
                          <h5 className="pt-5 pb-3">Personal Details</h5>
                          <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-8 col-8">
                              <div className="e-profile-detail-wrap">
                                <div className="e-profile-content">
                                  <p>Phone:<span> +91 7845648794</span></p>
                                  <p>Email:<span> johndoe@gmail.com</span></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      :
                        null
                    }


                    {
                      this.state.tabIndex === 2 ?
                        <div>

                          <h5 className="pt-5 pb-3">My Favourites</h5>




                        </div>
                      :
                        null
                    }


                  </div>
              </div>
            </div>
          </section>
        </div>
      );
    }
  }


export default Profile;


