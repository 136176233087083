/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';


/*Components*/

/*Assets*/


class SetLocation extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    $("#setLocationsModal").modal({ backdrop: 'static', keyboard: false });
  }


  render() {
    return (
      <section className="e-select-location-bg">
        
      </section>
    );
  }
}

export default SetLocation;
