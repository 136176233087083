/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import {Map, GoogleApiWrapper, Marker } from 'google-maps-react';

/*Components*/



const mapStyles = {
  width: '100%',
  height: '100%'
};

export class MapContainer extends Component {
  constructor(props) {
    super(props);


  }



  render() {
    return (
        <Map
          google={this.props.google}
          zoom={8}
          style={mapStyles}
          initialCenter={{ lat: 13.031688564623453, lng: 77.57006818371929}}
        >
          <Marker position={{ lat: 13.031688564623453, lng: 77.57006818371929}} />
        </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBkegLLVL975pjBeseCJKW97pbQrzGbz6Y'
})(MapContainer);