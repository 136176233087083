
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';


/*Assets*/
import VideoPlayer from '../../../components/common/video';
import banner from '../../../assets/images/online/section-banner-2.svg';
import category1 from '../../../assets/images/online/section-cat-img-1.png';
import category2 from '../../../assets/images/online/section-cat-img-2.png';
import category3 from '../../../assets/images/online/section-cat-img-3.png';

class ThreeFourthCategory extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  adAnalytics(tempId){
    if(tempId){
      var url = devconfig.userV1+"/ads/update-analytics";
      var data = JSON.stringify({
        "ads_id" : Number(tempId),
        "clicks" : 1,
        "views" : 1
      })
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          
        }
      }.bind(this))
    }
  }

  render() {
    const linkVerify = (item)=>{
      var tempLink = item;
      if (item && !item.match(/^http([s]?):\/\/.*/)){
        tempLink = 'http://' + item;
        return tempLink
      }
      else{
        return item
      }
    }


    var tempId1 = null;
    if(this.props.data[0]){
      if(this.props.data[0].id){
        tempId1 = this.props.data[0].id;
      }
    }

    var tempId2 = null;
    if(this.props.data[1]){
      if(this.props.data[1].id){
        tempId2 = this.props.data[1].id;
      }
    }

    var tempId3 = null;
    if(this.props.data[2]){
      if(this.props.data[2].id){
        tempId3 = this.props.data[2].id;
      }
    }

    var tempId4 = null;
    if(this.props.data[3]){
      if(this.props.data[3].id){
        tempId4 = this.props.data[3].id;
      }
    }
    

    return (
      <div className="row e-ThreeFourthCategory-wrap">
        <div className="col-lg-8 col-12">
          {
            this.props.data && this.props.data[0] ?
              <a href={linkVerify(this.props.data[0].link)} onClick={this.adAnalytics.bind(this, tempId1)} target="_blank">
                {
                  this.props.data[0].content && this.props.data[0].content.indexOf(".mp4") > -1 ?
                    <VideoPlayer url={this.props.data[0].content} />
                  :
                    <img className="e-banner-wrap" src={this.props.data[0].content} alt="ads banner" style={{height:"420px"}}/>
                }
              </a>
            :
              null
          }
          
        </div>
        <div className="col-lg-4 pt-4 pt-lg-0">
          {
            this.props.data && this.props.data[1] ?
              <a href={linkVerify(this.props.data[1].link)} onClick={this.adAnalytics.bind(this, tempId2)} target="_blank">
                {
                  this.props.data[1].content && this.props.data[1].content.indexOf(".mp4") > -1 ?
                    <VideoPlayer url={this.props.data[1].content} />
                  :
                    <img className="e-banner-wrap" src={this.props.data[1].content} alt="Section Category" style={{height:"120px"}}/>
                }
              </a>
            :
              null
          }

          {
            this.props.data && this.props.data[2] ?
              <a href={linkVerify(this.props.data[2].link)} onClick={this.adAnalytics.bind(this, tempId3)} target="_blank">
                {
                  this.props.data[2].content && this.props.data[2].content.indexOf(".mp4") > -1 ?
                    <VideoPlayer url={this.props.data[2].content} />
                  :
                    <img className="e-banner-wrap" src={this.props.data[2].content} alt="Section Category" style={{height:"120px"}}/>
                }
              </a>
            :
              null
          }

          {
            this.props.data && this.props.data[3] ?
              <a href={linkVerify(this.props.data[3].link)} onClick={this.adAnalytics.bind(this, tempId3)} target="_blank">
                {
                  this.props.data[3].content && this.props.data[3].content.indexOf(".mp4") > -1 ?
                    <VideoPlayer url={this.props.data[3].content} />
                  :
                    <img className="e-banner-wrap" src={this.props.data[3].content} alt="Section Category" style={{height:"120px"}}/>
                }
              </a>
            :
              null
          }
        </div>
      </div>
    );
  }
}

export default ThreeFourthCategory;
