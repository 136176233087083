
/*Package importing*/
import React, { Component } from 'react';

/*Assets*/

class WorkingProgress extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  render() {

    return (
      <div className="e-work-progess text-center">
        <p className="mb-1">{this.props.data1}</p>
        <p>{this.props.data2}</p>
      </div>
    );
  }
}

export default WorkingProgress;
