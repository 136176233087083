
/*Package importing*/
import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*Assets*/
import Banner1 from '../../assets/images/online/RecentlyViewed-1.png';
import Banner2 from '../../assets/images/online/RecentlyViewed-2.png';
import Banner3 from '../../assets/images/online/RecentlyViewed-3.png';
import Banner4 from '../../assets/images/online/RecentlyViewed-4.png';


class RecentlyViewed extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  render() {
    var settings = {
      infinite: false,
      slidesToShow: 4,
      arrow: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          }
        }
      ]
    };

    return (
      <Slider className="e-recent-viewed-wrap" {...settings}>
        <div>
          <img src={Banner1} alt="Truekhoj banner"/>
          <p>flight booking</p>
        </div>
        <div>
          <img src={Banner2} alt="Truekhoj banner"/>
          <p>smart watches</p>
        </div>
        <div>
          <img src={Banner3} alt="Truekhoj banner"/>
          <p>refrigerators</p>
        </div>
        <div>
          <img src={Banner4} alt="Truekhoj banner"/>
          <p>sports shoes</p>
        </div>
        <div>
          <img src={Banner3} alt="Truekhoj banner"/>
          <p>refrigerators</p>
        </div>
        <div>
          <img src={Banner4} alt="Truekhoj banner"/>
          <p>sports shoes</p>
        </div>
      </Slider>
    );
  }
}

export default RecentlyViewed;
