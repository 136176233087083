/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import MapContainer from '../../../components/map/direction.js';
import About from './about.js';
import Review from './review.js';
import AdsHeroBanner from '../../../components/ads/banners/hero-banner.js';
import AdsFourColumn_One from '../../../components/ads/list/four-column-style-1.js';
import LocalStoreStrip from '../../../components/ads/strip-banners/localstore-strips.js';
import AdsSectionBanner_1 from '../../../components/ads/banners/section-banner-1.js';
import AdsSectionBanner_2 from '../../../components/ads/banners/section-banner-2.js';
import RecentlyViewed from '../../../components/sliders/recently-viewed.js';
import Testimonials from '../../../components/sliders/testimonials.js';
import Subscription from '../../../components/common/subscription.js';
import PageLoader from '../../../components/common/page-loader.js';
import NothingFound from '../../../components/common/nothing.js';
import FindBannerType from '../../../components/ads/banners/findBannerType';
import CardSixColumn from '../../../components/cards/column-6.js';
import CardTwoColumn from '../../../components/cards/column-2.js';
import CardThreeColumn from '../../../components/cards/column-3.js';
import H2 from '../../../components/headings/h2.js';
import CardFourColumn from '../../../components/cards/column-4.js';



/*Assets*/
import Img from '../../../assets/images/online/login-img.svg';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';
import ButtonLoader from '../../../components/common/buttonLoader.js';
import WorkingProgress from '../../../components/common/working-progress.js';


class Store extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            apiLoading: false,
            error: ""
        }
    }

    componentDidMount() {
        GetURLVar().then(function (query) {
            if (query) {
                this.getLayout(query.id);
                this.setState({ id: query.id });
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }.bind(this));
    }

    getLayout(id) {
        this.setState({ dataLoading: true });
        var url = devconfig.localUser + "/layout/get-layout";
        var data = JSON.stringify({
            "vendor_id": id
        })
        APIPost(url, data).then(function (response) {
            if (response.status === "ok" && response.data && response.data.layout && response.data.layout.header) {
                this.setState({ data: response.data.layout, dataLoading: false, info: response.data });
            }
            else {
                this.setState({ dataLoading: false });
            }
        }.bind(this))
    }



    render() {


        return (
            <div className="container-fluid p-0">
                {
                    this.state.dataLoading ?
                        <div className="row pt-5">
                            <div className="col-lg-12 pt-5">
                                <div className="d-flex">
                                    <PageLoader />
                                </div>
                            </div>
                        </div>
                        : this.state.data ?
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-lg-12 py-3" style={{ backgroundColor: this.state.data && this.state.data.header && this.state.data.header.banner_bg ? this.state.data.header.banner_bg : "#B5121A" }}>
                                        <div className="py-3">
                                            <H2 data={this.state.data.header.content} type={this.state.data.header.type} bg_color={this.state.data.header.bg_color} font_color={this.state.data.header.font_color} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <FindBannerType content={this.state.data.banner.data} details={this.state.data.banner} />
                                    </div>
                                </div>

                                {this.state.data.layout && this.state.data.layout.collections && this.state.data.layout.collections.data.content ?
                                    <div className="container">
                                        <div className="row py-5">
                                            <div className="col-lg-12 py-3">
                                                <div className="py-3">
                                                    <H2 data={this.state.data.layout.collections.header.content} type={this.state.data.layout.collections.header.type} bg_color={this.state.data.layout.collections.header.bg_color} font_color={this.state.data.layout.collections.header.font_color} />
                                                </div>
                                            </div>
                                            {
                                                this.state.data.layout.collections.data.type == 1 ?
                                                    <div className="col-lg-12 mb-3 e-no-p">
                                                        <CardFourColumn data={this.state.data.layout.collections.data.content} type={this.state.data.layout.collections.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                    </div>
                                                    : this.state.data.layout.collections.data.type == 2 ?
                                                        <div className="col-lg-12 mb-4 e-no-p">
                                                            <CardSixColumn data={this.state.data.layout.collections.data.content} type={this.state.data.layout.collections.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                        </div>
                                                        : this.state.data.layout.collections.data.type == 3 ?
                                                            <div className="col-lg-12 mb-4 e-no-p">
                                                                <CardTwoColumn data={this.state.data.layout.collections.data.content} type={this.state.data.layout.collections.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                            </div>
                                                            : this.state.data.layout.collections.data.type == 4 ?
                                                                <div className="col-lg-12 mb-4 e-no-p">
                                                                    <CardThreeColumn data={this.state.data.layout.collections.data.content} type={this.state.data.layout.collections.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                </div>
                                                                :
                                                                null
                                            }
                                        </div>
                                    </div> : null}

                                {this.state.data.layout && this.state.data.layout.newArrivals && this.state.data.layout.newArrivals.banner.data ?
                                    <div className="row m-0 position-relative">
                                        <div className="col-lg-12 position-absolute" style={{ backgroundColor: "#B5121A", height: "300px", bottom: 0, left: 0 }}></div>
                                        <div className="container">
                                            <div className="row py-5">
                                                <div className="col-lg-12 py-3">
                                                    <div className="py-3">
                                                        <H2 data={this.state.data.layout.newArrivals.header.content} type={this.state.data.layout.newArrivals.header.type} bg_color={this.state.data.layout.newArrivals.header.bg_color} font_color={this.state.data.layout.newArrivals.header.font_color} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <FindBannerType content={this.state.data.layout.newArrivals.banner.data} details={this.state.data.layout.newArrivals.banner} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}

                                {this.state.data.layout && this.state.data.layout.offers && this.state.data.layout.offers.banner.data ?
                                    <div className="container">
                                        <div className="row py-5">
                                            <div className="col-lg-12 py-3">
                                                <FindBannerType content={this.state.data.layout.offers.banner.data} details={this.state.data.layout.offers.banner} />
                                            </div>
                                        </div>
                                    </div> : null}

                                {
                                    this.state.data.layout && this.state.data.layout.featured && this.state.data.layout.featured.banner.data ?
                                        <div className="container pb-3">
                                            <div className="row py-5">
                                                <div className="col-lg-12 py-3">
                                                    <div className="py-3">
                                                        <H2 data={this.state.data.layout.featured.header.content} type={this.state.data.layout.featured.header.type} bg_color={this.state.data.layout.featured.header.bg_color} font_color={this.state.data.layout.featured.header.font_color} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <FindBannerType content={this.state.data.layout.featured.banner.data} details={this.state.data.layout.featured.banner} />
                                                </div>
                                            </div>
                                        </div> : null}

                                {
                                    this.state.data.layout && this.state.data.layout.last && this.state.data.layout.last.data.content ?
                                        <div className="row m-0" style={{ backgroundColor: "#FFDADC" }}>
                                            <div className="container">
                                                <div className="row py-5">
                                                    <div className="col-lg-12 py-3">
                                                        <div className="py-3">
                                                            <H2 data={this.state.data.layout.last.header.content} type={this.state.data.layout.last.header.type} bg_color={this.state.data.layout.last.header.bg_color} font_color={this.state.data.layout.last.header.font_color} />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.data.layout.last.data.type == 1 ?
                                                            <div className="col-lg-12 mb-3 e-no-p">
                                                                <CardFourColumn data={this.state.data.layout.last.data.content} type={this.state.data.layout.last.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                            </div>
                                                            : this.state.data.layout.last.data.type == 2 ?
                                                                <div className="col-lg-12 mb-4 e-no-p">
                                                                    <CardSixColumn data={this.state.data.layout.last.data.content} type={this.state.data.layout.last.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                </div>
                                                                : this.state.data.layout.last.data.type == 3 ?
                                                                    <div className="col-lg-12 mb-4 e-no-p">
                                                                        <CardTwoColumn data={this.state.data.layout.last.data.content} type={this.state.data.layout.last.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                    </div>
                                                                    : this.state.data.layout.last.data.type == 4 ?
                                                                        <div className="col-lg-12 mb-4 e-no-p">
                                                                            <CardThreeColumn data={this.state.data.layout.last.data.content} type={this.state.data.layout.last.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                        </div>
                                                                        :
                                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div> : null}

                                {
                                    this.state.data.layout && this.state.data.layout.deals && this.state.data.layout.deals.banner.data ?
                                        <div className="container">
                                            <div className="row py-5">
                                                <div className="col-lg-12 py-3">
                                                    <div className="py-3">
                                                        <H2 data={this.state.data.layout.deals.header.content} type={this.state.data.layout.deals.header.type} bg_color={this.state.data.layout.deals.header.bg_color} font_color={this.state.data.layout.deals.header.font_color} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <FindBannerType content={this.state.data.layout.deals.banner.data} details={this.state.data.layout.deals.banner} />
                                                </div>
                                            </div>
                                        </div> : null}

                                {
                                    this.state.data.layout && this.state.data.layout.budgetBuys && this.state.data.layout.budgetBuys.data.content ?
                                        <div className="container pb-3">
                                            <div className="row py-5">
                                                <div className="col-lg-12 py-3">
                                                    <div className="py-3">
                                                        <H2 data={this.state.data.layout.budgetBuys.header.content} type={this.state.data.layout.budgetBuys.header.type} bg_color={this.state.data.layout.budgetBuys.header.bg_color} font_color={this.state.data.layout.budgetBuys.header.font_color} />
                                                    </div>
                                                </div>
                                                {
                                                    this.state.data.layout.budgetBuys.data.type == 1 ?
                                                        <div className="col-lg-12 mb-3 e-no-p">
                                                            <CardFourColumn data={this.state.data.layout.budgetBuys.data.content} type={this.state.data.layout.budgetBuys.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                        </div>
                                                        : this.state.data.layout.budgetBuys.data.type == 2 ?
                                                            <div className="col-lg-12 mb-4 e-no-p">
                                                                <CardSixColumn data={this.state.data.layout.budgetBuys.data.content} type={this.state.data.layout.budgetBuys.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                            </div>
                                                            : this.state.data.layout.budgetBuys.data.type == 3 ?
                                                                <div className="col-lg-12 mb-4 e-no-p">
                                                                    <CardTwoColumn data={this.state.data.layout.budgetBuys.data.content} type={this.state.data.layout.budgetBuys.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                </div>
                                                                : this.state.data.layout.budgetBuys.data.type == 4 ?
                                                                    <div className="col-lg-12 mb-4 e-no-p">
                                                                        <CardThreeColumn data={this.state.data.layout.budgetBuys.data.content} type={this.state.data.layout.budgetBuys.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                    </div>
                                                                    :
                                                                    null
                                                }
                                            </div>
                                        </div> : null}

                                {
                                    this.state.data.layout && this.state.data.layout.moreBrands && this.state.data.layout.moreBrands.data.content ?
                                        <div className="row m-0" style={{ backgroundColor: "#FFDADC" }}>
                                            <div className="container">
                                                <div className="row py-5">
                                                    <div className="col-lg-12 py-3">
                                                        <div className="py-3">
                                                            <H2 data={this.state.data.layout.moreBrands.header.content} type={this.state.data.layout.moreBrands.header.type} bg_color={this.state.data.layout.moreBrands.header.bg_color} font_color={this.state.data.layout.moreBrands.header.font_color} />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.data.layout.moreBrands.data.type == 1 ?
                                                            <div className="col-lg-12 mb-3 e-no-p">
                                                                <CardFourColumn data={this.state.data.layout.moreBrands.data.content} type={this.state.data.layout.moreBrands.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                            </div>
                                                            : this.state.data.layout.moreBrands.data.type == 2 ?
                                                                <div className="col-lg-12 mb-4 e-no-p">
                                                                    <CardSixColumn data={this.state.data.layout.moreBrands.data.content} type={this.state.data.layout.moreBrands.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                </div>
                                                                : this.state.data.layout.moreBrands.data.type == 3 ?
                                                                    <div className="col-lg-12 mb-4 e-no-p">
                                                                        <CardTwoColumn data={this.state.data.layout.moreBrands.data.content} type={this.state.data.layout.moreBrands.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                    </div>
                                                                    : this.state.data.layout.moreBrands.data.type == 4 ?
                                                                        <div className="col-lg-12 mb-4 e-no-p">
                                                                            <CardThreeColumn data={this.state.data.layout.moreBrands.data.content} type={this.state.data.layout.moreBrands.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                        </div>
                                                                        :
                                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div> : null}

                                        {
                                    this.state.data.layout && this.state.data.layout.productList && this.state.data.layout.productList.data.content ?
                                        <div className="row m-0" >
                                            <div className="container">
                                                <div className="row py-5">
                                                    <div className="col-lg-12 py-3">
                                                        <div className="py-3">
                                                            <H2 data={this.state.data.layout.productList.header.content} type={this.state.data.layout.productList.header.type} bg_color={this.state.data.layout.productList.header.bg_color} font_color={this.state.data.layout.productList.header.font_color} />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.data.layout.productList.data.type == 1 ?
                                                            <div className="col-lg-12 mb-3 e-no-p">
                                                                <CardFourColumn data={this.state.data.layout.productList.data.content} type={this.state.data.layout.productList.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                            </div>
                                                            : this.state.data.layout.productList.data.type == 2 ?
                                                                <div className="col-lg-12 mb-4 e-no-p">
                                                                    <CardSixColumn data={this.state.data.layout.productList.data.content} type={this.state.data.layout.productList.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                </div>
                                                                : this.state.data.layout.productList.data.type == 3 ?
                                                                    <div className="col-lg-12 mb-4 e-no-p">
                                                                        <CardTwoColumn data={this.state.data.layout.productList.data.content} type={this.state.data.layout.productList.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                    </div>
                                                                    : this.state.data.layout.productList.data.type == 4 ?
                                                                        <div className="col-lg-12 mb-4 e-no-p">
                                                                            <CardThreeColumn data={this.state.data.layout.productList.data.content} type={this.state.data.layout.productList.data.style.value} bg_color={"#ffffff"} local={true} product={true} />
                                                                        </div>
                                                                        :
                                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div> : null}

                                <section className="e-section-about pt-4 mt-2 mb-5 pt-5">
                                    <About id={this.state.id} info={this.state.data.layout.description} />
                                </section>
                                <section className="e-section-review pt-4 mt-2 mb-5 pt-5">
                                    <Review id={this.state.id} reloadPage={() => this.getLayout(this.state.id)} isFavourite={this.state.info ? this.state.info.is_favourite : null} />
                                </section>
                            </div>
                            :
                            <div className="container-fluid pt-5 text-center mt-lg-5">
                                <WorkingProgress data1={"Vendor store page in under construction. Please check back after some time."} data2={"Thank you for your patience."} />
                            </div>
                }
            </div>
        );
    }
}

export default Store;

