
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Assets*/

class NewCardFourColumnTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,

        }
    }

    componentDidMount() {
        this.setState({ type: this.props.type });
    }

    render() {



        return (
            <div className="w-100">
                {
                    this.props.data && this.props.data.length ?
                        <div className="row mx-n2">
                            <div className="col-lg-8 col-md-8 col-sm-12 px-2">
                                <div className="row my-2 mx-n2">
                                    {
                                        this.props.data.map((data, index) => {
                                            if (index < 3) {
                                                return (
                                                    <div className={index===0? "col-lg12 col-md-12 col-sm-12 col-12  py-2 h-100 px-2": "col-lg-6 col-md-6 col-sm-12 col-12 py-2 h-100 px-2"}>
                                                    <Link to={`/local/sub-category?id=${data.id}`}>
                                                        <div className={index===0? "e-new-col-img-wrapper-5 e-new-col-wrapper-small-5 position-relative":"e-new-col-img-wrapper-5 e-new-col-wrapper-med-5 position-relative"}>
                                                            <img className="e-new-col-img position-absolute" src={data.img} alt="ads banner" />
                                                            <div className="position-absolute e-new-col-label">{data.title}</div>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 px-2">
                                <div className="row my-n2">
                                    {
                                        this.props.data.map((data, index) => {
                                            if (index > 2) {
                                                return (
                                                    <div className="col-12 py-2 h-100">
                                                    <Link to={`/local/sub-category?id=${data.id}`}>
                                                        <div className="e-new-col-img-wrapper-5 position-relative">
                                                            <img className="e-new-col-img position-absolute" src={data.img} alt="ads banner" />
                                                            <div className="position-absolute e-new-col-label">{data.title}</div>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        );
    }
}

export default NewCardFourColumnTwo;
