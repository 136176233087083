
/*Package importing*/
import React, { Component } from 'react';

/*Assets*/
import localStoreBanner from '../../../assets/images/online/local-store-strip.svg';



class LocalStoreStrip extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12 e-local-strip-wrap">
          <img src={localStoreBanner} alt="Local stores banner" className="e-cursor-pointer" onClick={()=>window.location.href="/local"}/>
        </div>
      </div>
    );
  }
}

export default LocalStoreStrip;
